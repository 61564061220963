import React from "react";
import DatePicker from "react-datepicker";

import Chip from "../Chip/Chip";

type Props = {
  label: string;
  aside?: any;
  selected: any;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  optional?: boolean;
  required?: boolean;
  disabled?: boolean;
  onChange: any;
};

export default function FormDatePicker(props: any) {
  return (
    <div className={`input input--date ${props.required ? "is-required" : ""} ${props.disabled ? "is-disabled" : ""}`}>
      <div className="input__top">
        <label className="input__label">{ props.label }</label>
        {(props.aside || props.optional) && (
          <div className="input__aside">
            {props.aside && props.aside}
            {props.optional && <Chip status={4} label="Optional" />}
          </div>
        )}
      </div>
      <DatePicker
        {...props}
        calendarStartDay={1}
        popperModifiers={{
          preventOverflow: {
            enabled: true,
          }
        }}
      />
    </div>
  );
}
