import React from "react";

type Props = {
  children: any;
};

export default function AuthRoute(props: Props) {
  return (
    <div className="auth">
      <div className="auth__box">
        {props.children}
      </div>
      <p className="auth__extra-info">For info and questions:<br /><a href="mailto:g.vanengelenburg@gosselin-moving.com">g.vanengelenburg@gosselin-moving.com</a></p>
    </div>
  );
}
