import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import relationshipLabels from "../../../src/assets/data/relationship-labels.json";

import GlobalData from '../../context/globaldata';

import Button from "../../components/Button/Button";

export default function Result(props: any) {
  const history = useHistory();
  const context = useContext(GlobalData);

  const renderTypes = () => {
    const types = [];
    let typeIndex = 0;
    for (const type in props.result.type) {
      if (props.result.type[type]) {
        types.push(<span key={ typeIndex }>{ type }</span>);
      }

      typeIndex++;
    }

    return (
      <p className="result__types">
        { types }
      </p>
    );
  };

  return (
    <li className={`result result--suppliers ${props.result.status?.inactive ? "is-inactive" : ""}`}>
      <Link className="result__anchor" to={`suppliers/${ props.result.uid }`}>
        { props.result.relationship > 0 &&
          <>
            <ReactTooltip place="top" effect="solid" delayShow={500} />
            <div className="result__relationship" data-tip={ relationshipLabels[props.result.relationship].label }>
              { props.result.relationship }
            </div>
          </>
        }
        <div className="result__content">
          <div className="result__heading">
            { props.result.alias ? props.result.alias : props.result.car.Client }
          </div>
          <div className="result__subheading">
            <span>{ props.result.car.CompanyAddress.GENCITY }, { props.result.car.CompanyAddress.GENLAND }</span>
            {/* { context.currentUser.custom?.admin && <Rating rating={ props.result.rating } ratingCount={ props.result.ratingCount } /> } */}
          </div>
          <div className="result__subtitle result__subtitle--warning">
            <span>{ props.result.subtitle }</span>
          </div>
        </div>
        <div className="result__aside">
          { renderTypes() }
          {/* { !context.currentUser.custom?.admin && <Rating rating={ props.result.rating } ratingCount={ props.result.ratingCount } /> } */}
        </div>
      </Link>
      { context.currentUser.custom?.admin && <Button onClick={ () => history.push(`suppliers/${ props.result.uid }?edit=true`) } variant="ghost">View supplier</Button> }
    </li>
  )
}
