import React from "react";
import { ReactComponent as IconInfo } from "../../assets/images/icons/info.svg";

export default [
  {
    "date": "Apr 7, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Fixed an issue on suppliers overview page with incorrect types showing while these types are not available.</li>
          <li>Added loading date and delivery date approximate checkbox. Now the rate request form will have an open input field when "Approximate date" is selected. You can enter any form text in there.</li>
          <li>Added inclusions details for each selected rate request mode in supplier response screen.</li>
        </ul>
      </>
    ),
  },
  {
    "date": "May 4, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Updated all links to terms and conditions in the app and emails to reference the new URL.</li>
          <li>Updated all suppliers without an assigned relationship to status "inactive".</li>
          <li>Updated label "Learn more" to "Inclusions" for rate request modes on the supplier response screen.</li>
          <li>Added a changelog modal and overview page that will be used to inform about the changes made in updates. The changelog will always be accessible through the account dropdown menu (top right).</li>
        </ul>
      </>
    ),
  },
  {
    "date": "May 19, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Changed the overflow behaviour in rate request submit screen to use a scrollbar when a lot of suppliers are selected.</li>
          <li>Fixed a bug with "Remind eligible suppliers" that sent the reminder to every supplier, not just eligible ones.</li>
          <li>Changed the text in emails to the new provided texts.</li>
          <li>Changed the logic for "Alternative supplier". It now uses the logic of the initial list on the "Select suppliers" screen while creating a rate request. The already selected suppliers are hidden so it will only show you the ones that aren't selected yet.</li>
          <li>Changed the text about terms & conditions and SLA on the Submit quote modal and made the message hide when submitted.</li>
        </ul>
      </>
    ),
  },
  {
    "date": "May 31, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Implemented a potential fix for data loss when multiple users use the system at the same time.</li>
        </ul>
      </>
    ),
  },
  {
    "date": "June 15, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Suppliers are now able to access the rate response screen and submit rates even after the (optionally) specified response deadline.</li>
          <li>The rate request overview screen now hides the time under the "Created" column if the rate request was not created today to prevent clutter.</li>
          <li>Added a new section "Quote options" to the rate <em>Request details screen</em> (available when rate request has been submitted) to show all the available quote options.</li>
        </ul>
      </>
    ),
  },
  {
    "date": "June 24, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Changed the CAR API endpoints in the backend to keep synchronisation working in the future.</li>
          <li>Changed the response deadline to two business days from today, taking weekends into consideration.</li>
          <li>Fixed an issue that prevented (fixed) loading and delivery dates showing in quotes when no date was specified in response.</li>
          <li>Fixed an issue that prevented file attachments to show up during rate request creation.</li>
        </ul>
      </>
    ),
  },
  {
    "date": "July 5, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Fixed and optimised the background logic for loading and saving rate requests.</li>
          <li>Fixed an issue that prevented file uploads to be added to the attachments list.</li>
          <li>Fixed an issue that caused too many save events while editing notes.</li>
          <li>Fixed an issue that displayed loading and delivery dates as "invalid date".</li>
        </ul>
      </>
    ),
  },
  {
    "date": "August 2, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Fixed CAR sync script crashes due to insufficient memory allocated.</li>
          <li>Fixed an issue that prevented the screen to scroll to missed required fields.</li>
          <li>Changed behaviour of expired quotes. These are now still clickable when expired to see and adjust details.</li>
          <li>Added more logging to functions to see when errors occur during email dispatch.</li>
          <li>Added a feature to the pagination component that remembers which page you were on when navigating back.</li>
          <li>Added a feature to the search component that remembers the last search query when navigating back.</li>
          <li>Added a modal for detailed information about rate request modes. This modal is accessible when clicking the info "<IconInfo fill="#000" />" icon next to the label "Rate request mode" on the rate request creation screen under "Quotation options".</li>
        </ul>
      </>
    ),
  },
  {
    "date": "August 26, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Fixed a recurring issue with how dates are parsed in the app. There were cases where improper parsing caused the app to crash. Please report any crashes that you encounter along with the URL where this occured.</li>
        </ul>
      </>
    ),
  },
  {
    "date": "September 30, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Reverted a feature of the search component that remembered the last search query when navigating back.</li>
        </ul>
      </>
    ),
  },
  {
    "date": "November 4, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>For performance reasons temporarily disabled the loading of meta data in the supplier select screen when creating new rate requests and when viewing alternatives.</li>
        </ul>
      </>
    ),
  },{
    "date": "November 25, 2022",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Reverted a feature of the search component on the suppliers overview screen that remembered the last search query when navigating back.</li>
          <li>Updated the autocomplete API to correctly handle countries with a new name. For example Turkey was renamed to Türkiye and caused some issues with supplier matching.</li>
          <li>Added the Notes section to the single quote details screen. Notes were previously only visible on the rate request details screen.</li>
          <li>Fixed various bugs with the autocomplete API. Should now (almost) always show the continent and some smaller countries are now available (for example: Guernsey).</li>
        </ul>
      </>
    ),
  },{
    "date": "March 28, 2023",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Fixed an issue with the autocomplete field (for adding countries). Now should show all countries correctly.</li>
          <li>Purged inactive suppliers from the database.</li>
        </ul>
      </>
    ),
  },{
    "date": "June 7, 2023",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>For performance reasons, fully disabled the loading of meta data in the supplier select screen when creating new rate requests and when viewing alternatives.</li>
          <li>The CAR API synchronization function has been rewritten to automatically sync CAR data every 2 hours.</li>
        </ul>
      </>
    ),
  },{
    "date": "July 6, 2023",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Fixed an issue with the supplier matching algorithm that prevented suppliers from showing up in the select screen when they were matched on supplier location.</li>
        </ul>
      </>
    ),
  },{
    "date": "July 14, 2023",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Updated background data synchronisation algorithms to optimize app for large data sets. Since this might bring unexpected changes, please report any broken functionalitity.</li>
        </ul>
      </>
    ),
  },{
    "date": "July 18, 2023",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Fixed an issue with the logic on the quotes overview screen. Previously would show zero results if one of the suppliers had status inactive. Now correctly only hides the affected quote, and displays the rest of the quotes.</li>
        </ul>
      </>
    ),
  },{
    "date": "July 19, 2023",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Quotes overview now displays quotes from inactive suppliers with a greyed out label and "(inactive)" appended to the name.</li>
        </ul>
      </>
    ),
  },{
    "date": "July 20, 2023",
    "summary": (
      <>
        <p><b>The SCW app has been updated with the following changes:</b></p>
        <ul>
          <li>Supplier status will now only be set to "inactive" for new suppliers during sync. Previously during sync, every supplier would be set to inactive, even if the supplier already existed in the database.</li>
        </ul>
      </>
    ),
  },{
    "date": "July 21, 2023",
    "summary": (
      <>
        <p>Lately we have been experiencing some issues causing SCW to operate very slow. This should be solved by now but please report to <a href="mailto:Gert van Engelenburg <g.vanengelenburg@gosselin-moving.com">Gert van Engelenburg</a> should you still experience SCW to operate very slow.</p>
      </>
    ),
  },{
    "date": "August 2, 2023",
    "summary": (
      <>
        <p>We have been experiencing issues with attaching files to a rate request. This is now up and running again.</p>
      </>
    ),
  },{
    "date": "November 17, 2023",
    "summary": (
      <>
        <p>We have been experiencing issues with rate requests disappearing. This should now be fixed.</p>
      </>
    ),
  },
].reverse();
