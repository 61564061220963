import React from "react";

export default {
  "rateRequestModes": [
    {
      "label": "DTD (door to door)",
      "labelLong": "Door to door",
      "labelShort": "DTD",
      "value": 0,
      "info": (<>
        <p>We expect the following services to be included, please also see notes for further info:</p>
        <ul>
          <li>Full packing of small and fragile items into cartons</li>
          <li>Normal disassembly of used furniture</li>
          <li>Full itemized English packing inventory</li>
          <li>Traditional blanket wrap loading or full export wrap, pending on option selected under packing type</li>
          <li>Loading from residence of normal access, max ground and/or 1st and/or 2nd floor</li>
          <li>Road transport to new residence</li>
          <li>Unloading into residence of normal access, max ground and/or 1st and/or 2nd floor</li>
          <li>Normal assembly of disassembled used furniture</li>
          <li>Removal of blankets or full unwrapping pending on option selected under packing type</li>
          <li>Unpacking of cartons onto nearest flat surface</li>
          <li>Removal of packing debris at time of delivery</li>
          <li>Export or import clearance if needed to be quoted separately via notes</li>
        </ul>
      </>),
    },
    {
      "label": "WTD (warehouse to door)",
      "labelLong": "Warehouse to door",
      "labelShort": "WTD",
      "value": 1,
      "info": (<>
        <p>We expect the following services to be included, please also see notes for further info:</p>
        <ul>
          <li>Pending on origin mentioned in this request include transport from our warehouse or;</li>
          <li>FOT receipt of shipment at your warehouse and warehouse handling</li>
          <li>Max 2 weeks free storage at your warehouse</li>
          <li>Road transport to new residence</li>
          <li>Unloading into residence of normal access, max ground and/or 1st and/or 2nd floor</li>
          <li>Normal assembly of disassembled used furniture</li>
          <li>Removal of blankets or full unwrapping pending on option selected under packing type</li>
          <li>Unpacking of cartons onto nearest flat surface</li>
          <li>Removal of packing debris at time of delivery</li>
          <li>Export or import clearance if needed to be quoted separately via notes</li>
        </ul>
      </>),
    },
    {
      "label": "DTW (door to warehouse)",
      "labelLong": "Door to warehouse",
      "labelShort": "DTW",
      "value": 2,
      "info": (<>
        <p>We expect the following services to be included, please also see notes for further info:</p>
        <ul>
          <li>Full packing of small and fragile items into cartons</li>
          <li>Normal disassembly of used furniture</li>
          <li>Full itemized English packing inventory</li>
          <li>Traditional blanket wrap loading or full export wrap, pending on option selected under packing type</li>
          <li>Loading from residence of normal access, max ground and/or 1st and/or 2nd floor</li>
          <li>Road transport to your warehouse and warehouse handling</li>
          <li>Max 2 weeks free storage at your warehouse</li>
          <li>FOT loading onto truck at your warehouse</li>
          <li>Pending on destination in request also include transport to our warehouse</li>
          <li>Export or import clearance if needed to be quoted separately via notes</li>
        </ul>
      </>),
    },
    {
      "label": "OS (origin services)",
      "labelLong": "Origin services",
      "labelShort": "OS",
      "value": 3,
      "info": (<>
        <p>We expect the following services to be included, please also see notes for further info:</p>
        <ul>
          <li>Full packing of small and fragile items into cartons</li>
          <li>Normal disassembly of used furniture</li>
          <li>Full itemized English packing inventory</li>
          <li>Traditional blanket wrap loading or full export wrap, pending on option selected under packing type</li>
          <li>Loading from residence of normal access, max ground and/or 1st and/or 2nd floor</li>
          <li>FOT loading onto truck at residence, we will arrange transport</li>
          <li>Export or import clearance if needed to be quoted separately via notes</li>
        </ul>
      </>),
    },
    {
      "label": "OSTR (origin services + freight)",
      "labelLong": "Origin services + freight",
      "labelShort": "OSTR",
      "value": 4,
      "info": (<>
        <p>We expect the following services to be included, please also see notes for further info:</p>
        <ul>
          <li>Full packing of small and fragile items into cartons</li>
          <li>Normal disassembly of used furniture</li>
          <li>Full itemized English packing inventory</li>
          <li>Traditional blanket wrap loading or full export wrap, pending on option selected under packing type</li>
          <li>Loading from residence of normal access, max ground and/or 1st and/or 2nd floor</li>
          <li>FOT loading onto truck at residence</li>
          <li>Transport to destination</li>
          <li>Export or import clearance if needed to be quoted separately via notes</li>
        </ul>
      </>),
    },
    {
      "label": "DS (destination services)",
      "labelLong": "Destination services",
      "labelShort": "DS",
      "value": 5,
      "info": (<>
        <p>We expect the following services to be included, please also see notes for further info:</p>
        <ul>
          <li>Gosselin to arrange transport to the residence</li>
          <li>Unloading into residence of normal access, max ground and/or 1st and/or 2nd floor</li>
          <li>Normal assembly of disassembled used furniture</li>
          <li>Removal of blankets or full unwrapping pending on option selected under packing type</li>
          <li>Unpacking of cartons onto nearest flat surface</li>
          <li>Removal of packing debris at time of delivery</li>
          <li>Export or import clearance if needed to be quoted separately via notes</li>
        </ul>
      </>),
    },
    {
      "label": "TRDS (transport + destination services)",
      "labelLong": "Transport + destination services",
      "labelShort": "TRDS",
      "value": 6,
      "info": (<>
        <p>We expect the following services to be included, please also see notes for further info:</p>
        <ul>
          <li>FOT loading at origin residence to be arrange by Gosselin</li>
          <li>Road transport to residence of destination</li>
          <li>Unloading into residence of normal access, max ground and/or 1st and/or 2nd floor</li>
          <li>Normal assembly of disassembled used furniture</li>
          <li>Removal of blankets or full unwrapping pending on option selected under packing type</li>
          <li>Unpacking of cartons onto nearest flat surface</li>
          <li>Removal of packing debris at time of delivery</li>
          <li>Export or import clearance if needed to be quoted separately via notes</li>
        </ul>
      </>),
    },
    {
      "label": "TR (transport)",
      "labelLong": "Transport",
      "labelShort": "TR",
      "value": 7,
      "info": (<>
        <p>We expect the following services to be included, please also see notes for further info:</p>
        <ul>
          <li>FOT loading at origin residence or warehouse to be arranged by Gosselin</li>
          <li>Transport from origin to destination</li>
          <li>FOT unloading at destination residence or warehouse to be arranged by Gosselin</li>
          <li>Export or import clearance if needed to be quoted separately via notes</li>
        </ul>
      </>),
    }
  ],

  "packagingTypes": [
    {
      "label": "Blanket wrap",
      "value": 0
    },
    {
      "label": "Export wrap",
      "value": 1
    }
  ],

  "transportModes" : [
    {
      "label": "Direct load",
      "value": 0
    },
    {
      "label": "Combined load",
      "value": 1
    }
  ]
}
