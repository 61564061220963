import React, { useContext, useCallback, useEffect, useState } from "react";
import { nanoid } from "nanoid";
import firebase from "firebase/app";
import { useDropzone } from "react-dropzone";
import GlobalData from "../../context/globaldata";
import Button from "../Button/Button";
import { ReactComponent as AttachmentIcon } from "../../assets/images/icons/attachment.svg";
import { ReactComponent as ThrobberIcon } from "../../assets/images/icons/throbber2.svg";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross.svg";
import Modal from "../../components/Modal/Modal";

interface IProps {
  rateRequest: RateRequest;
  forceAdmin?: boolean;
}

export default function Attachments(props: IProps) {
  const context = useContext(GlobalData);
  const isAdmin = props.forceAdmin !== undefined ? props.forceAdmin : context.currentUser.custom !== undefined;
  const [ uploadingFiles, setUploadingFiles ] = useState<Array<StrictDict>>([]);
  const [ deleteModalVisible, setDeleteModalVisible ] = useState(false);
  const [ deleteAttachmentIndex, setDeleteAttachmentIndex] = useState<number>();

  useEffect(() => {
    const uploadingFilesLeft = uploadingFiles.filter(uploadingFile => {
      return !props.rateRequest.attachments?.some((file: any) => {
        return uploadingFile.name === file.name;
      });
    });

    // console.log(uploadingFilesLeft, uploadingFiles, props.rateRequest.attachments);

    if (uploadingFilesLeft.length !== uploadingFiles.length) {
      setUploadingFiles(uploadingFilesLeft);
    }
  }, [ props.rateRequest.attachments, uploadingFiles ]);

  const onDrop = useCallback(async droppedFiles => {
    const saveAttachment = (file: StrictDict) => {
      context.firestore.collection("rate_requests").doc(props.rateRequest.uid).update({
        ...props.rateRequest,
        attachments: firebase.firestore.FieldValue.arrayUnion(file),
      });
    };

    // Perform upload
    if (context.storage) {
      droppedFiles.forEach((droppedFile: File) => {
        const fileName = droppedFile.name;
        const storageRef = context.storage.ref(`${ props.rateRequest.uid }/${ nanoid(8) }_${ fileName }`);
        setUploadingFiles([ ...uploadingFiles, droppedFile ]);

        console.log(uploadingFiles, droppedFile);

        storageRef.put(droppedFile).then((snapshot: any) => {
          const fileUrl = `https://storage.googleapis.com/${ snapshot.metadata.bucket }/${ snapshot.metadata.fullPath }`;

          console.log(snapshot);

          saveAttachment({
            name: fileName,
            url: fileUrl,
            size: snapshot.metadata.size,
            type: snapshot.metadata.type,
            contentType: snapshot.metadata.contentType,
            createdAt: snapshot.metadata.timeCreated
          });
        });
      });
    }
  }, [ context.storage, props.rateRequest, uploadingFiles, context.firestore ]);

  const deleteAttachment = () => {
    if (deleteAttachmentIndex === undefined || !props.rateRequest.attachments || !props.rateRequest.attachments[deleteAttachmentIndex]) {
      return;
    }

    const file = props.rateRequest.attachments[deleteAttachmentIndex];

    // Create a reference to the file to delete
    const desertRef = context.storage.refFromURL(file.url);

    // Delete the file
    desertRef.delete().then(() => {
      if (deleteAttachmentIndex === undefined || !props.rateRequest.attachments || !props.rateRequest.attachments[deleteAttachmentIndex]) {
        return;
      }

      context.firestore.collection("rate_requests").doc(props.rateRequest.uid).update({
        attachments: firebase.firestore.FieldValue.arrayRemove(props.rateRequest.attachments[deleteAttachmentIndex]),
      });

      setDeleteAttachmentIndex(undefined);
      setDeleteModalVisible(false);
    }).catch((error: any) => {
      // Uh-oh, an error occurred!
    });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <Modal variant="danger" title="Are you sure you want to delete this attachment?" visible={ deleteModalVisible } confirmAction={ deleteAttachment } confirmLabel="Delete attachment" cancelAction={ () => setDeleteModalVisible(false) }>
        This action can not be restored.
      </Modal>

      { isAdmin && (
        <div className="attachments__dropzone" { ...getRootProps() }>
          <Button>Choose files to upload</Button>
          <input {...getInputProps()} />
        </div>
      ) }

      { props.rateRequest.attachments && (
        <div className="attachments-list">
          { props.rateRequest.attachments.map((attachment, attachmentIndex) => (
            <div className="attachments-list__item" key={ attachmentIndex }>
              <div className="attachments-list__item-name"><AttachmentIcon />{ attachment.name }</div>
              <div className="attachments-list__item-actions">
                <Button variant="ghost" type="link" target="_blank" download={ attachment.name } href={ attachment.url }>Download</Button>
                { isAdmin && (
                  <Button variant="icon" onClick={ () => { setDeleteAttachmentIndex(attachmentIndex); setDeleteModalVisible(true); } }><CrossIcon className="button__icon" /></Button>
                ) }
              </div>
            </div>
          )) }
          { uploadingFiles.map((attachment, attachmentIndex) => (
            <div className="attachments-list__item" key={ attachmentIndex }>
              <div className="attachments-list__item-name"><AttachmentIcon />{ attachment.name }</div>
              <div className="attachments-list__item-actions"><ThrobberIcon className="attachments-list__throbber" /></div>
            </div>
          )) }
        </div>
      ) }
    </>
  );
}
