import React, { FormEvent, useContext, useState } from "react";
import { Link } from "react-router-dom";

import { Input } from "../../components/Form";
import Button from "../../components/Button/Button";
import GlobalData from "../../context/globaldata";

import { ReactComponent as IconDanger } from "../../assets/images/icons/danger.svg";
import { ReactComponent as IconSuccess } from "../../assets/images/icons/success.svg";

export default function AuthReset() {
  const context = useContext(GlobalData);
  const [ emailField, setEmailField ] = useState("");
  const [ errors, setErrors ] = useState("");
  const [ success, setSuccess ] = useState("");

  const resetPassword = (event: FormEvent) => {
    event.preventDefault();

    if (emailField) {
      context.firebaseApp.auth()
        .sendPasswordResetEmail(emailField)
        .then(() => {
          setSuccess("Password reset link has been send");
        })
        .catch((error: any) => {
          console.log(error.code);
          console.log(error.message);
          setErrors(error.message);
        });
    } else {
      setErrors("Email and password fields are required.");
    }
  };

  return (
    <>
      <h1 className="auth__heading">Reset password</h1>
      <p className="auth__description">Enter your email address below and we’ll send you instructions in how to reset your password.</p>
      <form className="auth__form" onSubmit={resetPassword}>
        <Input value={emailField} type="email" placeholder="Email address" name="email" label="Email address" onChange={(event: any) => setEmailField(event.target.value)} required />
        { errors &&
          <div className="auth__errors">
            <IconDanger className="icon" />
            { errors }
          </div>
        }
        { success &&
          <div className="auth__success">
            <IconSuccess className="icon" />
            { success }
          </div>
        }
        <Button type="submit">Reset password</Button>
        <Link className="auth__sublink" to="/login">Do you know your password? Log in</Link>
      </form>
    </>
  );
}
