import React, { useContext, useState, useEffect, useCallback, useMemo, ChangeEvent, FormEvent } from "react";
import { useLocation, useHistory } from "react-router-dom";

import GlobalData from '../../context/globaldata';

// @ts-ignore
import { store } from "react-notifications-component";

import { Header, Navigation } from "../../components/Layout";
import { Input, Select, Radio, AccentuateBox, Checkbox } from "../../components/Form";
import Button from "../../components/Button/Button";

import { ReactComponent as IconPlusSmall } from "../../assets/images/icons/plus-small.svg";
import Pagination from "../../components/Pagination/Pagination";
import ResultList from "./ResultList";
import Result from "./Result";
import Modal from "../../components/Modal/Modal";

export default function UsersOverview(props: any) {
  const history = useHistory();
  const location = useLocation();
  const context = useContext(GlobalData);
  const search = location.search;
  const params = new URLSearchParams(search);
  const paramQuery = params.get("q");
  const paramPage = params.get("page");
  const itemsPerPage = 15;

  // const searchValueStorage = localStorage.getItem(`SEARCH_${location.pathname}`);
  const searchValueStorage = '';

  const [ currentPage, setCurrentPage ] = useState<number>(paramPage ? parseInt(paramPage) : 1);
  const [ searchValue, setSearchValue ] = useState<string>(searchValueStorage ? searchValueStorage : paramQuery ? paramQuery : '');
  const [ pagedUsersResults, setPagedUsersResults ] = useState<Array<Account>>([]);

  const [ newUser, setNewUser ] = useState<StrictDict>({
    displayName: "",
    email: "",
    admin: false,
    disabled: false,
    message: null
  });

  const [ editUser, setEditUser ] = useState<StrictDict>({
    displayName: "",
    email: "",
    admin: false,
    disabled: false,
    message: null,
    sendInvite: false
  });

  const [ createModalVisible, setCreateModalVisible ] = useState<boolean>(false);
  const [ editModalVisible, setEditModalVisible ] = useState<boolean>(false);

  const navigationItems = [
    {
      to: "/rate-requests",
      label: "Rate requests"
    },
    {
      to: "/suppliers",
      label: "Suppliers"
    },
    {
      to: "/users",
      label: "Users"
    }
  ];

  const sortOptions = [
    {
      label: "Alphabetical (A-Z)",
      value: "alphabetical-asc",
      field: "displayName",
      order: "asc"
    }, {
      label: "Alphabetical (Z-A)",
      value: "alphabetical-desc",
      field: "displayName",
      order: "desc"
    }, {
      label: "E-mail (A-Z)",
      value: "email-asc",
      field: "email",
      order: "asc"
    }, {
      label: "E-mail (Z-A)",
      value: "email-desc",
      field: "email",
      order: "desc"
    }, {
      label: "Account type (asc)",
      value: "account-type-asc",
      field: "admin",
      order: "asc"
    }, {
      label: "Account type (desc)",
      value: "account-type-desc",
      field: "admin",
      order: "desc"
    }
  ];

  const [ sort, setSort ] = useState(sortOptions[0]);

  const createUser = (event: FormEvent) => {
    event.preventDefault();

    if (!newUser.email || !newUser.displayName) {
      setNewUser({...newUser, message: { type: "error", content: "Name and e-mail address fields are required." }});

      return false;
    }

    if (context.users.some((user: StrictDict) => user.email === newUser.email)) {
      setNewUser({...newUser, message: { type: "error", content: "E-mail address already used" }});

      return false;
    }

    context.firestore.collection("users").add({
      email: newUser.email,
      displayName: newUser.displayName,
      admin: (newUser.admin === "true")
    })
    .then((docRef: any) => {
      store.addNotification({
        message: "New user successfully added",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      setNewUser({
        displayName: "",
        email: "",
        admin: false,
        disabled: false,
        message: null
      });

      setCreateModalVisible(false);
    })
    .catch((error: any) => {
      console.log(error.code);
      console.log(error.message);
      store.addNotification({
        message: `Error adding new user: ${error}`,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });
    });
  };

  const saveUser = useCallback((event?: FormEvent) => {
    event?.preventDefault();

    if (!editUser.email || !editUser.displayName) {
      setEditUser({...editUser, message: { type: "error", content: "Name and e-mail address fields are required." }});

      return false;
    }

    console.log(editUser);

    const newData:StrictDict = {
      displayName: editUser.displayName,
      email: editUser.email,
      admin: (editUser.admin === "true" || editUser.admin === true),
      disabled: (editUser.disabled === "true" || editUser.disabled === true)
    };

    if (editUser.sendInvite) {
      newData.sendInvite = true;
    }

    context.firestore.collection("users").doc(editUser.uid).update(newData)
    .then(() => {
      setEditModalVisible(false);
      store.addNotification({
        message: "User successfully updated",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });
    })
    .catch((error: string) => {
      store.addNotification({
        message: `Error updating user: ${error}`,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });
    });
  }, [ editUser, context.firestore ]);

  // Search account by string
  const performSearch = useCallback((users: Array<Account>, searchValue: string): Array<Account> => {
    searchValue = searchValue.toLowerCase();

    localStorage.setItem(`SEARCH_${ location.pathname }`, searchValue);

    const ignoreProps: StrictDict = [];

    if (users.length === 0) {
      return [];
    }

    function iterateSearchObject(subject: StrictDict): boolean {
      let keys:Array<any> = [];
      let keysLength = 0;

      if (subject instanceof Array) {
        keysLength = subject.length;
      } else {
        keys = Object.keys(subject);
        keysLength = keys.length;
      }

      for (let i = 0; i < keysLength; i++) {
        let currentProperty;

        if (keys.length === 0) {
          currentProperty = subject[i];
        } else {
          currentProperty = subject[keys[i]];
        }

        if (typeof currentProperty === "object" && currentProperty !== null) {
          if (!ignoreProps.includes(keys[i])) {
            const found = iterateSearchObject(currentProperty);

            if (found) {
              return true;
            }
          }
        } else if (currentProperty !== null) {
          const value = typeof currentProperty === 'string'
                          ? currentProperty.toLowerCase()
                          : currentProperty.toString().toLowerCase();

          if (value.length > 0 && value.indexOf(searchValue) > -1) {
            return true;
          }
        }
      }

      return false;
    };

    let result:Array<Account> = users.slice();

    result = result.filter((item): boolean => {
      return iterateSearchObject(item);
    });

    return result;
  }, [ location.pathname ]);

  // Sort by selected sort field and order
  const performSort = useCallback((users: Array<Account>, sort: StrictDict): Array<Account> => {
    if (users.length === 0) {
      return [];
    }

    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

    const dotPick = (dotReference: string, item: StrictDict) => {
      if (dotReference.indexOf(".") !== -1) {
        return dotReference.split(".").reduce((o: StrictDict, i: string) => o[i], item);
      }

      return item[dotReference];
    };

    const usersFilteredSorted = users.slice();

    usersFilteredSorted.sort((a: object, b: object) => {
      [a, b] = sort.order === "asc"
                ? [a, b]
                : [b, a];

      return collator.compare(dotPick(sort.field, a), dotPick(sort.field, b));
    });

    return usersFilteredSorted;
  }, []);

  // Perform pagination
  const performPagination = useCallback((users: Array<Account>, currentPage: number): Array<Account> => {
    const startOffset = (currentPage - 1) * itemsPerPage;
    return users.slice(startOffset, startOffset + itemsPerPage);
  }, []);

  const usersResults = useMemo(() => {
    let results = performSearch(context.users, searchValue);
    results = performSort(results, sort);

    return results;
  }, [ searchValue, sort, context.users, performSort, performSearch ]);

  useEffect(() => {
    const results = performPagination(usersResults, currentPage);

    setPagedUsersResults(results);
  }, [ usersResults, currentPage, performPagination ]);

  const updateNewUser = (event: ChangeEvent<HTMLInputElement>) => {
    setNewUser({
      ...newUser,
      [event.target.name]: event.target.value
    });
  };

  const updateEditUser = (event: ChangeEvent<HTMLInputElement>) => {
    setEditUser({
      ...editUser,
      [event.target.name]: event.target.value
    });
  };

  const showEditModal = (uid: string) => {
    const selectedUser = context.users.find((user: StrictDict) => user.uid === uid);
    setEditUser(selectedUser);
    setEditModalVisible(true);
  };

  useEffect(() => {
    if (context.currentUser.custom?.uid && context.users.length > 0 && new URLSearchParams(history.location.search).get("edit") === "true") {
      setEditUser(context.users.find((user: StrictDict) => user.uid === context.currentUser.custom.uid));
      setEditModalVisible(true);
    }
  }, [ context.currentUser.custom, context.users, history.location ]);

  const inviteUser = useCallback(() => {
    setEditUser({
      ...editUser,
      sendInvite: true
    });
  }, [ editUser, setEditUser ]);

  useEffect(() => {
    if (editUser.sendInvite === true) {
      saveUser();
    }
  }, [ editUser, saveUser ]);

  return (
    <>
      <Header
        title="Users"
        leftContent={ <Navigation navItems={navigationItems} /> }
        aside={
          <>
            <Input value={searchValue} name="search" onChange={(event: any) => setSearchValue(event.target.value)} placeholder="Search for user or e-mail address" search />
            { context.currentUser.custom?.admin &&
              <Button onClick={ () => setCreateModalVisible(true) }>
                <>
                  <IconPlusSmall className="button__icon" />
                  <span>New user</span>
                </>
              </Button>
            }
          </>
        }
        logo
        accountDropdown
      />

      <div className="overview">
        <div className="container">
          <div className="card card--overview">
            <div className="group">
              <section className="xxs12">
                <div className="results">
                  <div className="results__meta group">
                    <div className="xxs12 md8">
                      <h4>{ usersResults.length } User{ usersResults.length !== 1 ? "s" : "" }</h4>
                    </div>
                    <div className="xxs12 md4">
                      <Select
                        value={sort}
                        name="demoSelect"
                        label="Sort on"
                        onChange={setSort}
                        options={sortOptions}
                        searchable={false}
                      />
                    </div>
                  </div>

                  <ResultList results={ pagedUsersResults } showEditModal={ showEditModal }>
                    <Result />
                  </ResultList>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <Pagination
        dataLength={ usersResults.length }
        itemsPerPage={ itemsPerPage }
        currentPage={ currentPage }
        setCurrentPage={ setCurrentPage }
      />

      { /* New user modal */ }
      <Modal title="Add new user" visible={ createModalVisible } confirmAction={ createUser } confirmLabel="Add new user" cancelAction={ () => setCreateModalVisible(false) } form>
        <>
          <div className="form-group">
            <Input
              value={ newUser.displayName }
              label="Name"
              placeholder="First name &amp; last name"
              name="displayName"
              onChange={ updateNewUser }
            />
          </div>
          <div className="form-group">
            <Input
              value={ newUser.email }
              label="E-mail address"
              placeholder="example@domain.com"
              name="email"
              onChange={ updateNewUser }
              type="email"
            />
          </div>
          <div className="form-group">
            <div className="input__top"><label className="input__label">Permission</label></div>
            <div className="form-group__row">
              <Radio
                checked={ newUser.admin === "false" }
                value="false"
                label="Regular access"
                name="admin"
                onChange={ updateNewUser }
              />
              <Radio
                checked={ newUser.admin === "true" }
                value="true"
                label="Admin access"
                name="admin"
                onChange={ updateNewUser }
              />
            </div>
          </div>
          { newUser.message &&
            <div className="form-group">
              <strong>Form { newUser.message.type }</strong>
              <p>{ newUser.message.content }</p>
            </div>
          }
        </>
      </Modal>

      { /* Edit modal */ }
      { context.currentUser.custom?.admin &&
        <Modal title="User settings" visible={ editModalVisible } confirmAction={ saveUser } confirmLabel="Save user" cancelAction={ () => setEditModalVisible(false) } form>
          <>
            <div className="form-group">
              <Input
                value={ editUser.displayName }
                label="Name"
                placeholder="First name &amp; last name"
                name="displayName"
                onChange={ updateEditUser }
              />
            </div>
            <div className="form-group">
              <Input
                value={ editUser.email }
                label="E-mail address"
                placeholder="example@domain.com"
                name="email"
                type="email"
                onChange={ updateEditUser }
              />
            </div>

            <div className="form-group">
              <div className="input__top"><label className="input__label">Permission</label></div>
              <div className="form-group__row">
                <Radio
                  checked={ editUser.admin === "false" || editUser.admin === false }
                  value="false"
                  label="Regular access"
                  name="admin"
                  onChange={ updateEditUser }
                />
                <Radio
                  checked={ editUser.admin === "true" || editUser.admin === true }
                  value="true"
                  label="Admin access"
                  name="admin"
                  onChange={ updateEditUser }
                />
              </div>
            </div>

            <div className="form-group">
              <AccentuateBox heading="User status" content={`User is ${ editUser.disabled || editUser.disabled === "true" ? "disabled" : "activated" }`}>
                <Checkbox value={ (editUser.disabled === "false" || editUser.disabled === false) ? "true" : "false" } checked={ editUser.disabled === "false" || editUser.disabled === false } name="disabled" onChange={ updateEditUser } switch />
              </AccentuateBox>
            </div>

            { !editUser.verified &&
              <div className="form-group">
                <div className="input__top"><label className="input__label">Reinvite user</label></div>
                <Button onClick={ () => inviteUser() } disabled={ editUser.sendInvite }>Send invitation e-mail</Button>
              </div>
            }

            { editUser.message &&
              <div className="form-group">
                <strong>Form { editUser.message.type }</strong>
                <p>{ editUser.message.content }</p>
              </div>
            }
          </>
        </Modal>
      }
    </>
  )
}
