import React, { useContext, useState, useEffect, useCallback } from "react";
import ReactTooltip from "react-tooltip";
import relationshipLabels from "../../../../src/assets/data/relationship-labels.json";

import GlobalData from "../../../context/globaldata";

import Chip from "../../../components/Chip/Chip";
import { Checkbox } from "../../../components/Form";
import Button from "../../../components/Button/Button";
import moment from "moment";
import parseDate from "../../../utils/ParseDate";

type Props = {
  supplier: Supplier;
  rateRequest: RateRequest;
  filters: StrictDict;
  alternativeQuoteOptionsResponses?: Array<QuoteOptionResponse>;
  selectSupplier?: Function;
  requestSupplier?: Function;
  isSelected?: boolean;
  labels?: StrictDict;
  isVisible?: Boolean;
};

export default function RateRequestsSelectCard(props: Props) {
  const context = useContext(GlobalData);
  const [ quoteOptionsResponses, setQuoteOptionsResponses ] = useState<Array<QuoteOptionResponse>>([]);
  const [ filteredQuoteOptionsResponses, setFilteredQuoteOptionsResponses ] = useState<Array<QuoteOptionResponse>>([]);

  const supplier = props.supplier;

  useEffect(() => {
    const thisSupplierQuoteOptionsResponses = props.alternativeQuoteOptionsResponses?.filter((quoteOptionResponse: QuoteOptionResponse) => quoteOptionResponse.rateRequestResponse?.supplierUid === supplier.uid);

    setQuoteOptionsResponses(thisSupplierQuoteOptionsResponses || []);
  }, [ props.alternativeQuoteOptionsResponses, supplier ]);

  const getAverages = useCallback((quoteOptionsResponse) => {
    // Create a list of comparable quotes if they fit the volume range and filters
    const comparableQuotes = props.alternativeQuoteOptionsResponses?.filter((otherQuoteOptionsResponse: QuoteOptionResponse) => {
      // Rate not accepted
      if (!otherQuoteOptionsResponse.accepted) {
        return false;
      }

      // No rate available
      if (!otherQuoteOptionsResponse.rate) {
        return false;
      }

      // No volume available
      if (!otherQuoteOptionsResponse.quoteOption?.volume) {
        return false;
      }

      // Different rate request mode
      if (otherQuoteOptionsResponse.quoteOption?.rateRequestMode !== quoteOptionsResponse.quoteOption?.rateRequestMode) {
        return false;
      }

      // Check if volume fits in the 20% range
      // @ts-ignore
      const currentVolume = parseInt(quoteOptionsResponse.quoteOption.volume)
      const otherQuoteOptionVolume = otherQuoteOptionsResponse.quoteOption.volume;

      // @ts-ignore
      let minVolume = parseInt(currentVolume) * .8;

      // @ts-ignore
      let maxVolume = parseInt(currentVolume) * 1.2;

      if (minVolume > (currentVolume - 2)) {
        minVolume = currentVolume - 2;
      }

      if (maxVolume < (currentVolume + 2)) {
        maxVolume = currentVolume + 2;
      }

      if (otherQuoteOptionVolume < minVolume) {
        return false;
      }

      if (otherQuoteOptionVolume > maxVolume) {
        return false;
      }

      return true;
    });

    if (!comparableQuotes?.length) {
      return;
    }

    // Calculate difference for rate and duration based on average
    // of all other rates that fit the range/filters
    let rateTotal: number = 0;
    let durationTotal: number = 0;

    comparableQuotes.forEach((otherQuoteOptionsResponse: QuoteOptionResponse) => {
      // @ts-ignore
      rateTotal += parseInt(otherQuoteOptionsResponse.rate);

      durationTotal += otherQuoteOptionsResponse.duration || 0;
    });

    return {
      rate: rateTotal / comparableQuotes.length,
      duration: durationTotal / comparableQuotes.length
    };

  }, [ props.alternativeQuoteOptionsResponses ]);

  const compareAverageDuration = useCallback((quoteOptionsResponse: QuoteOptionResponse) => {
    const averageDuration = getAverages(quoteOptionsResponse)?.duration;

    const duration = quoteOptionsResponse.duration;

    if (!averageDuration) {
      return;
    }

    if (typeof duration !== 'number') {
      return <span>Calc error</span>;
    }

    const compAverage = Math.round(averageDuration - duration);

    let message = "Average";
    const day = Math.abs(compAverage);
    let dayString = "";
    let stateClass = "compared-value";

    if (day !== 0) {
      dayString = (day === 1)
        ? "1 day"
        : `${day} days`;

      if (compAverage < 0) {
        message = " above average";
        stateClass += " compared-value--above-average";
      } else if (compAverage > 0) {
        message = " below average";
        stateClass += " compared-value--below-average";
      }
    } else {
      stateClass += " compared-value--average";
    }

    return <span className={ stateClass }>{ dayString }{ message }</span>;
  }, [ getAverages ]);

  const compareAverageRate = useCallback((quoteOptionsResponse: QuoteOptionResponse) => {
    const averageRate = getAverages(quoteOptionsResponse)?.rate;

    if (quoteOptionsResponse?.rateRequestResponse?.supplier?.relationship === 4) {
      return false;
    }

    const rate = quoteOptionsResponse.rate;

    if (!averageRate) {
      return;
    }

    if (typeof rate !== 'number') {
      return <span>Calc error</span>;
    }

    const compAverage = Math.round(averageRate - rate);

    let message = "Average";
    const rateAbs = Math.abs(compAverage);
    let rateString = "";
    let stateClass = "compared-value";

    // Only count as not-average when exceeding 10 euro threshold
    if (rateAbs > 10) {
      rateString = `€ ${rateAbs}`;

      if (compAverage < 0) {
        message = " above average";
        stateClass += " compared-value--above-average";
      } else if (compAverage > 0) {
        message = " below average";
        stateClass += " compared-value--below-average";
      }
    } else {
      stateClass += " compared-value--average";
    }

    return <span className={ stateClass }>{ rateString }{ message }</span>;
  }, [ getAverages ]);

  useEffect(() => {
    const result:Array<QuoteOptionResponse> = quoteOptionsResponses.filter((quoteOptionsResponse: QuoteOptionResponse) => {
      if (props.filters.selectedQuoteOptions.length > 0) {
        if (props.filters.selectedQuoteOptions.indexOf(quoteOptionsResponse.quoteOption?.id) === -1) {
          return true;
        } else {
          return false;
        }
      }

      return true;
    });

    setFilteredQuoteOptionsResponses(result);
  }, [ quoteOptionsResponses, props.filters ]);

  const isQuoteValid = (submittedAt: Date) => {
    return submittedAt ? true : false;
  };

  const dynamicChip = (quoteOptionsResponse: QuoteOptionResponse) => {
    if (!quoteOptionsResponse.accepted) {
      return <Chip status={3} />;
    }

    let duration = 0;

    // Calculate days still valid from submittedAt date
    duration = Math.round(
      moment.duration(moment(parseDate(quoteOptionsResponse.rateRequestResponse?.submittedAt))
      .add(60, 'days')
      .diff(moment()), 'milliseconds')
      .asDays()
    );

    if (duration < 1) {
      return <Chip status={4} />;
    }

    const pluralS = duration !== 1 ? 's' : '';

    return <Chip status={1} label={ `${duration} day${pluralS} valid` } />;
  };

  if (props.isVisible === false) {
    return null;
  }

  return (
    <div className="result result--card">
      <div className="result-labels">
        { props.supplier.meta?.labels?.map((labelObj: StrictDict, index: number) =>
          (labelObj.active)
          ? <div className="result-labels__item" key={ index }>{ labelObj.label }</div>
          : null
        ) }
      </div>
      <div className="result__top">
        <div className="result__headings">
          { props.supplier.relationship &&
            <>
              <ReactTooltip place="top" effect="solid" delayShow={500} />
              <div className="result__relationship" data-tip={ relationshipLabels[props.supplier.relationship].label }>
                { props.supplier.relationship }
              </div>
            </>
          }
          <div className="result__heading">
            { props.supplier.alias ? props.supplier.alias : props.supplier.car?.Client }
            { props.supplier.subtitle && (
            <div className="result__subtitle">
              <span>{ props.supplier.subtitle }</span>
            </div>
            ) }
          </div>
        </div>
        <div className="result__aside">
          { props.selectSupplier &&
            <Button variant="ghost-primary" onClick={ () => { props.selectSupplier && props.selectSupplier(props.supplier) } }>
              <>
                <Checkbox value={props.supplier.car.id} name="selectedSuppliers" checked={props.isSelected} onChange={() => {}} />
                <span>Select supplier</span>
              </>
            </Button>
          }

          { props.requestSupplier &&
            <Button variant="primary" onClick={ () => { props.requestSupplier && props.requestSupplier(props.supplier) } }>
              <span>Request rates</span>
            </Button>
          }
        </div>
      </div>

      { filteredQuoteOptionsResponses.length > 0 &&
        <ul className="result__orders">
          <li className="result__orders-title"><h5>Comparable orders</h5></li>
          { filteredQuoteOptionsResponses.map((quoteOptionResponse: QuoteOptionResponse, index: number) =>
            <li className="result__orders-item" key={ index }>
              <div className="result__orders-column">
                <strong>{ quoteOptionResponse.quoteOption?.volume } m³</strong>
                <span>{ context.rateRequestLabels.packagingTypes[quoteOptionResponse.quoteOption?.packagingType]?.label }</span>
              </div>
              <div className="result__orders-column">
                <strong>{ context.rateRequestLabels.rateRequestModes[quoteOptionResponse.quoteOption?.rateRequestMode]?.labelShort }</strong>
                <span>{ context.rateRequestLabels.transportModes[quoteOptionResponse.quoteOption?.transportMode]?.label }</span>
              </div>
              <div className="result__orders-column">
                { quoteOptionResponse.accepted && quoteOptionResponse.duration !== null ? (
                  <>
                    <strong>{ quoteOptionResponse.duration === 0
                                ? "Same day"
                                : (quoteOptionResponse.duration === 1
                                    ? `${quoteOptionResponse.duration} day`
                                    : `${quoteOptionResponse.duration} days`)
                    }</strong>
                    { compareAverageDuration(quoteOptionResponse) }
                  </>
                ) : (
                  <>
                    <strong>Not applicable</strong>
                    -
                  </>
                ) }
              </div>
              <div className="result__orders-column">
                <strong>&euro; { quoteOptionResponse.rate }</strong>
                { compareAverageRate(quoteOptionResponse) }
              </div>
              <div className="result__orders-column">
                { isQuoteValid(quoteOptionResponse.rateRequestResponse?.submittedAt as Date)
                    ? dynamicChip(quoteOptionResponse)
                    : <Chip status={2} />
                }
              </div>
            </li>
          ) }
        </ul>
      }
    </div>
  )
}
