import React, { ReactElement, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { ReactComponent as EllipsisIcon } from "../../assets/images/icons/ellipsis.svg";

type Props = {
  variant?: "primary" | "secondary" | "tertiary" | "ghost" | "text" | "danger" | "ghost-danger" | "ghost-primary" | "full-width";
  children?: string | ReactElement;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  circle?: boolean;
  tip?: string;
  size?: "small" | "large";
  customClassName?: string;
};

export default function Button(props: Props) {
  const [ isVisible, setIsVisible ] = useState<boolean|undefined>(false);
  const buttonRef = useRef(null);

  const toggleVisibility = (visability?: boolean) => {
    const visible = (visability !== undefined) ? visability : !isVisible;
    setIsVisible(visible);

    if (visible) {
      document.body.addEventListener('click', closeOptions);
    }
  };

  const closeOptions = (event: MouseEvent) => {
    if (event.target !== buttonRef.current)
    toggleVisibility(false);
    document.body.removeEventListener('click', closeOptions);
  };

  return (
    <>
      { props.tip && <ReactTooltip place="top" effect="solid" delayShow={500} /> }
      <div className="ellipsis-button">
        <button
          className={`button button--${props.variant ? props.variant : "primary"} ${props.size ? `button--${props.size}` : ""} ${props.circle ? "button--circle" : ""} ${props.customClassName ? props.customClassName : ""}`}
          onClick={ () => toggleVisibility() }
          disabled={ props.disabled }
          type={ props.type ? props.type : "button" }
          data-tip={ props.tip }
          ref={ buttonRef }
          >
            <EllipsisIcon />
        </button>
        <div className={ `ellipsis-button__options ${ isVisible ? 'ellipsis-button__options--is-visible' : '' }` }>
          { props.children }
        </div>
      </div>
    </>
  );
}
