import React, { useCallback, useEffect, useState } from "react";
// @ts-ignore
import ReactNotification, { store } from "react-notifications-component";

import countriesContinents from '../assets/data/country-continents.json';
import rateRequestLabels from '../assets/data/rate-request-labels';
import relationshipLabels from '../assets/data/relationship-labels.json';
import typeLabels from '../assets/data/type-labels.json';
import countryCodesJSON from '../assets/data/country-codes-iso3316-2.json';

//eslint-disable-next-line @typescript-eslint/no-unused-vars
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { config } from "../firebase.config.js";

const provider = new firebase.auth.GoogleAuthProvider();
const firebaseApp = firebase.initializeApp(config);
const firestore = firebase.firestore();
const storage = firebase.storage();

const firebaseAuth = firebase.auth();

const countryCodes: StrictDict = countryCodesJSON;
const countryCodesInverted: StrictDict = {};

Object.keys(countryCodes).forEach((countryCode: string) => {
  const countryItem = countryCodes[countryCode];

  countryCodesInverted[countryItem.name] = {
    ...countryItem,
    countryCode: countryCode
  };
});

// Run firestore locally when on localhost
// if (window.location.hostname === "localhost") {
//   firestore.settings({
//     host: "localhost:8080",
//     ssl: false
//   });
// }

// Limit amount of rate requests on localhost
const rateRequestLimit = window.location.hostname === "localhost" ? 3000 : 3000;
const supplierRequestLimit = window.location.hostname === "localhost" ? 3000 : 5000;

const GlobalData = React.createContext<any>(null);

export default GlobalData;

export function GlobalDataProvider({ children }: { children: any }) {
  const [ rateRequestSnapshotData, setRateRequestSnapshotData ] = useState<any>(null);
  const [ currentUser, setCurrentUser ] = useState<StrictDict>({});
  const [ suppliers, setSuppliers ] = useState<Array<Supplier>>([]);
  const [ rateRequests, setRateRequests ] = useState<Array<RateRequest>>([]);
  const [ groups, setGroups ] = useState<Array<Object>>([]);
  const [ users, setUsers ] = useState<Array<Account>>([]);

  const updateRateRequests = useCallback((newRateRequests:Array<RateRequest>) => {
    // console.log('### Current rate requests saved:', rateRequests);
    // console.log('@@@ Adding new rate requests:', newRateRequests);

    setRateRequests(newRateRequests);
  }, [ rateRequests ]);

  const getSuppliers = () => {
    // Set up suppliers snapshot only once on load
    const suppliersSnapshot = firestore.collection("suppliers").where('status.inactive', '!=', true).limit(supplierRequestLimit).onSnapshot((querySnapshot: any) => {
      /**
       * Use commented code in this function to export the suppliers list
       */
      // const exportArray:Array<StrictDict> = [];

      const foundSuppliers:Array<Supplier> = querySnapshot.docs.map((doc: any) => {
        const docData = {
          ...doc.data(),
          uid: doc.id,
          carId: doc.id,
        };

        return docData;

        // Add supplier to export array if it's not inactive
        // if (!docData.status || !docData.status.inactive || docData.status.inactive === false) {
        //   exportArray.push(docData);
        // }

        // const exportObj = {
        //   carId: doc.id,
        //   name: docData.car.Client,
        //   alias: "",
        //   status: "",
        //   relationship: "",
        //   agreementSigned: "",
        //   customEmail: "",
        //   types: "",
        //   groups: "",
        //   coverage: "",
        //   comment: ""
        // };

        // exportArray.push(exportObj);
      });

      // Log all active suppliers to console
      // console.log(JSON.stringify(exportArray));

      // let exportString = '';

      // exportArray.forEach(supplier => exportString = exportString + `${supplier.carId};${supplier.name}\n`);

      // console.log(exportString);

      console.log('Suppliers snapshot updated');

      setSuppliers(foundSuppliers);
    });

    return suppliersSnapshot;
  };

  const getInactiveSuppliersWithinLastMonth = () => {
    // Set up suppliers snapshot only once on load
    let foundSuppliers:Array<Supplier> = [];

    const monthAgo = new Date();
    monthAgo.setMonth(monthAgo.getMonth() - 1);

    const suppliersSnapshot2 = firestore.collection("suppliers").where('status.inactive', '==', true).where('createdAt', '>', monthAgo).limit(supplierRequestLimit).get().then((querySnapshot2: any) => {
      foundSuppliers = foundSuppliers.concat(querySnapshot2.docs.map((doc: any) => {
        const docData = {
          ...doc.data(),
          uid: doc.id,
          carId: doc.id,
          };

        return docData;
        }
      ));

      console.log('Suppliers snapshot updated');

      setSuppliers((prevState) => {
        return prevState.concat(foundSuppliers);
      });
    });

    return suppliersSnapshot2;
  };

  useEffect(() => {
    // console.log(rateRequestSnapshotData);

    const querySnapshot = rateRequestSnapshotData;

    if (!querySnapshot) {
      return;
    }

    // Initialize rate requests array with existing rate requests
    const foundRateRequests:Array<RateRequest> = [];
    const changedDocs = querySnapshot.docChanges();
    const changedDocsLength = changedDocs.length;
    const currentRateRequests = [...rateRequests];


    for (let index:number = 0; index < changedDocsLength; index++) {
      const change = changedDocs[index];

      // console.log(change);

      if (change.type === 'removed') {
        currentRateRequests.splice(change.oldIndex, 1);
      }

      if (change.type === 'modified') {
        currentRateRequests[change.oldIndex] = changedDocs[index].doc.data() as RateRequest;
      }

      if (change.type === 'added') {
        const doc:StrictDict = changedDocs[index].doc;
        const data = doc.data();
        data.uid = doc.id;

        if (typeof data.user === "string") {
          data.user = users.find((user: Account) => user.uid === data.user) || data.user;
        }

        if (change.newIndex === 0) {
          currentRateRequests.unshift(data);
        } else {
          currentRateRequests[change.newIndex] = data;
        }
      }
    }

    updateRateRequests(currentRateRequests);

  }, [ rateRequestSnapshotData ]);


  const getRateRequests = () => {
    // Set up suppliers snapshot only once on load
    const rateRequestSnapshot = firestore.collection("rate_requests").limit(rateRequestLimit).orderBy("createdAt", "desc");

    const unsubscribe = rateRequestSnapshot.onSnapshot((querySnapshot: any) => {
      setRateRequestSnapshotData(querySnapshot);
    });

    return unsubscribe;
  };

  const getGroups = () => {
    // Set up groups snapshot only once on load
    const groupsSnapshot = firestore.collection("supplier_groups").orderBy('id', 'asc').limit(9999).onSnapshot(async (querySnapshot: any) => {
      const foundGroups:Array<Object> = [];

      await Promise.all(querySnapshot.docs.map(async (doc: any) => {
        foundGroups.push(doc.data());
      }));

      setGroups(foundGroups);
    });

    console.log('Groups snapshot');

    return groupsSnapshot;
  };

  const getUsers = () => {
    // Set up users snapshot only once on load
    const usersSnapshot = firestore.collection("users").limit(250).onSnapshot((querySnapshot: any) => {
      const foundUsers:Array<Account> = querySnapshot.docs.map((doc: any) => {
        const data = {
          ...doc.data(),
          uid: doc.id
        };

        return data;
      });

      setUsers(foundUsers);
    });

    console.log('Users snapshot');

    return usersSnapshot;
  };

  // Run this once on load
  useEffect(() => {
    // Keep snapshot variables accessible inside useEffect component for unsubscribing
    let authSnapshot: firebase.Unsubscribe;
    let suppliersSnapshot: firebase.Unsubscribe
    let rateRequestSnapshot: firebase.Unsubscribe;
    let groupsSnapshot: firebase.Unsubscribe;
    let usersSnapshot: firebase.Unsubscribe;

    console.log("Initial App.tsx render");

    // store.addNotification({
    //   message: "Suppliers list is manually limited to 500 accounts for testing purposes.",
    //   type: "danger",
    //   insert: "top",
    //   container: "top-center",
    //   animationIn: ["animated", "slideDown"],
    //   animationOut: ["animated", "slideUp"],
    //   dismiss: {
    //     pauseOnHover: true,
    //     duration: 5000
    //   }
    // });

    firebaseAuth.onAuthStateChanged((user: any) => {
      if (user) {
        setCurrentUser((c: StrictDict) => {
          c.firebase = user;

          return c;
        });

        localStorage.setItem("expectSignIn", "true");

        if (user.emailVerified) {
          localStorage.setItem("verified", "true");
        }

        // Set up snapshots after signin in
        groupsSnapshot = getGroups();
        usersSnapshot = getUsers();
        suppliersSnapshot = getSuppliers();
        rateRequestSnapshot = getRateRequests();

        // Return a function to the useEffect that calls all snapshots to unbind snapshots on component unmount
        return () => {
          authSnapshot();
          usersSnapshot();
          suppliersSnapshot();
          rateRequestSnapshot();
          groupsSnapshot();
        };
      } else {
        setCurrentUser((c: StrictDict) => {
          c.firebase = null;

          return c;
        });

        suppliersSnapshot && suppliersSnapshot();
        groupsSnapshot && groupsSnapshot();
        usersSnapshot && usersSnapshot();
        rateRequestSnapshot && rateRequestSnapshot();

        setUsers([]);
        setGroups([]);
        setRateRequests([]);
        setSuppliers([]);

        localStorage.removeItem("expectSignIn");
      }
    });
  }, []);

  // Update currentUser when users state updates
  useEffect(() => {
    setCurrentUser((prevState: StrictDict) => {
      const currentState = prevState;

      if (currentState) {
        currentState.custom = users.find((user: Account) => user.uid === currentState.firebase.uid);
      }

      // Load inactive suppliers within last month if user is admin
      if (currentState.custom?.admin) {
        getInactiveSuppliersWithinLastMonth();

        store.addNotification({
          message: "Admin only: Loading inactive suppliers added within last month",
          type: "info",
          insert: "top",
          container: "top-center",
          animationIn: ["animated", "slideDown"],
          animationOut: ["animated", "slideUp"],
          dismiss: {
            pauseOnHover: true,
            duration: 10000
          }
        });
      }

      return currentState;
    });
  }, [ users ]);

  const state = {
    // Labels
    countriesContinents,
    rateRequestLabels,
    relationshipLabels,
    typeLabels,
    countryCodes,
    countryCodesInverted,

    // States
    currentUser,
    setCurrentUser,
    suppliers,
    setSuppliers,
    rateRequests,
    setRateRequests,
    groups,
    setGroups,
    users,
    setUsers,

    // Firebase
    storage,
    firebaseApp,
    firestore,
    firebaseAuth,
    provider,
  };

  return (
    <GlobalData.Provider value={ state }>
      { children }
    </GlobalData.Provider>
  );
}
