import React, { useContext } from "react";
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import GlobalData from '../context/globaldata';
import Styleguide from "./Styleguide/Styleguide";

import SupplierOverview from "./Supplier/Overview";
import SupplierIndex from "./Supplier/Index";

import RateRequestsOverview from "./RateRequests/Overview";
import RateRequestIndex from "./RateRequests/Index";

import UsersOverview from "./Users/Overview";

import ErrorPageNotFound from "./Error/PageNotFound";

import ChangelogOverview from "./Changelog/Overview";

import { ReactComponent as LoadingIcon } from "../assets/images/logo-animated.svg";

export default function Root() {
  const context = useContext(GlobalData);

  return (
    context.currentUser && context.rateRequests.length ? (
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/rate-requests" />} />
        <Route exact path="/styleguide/" render={() => <Styleguide />} />
        <Route exact path="/changelog/" render={() => <ChangelogOverview />} />
        <Route exact path="/suppliers/" render={() => <SupplierOverview />} />
        <Route exact path="/users/" render={() => <UsersOverview />} />
        <Route exact path="/rate-requests/" render={() => <RateRequestsOverview />} />
        <Route path="/rate-requests/:uid/:page?" render={() => <RateRequestIndex />} />
        <Route path="/suppliers/:uid" render={() => <SupplierIndex />} />
        <Route path="/" render={() => <ErrorPageNotFound />} />
      </Switch>
    ) : (
      <div className="loader loader--full">
        <LoadingIcon />
      </div>
    )
  );
}
