import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";

import GlobalData from "../../context/globaldata";
import Button from "../../components/Button/Button";

import { ReactComponent as Logo } from "../../assets/images/logo-auth.svg";
import { ReactComponent as IconDanger } from "../../assets/images/icons/danger.svg";
import { ReactComponent as IconSuccess } from "../../assets/images/icons/success.svg";
import { FirebaseError } from "firebase";

export default function VerifyEmail() {
  const context = useContext(GlobalData);
  const [ emailIsSend, setEmailIsSend ] = useState(false);
  const [ errors, setErrors ] = useState("");
  const [ success, setSuccess ] = useState("");

  const sendVerification = () => {
    setEmailIsSend(true);
    context.firebaseAuth.currentUser?.sendEmailVerification()
    .then(() => {
      setErrors("");
      setSuccess("Verification email has been send!");
    })
    .catch((error: FirebaseError) => {
      console.log(error);
      setErrors(error.message);
      setEmailIsSend(false);
    });
  };

  return (
    <>
      <Link className="auth__logo" to="/login">
        <Logo />
      </Link>
      <h1 className="auth__heading">Verify email</h1>
      <p className="auth__description">Your account needs to be verfied before being able to log in, we'll send an email to: <strong>{ context.firebaseAuth.currentUser?.email }</strong></p>
      { errors &&
        <div className="auth__errors">
          <IconDanger className="icon" />
          { errors }
        </div>
      }
      { success &&
        <div className="auth__success">
          <IconSuccess className="icon" />
          { success }
        </div>
      }
      { !success &&
        <Button onClick={ sendVerification } disabled={ emailIsSend }>Send verification</Button>
      }
    </>
  );
}
