import React, { useContext, useState, useEffect, useCallback } from "react";
import { Switch, Route, useHistory, useParams } from "react-router-dom";

import moment, { Moment } from "moment";
import { nanoid } from "nanoid";

import GlobalData from '../../context/globaldata';

import DetailsIndex from "./Details/Index";
import EditIndex from "./EditIndex";

function addBusinessDays(originalDate: Moment, numDaysToAdd: number) {
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = numDaysToAdd;

  const newDate = originalDate.clone();

  while (daysRemaining > 0) {
    newDate.add(1, 'days');
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining--;
    }
  }

  return newDate;
}

export default function RateRequestIndex() {
  const context = useContext(GlobalData);
  // @ts-ignore
  const { uid } = useParams();
  const [ rateRequest, setRateRequest ] = useState<RateRequest>({} as RateRequest);
  const [ pending, setPending ] = useState(true);
  const [ deleted, setDeleted ] = useState(false);

  const getDefaultAdditionals = useCallback(context.firestore.collection("additionals").get().then((additionalsSnapshot: any) => {
    const additionals:Array<RateRequestAdditionalSelection> = [];

    if (!additionalsSnapshot.empty) {
      additionalsSnapshot.forEach(function(additinalDoc: any) {
        const selectionItem = {
          id: nanoid(8),
          item: additinalDoc.data() as RateRequestAdditional,
          selectedOrigin: false,
          selectedDestination: false
        };

        additionals.push(selectionItem);
      });
    }

    return {
      key: "additionals",
      value: additionals
    };
  }), []);

  const currentUserUid = context.currentUser.custom?.uid;

  const setExistingRateRequest = () => {
    const foundRateRequest = context.rateRequests.find((rateRequest: RateRequest) => rateRequest.uid === uid);

    if (!foundRateRequest) {
      return;
    }

    // @ts-ignore
    foundRateRequest.loadingDate = foundRateRequest.loadingDate && foundRateRequest.loadingDate.toDate ? foundRateRequest.loadingDate?.toDate() :  foundRateRequest.loadingDate;
    // @ts-ignore
    foundRateRequest.deliveryDate = foundRateRequest.deliveryDate && foundRateRequest.deliveryDate.toDate ? foundRateRequest.deliveryDate?.toDate() : foundRateRequest.deliveryDate;
    // @ts-ignore
    foundRateRequest.timeframe = foundRateRequest.timeframe && foundRateRequest.timeframe.toDate ? foundRateRequest.timeframe?.toDate() : foundRateRequest.timeframe;

    foundRateRequest.uid = uid;

    foundRateRequest.notes?.forEach((note: StrictDict) => {
      note.isEditing = false;
    });

    if (rateRequest && rateRequest.createdAt) {
      if ((!rateRequest.attachments && foundRateRequest.attachments)
          || (rateRequest.attachments?.length !== foundRateRequest.attachments?.length)) {

        setPending(true);

        setRateRequest({
          ...rateRequest,
          attachments: foundRateRequest.attachments,
        });

        setPending(false);
      }
    } else {
      setRateRequest(foundRateRequest);
      setPending(false);
    }
  };

  useEffect(() => {
    if (rateRequest) {
      setExistingRateRequest();
    }
  }, [ context.rateRequests ]);

  useEffect(() => {
    if (deleted) {
      return;
    }

    context.firestore.collection("rate_requests").doc(uid).get().then((rateRequestSnapshot: any) => {
      if (rateRequestSnapshot.exists) {
        let foundRateRequest = rateRequestSnapshot.data();

        if (foundRateRequest) {
          // We're opening an existing rate request
          setPending(true);

          if (foundRateRequest.createdAt) {
            console.log('Found existing rate request!');
            setExistingRateRequest();
            setPending(false);
          } else {
            console.log('Create new rate request!');

            // We're creating a new rate request!
            const prevRateRequestNumber = context.rateRequests[0] ? parseInt(context.rateRequests[0].rateRequestNumber) : 1000;

            const newRateRequestNumber = prevRateRequestNumber + 1;

            const newRateRequest = {
              createdAt: new Date(),
              origin: {} as MapLocation,
              destination: {} as MapLocation,
              originAddress: {} as MapLocation,
              destinationAddress: {} as MapLocation,
              loadingDate: null,
              loadingDateApproximate: true,
              loadingDateApproximateValue: '',
              deliveryDate: null,
              deliveryDateApproximate: true,
              deliveryDateApproximateValue: '',
              referenceOrder: `Order #${newRateRequestNumber}`,
              timeframe: addBusinessDays(moment(), 2).toDate(),
              targetRate: null,
              rateRequestNumber: newRateRequestNumber,
              additionals: [],
              customAdditionals: [],
              quoteOptions: [] as any,
              selectedSuppliers: [],
              message: "",
              status: 0 as RateRequestStatus,
              user: currentUserUid,
              uid: uid,
              // Create subcollection for responses when first response is submitted
            };

            newRateRequest.quoteOptions = [{
              id: nanoid(8),
              rateRequestMode: null,
              volume: null,
              transportMode: null,
              packagingType: null
            }];

            Promise.all([
              getDefaultAdditionals
            ])
            .then(async (resolve: StrictDict) => {
              newRateRequest.additionals = resolve[0].value;
              setRateRequest(newRateRequest);

              await context.firestore.collection("rate_requests").doc(uid).set(newRateRequest, { merge: true });

              setPending(false);
            });
          }
        }
      }
    });
  }, []);

  return (
    <>
      <Switch>
        <Route exact path="/rate-requests/:uid/details" render={ () => <DetailsIndex rateRequest={ rateRequest } pending={ pending } setPending={ setPending } setDeleted={ setDeleted } setRateRequest={ setRateRequest } /> } />
        <Route exact path="/rate-requests/:uid/quotes" render={ () => <DetailsIndex rateRequest={ rateRequest } pending={ pending } setPending={ setPending } setDeleted={ setDeleted } /> } />
        <Route exact path="/rate-requests/:uid/quotes/:responseId/:quoteId" render={ () => <DetailsIndex rateRequest={ rateRequest } pending={ pending } setPending={ setPending } setDeleted={ setDeleted } setRateRequest={ setRateRequest } /> } />
        <Route exact path="/rate-requests/:uid/alternatives" render={ () => <DetailsIndex rateRequest={ rateRequest } pending={ pending } setPending={ setPending } setDeleted={ setDeleted } setRateRequest={ setRateRequest } /> } />
        <Route exact path="/rate-requests/:uid/edit" render={ () => <EditIndex rateRequest={ rateRequest } pending={ pending } setPending={ setPending } setDeleted={ setDeleted } setRateRequest={ setRateRequest } /> } />
        <Route exact path="/rate-requests/:uid/select" render={ () => <EditIndex rateRequest={ rateRequest } pending={ pending } setPending={ setPending } setDeleted={ setDeleted } setRateRequest={ setRateRequest } /> } />
      </Switch>
    </>
  )
}
