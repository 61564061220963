import React, { ReactNode } from "react";

import Button from "../Button/Button";

type Props = {
  visible: boolean;
  title: string;
  confirmAction?: any;
  confirmLabel?: string;
  cancelLabel?: string;
  cancelAction?: any;
  closeAction?: any;
  resetAction?: any;
  children: ReactNode;
  pending?: boolean;
  form?: boolean;
  noCloseButton?: boolean;
  noCancelButton?: boolean;
  metaContent?: ReactNode;
  headerNoPaddingBottom?: boolean;
  variant?: "primary" | "danger" | "confirm";
  size?: "small" | "regular" | "large";
  customClassName?: string;
};

export default function Modal(props: Props) {

  const modalContent = <>
    <div className={ `modal__box-header ${ props.headerNoPaddingBottom ? 'modal__box-header--no-padding-bottom' : '' }` }>
      <h4>{ props.title }</h4>
      { !props.noCloseButton && <button className="modal__close" onClick={ !props.pending ? props.closeAction || props.cancelAction : undefined } type="button"></button> }
    </div>
    <div className="modal__box-content">
      { props.children }
    </div>
  </>

  return (
    <div className={`modal ${props.pending ? "is-pending" : ""} ${props.size ? `modal--${props.size}` : "modal--regular"} ${props.variant ? `modal--${props.variant}` : "modal--primary"} ${props.visible ? "is-visible" : ""}`}>
      { props.form ? (
        <form className={ `modal__box ${ props.customClassName ? props.customClassName : "" }` } onSubmit={ props.confirmAction }>
          { modalContent }
          <div className="modal__box-footer">
            <Button variant="ghost" onClick={ props.resetAction || props.cancelAction } disabled={ props.pending }>{ props.cancelLabel || "Cancel" }</Button>
            <Button variant="primary" type="submit" disabled={ props.pending }>{ props.confirmLabel }</Button>
          </div>
        </form>
      ) : (
        <div className={ `modal__box ${ props.customClassName ? props.customClassName : "" }` }>
          { modalContent }

          { props.variant === "confirm" && props.confirmAction ? (
            <div className="modal__box-submit">
              <Button variant="primary" onClick={ props.confirmAction } disabled={ props.pending }>{ props.confirmLabel }</Button>
            </div>
          ) : (
            ((props.cancelAction && !props.noCancelButton) || props.confirmAction) &&
              <div className="modal__box-footer">
                { props.cancelAction && !props.noCancelButton && (
                  <Button variant="ghost" onClick={ props.resetAction || props.cancelAction } disabled={ props.pending }>{ props.cancelLabel || "Cancel" }</Button>
                ) }

                { props.confirmAction && (<>
                  { props.variant === "danger" ? (
                    <Button variant="danger" onClick={ props.confirmAction } disabled={ props.pending }>{ props.confirmLabel }</Button>
                  ) : (
                    <Button variant="primary" onClick={ props.confirmAction } disabled={ props.pending }>{ props.confirmLabel }</Button>
                  ) }
                </>) }
              </div>
          ) }

          { props.metaContent &&
            <div className="modal__box-meta">
              { props.metaContent }
            </div>
          }
        </div>
      ) }
      <button className="modal__overlay" onClick={ !props.noCloseButton && !props.pending ? props.cancelAction : undefined } title="Close modal" type="button"></button>
    </div>
  );
}
