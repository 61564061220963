import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import moment from "moment";
import haversineDistance from "../../utils/HaverSineDistance";

import GlobalData from '../../context/globaldata';

import Chip from "../../components/Chip/Chip";
import Badge from "../../components/Badge/Badge";
import { Header, Navigation } from "../../components/Layout";
import { Input, Select, DatePicker, DropdownButton } from "../../components/Form";
import Button from "../../components/Button/Button";

import { ReactComponent as IconPlusSmall } from "../../assets/images/icons/plus-small.svg";
import { ReactComponent as IconFilter } from "../../assets/images/icons/filter.svg";
import { ReactComponent as IconChevronDown } from "../../assets/images/icons/chevron-down.svg";
import { ReactComponent as IconChevronUp } from "../../assets/images/icons/chevron-up.svg";
import Pagination from "../../components/Pagination/Pagination";
import Modal from "../../components/Modal/Modal";
import MapsAutocomplete from "../../components/Maps/Autocomplete";

export default function RateRequestsOverview(props: any) {
  process.env.NODE_ENV === 'development' && console.log('Re-rendering RateRequestsOverview.tsx');

  const location = useLocation();
  const history = useHistory();
  const context = useContext(GlobalData);
  const search = location.search;
  const params = new URLSearchParams(search);
  const paramQuery = params.get("q");
  const paramPage = params.get("page");
  const itemsPerPage = 15;
  const labels = context.rateRequestLabels;

  const statusList = [
    {
      label: "All",
      value: null
    }, {
      label: "Draft",
      value: 0
    }, {
      label: "Active",
      value: 1
    }
  ];

  // const searchValueStorage = localStorage.getItem(`SEARCH_${location.pathname}`);
  const searchValueStorage = '';

  const [ filterDraft, setFilterDraft ] = useState<StrictDict>({ status: statusList[0] });
  const [ filter, setFilter ] = useState<StrictDict>({});
  const [ filterModalVisible, setFilterModalVisible ] = useState<boolean>(false);
  const [ currentPage, setCurrentPage ] = useState<number>(paramPage ? parseInt(paramPage) : 1);
  const [ searchValue, setSearchValue ] = useState<string>(searchValueStorage ? searchValueStorage : paramQuery ? paramQuery : '');
  const [ pagedRateRequestResults, setPagedRateRequestsResults ] = useState<Array<RateRequest>>([]);
  const [ creatingRateRequest, setCreatingRateRequest ] = useState<boolean>(false);
  const [ sort, setSort ] = useState({ field: "createdAt", order: "desc" });

  const filterCounter = Object.keys(filter).filter(filterItem => (!!filter[filterItem] && !!filter[filterItem].length && filterItem !== "status")).length;

  useEffect(() => {
    let foundFilters = window.localStorage.getItem('rate-request-filter');

    if (foundFilters) {
      foundFilters = JSON.parse(foundFilters);
      setFilter((foundFilters || {}) as StrictDict);
      setFilterDraft((foundFilters || {}) as StrictDict);
    }
  }, []);

  const navigationItems = [
    {
      to: "/rate-requests",
      label: "Rate requests"
    }, {
      to: "/suppliers",
      label: "Suppliers"
    }, {
      to: "/users",
      label: "Users"
    }
  ];

  const createRequest = () => {
    if (creatingRateRequest) {
      return false;
    }

    setCreatingRateRequest(true);

    context.firestore.collection("rate_requests").add({})
    .then((docRef: any) => {
      setCreatingRateRequest(false);
      history.push(`/rate-requests/${docRef.id}/edit`);
    })
    .catch((error: any) => {
      console.error("Error writing document: ", error);
    });
  };

  // Search rate request by string
  const performSearch = useCallback((rateRequests, searchValue: string): Array<RateRequest> => {
    searchValue = searchValue.toLowerCase();

    localStorage.setItem(`SEARCH_${ location.pathname }`, searchValue);

    const ignoreProps: StrictDict = [
      'user'
    ];

    if (rateRequests.length === 0) {
      return [];
    }

    function iterateSearchObject(subject: StrictDict): boolean {
      if (!subject) {
        return false;
      }

      let keys:Array<any> = [];
      let keysLength = 0;

      if (subject instanceof Array) {
        keysLength = subject.length;
      } else {
        keys = Object.keys(subject);
        keysLength = keys.length;
      }

      for (let i = 0; i < keysLength; i++) {
        let currentProperty;

        if (keys.length === 0) {
          currentProperty = subject[i];
        } else {
          currentProperty = subject[keys[i]];
        }

        if (typeof currentProperty === "object" && currentProperty !== null) {
          if (!ignoreProps.includes(keys[i])) {
            const found = iterateSearchObject(currentProperty);

            if (found) {
              return true;
            }
          }
        } else if (currentProperty !== null) {
          const value = typeof currentProperty === 'string'
                          ? currentProperty.toLowerCase()
                          : currentProperty.toString().toLowerCase();

          if (value.length > 0 && value.indexOf(searchValue) > -1) {
            return true;
          }
        }
      }

      return false;
    };

    let result:Array<RateRequest> = rateRequests.slice();

    result = result.filter((item): boolean => {
      return iterateSearchObject(item);
    });

    return result;
  }, [ location.pathname ]);

  // Perform filter
  const performFilter = useCallback((rateRequests: Array<RateRequest>, filter: StrictDict): Array<RateRequest> => {
    let filteredRateRequests = rateRequests.filter((rateRequest: RateRequest) => {

      // Filter on origin
      if (filter.origin && rateRequest.origin) {
        if (!rateRequest.origin.lat) {
          // Rate request has no origin
          return false;
        }

        // Calculate distance between filter origin and rate request origin
        const distanceOrig = haversineDistance({
          lat: filter.origin.lat,
          long: filter.origin.long
        }, {
          lat: rateRequest.origin.lat,
          long: rateRequest.origin.long
        });

        // Check if out of range
        if (distanceOrig > 50) {
          return false;
        }
      }

      // Filter on destination
      if (filter.destination && rateRequest.destination) {
        if (!rateRequest.destination.lat) {
          // Rate request has no destination
          return false;
        }

        // Calculate distance between filter destination and rate request destination
        const distanceDest = haversineDistance({
          lat: filter.destination.lat,
          long: filter.destination.long
        }, {
          lat: rateRequest.destination.lat,
          long: rateRequest.destination.long
        });

        // Check if out of range
        if (distanceDest > 50) {
          return false;
        }
      }

      // Filter on loading date
      if (filter.loadingDate) {
        if (!rateRequest.loadingDate) {
          // Rate request has no loading date
          return false;
        }

        // Check if dates are not identical
        // @ts-ignore
        if (filter.loadingDate && moment(filter.loadingDate).format('YYYY-MM-DD') !== moment(rateRequest.loadingDate.toDate()).format('YYYY-MM-DD')) {
          return false
        }
      }

      // Filter on delivery date
      if (filter.deliveryDate) {
        if (!rateRequest.deliveryDate) {
          // Rate request has no delivery date
          return false;
        }

        // Check if dates are not identical
        // @ts-ignore
        if (filter.deliveryDate && moment(filter.deliveryDate).format('YYYY-MM-DD') !== moment(rateRequest.deliveryDate.toDate()).format('YYYY-MM-DD')) {
          return false
        }
      }

      // Filter on min. volume
      if (filter.minVolume) {
        if (!rateRequest.quoteOptions.some((option: QuoteOption) => option.volume && option.volume >= filter.minVolume)) {
          return false;
        }
      }

      // Filter on max. volume
      if (filter.maxVolume) {
        if (!rateRequest.quoteOptions.some((option: QuoteOption) => option.volume && option.volume <= filter.maxVolume)) {
          return false;
        }
      }

      // Filter on rate request modes (multiple)
      if (filter.rateRequestMode && filter.rateRequestMode.length > 0) {
        if (rateRequest.quoteOptions.length === 0) {
          // No quote options, ignore this rate request
          return false;
        }

        return filter.rateRequestMode.some((mode: StrictDict) => {
          return rateRequest.quoteOptions.some((option: QuoteOption) => option.rateRequestMode === mode.value);
        });
      }

      // Filter on rate transport modes (multiple)
      if (filter.transportMode && filter.transportMode.length > 0) {
        if (rateRequest.quoteOptions.length === 0) {
          // No quote options, ignore this rate request
          return false;
        }

        return filter.transportMode.some((mode: StrictDict) => {
          return rateRequest.quoteOptions.some((option: QuoteOption) => option.transportMode === mode.value);
        });
      }

      // Filter on rate packaging types (multiple)
      if (filter.packagingType && filter.packagingType.length > 0) {
        if (rateRequest.quoteOptions.length === 0) {
          // No quote options, ignore this rate request
          return false;
        }

        return filter.packagingType.some((mode: StrictDict) => {
          return rateRequest.quoteOptions.some((option: QuoteOption) => option.packagingType === mode.value);
        });
      }

      // Filter on user
      if (filter.user && filter.user.length > 0) {
        return filter.user.some((mode: StrictDict) => {
          return typeof rateRequest.user === "object" && rateRequest.user.uid === mode.value;
        });
      }

      // Filter on status
      if (filter.status) {
        if (filter.status.value !== null && rateRequest.status !== filter.status.value) {
          return false;
        }
      }

      // Passed all tests!
      return true;
    });

    return filteredRateRequests;
  }, []);

  // Perform pagination
  const performPagination = useCallback((rateRequests: Array<RateRequest>, currentPage: number): Array<RateRequest> => {
    const startOffset = (currentPage - 1) * itemsPerPage;
    return rateRequests.slice(startOffset, startOffset + itemsPerPage);
  }, []);

  // Sort by selected sort field and order
  const performSort = useCallback((rateRequests: Array<RateRequest>, sort: StrictDict): Array<RateRequest> => {
    if (rateRequests.length === 0) {
      return [];
    }

    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});

    const dotPick = (dotReference: string, item: StrictDict) => {
      if (dotReference.indexOf(".") !== -1) {
        return dotReference.split(".").reduce((o: StrictDict, i: string) => o[i], item);
      }

      return item[dotReference];
    };

    const rateRequestsFilteredSorted = rateRequests.slice();

    rateRequestsFilteredSorted.sort((a: object, b: object) => {
      [a, b] = sort.order === "asc"
                ? [a, b]
                : [b, a];

      return collator.compare(dotPick(sort.field, a), dotPick(sort.field, b));
    });

    return rateRequestsFilteredSorted;
  }, []);

  const rateRequestsResults = useMemo(() => {
    let results = performFilter(context.rateRequests, filter);
    results = performSearch(results, searchValue);
    results = performSort(results, sort);

    return results;
  }, [ searchValue, sort, filter, context.rateRequests, performFilter, performSearch, performSort ]);

  useEffect(() => {
    const results = performPagination(rateRequestsResults, currentPage);

    setPagedRateRequestsResults(results);
  }, [ rateRequestsResults, currentPage, performPagination ]);

  const updateFilterDraft = (key: string, value: string|number|Date|StrictDict|MapLocation|null) => {
    setFilterDraft({
      ...filterDraft,
      [key]: value
    });
  };

  const applyFilterDraft = (event: Event) => {
    event?.preventDefault();
    setFilterModalVisible(false);

    saveFilterInLocalStorage(filterDraft);

    setFilter(filterDraft);
  };

  const saveFilterInLocalStorage = (filterDraft: StrictDict) => {
    window.localStorage.setItem('rate-request-filter', JSON.stringify(filterDraft));
  };

  const resetFilterDraft = (event: Event) => {
    event?.preventDefault();

    const resetObject = {
      status: filterDraft.status,
      user: []
    };

    setFilterDraft(resetObject);
    setFilter(resetObject);
    saveFilterInLocalStorage(resetObject);
  };

  const setSortOrder = (field: string) => {
    let order = "desc";

    if (sort.field === field) {
      order = sort.order === "asc" ? "desc" : "asc";
    }

    setSort({
      field,
      order
    });
  };

  const sortedClass = (field: string): string => {
    if (sort.field !== field) {
      return "";
    }

    return `sorted ${sort.order}`;
  };

  const duplicateRateRequest = (rateRequest: RateRequest) => {
    if (creatingRateRequest) {
      return false;
    }

    setCreatingRateRequest(true);

    const newRateRequest = rateRequest;
    newRateRequest.user = context.currentUser.custom.uid;
    const prevRateRequestNumber = context.rateRequests[0] ? parseInt(context.rateRequests[0].rateRequestNumber) : 1000;
    const newRateRequestNumber = prevRateRequestNumber + 1;

    // Check if timeframe (deadline) is today or in the past.
    console.log(rateRequest.timeframe);
    // @ts-ignore
    const timeframe = typeof rateRequest.timeframe.toDate !== 'undefined'
      // @ts-ignore
      ? rateRequest.timeframe.toDate()
      : rateRequest.timeframe;

    if (timeframe && timeframe < new Date()) {
      newRateRequest.timeframe = moment().add(14, 'days').toDate();
    }

    context.firestore.collection("rate_requests").add(newRateRequest)
    .then((docRef: any) => {
      docRef.update({
        "uid": docRef.id,
        "referenceOrder": `Order #${newRateRequestNumber}`,
        "rateRequestNumber": newRateRequestNumber,
        "status": 0,
        "createdAt": new Date(),
        "user": context.currentUser.custom?.uid,
        "selectedSuppliers": []
      });
      setCreatingRateRequest(false);
      history.push(`/rate-requests/${docRef.id}/edit`);
      console.log("Document created");
    })
    .catch((error: any) => {
      console.error("Error writing document: ", error);
    });
  };

  const getDisplayName = (uid: string) => {
    if (context.users && uid) {
      const foundUser = context.users?.find((user: any) => user.uid === uid);

      if (foundUser) {
        return foundUser.displayName;
      }
    } else {
      return "";
    }
  };

  const getCreatorOptions = () => {
    const creators:StrictDict[] = [];

    context.users.forEach((user: any) => {
      creators.push({
        label: user.displayName,
        value: user.uid
      });
    });

    return creators;
  };

  const updateStatusFilter = (key: string, value: string|number|Date|StrictDict|MapLocation|null) => {
    setFilterDraft({
      ...filterDraft,
      [key]: value
    });

    setFilter({
      ...filter,
      status: value
    });
  };

  return (
    <>
      <Header
        title="Rate requests"
        leftContent={ <Navigation navItems={navigationItems} /> }
        aside={
          <>
            <Input value={searchValue} name="search" onChange={(event: any) => setSearchValue(event.target.value)} placeholder="Search for reference or ordernumber" search />
            <Button variant="tertiary" onClick={() => setFilterModalVisible(true)}>
              <>
                <IconFilter className="button__icon" />
                <span>Filter</span>
                { filterCounter > 0 &&
                  <Badge label={filterCounter} />
                }
              </>
            </Button>
            <Button onClick={createRequest} disabled={ creatingRateRequest }>
              <>
                <IconPlusSmall className="button__icon" />
                <span>New request</span>
              </>
            </Button>
          </>
        }
        logo
        accountDropdown
      />
      <div className="overview">
        <div className="container">
          <div className="card card--overview">
            <div className="card__top">
              <h4 className="card__heading">{ rateRequestsResults.length } Result{ pagedRateRequestResults.length !== 1 ? "s" : "" }</h4>
              <div className="card__actions">
                <Select
                  label="Status"
                  value={ filterDraft.status }
                  name="status"
                  onChange={ (option: StrictDict) => updateStatusFilter('status', option) }
                  options={ statusList }
                />
              </div>
            </div>
            <div className="rate-requests">
              <div className="rate-requests__top">
                <div className={ `rate-requests__column rate-requests__column--head ${ sortedClass("referenceOrder") }` } onClick={ () => setSortOrder("referenceOrder") }>
                  Reference
                  { sort.field === "referenceOrder" && sort.order === "desc" && <IconChevronDown className="icon" /> }
                  { sort.field === "referenceOrder" && sort.order === "asc" && <IconChevronUp className="icon" /> }
                </div>
                <div className={ `rate-requests__column rate-requests__column--head ${ sortedClass("origin.city") }` } onClick={ () => setSortOrder("origin.city") }>
                  Origin
                  { sort.field === "origin.city" && sort.order === "desc" && <IconChevronDown className="icon" /> }
                  { sort.field === "origin.city" && sort.order === "asc" && <IconChevronUp className="icon" /> }
                </div>
                <div className={ `rate-requests__column rate-requests__column--head ${ sortedClass("destination.city") }` } onClick={ () => setSortOrder("destination.city") }>
                  Destination
                  { sort.field === "destination.city" && sort.order === "desc" && <IconChevronDown className="icon" /> }
                  { sort.field === "destination.city" && sort.order === "asc" && <IconChevronUp className="icon" /> }
                </div>
                <div className={ `rate-requests__column rate-requests__column--head ${ sortedClass("createdAt") }` } onClick={ () => setSortOrder("createdAt") }>
                  Created
                  { sort.field === "createdAt" && sort.order === "desc" && <IconChevronDown className="icon" /> }
                  { sort.field === "createdAt" && sort.order === "asc" && <IconChevronUp className="icon" /> }
                </div>
              </div>
              <ul className="rate-requests__list">
                { pagedRateRequestResults?.map((request: any, requestIndex: number) =>
                  <li className="rate-requests__item" key={ requestIndex }>
                    <div className="rate-requests__column">
                      <div className="rate-requests__reference">
                        <span className="rate-requests__reference-label">{ request.referenceOrder }</span>
                        { request.status === 0 && <Chip status={5} /> }
                      </div>
                      <div className="rate-requests__number">{ request.rateRequestNumber }</div>
                    </div>
                    <div className="rate-requests__column">
                      <div>{ request.origin?.city }</div>
                      <div className="rate-requests__secondary-label">{ request.origin?.country }</div>
                    </div>
                    <div className="rate-requests__column">
                      <div>{ request.destination?.city }</div>
                      <div className="rate-requests__secondary-label">{ request.destination?.country }</div>
                    </div>
                    <div className="rate-requests__column">
                      <div>{ moment(request.createdAt.toDate()).format(`YYYY-MM-DD${ moment(request.createdAt.toDate()).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? ' / HH:mm:ss' : '' }`) }</div>
                      <div className="rate-requests__secondary-label">{ request.user.displayName }</div>
                    </div>
                    <div className="rate-requests__column">
                      { request.status === 0 ? (
                        <DropdownButton variant="secondary" label="" action={() => { history.push(`/rate-requests/${request.uid}/edit`) }} actionLabel="Edit request">
                          <Button variant="ghost" onClick={() => { duplicateRateRequest(request) }}>
                            Duplicate
                          </Button>
                        </DropdownButton>
                      ) : (
                        <DropdownButton variant="secondary" label="" action={() => { history.push(`/rate-requests/${request.uid}/quotes`) }} actionLabel="View request">
                          <Button variant="ghost" onClick={() => { duplicateRateRequest(request) }}>
                            Duplicate
                          </Button>
                        </DropdownButton>
                      ) }
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Pagination
        dataLength={ rateRequestsResults.length }
        itemsPerPage={ itemsPerPage }
        currentPage={ currentPage }
        setCurrentPage={ setCurrentPage }
      />

      <Modal title="Filter rate requests" visible={ filterModalVisible } confirmAction={ applyFilterDraft } confirmLabel="Apply filters" cancelLabel="Reset filters" resetAction={ resetFilterDraft } cancelAction={ () => setFilterModalVisible(false) } form>
        <>
          <div className="form-group">
            <MapsAutocomplete
              placeholder="Search for cities..."
              label="Origin"
              value={ filterDraft.origin }
              name="origin"
              type="place"
              onSelectItem={ (value: MapLocation) => updateFilterDraft('origin', value) }
              append="+ 50 km"
            />
          </div>

          <div className="form-group">
            <MapsAutocomplete
              placeholder="Search for cities..."
              label="Destination"
              value={ filterDraft.destination }
              name="destination"
              type="place"
              onSelectItem={ (value: MapLocation) => updateFilterDraft('destination', value) }
              append="+ 50 km"
            />
          </div>

          <div className="form-group">
            <div className="group">
              <div className="xxs12 md6">
                <DatePicker
                  label="Loading date"
                  selected={ filterDraft.loadingDate }
                  shouldCloseOnSelect={false}
                  dateFormat="dd/MM/yyyy"
                  onChange={ (date: Date) => updateFilterDraft('loadingDate', date) }
                />
              </div>
              <div className="xxs12 md6">
                <DatePicker
                  label="Delivery date"
                  selected={ filterDraft.deliveryDate }
                  shouldCloseOnSelect={false}
                  dateFormat="dd/MM/yyyy"
                  onChange={ (date: Date) => updateFilterDraft('deliveryDate', date) }
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <Select
              label="Creator"
              value={ filterDraft.user }
              name="creator"
              multi={ true }
              placeholder="Select a creator"
              onChange={ (option: StrictDict) => updateFilterDraft('user', option) }
              options={ getCreatorOptions() }
            />
          </div>

          <div className="form-group">
            <h5 className="form-group__title">Quote Options</h5>

            <Select
              label="Rate request modes"
              value={ filterDraft.rateRequestMode }
              name="rateRequestMode"
              multi={ true }
              placeholder="Select modes"
              onChange={ (option: StrictDict) => updateFilterDraft('rateRequestMode', option) }
              options={ labels.rateRequestModes }
            />
          </div>

          <div className="form-group">
            <div className="group">
              <div className="xxs12 md6">
                <Input
                  value={ filterDraft.minVolume || "" }
                  label="Min. Volume"
                  name="volume"
                  append="m³"
                  onChange={ (event: StrictDict) => updateFilterDraft('minVolume', isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value)) }
                />
              </div>
              <div className="xxs12 md6">
                <Input
                  value={ filterDraft.maxVolume || "" }
                  label="Max. Volume"
                  name="volume"
                  append="m³"
                  onChange={ (event: StrictDict) => updateFilterDraft('maxVolume', isNaN(parseInt(event.target.value)) ? 0 : parseInt(event.target.value)) }
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <Select
              label="Transport mode"
              value={ filterDraft.transportMode }
              name="transportMode"
              multi={ true }
              placeholder="Select option"
              onChange={ (option: StrictDict) => updateFilterDraft('transportMode', option) }
              options={ labels.transportModes }
            />
          </div>

          <div className="form-group">
            <Select
              label="Packaging type"
              value={ filterDraft.packagingType }
              name="packagingType"
              multi={ true }
              placeholder="Select packaging type"
              onChange={ (option: StrictDict) => updateFilterDraft('packagingType', option) }
              options={ labels.packagingTypes }
            />
          </div>
        </>
      </Modal>
    </>
  )
}
