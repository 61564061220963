import React, { useEffect, useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
// @ts-ignore
import { store } from "react-notifications-component";

import GlobalData from "../../../../context/globaldata";
import TransportDetails from "../../../../components/TransportDetails/TransportDetails";

import { Header } from "../../../../components/Layout";
import Button from "../../../../components/Button/Button";
import EllipsisButton from "../../../../components/Button/EllipsisButton";
import Chip from "../../../../components/Chip/Chip";
import { Textarea } from "../../../../components/Form";

import { ReactComponent as IconExport } from "../../../../assets/images/icons/export.svg";
import Modal from "../../../../components/Modal/Modal";

import { ReactComponent as IconCrossRed } from "../../../../assets/images/icons/cross-red.svg";
import { ReactComponent as IconCheckmarkGreen } from "../../../../assets/images/icons/checkmark-green.svg";
import parseDate from "../../../../utils/ParseDate";

type Props = {
  rateRequest: RateRequest;
  setRateRequest: any;
  allQuoteOptionsResponses: Array<QuoteOptionResponse>;
  setQuoteOptionsResponses: any;
  alternativeQuoteOptionsResponses?: Array<QuoteOptionResponse>;
};

export default function RateRequestQuotesSingle(props: Props) {
  // @ts-ignore
  const { uid, responseId, quoteId } = useParams();
  const context = useContext(GlobalData);
  const [ quoteOptionsResponse, setQuoteOptionsResponse ] = useState<QuoteOptionResponse|null>(null);
  const [ acceptModalVisible, setAcceptModalVisible ] = useState<boolean>(false);
  const [ pending, setPending ] = useState<boolean>(false);
  const [ averageRate, setAverageRate ] = useState<number|null>(null);
  const [ averageDuration, setAverageDuration ] = useState<number|null>(null);
  const [ newComment, setNewComment ] = useState('');
  const [ addingNewNote, setAddingNewNote ] = useState(false);
  const [ newNote, setNewNote ] = useState('');
  const [ deleteModalVisible, setDeleteModalVisible ] = useState(false);
  const [ deleteNoteIndex, setDeleteNoteIndex] = useState<number>();

  useEffect(() => {
    const currentQuoteOption = props.allQuoteOptionsResponses.find((currentQuoteOptionsResponse: QuoteOptionResponse) => {
      return currentQuoteOptionsResponse.rateRequestResponse?.uid === responseId && currentQuoteOptionsResponse.quoteOptionId === quoteId;
    });

    if (currentQuoteOption) {
      setQuoteOptionsResponse(currentQuoteOption);
    }

  }, [ props.allQuoteOptionsResponses, responseId, quoteId ]);

  const compareAverageDuration = useCallback((duration: number = 0) => {
    if (averageDuration === null) {
      return;
    }

    if (typeof duration !== 'number') {
      return <span>Calc error</span>;
    }

    const compAverage = Math.round(averageDuration - duration);

    let message = "Average";
    const day = Math.abs(compAverage);
    let dayString = "";
    let stateClass = "compared-value";

    if (day !== 0) {
      dayString = (day === 1)
        ? "1 day"
        : `${day} days`;

      if (compAverage < 0) {
        message = " above average";
        stateClass += " compared-value--above-average";
      } else if (compAverage > 0) {
        message = " below average";
        stateClass += " compared-value--below-average";
      }
    } else {
      stateClass += " compared-value--average";
    }

    return <span className={ stateClass }>{ dayString }{ message }</span>;
  }, [ averageDuration ]);

  const compareAverageRate = useCallback((rate: number) => {
    if (averageRate === null) {
      return;
    }

    if (quoteOptionsResponse?.rateRequestResponse?.supplier?.relationship === 4) {
      return false;
    }

    const compAverage = Math.round(averageRate - rate);

    let message = "Average";
    const rateAbs = Math.abs(compAverage);
    let rateString = "";
    let stateClass = "compared-value";

    // Only count as not-average when exceeding 10 euro threshold
    if (rateAbs > 10) {
      rateString = `€ ${rateAbs}`;

      if (compAverage < 0) {
        message = " above average";
        stateClass += " compared-value--above-average";
      } else if (compAverage > 0) {
        message = " below average";
        stateClass += " compared-value--below-average";
      }
    } else {
      stateClass += " compared-value--average";
    }

    return <span className={ stateClass }>{ rateString }{ message }</span>;
  }, [ averageRate, quoteOptionsResponse ]);

  const isQuoteValid = () => {
    return quoteOptionsResponse && quoteOptionsResponse.rateRequestResponse?.submittedAt ? true : false;
  };

  const dynamicChip = () => {
    if (quoteOptionsResponse && !quoteOptionsResponse.accepted) {
      return <Chip status={3} />;
    }

    // Calculate days still valid from submittedAt date
    const duration = Math.round(moment.duration(moment(quoteOptionsResponse?.rateRequestResponse?.submittedAt).add(60, 'days').diff(
      // @ts-ignore (ignore .toDate() error below)
      moment()
    ), 'milliseconds').asDays());

    if (duration < 1) {
      return <Chip status={4} />;
    }

    const pluralS = duration !== 1 ? 's' : '';

    return <Chip status={1} label={ `${duration} day${pluralS} valid` } />;
  };

  const formatDate = (date: Date|null|undefined) => {
    if (!date) {
      return 'Not applicable';
    }

    return moment(parseDate(date)).format('DD-MM-YYYY');
  };

  const getAdditional = (id: string): RateRequestAdditional => {
    const foundAdditionalSelection = quoteOptionsResponse?.rateRequest?.additionals.find((additional: RateRequestAdditionalSelection) => id === additional.id);

    return foundAdditionalSelection?.item || {} as RateRequestAdditional;
  };

  const getCustomAdditional = (id: string): RateRequestAdditional => {
    const foundAdditionalSelection = quoteOptionsResponse?.rateRequest?.customAdditionals.find((additional: RateRequestAdditionalSelection) => id === additional.id);

    return foundAdditionalSelection?.item || {} as RateRequestAdditional;
  };

  const checkAdditionalSelection = (id: string, key: string): boolean => {
    const foundAdditionalSelection = quoteOptionsResponse?.rateRequest?.additionals.find((additional: RateRequestAdditionalSelection) => id === additional.id);

    const indexedKey:keyof RateRequestAdditionalSelection = key as any;

    return foundAdditionalSelection
      ? (foundAdditionalSelection[indexedKey] as boolean)
      : false;
  };

  const checkCustomAdditionalSelection = (id: string, key: string): boolean => {
    const foundAdditionalSelection = quoteOptionsResponse?.rateRequest?.customAdditionals.find((additional: RateRequestAdditionalSelection) => id === additional.id);

    const indexedKey:keyof RateRequestAdditionalSelection = key as any;

    return foundAdditionalSelection
      ? (foundAdditionalSelection[indexedKey] as boolean)
      : false;
  };

  const acceptQuote = () => {
    if (quoteOptionsResponse && quoteOptionsResponse.rateRequest) {
      setPending(true);

      context.firestore.collection('rate_requests').doc(quoteOptionsResponse.rateRequest.uid).update({
        acceptedResponse: quoteOptionsResponse?.rateRequestResponse?.uid,
        acceptedQuoteOption: quoteOptionsResponse.quoteOptionId
      })
      .then((response: any) => {
        const newRateRequestResponse: StrictDict = Object.assign({}, quoteOptionsResponse?.originalRateRequestResponse);

        if (newComment.trim().length) {
          const addedComment = {
            text: newComment,
            postedDate: new Date(),
            author: 'Gosselin',
          };

          if (!newRateRequestResponse.comments) {
            newRateRequestResponse.comments = [];
          }

          newRateRequestResponse.comments?.unshift(addedComment);
        }

        context.firestore.collection('rate_requests').doc(quoteOptionsResponse.rateRequest?.uid).collection('responses').doc(quoteOptionsResponse.rateRequestResponse?.uid).update({
          acceptedQuoteOption: quoteOptionsResponse.quoteOptionId,
          comments: newRateRequestResponse.comments,
        })
        .then(() => {
          setQuoteOptionsResponse({
            ...quoteOptionsResponse,
            quoteAccepted: true
          });

          setNewComment('');

          setPending(false);
          setAcceptModalVisible(false);
        })
      });
    }
  };

  useEffect(() => {
    if (!props.allQuoteOptionsResponses.length || !props.alternativeQuoteOptionsResponses?.length) {
      return;
    }

    const combinedQuoteOptionsResponses = props.allQuoteOptionsResponses.concat(props.alternativeQuoteOptionsResponses);

    // Create a list of comparable quotes if they fit the volume range and filters
    const comparableQuotes = combinedQuoteOptionsResponses.filter((otherQuoteOptionsResponse: QuoteOptionResponse) => {
      // Rate not accepted
      if (!otherQuoteOptionsResponse.accepted) {
        return false;
      }

      // No rate available
      if (!otherQuoteOptionsResponse.rate) {
        return false;
      }

      // No volume available
      if (!otherQuoteOptionsResponse.quoteOption?.volume) {
        return false;
      }

      // Different rate request mode
      if (otherQuoteOptionsResponse.quoteOption?.rateRequestMode !== quoteOptionsResponse?.quoteOption?.rateRequestMode) {
        return false;
      }

      // Check if volume fits in the 20% range
      // @ts-ignore
      const currentVolume = parseInt(quoteOptionsResponse.quoteOption.volume)
      const otherQuoteOptionVolume = otherQuoteOptionsResponse.quoteOption.volume;

      // @ts-ignore
      let minVolume = parseInt(currentVolume) * .8;

      // @ts-ignore
      let maxVolume = parseInt(currentVolume) * 1.2;

      if (minVolume > (currentVolume - 2)) {
        minVolume = currentVolume - 2;
      }

      if (maxVolume < (currentVolume + 2)) {
        maxVolume = currentVolume + 2;
      }

      if (otherQuoteOptionVolume < minVolume) {
        return false;
      }

      if (otherQuoteOptionVolume > maxVolume) {
        return false;
      }


      return true;
    });

    if (!comparableQuotes.length) {
      return;
    }

    // Calculate difference for rate and duration based on average
    // of all other rates that fit the range/filters
    let rateTotal: number = 0;
    let durationTotal: number = 0;

    comparableQuotes.forEach((otherQuoteOptionsResponse: QuoteOptionResponse) => {
      // @ts-ignore
      rateTotal += parseInt(otherQuoteOptionsResponse.rate);

      durationTotal += otherQuoteOptionsResponse.duration || 0;
    });

    setAverageRate(rateTotal / comparableQuotes.length);
    setAverageDuration(durationTotal / comparableQuotes.length);

  }, [ props.allQuoteOptionsResponses, props.alternativeQuoteOptionsResponses, quoteOptionsResponse ]);

  const addNewComment = useCallback(() => {
    if (!newComment.trim().length) {
      return;
    }

    setPending(true);

    const newRateRequestResponse: RateRequestResponse = Object.assign({}, quoteOptionsResponse?.originalRateRequestResponse);

    if (!newRateRequestResponse.comments) {
      newRateRequestResponse.comments = [];
    }

    newRateRequestResponse.comments?.unshift({
      text: newComment,
      postedDate: new Date(),
      author: 'Gosselin',
    });

    context.firestore
    .collection('rate_requests').doc(quoteOptionsResponse?.rateRequest?.uid)
    .collection('responses').doc(quoteOptionsResponse?.rateRequestResponse?.uid)
    .update(newRateRequestResponse)
    .then(() => {
      setPending(false);
      setNewComment('');
    });

  }, [ newComment, quoteOptionsResponse, context.firestore ]);

  const addNewNote = () => {
    props.setRateRequest((prevState: any) => {
      const nextState = Object.assign({}, prevState);

      if (!nextState.notes) {
        nextState.notes = [];
      }

      nextState.notes.unshift({
        text: newNote,
        postedDate: new Date(),
      });

      saveChanges(nextState, 'newNote');

      return nextState;
    });
  };

  const editNote = (index: number) => {
    props.setRateRequest((prevState: any) => {
      const nextState = Object.assign({}, prevState);
      const originalNote = nextState.notes[index];

      originalNote.isEditing = false;
      originalNote.editedDate = new Date();

      saveChanges(nextState, 'editNote');

      return nextState;
    });
  };

  const deleteNote = () => {
    if (typeof deleteNoteIndex !== 'undefined') {
      props.setRateRequest((prevState: any) => {
        const nextState = Object.assign({}, prevState);
        nextState.notes.splice(deleteNoteIndex, 1);

        return nextState;
      });

      saveChanges(props.rateRequest, 'deleteNote');
      setDeleteModalVisible(false);
    }
  };

  const updateNote = (index: number, text: string) => {
    props.setRateRequest((prevState: any) => {
      const nextState = Object.assign({}, prevState);
      const originalNote = nextState.notes[index];

      originalNote.text = text;

      return nextState;
    });
  };

  const toggleEditNote = (index: number, state: boolean) => {
    props.setRateRequest((prevState: any) => {
      const nextState = Object.assign({}, prevState);
      const originalNote = nextState.notes[index];

      originalNote.isEditing = state;

      return nextState;
    });
  };

  const saveChanges = (newData: RateRequest, event: 'newNote' | 'editNote' | 'deleteNote') => {
    setPending(true);

    const actionLabels = {
      newNote: {
        successMessage: 'New note added successfully',
        errorMessage: 'Error adding new note',
      },
      editNote: {
        successMessage: 'Note edited successfully',
        errorMessage: 'Error editing note',
      },
      deleteNote: {
        successMessage: 'Note deleted successfully',
        errorMessage: 'Error deleting note',
      },
    };

    if (event === 'newNote') {
      setNewNote('');
      setAddingNewNote(false);
    }

    context.firestore.collection("rate_requests").doc(uid).set(newData||props.rateRequest)
    .then(() => {
      store.addNotification({
        message: actionLabels[event].successMessage,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      setPending(false);
    })
    .catch((error: string) => {
      store.addNotification({
        message: `${ actionLabels[event].errorMessage }: ${error}`,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      setPending(false);
    });
  };

  return (
    <>
      <div className="printable">
        <Header
          title={`${!quoteOptionsResponse?.rateRequestResponse?.supplier?.car ? "Loading..." : quoteOptionsResponse?.rateRequestResponse?.supplier?.alias ? quoteOptionsResponse?.rateRequestResponse?.supplier?.alias : quoteOptionsResponse?.rateRequestResponse?.supplier?.car?.Client}`}
          subTitle={ quoteOptionsResponse?.rateRequestResponse?.supplier?.car
                      ? `${quoteOptionsResponse?.rateRequestResponse?.supplier?.car.CompanyAddress.GENCITY}, ${quoteOptionsResponse?.rateRequestResponse?.supplier?.car.CompanyAddress.GENLAND}`
                      : ""
                  }
          backLink={`/rate-requests/${uid}/quotes`}
          aside={ isQuoteValid()
                    ? dynamicChip()
                    : <Chip status={2} />
                }
          rightContent={
            <>
              <Button variant="tertiary" onClick={() => window.print()}>
                <>
                  <span>Export quote</span>
                  <IconExport className="button__icon" />
                </>
              </Button>

              { quoteOptionsResponse?.rateRequest && (!quoteOptionsResponse?.rateRequest?.acceptedResponse && !quoteOptionsResponse.quoteAccepted) &&
                <Button variant="primary" onClick={() => setAcceptModalVisible(true)}>
                  <span>Accept quote</span>
                </Button>
              }
            </>
          }
        />

        { (quoteOptionsResponse && quoteOptionsResponse.rateRequest) &&
          <div>
            <div className="container">
              <div className="card card--overview card--primary">
                <div className="result-labels">
                  { quoteOptionsResponse.quoteAccepted &&
                    <div className="result-labels__item default">Accepted</div>
                  }

                  { quoteOptionsResponse.meta?.labels.map((labelObj: StrictDict, index: number) =>
                    (labelObj.active)
                    ? <div className="result-labels__item" key={ index }>{ labelObj.label }</div>
                    : null
                  ) }
                </div>
                <div className="group">
                  <div className="xxs12">
                    <h4>Quotation</h4>
                  </div>
                </div>
                <div className="card__content">
                  <ul className="card-list">
                    <li className="card-list__item">
                      <div className="card-list__column">
                        <strong>{ quoteOptionsResponse.quoteOption?.volume } m³</strong>
                        <span>{ context.rateRequestLabels.packagingTypes[quoteOptionsResponse.quoteOption?.packagingType]?.label }</span>
                      </div>
                      <div className="card-list__column">
                        <strong>{ context.rateRequestLabels.rateRequestModes[quoteOptionsResponse.quoteOption?.rateRequestMode]?.label }</strong>
                        <span>{ context.rateRequestLabels.transportModes[quoteOptionsResponse.quoteOption?.transportMode]?.label }</span>
                      </div>
                      <div className="card-list__column">
                        { quoteOptionsResponse.accepted
                          ? ( <strong>{ formatDate(quoteOptionsResponse.loadingDate) }</strong>)
                          : ( <strong>Not applicable</strong> )
                        }
                        Loading date
                      </div>
                      <div className="card-list__column">
                        <strong>{ (quoteOptionsResponse.duration === 0)
                                  ? "Same day"
                                  : (quoteOptionsResponse.duration === 1)
                                    ? `${quoteOptionsResponse.duration} day`
                                    : (quoteOptionsResponse.duration === null)
                                      ? `Not applicable`
                                      : `${quoteOptionsResponse.duration} days`
                        }</strong>
                        { compareAverageDuration(quoteOptionsResponse.duration as number) }
                      </div>
                      <div className="card-list__column">
                        { quoteOptionsResponse.accepted
                          ? ( <strong>{ formatDate(quoteOptionsResponse.deliveryDate) }</strong>)
                          : ( <strong>Not applicable</strong> )
                        }
                        Delivery date
                      </div>
                      <div className="card-list__column">
                        <strong className="card-meta__price">{ quoteOptionsResponse.rate ? `€ ${quoteOptionsResponse.rate}` : "Not applicable" }</strong>
                        { quoteOptionsResponse.rate ? compareAverageRate(quoteOptionsResponse.rate) : "-" }
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              { (quoteOptionsResponse.accepted !== false && quoteOptionsResponse.rateRequestResponse?.additionalsResponses.length)
                  ? (
                  <div className="card card--primary">
                    <div className="group">
                      <div className="xxs12">
                        <div className="card-headings">
                          <h4>Additionals</h4>
                        </div>
                      </div>
                    </div>
                    <div className="additionals-list is-expanded">
                      <div className="additionals-list__header">
                        <div className="additionals-list__column additionals-list__column-label"></div>
                        <div className="additionals-list__column">
                          <strong>Origin rate</strong>
                        </div>
                        <div className="additionals-list__column">
                          <strong>Destination rate</strong>
                        </div>
                      </div>
                      <ul className="list list--additionals">
                        { quoteOptionsResponse.rateRequestResponse?.additionalsResponses?.map((additionalResponse: AdditionalResponse, index: number) =>
                          <li key={ additionalResponse.additionalId } className="list__item">
                            <div className="additionals-list__column additionals-list__column-label">
                              { getAdditional(additionalResponse.additionalId).label }
                            </div>
                            <div className="additionals-list__column">
                              {
                                (!checkAdditionalSelection(additionalResponse.additionalId, 'selectedOrigin'))
                                  ? ""
                                  : (additionalResponse.rateOrigin)
                                    ? `€ ${additionalResponse.rateOrigin}`
                                    : "-"
                              }
                            </div>
                            <div className="additionals-list__column">
                              {
                                (!checkAdditionalSelection(additionalResponse.additionalId, 'selectedDestination'))
                                  ? ""
                                  : (additionalResponse.rateDestination)
                                    ? `€ ${additionalResponse.rateDestination}`
                                    : "-"
                              }
                            </div>
                          </li>
                        )}
                        { quoteOptionsResponse.rateRequestResponse?.customAdditionalsResponses?.map((customAdditionalResponse: AdditionalResponse, index: number) =>
                          <li key={ customAdditionalResponse.additionalId } className="list__item">
                            <div className="additionals-list__column additionals-list__column-label">
                              { getCustomAdditional(customAdditionalResponse.additionalId).label }
                            </div>
                            <div className="additionals-list__column">
                              {
                                (!checkCustomAdditionalSelection(customAdditionalResponse.additionalId, 'selectedOrigin'))
                                  ? ""
                                  : (customAdditionalResponse.rateOrigin)
                                    ? `€ ${customAdditionalResponse.rateOrigin}`
                                    : "-"
                              }
                            </div>
                            <div className="additionals-list__column">
                              {
                                (!checkCustomAdditionalSelection(customAdditionalResponse.additionalId, 'selectedDestination'))
                                  ? ""
                                  : (customAdditionalResponse.rateDestination)
                                    ? `€ ${customAdditionalResponse.rateDestination}`
                                    : "-"
                              }
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )
                : null
              }

              { quoteOptionsResponse.accepted === false &&
                <div className="card card--primary">
                  <div className="card__top card__top--secondary">
                    <div className="card-headings">
                      <h4>Motivation</h4>
                    </div>
                  </div>
                  <div className="card__content">
                  <p>{ quoteOptionsResponse.message || "N/A" }</p>
                  </div>
                </div>
              }

              { (props.rateRequest.additionals?.find((additional: any) => additional.selectedDestination === true || additional.selectedOrigin === true) || props.rateRequest.customAdditionals?.length > 0) &&
                <div className="card card--primary">
                  <div className="group">
                    <div className="xxs12">
                      <h4>Additionals</h4>
                    </div>
                  </div>
                  <ul className="list list--additionals">
                    <li className="list__item">
                      <div className="additionals-list__column additionals-list__column-label"></div>
                      <div className="additionals-list__column additionals-list__column--center">
                        <strong>Origin</strong>
                      </div>
                      <div className="additionals-list__column additionals-list__column--center">
                        <strong>Destination</strong>
                      </div>
                    </li>

                    { props.rateRequest.additionals?.map((additional: any) =>
                      ( additional.selectedDestination || additional.selectedOrigin) && (
                        <li className="list__item" key={additional.id}>
                          <div className="additionals-list__column additionals-list__column-label">
                            { additional.item.label }
                          </div>
                          <div className="additionals-list__column additionals-list__column--center">
                            { additional.selectedOrigin ? (<IconCheckmarkGreen />) : (<IconCrossRed />) }
                          </div>
                          <div className="additionals-list__column additionals-list__column--center">
                            { additional.selectedDestination ? (<IconCheckmarkGreen />) : (<IconCrossRed />) }
                          </div>
                        </li>
                      )
                    ) }

                    { props.rateRequest.customAdditionals?.map((additional: any) =>
                      ( additional.selectedDestination || additional.selectedOrigin) && (
                        <li className="list__item" key={additional.id}>
                          <div className="additionals-list__column additionals-list__column-label">
                            { additional.item.label }
                          </div>
                          <div className="additionals-list__column additionals-list__column--center">
                            { additional.selectedOrigin ? (<IconCheckmarkGreen />) : (<IconCrossRed />) }
                          </div>
                          <div className="additionals-list__column additionals-list__column--center">
                            { additional.selectedDestination ? (<IconCheckmarkGreen />) : (<IconCrossRed />) }
                          </div>
                        </li>
                      )
                    ) }
                  </ul>
                </div>
              }

              <div className="card card--primary">
                <div className="group">
                  <div className="xxs12">
                    <h4>Rate request details</h4>
                  </div>
                </div>
                <div className="group">
                  <div className="xxs12 md6">
                    <div className="card__column">
                      <h6 className="card__column-subheading">Reference order</h6>
                      <p>{ props.rateRequest.referenceOrder }</p>
                    </div>
                    <div className="card__column">
                      <h6 className="card__column-subheading">Target rate</h6>
                      <p> { props.rateRequest.targetRate ? `€ ${props.rateRequest.targetRate}` : 'N/A' }</p>
                    </div>
                  </div>
                  <div className="xxs12 md6">
                    <div className="card__column">
                      <h6 className="card__column-subheading">Rate request number</h6>
                      <p>{ props.rateRequest.rateRequestNumber }</p>
                    </div>
                    <div className="card__column">
                      <h6 className="card__column-subheading">Supplier response deadline</h6>
                      <p>{ moment(props.rateRequest.timeframe).format('DD-MM-YYYY') }</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card--primary">
                <div className="group">
                  <div className="xxs12">
                    <h4>Origin &amp; Destination</h4>
                  </div>
                </div>
                <div className="group">
                  <div className="xxs12 md6">
                    <div className="card__column">
                      <h6 className="card__column-subheading">Origin</h6>
                      <p>
                        {
                          props.rateRequest.originAddress
                            ? props.rateRequest.originAddress.city
                              ? props.rateRequest.originAddress.name
                              : `${ props.rateRequest.originAddress.name }, ${ props.rateRequest.origin.city }, ${ props.rateRequest.origin.country }`
                            : props.rateRequest.origin?.name
                        }
                      </p>
                    </div>
                    <div className="card__column">
                      <h6 className="card__column-subheading">Target loading date</h6>
                      <p>{ props.rateRequest.loadingDate ? moment(props.rateRequest.loadingDate).format("DD-MM-YYYY") : "N/A" }</p>
                    </div>
                  </div>
                  <div className="xxs12 md6">
                    <div className="card__column">
                      <h6 className="card__column-subheading">Destination</h6>
                      <p>
                        {
                          props.rateRequest.destinationAddress
                            ? props.rateRequest.destinationAddress.city
                              ? props.rateRequest.destinationAddress.name
                              : `${ props.rateRequest.destinationAddress.name }, ${ props.rateRequest.destination.city }, ${ props.rateRequest.destination.country }`
                            : props.rateRequest.destination?.name
                        }
                      </p>
                    </div>
                    <div className="card__column">
                      <h6 className="card__column-subheading">Target delivery date</h6>
                      <p>{ props.rateRequest.loadingDate ? moment(props.rateRequest.loadingDate).format("DD-MM-YYYY") : "N/A" }</p>
                    </div>
                  </div>
                </div>

                { props.rateRequest.origin && props.rateRequest.destination &&
                  <div className="group">
                    <div className="xxs12">
                      <TransportDetails
                        originAddress={ props.rateRequest.originAddress }
                        origin={ props.rateRequest.origin }
                        destinationAddress={ props.rateRequest.destinationAddress }
                        destination={ props.rateRequest.destination }
                      />
                    </div>
                  </div>
                }
              </div>

              <div className="card card--primary">
                <div className="group">
                  <div className="xxs12">
                    <h4>Supplier details</h4>
                  </div>
                </div>
                <div className="card__content">
                  <div className="group">
                    <div className="xxs12 md6">
                      <div className="card__column">
                        <h6 className="card__column-subheading">E-mail address</h6>
                        <p>{ quoteOptionsResponse.rateRequestResponse?.overrideSupplierEmail || quoteOptionsResponse.rateRequestResponse?.supplier?.customEmail || quoteOptionsResponse.rateRequestResponse?.supplier?.car?.ContactInfo.INFEM }</p>
                      </div>
                    </div>
                    <div className="xxs12 md6"></div>
                  </div>
                  <div className="group">
                    <div className="xxs12 md6">
                      <div className="card__column">
                        <h6 className="card__column-subheading">Contact person</h6>
                        <p>{ quoteOptionsResponse.rateRequestResponse?.contactPerson
                              ? quoteOptionsResponse.rateRequestResponse?.contactPerson
                              : quoteOptionsResponse.rateRequestResponse?.supplier?.customName
                                ? quoteOptionsResponse.rateRequestResponse?.supplier?.customName
                                : quoteOptionsResponse.rateRequestResponse?.supplier?.car.ContactInfo.INFCONT
                        }</p>
                      </div>
                    </div>
                    <div className="xxs12 md6">
                      <div className="card__column">
                        <h6 className="card__column-subheading">Reference input</h6>
                        <p>{ quoteOptionsResponse.rateRequestResponse?.referenceInput || "N/A" }</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card card--primary">
                <div className="group">
                  <div className="xxs12">
                    <h4>Notes</h4>
                  </div>
                </div>
                <>
                  { addingNewNote
                    ? (
                      <>
                        <div className="group group--small-margin">
                          <div className="xxs12">
                            <Textarea
                              value={ newNote }
                              name="notes[]"
                              placeholder="e.g. difficult access or busy street"
                              onChange={ (event: React.FormEvent<HTMLInputElement>) => setNewNote((event.target as HTMLTextAreaElement).value) }
                              disabled={ pending }
                            />
                          </div>
                        </div>
                        <div className="group">
                          <div className="xxs12 flex align-right">
                            <Button variant='ghost' onClick={ () => setAddingNewNote(false) }>Cancel</Button>
                            <Button variant='primary' onClick={ addNewNote }>Add new note</Button>
                          </div>
                        </div>
                      </>
                    )
                    : (
                      <div className="group">
                        <div className="xxs12">
                          <Button variant='ghost' onClick={ () => setAddingNewNote(true) }>Add extra note</Button>
                        </div>
                      </div>
                    )
                  }
                </>

                { props.rateRequest.notes?.map((note: StrictDict, index: number) =>
                  <React.Fragment key={ index }>
                      { note.isEditing
                      ? (
                        <div className="note-container-editing">
                          <div className="group group--small-margin">
                            <div className="xxs12">
                              <Textarea
                                value={ note.text }
                                name="notes[]"
                                placeholder="e.g. difficult access or busy street"
                                onChange={ (event: React.FormEvent<HTMLInputElement>) => updateNote(index, (event.target as HTMLTextAreaElement).value) }
                                disabled={ pending }
                              />
                            </div>
                          </div>
                          <div className="group">
                            <div className="xxs12 flex align-right">
                              <Button variant='ghost' onClick={ () => toggleEditNote(index, false) }>Cancel</Button>
                              <Button variant='primary' onClick={ () => editNote(index) }>Save changes</Button>
                            </div>
                          </div>
                        </div>
                      )
                      : (
                        <div className="group note">
                          <div className="xxs10">
                            <p>{ note.text }</p>
                            { note?.postedDate && (
                              <time className='note__date' title={ note.postedDate }>Posted on {
                                moment(typeof note.postedDate.toDate === 'function' ? note.postedDate.toDate() : note.postedDate).format('MMMM Do YYYY [at] HH:mm')
                              }</time>
                            ) }
                            { note?.editedDate && (
                              <time className='note__date' title={ note.editedDate }>&nbsp;• Last edited on {
                                moment(typeof note.postedDate.toDate === 'function' ? note.postedDate.toDate() : note.postedDate).format('MMMM Do YYYY [at] HH:mm')
                              }</time>
                            ) }
                          </div>
                          <div className="xxs2">
                            <EllipsisButton variant="ghost">
                              <>
                                <button onClick={ () => toggleEditNote(index, true) }>Edit note</button>
                                <button onClick={ () => { setDeleteModalVisible(true); setDeleteNoteIndex(index); } }>Delete note</button>
                              </>
                            </EllipsisButton>
                          </div>
                        </div>
                      )
                    }
                  </React.Fragment>
                ) }
              </div>

              {/* Below section is for legacy notes. In the past there could be only one note added to a rate request */}
              { props.rateRequest.message &&
                <div className="card card--primary">
                  <div className="group">
                    <div className="xxs12">
                      <h4>Notes</h4>
                    </div>
                  </div>
                  <div className="group">
                    <div className="xxs12">
                      <p>{ props.rateRequest.message }</p>
                    </div>
                  </div>
                </div>
              }

              <div className={ `card card--primary ${ !quoteOptionsResponse.originalRateRequestResponse?.comments ? 'hide-print' : ''}` }>
                <div className="group">
                  <div className="xxs12">
                    <h4>Comments</h4>
                  </div>
                </div>
                <>
                  <div className="group group--small-margin">
                    <div className="xxs12">
                      <Textarea
                        value={ newComment }
                        name="notes[]"
                        placeholder={ `Your message for ${ context.currentUser.custom ? (quoteOptionsResponse.rateRequestResponse?.supplier?.alias ? quoteOptionsResponse.rateRequestResponse?.supplier?.alias : quoteOptionsResponse.rateRequestResponse?.supplier?.car?.Client) : 'Gosselin' }...` }
                        onChange={ (event: React.FormEvent<HTMLInputElement>) => setNewComment((event.target as HTMLTextAreaElement).value) }
                        disabled={ pending }
                      />
                    </div>
                  </div>
                  <div className="group">
                    <div className="xxs12 flex align-right">
                      <Button variant='primary' onClick={ addNewComment }>Post comment</Button>
                    </div>
                  </div>
                </>
                { quoteOptionsResponse.originalRateRequestResponse?.comments?.map((comment: StrictDict, index: number) =>
                  <React.Fragment key={ index }>
                    <div className="group note">
                      <div className="xxs10">
                        <p>{ comment.text }</p>
                        <p className='comment__date'>Posted by { comment.author } on { ' ' }
                          <time>{ moment(parseDate(comment.postedDate)).format('MMMM Do YYYY [at] HH:mm') }</time>
                          { comment.editedDate && (
                            <>
                              &nbsp;• Last edited on
                              <time>{ moment(parseDate(comment.editedDate)).format('MMMM Do YYYY [at] HH:mm') }</time>
                            </>
                          ) }
                        </p>
                      </div>
                    </div>
                  </React.Fragment>
                ) }
              </div>
            </div>
          </div>
        }
      </div>

      <Modal variant="primary" title="Accepting this quote" visible={ acceptModalVisible } pending={ pending } confirmAction={ acceptQuote } confirmLabel="Accept quote" cancelAction={ () => setAcceptModalVisible(false) }>
        Accepting this quote will send out a message to the selected supplier that the order has been made final.
      </Modal>

      <Modal variant="danger" title="Are you sure you want to delete this note?" visible={ deleteModalVisible } confirmAction={ deleteNote } confirmLabel="Delete note" cancelAction={ () => setDeleteModalVisible(false) }>
        This action can not be restored.
      </Modal>
    </>
  )
}
