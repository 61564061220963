import React from "react";

type Props = {
  checked?: boolean;
  value: string;
  name: string;
  onChange?: any;
  required?: boolean;
  label: string;
  aside?: any;
  disabled?: boolean;
};

export default function FormRadio(props: Props) {
  return (
    <label className={`radio ${props.disabled ? "is-disabled" : ""}`}>
      <input className="radio__input" type="radio" name={props.name} value={props.value} checked={props.checked} onChange={props.onChange} required={props.required} disabled={props.disabled}/>
      <div className="radio__element"></div>
      <span className="radio__label">{props.label}</span>
    </label>
  );
}
