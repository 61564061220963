import React, { useState, useRef, useEffect } from "react";
import { default as ReactSelect } from "react-select";

import { ReactComponent as IconDanger } from "../../assets/images/icons/danger.svg";

type Props = {
  value: any;
  name: string;
  onChange: any;
  options: any;
  required?: boolean;
  label?: string;
  aside?: any;
  disabled?: boolean;
  placeholder?: string;
  searchable?: boolean;
  multi?: boolean;
  error?: any;
  showError?: boolean;
};

export default function FormSelect(props: Props) {
  const [ isDirty, setIsDirty ] = useState(false);
  const [ isInvalid, setIsInvalid ] = useState(props.required === true && (props.value === undefined || props.value === "" || props.value === null));

  const inputElement = useRef<HTMLInputElement>(null);

  const validateInput = (inputField: HTMLInputElement|null) => {
    if (!inputField) {
      return;
    }

    setIsDirty(true);

    let isValid = true;

    // @ts-ignore
    if (!inputField.validity.valid) {
      isValid = false;
    }

    setIsInvalid(!isValid);
  };

  const onChangeHandler = ((value: StrictDict, actionMeta: StrictDict) => {
    setIsDirty(true);

    let isValid = true;

    // @ts-ignore
    if (props.required) {
      if (!value || !value.hasOwnProperty('value') || value.value === null || value.value === "") {
        isValid = false;
      }
    }

    setIsInvalid(!isValid);

    props.onChange(value, actionMeta);
  });

  useEffect(() => {
    if (props.showError) {
      validateInput(inputElement.current);
    }

  }, [ props.showError, props.value ]);

  return (
    <div className={`select ${props.error ? "has-error" : ""} ${isDirty ? "is-dirty" : ""} ${isInvalid ? "is-invalid" : ""}  ${props.required ? "is-required" : ""}`}>
      {props.label && (
        <div className="select__label">
          <label className="select__label-element" htmlFor={props.name}>{props.label}</label>
          {props.aside && (
            <div className="select__label-aside">{props.aside}</div>
          )}
        </div>
      )}
      <div className="select__container">
        <ReactSelect
          className="react-select"
          classNamePrefix="react-select"
          options={props.options}
          value={props.value}
          onChange={onChangeHandler}
          placeholder={props.placeholder}
          required={props.required}
          isSearchable={props.searchable}
          isMulti={props.multi}
          isDisabled={props.disabled}
        />
        {!props.disabled && (
          <input
            tabIndex={-1}
            autoComplete="off"
            defaultValue={ props.value?.value }
            required={props.required}
            ref={ inputElement }
          />
        )}
      </div>
      {props.error &&
        <div className="select__error-label">
          <IconDanger />
          <span>{props.error}</span>
        </div>
      }
    </div>
  );
}
