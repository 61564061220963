import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import relationshipLabels from "../../../../../src/assets/data/relationship-labels.json";
import { Input, Select } from "../../../../components/Form";

import GlobalData from "../../../../context/globaldata";

// @ts-ignore
import { store } from "react-notifications-component";

import Chip from "../../../../components/Chip/Chip";
import Button from "../../../../components/Button/Button";
import EllipsisButton from "../../../../components/Button/EllipsisButton";
import Modal from "../../../../components/Modal/Modal";

import { ReactComponent as IconNotification } from "../../../../assets/images/icons/notification.svg";
import parseDate from "../../../../utils/ParseDate";

type Props = {
  filters?: StrictDict;
  quoteOptionsResponse: QuoteOptionResponse;
  allQuoteOptionsResponses: Array<QuoteOptionResponse>;
  alternativeQuoteOptionsResponses?: Array<QuoteOptionResponse>;
  setGetRateRequestResponses?: any;
  showRateRequestTitle?: boolean;
};

export default function QuotesCard(props: Props) {
  const history = useHistory();
  const context = useContext(GlobalData);
  const [ quoteOptionsResponse, setQuoteOptionsResponse ] = useState<QuoteOptionResponse>({} as QuoteOptionResponse);
  const [ averageRate, setAverageRate ] = useState<number|null>(null);
  const [ averageDuration, setAverageDuration ] = useState<number|null>(null);
  const [ supplier, setSupplier ] = useState<StrictDict|null>(null);
  const [ reminderModalVisible, setReminderModalVisible ] = useState<boolean>(false);
  const [ deleteModalVisible, setDeleteModalVisible ] = useState<boolean>(false);
  const [ reminderSent, setReminderSent ] = useState<boolean>(false);

  const supplierName = useMemo(() => {
    return supplier?.alias ? supplier?.alias : supplier?.car?.Client;
  }, [ supplier ]);

  useEffect(() => {
    setQuoteOptionsResponse(props.quoteOptionsResponse);
  }, [ props.quoteOptionsResponse ]);

  const compareAverageDuration = useCallback((duration: number = 0) => {
    // if (props.quoteOptionsResponse.rateRequestResponse?.supplier?.relationship === 4) {
    //   return false;
    // }

    if (averageDuration === null) {
      return;
    }

    if (typeof duration !== 'number') {
      return <span>Calc error</span>;
    }

    const compAverage = Math.round(averageDuration - duration);

    let message = "Average";
    const day = Math.abs(compAverage);
    let dayString = "";
    let stateClass = "compared-value";

    if (day !== 0) {
      dayString = (day === 1)
        ? "1 day"
        : `${day} days`;

      if (compAverage < 0) {
        message = " above average";
        stateClass += " compared-value--above-average";
      } else if (compAverage > 0) {
        message = " below average";
        stateClass += " compared-value--below-average";
      }
    } else {
      stateClass += " compared-value--average";
    }

    return <span className={ stateClass }>{ dayString }{ message }</span>;
  }, [ averageDuration ]);

  const compareAverageRate = useCallback((rate: number) => {
    if (averageRate === null) {
      return;
    }

    if (props.quoteOptionsResponse.rateRequestResponse?.supplier?.relationship === 4) {
      return false;
    }

    const compAverage = Math.round(averageRate - rate);

    let message = "Average";
    const rateAbs = Math.abs(compAverage);
    let rateString = "";
    let stateClass = "compared-value";

    // Only count as not-average when exceeding 10 euro threshold
    if (rateAbs > 10) {
      rateString = `€ ${rateAbs}`;

      if (compAverage < 0) {
        message = " above average";
        stateClass += " compared-value--above-average";
      } else if (compAverage > 0) {
        message = " below average";
        stateClass += " compared-value--below-average";
      }
    } else {
      stateClass += " compared-value--average";
    }

    return <span className={ stateClass }>{ rateString }{ message }</span>;
  }, [ averageRate, props.quoteOptionsResponse.rateRequestResponse ]);

  const isQuoteValid = () => {
    return quoteOptionsResponse.rateRequestResponse?.submittedAt ? true : false;
  };

  const dynamicChip = () => {
    if (!quoteOptionsResponse.accepted) {
      return <Chip status={3} />;
    }

    // Calculate days still valid from submittedAt date
    const duration = Math.round(moment.duration(moment(quoteOptionsResponse.rateRequestResponse?.submittedAt).add(60, 'days').diff(
      // @ts-ignore (ignore .toDate() error below)
      moment()
    ), 'milliseconds').asDays());

    if (duration < 1) {
      return <Chip status={4} />;
    }

    const pluralS = duration !== 1 ? 's' : '';

    return <Chip status={1} label={ `${duration} day${pluralS} valid` } />;
  };

  const formatDate = (date: Date|null|undefined) => {
    if (!date) {
      return 'Not applicable';
    }

    return moment(parseDate(date)).format('DD-MM-YYYY');
  };

  const findUser = (uid: String) => {
    return context.users.find((user: Account) => user.uid === uid) || {};
  };

  useEffect(() => {
    if (!props.allQuoteOptionsResponses.length && !props.alternativeQuoteOptionsResponses?.length) {
      return;
    }

    const combinedQuoteOptionsResponses = props.allQuoteOptionsResponses.concat(props.alternativeQuoteOptionsResponses || []);

    // Create a list of comparable quotes if they fit the volume range and filters
    const comparableQuotes = combinedQuoteOptionsResponses.filter((otherQuoteOptionsResponse: QuoteOptionResponse) => {
      // Rate not accepted
      if (!otherQuoteOptionsResponse.accepted) {
        return false;
      }

      if (otherQuoteOptionsResponse.rateRequestResponse?.supplier?.relationship === 4) {
        return false;
      }

      // No rate available
      if (!otherQuoteOptionsResponse.rate) {
        return false;
      }

      // No volume available
      if (!otherQuoteOptionsResponse.quoteOption?.volume) {
        return false;
      }

      // Different rate request mode
      if (otherQuoteOptionsResponse.quoteOption?.rateRequestMode !== quoteOptionsResponse.quoteOption?.rateRequestMode) {
        return false;
      }

      // Check if volume fits in the 20% range
      // @ts-ignore
      const currentVolume = parseInt(quoteOptionsResponse.quoteOption.volume)
      const otherQuoteOptionVolume = otherQuoteOptionsResponse.quoteOption.volume as number;

      // @ts-ignore
      let minVolume = parseInt(currentVolume) * .8;

      // @ts-ignore
      let maxVolume = parseInt(currentVolume) * 1.2;

      if (minVolume > (currentVolume - 2)) {
        minVolume = currentVolume - 2;
      }

      if (maxVolume < (currentVolume + 2)) {
        maxVolume = currentVolume + 2;
      }

      if (otherQuoteOptionVolume < minVolume) {
        return false;
      }

      if (otherQuoteOptionVolume > maxVolume) {
        return false;
      }

      return true;
    });

    if (!comparableQuotes.length) {
      return;
    }

    // Calculate difference for rate and duration based on average
    // of all other rates that fit the range/filters
    let rateTotal: number = 0;
    let durationTotal: number = 0;

    comparableQuotes.forEach((otherQuoteOptionsResponse: QuoteOptionResponse) => {
      // @ts-ignore
      rateTotal += parseInt(otherQuoteOptionsResponse.rate);

      durationTotal += otherQuoteOptionsResponse.duration || 0;
    });

    setAverageRate(rateTotal / comparableQuotes.length);
    setAverageDuration(durationTotal / comparableQuotes.length);

  }, [ props.allQuoteOptionsResponses, props.alternativeQuoteOptionsResponses, quoteOptionsResponse.quoteOption ]);

  useEffect(() => {
    if (quoteOptionsResponse.rateRequestResponse?.supplier) {
      quoteOptionsResponse.rateRequestResponse.supplier.overrideEmail = quoteOptionsResponse.rateRequestResponse?.overrideSupplierEmail;

      if (quoteOptionsResponse.rateRequestResponse.supplier?.overrideEmail) {
        quoteOptionsResponse.rateRequestResponse.supplier.showCustomEmail = true;
      }

      setSupplier(quoteOptionsResponse.rateRequestResponse?.supplier);
    }
  }, [ quoteOptionsResponse.rateRequestResponse ]);

  // Send reminder e-mail on modal confirmation
  const sendReminder = useCallback(() => {
    const newData = {
      sendReminder: true,
      sendReminderEmail: supplier?.overrideEmail
    };

    context.firestore
    .collection('rate_requests').doc(quoteOptionsResponse?.rateRequest?.uid)
    .collection('responses').doc(quoteOptionsResponse?.rateRequestResponse?.uid)
    .update(newData)
    .then(() => {
      setReminderModalVisible(false);
      setReminderSent(true);

      store.addNotification({
        message: "The reminder has been sent",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });
    })
    .catch((error: string) => {
      store.addNotification({
        message: `Error sending reminder: ${error}`,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });
    });
  }, [ supplier, quoteOptionsResponse, context.firestore ]);

  const getEmailOptions = useCallback(() => {
    if (!supplier) {
      return null;
    }

    const primaryEmail = supplier.customEmail || supplier.car?.ContactInfo.INFEM[0].toLowerCase();

    let emailOptions: Array<OptionType> = [
      {
        "label": `${ primaryEmail }\n(Primary contact)`,
        "value": primaryEmail
      }
    ];

    if (supplier.escalationEmail) {
      emailOptions.push({
        "label": `${ supplier.escalationEmail }\n(Escalation contact)`,
        "value": supplier.escalationEmail
      });
    }

    let additionalContacts: Array<OptionType> = [];

    if (supplier.contacts) {
      additionalContacts = supplier.contacts.map((contact: SupplierContact, contactIndex: number): OptionType => {
        return {
          "label": `${ contact.email }\n(Additional contact ${ contactIndex + 1 })`,
          "value": contact.email
        };
      });
    }

    emailOptions = emailOptions.concat(additionalContacts);

    emailOptions.push({
      "label": `Add custom e-mail`,
      "value": supplier.overrideEmail || null
    });

    return emailOptions;
  }, [ supplier ]);

  const getSelectedEmail = () => {
    const options = getEmailOptions();

    if (!options) {
      return;
    }

    if (supplier?.showCustomEmail) {
      return options[options.length - 1];
    }

    if (supplier?.overrideEmail && supplier?.overrideEmail.length > 0) {
      return options.find(option => option.value === supplier?.overrideEmail);
    } else {
      return options[0];
    }
  };

  function toggleCustomEmail(visible: boolean) {
    setSupplier((prevState: any) => {
      const nextState = Object.assign({}, prevState);

      console.log(visible);

      nextState.showCustomEmail = visible;

      return nextState;
    });
  }

  const deleteQuote = () => {
    // delete nonCircularResponse;
    const newRateRequestResponse = quoteOptionsResponse.originalRateRequestResponse as RateRequestResponse;
    const newQuoteOptionResponse = newRateRequestResponse.quoteOptionsResponses.find((otherQuoteOptionsResponse: QuoteOptionResponse) => otherQuoteOptionsResponse.id === quoteOptionsResponse.id);

    if (!newQuoteOptionResponse) {
      return;
    }

    newQuoteOptionResponse.status = -1;

    console.log(quoteOptionsResponse);

    context.firestore
    .collection('rate_requests').doc(quoteOptionsResponse?.rateRequest?.uid)
    .collection('responses').doc(quoteOptionsResponse?.rateRequestResponse?.uid)
    .update(newRateRequestResponse)
    .then(() => {
      setDeleteModalVisible(false);
      props.setGetRateRequestResponses && props.setGetRateRequestResponses(true);

      store.addNotification({
        message: `Quote ${ quoteOptionsResponse.id } has been deleted`,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });
    })
    .catch((error: string) => {
      store.addNotification({
        message: `Error deleting quote: ${error}`,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });
    });
  };

  const getLoadingDate = () => {
    if (quoteOptionsResponse.loadingDate) {
      return formatDate(quoteOptionsResponse.loadingDate);
    } else if (quoteOptionsResponse.rateRequest?.loadingDateApproximate && quoteOptionsResponse.rateRequest?.loadingDateApproximateValue) {
      return quoteOptionsResponse.rateRequest?.loadingDateApproximateValue;
    } else {
      return formatDate(quoteOptionsResponse.rateRequest?.loadingDate);
    }
  }

  const getDeliveryDate = () => {
    if (quoteOptionsResponse.deliveryDate) {
      return formatDate(quoteOptionsResponse.deliveryDate);
    } else if (quoteOptionsResponse.rateRequest?.deliveryDateApproximate && quoteOptionsResponse.rateRequest?.deliveryDateApproximateValue) {
      return quoteOptionsResponse.rateRequest?.deliveryDateApproximateValue;
    } else {
      return formatDate(quoteOptionsResponse.rateRequest?.deliveryDate);
    }
  }

  return (
    <>
    { (quoteOptionsResponse.quoteOption) && (
      <div className="result result--card">
        <div className="result-labels">
          { quoteOptionsResponse.quoteAccepted &&
            <div className="result-labels__item default">Accepted</div>
          }

          { quoteOptionsResponse.meta?.labels.map((labelObj: StrictDict, index: number) =>
            (labelObj.active)
            ? <div className="result-labels__item" key={ index }>{ labelObj.label }</div>
            : null
          ) }
        </div>
        <div className="result__top">
          { props.showRateRequestTitle &&
            <>
              <div className="card-headings">
                <h4>{ quoteOptionsResponse.rateRequest?.referenceOrder }</h4>
                <h5>{ quoteOptionsResponse.rateRequest?.rateRequestNumber }</h5>
              </div>
            </>
          }

          { !props.showRateRequestTitle &&
            <div className="result__headings">
              { supplier?.relationship &&
                <>
                  <ReactTooltip place="top" effect="solid" delayShow={500} />
                  <div className={ `result__relationship ${ supplier?.status.inactive ? 'result__relationship--inactive' : '' }` } data-tip={ relationshipLabels[supplier?.relationship].label }>
                    { supplier?.relationship }
                  </div>
               </>
              }
              <div className={ `result__heading ${ supplier?.status.inactive ? 'result__heading--inactive' : '' }` }>
                { supplierName }
              </div>
              { quoteOptionsResponse.rateRequestResponse?.submittedUserUid &&
                <div className="result__subheading result__subheading--alt">Quote added by { findUser(quoteOptionsResponse.rateRequestResponse?.submittedUserUid).displayName }</div>
              }
            </div>
          }
          <div className="result__aside">
            { quoteOptionsResponse.accepted && quoteOptionsResponse.rate &&
              <>
                <div className="card-meta">
                  <h4 className="card-meta__price">€ { quoteOptionsResponse.rate }</h4>
                  {/* { compareAverageRate(quoteOptionsResponse.rate || 0) } */}
                </div>
              </>
            }

            <EllipsisButton variant="ghost">
              <>
                <button onClick={ () => setDeleteModalVisible(true) }>Delete quote</button>
              </>
            </EllipsisButton>

            { quoteOptionsResponse.rateRequestResponse?.submittedAt &&
              <Button variant="primary" onClick={ () => history.push(`/rate-requests/${quoteOptionsResponse.rateRequest?.uid}/quotes/${quoteOptionsResponse.rateRequestResponse?.uid}/${quoteOptionsResponse.quoteOptionId}`) }>
                <span>{
                  quoteOptionsResponse.accepted
                    ? "View quote"
                    : "View denial"
                  }
                </span>
              </Button>
            }

            { !quoteOptionsResponse.rateRequestResponse?.submittedAt &&
              <>
                {
                  // @ts-ignore
                  (quoteOptionsResponse.rateRequest?.timeframe >= new Date())
                    ? <>
                        <Button variant="ghost" onClick={ () => setReminderModalVisible(true) } disabled={ reminderSent }>
                          <>
                            <IconNotification className="button__icon" />
                            <span>{ reminderSent ? 'Reminder sent' : 'Send reminder' }</span>
                          </>
                        </Button>
                        <Button
                          tip="Add manual quote if provider does not use the web tool"
                          variant="primary" onClick={ () => history.push(`/response/${quoteOptionsResponse.rateRequestResponse?.uuid}`) }>
                          <span>Add quote</span>
                        </Button>
                      </>
                    : <Button variant="primary" customClassName="is-disabled-clickable" onClick={ () => history.push(`/response/${quoteOptionsResponse.rateRequestResponse?.uuid}`) }>
                        <span>Expired</span>
                      </Button>
                }
              </>
            }
          </div>
        </div>

        <ul className="result__orders">
          <li className="result__orders-item">
            <div className="result__orders-column">
              <strong>{ quoteOptionsResponse.quoteOption?.volume } m³</strong>
              <span>{ context.rateRequestLabels.packagingTypes[quoteOptionsResponse.quoteOption?.packagingType]?.label }</span>
            </div>
            <div className="result__orders-column">
              <strong>{ context.rateRequestLabels.rateRequestModes[quoteOptionsResponse.quoteOption?.rateRequestMode]?.labelShort }</strong>
              <span>{ context.rateRequestLabels.transportModes[quoteOptionsResponse.quoteOption?.transportMode]?.label }</span>
            </div>
            <div className="result__orders-column">
              { quoteOptionsResponse.accepted
                ? ( <strong>{ getLoadingDate() }</strong>)
                : ( <strong>Not applicable</strong> )
              }
              Loading date
            </div>
            <div className="result__orders-column">
              { quoteOptionsResponse.accepted && quoteOptionsResponse.duration !== null ? (
                <>
                  <strong>{ quoteOptionsResponse.duration === 0
                              ? "Same day"
                              : (quoteOptionsResponse.duration === 1
                                  ? `${quoteOptionsResponse.duration} day`
                                  : `${quoteOptionsResponse.duration} days`)
                  }</strong>
                  {/* { compareAverageDuration(quoteOptionsResponse.duration) } */}
                </>
              ) : (
                <>
                  <strong>Not applicable</strong>
                  -
                </>
              ) }
            </div>
            <div className="result__orders-column">
              { quoteOptionsResponse.accepted
                ? ( <strong>{ getDeliveryDate() }</strong>)
                : ( <strong>Not applicable</strong> )
              }
              Delivery date
            </div>
            <div className="result__orders-column">
              { isQuoteValid()
                  ? dynamicChip()
                  : <Chip status={2} />
              }
            </div>
          </li>
        </ul>
      </div>
    )}
    <Modal variant="danger" title="Are you sure you want to delete this quote?" visible={ deleteModalVisible } confirmAction={ deleteQuote } confirmLabel="Delete quote" cancelAction={ () => setDeleteModalVisible(false) }>
      This action can not be restored.
    </Modal>
    <Modal variant="primary" title="Send reminder" size="large" visible={ reminderModalVisible } confirmAction={ sendReminder } confirmLabel="Send reminder" cancelAction={ () => setReminderModalVisible(false) } customClassName='modal__box--overflow'>
      <>
        <ul className="list list--selected-suppliers">
          <li key={ supplier?.uid } className="list__item list__item--align-top">
            <div className="xxs12 md6 item-label item-label--wide">
              { supplierName }
            </div>
            <div className="xxs12 md6">
              <Select
                value={ getSelectedEmail() }
                name="supplierEmail"
                placeholder="Select e-mail address"
                onChange={ (option: StrictDict) => {
                  setSupplier({
                    ...supplier,
                    overrideEmail: option.value
                  });

                  if (option.label === "Add custom e-mail") {
                    toggleCustomEmail(true);
                  } else {
                    toggleCustomEmail(false);
                  }
                } }
                options={ getEmailOptions() }
                searchable={ false }
              />
              { supplier?.showCustomEmail &&
                <Input placeholder="Custom e-mail address"
                  type="email"
                  value={ supplier.overrideEmail }
                  onChange={(event: InputEvent) => {
                    setSupplier({
                      ...supplier,
                      overrideEmail: (event.target as HTMLInputElement).value
                    });
                  }}
                  name={`email-${supplier.supplierUid}`}
                  required
                />
              }
            </div>
          </li>
        </ul>
      </>
    </Modal>
  </>
  )
}
