import React, { useContext, useState, useEffect, useCallback } from "react";
import { Switch, Route, useHistory, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// @ts-ignore
import { store } from "react-notifications-component";

import GlobalData from '../../context/globaldata';

import RateRequestEdit from "./Edit";
import RateRequestSelect from "./Select";

import Button from "../../components/Button/Button";
import { Header, Navigation } from "../../components/Layout";
import Modal from "../../components/Modal/Modal";

import { ReactComponent as IconArrowRight } from "../../assets/images/icons/arrow-right.svg";
import { nanoid } from "nanoid";

export default function RateRequestEditIndex(props: any) {
  process.env.NODE_ENV === 'development' && console.log('Re-rendering RateRequestEditIndex.tsx');

  const history = useHistory();
  const { uid } = useParams() as any;
  const context = useContext(GlobalData);
  const [ deleteModalVisible, setDeleteModalVisible ] = useState(false);
  const [ showError, setShowError ] = useState(false);
  const [ hasErrors, setHasErrors ] = useState(true);
  const [ requestRatesModalVisible, setRequestRatesModalVisible ] = useState(false);
  const [ infoModalOpen, setInfoModalOpen ] = useState<boolean>(false);
  const [ selectedInfoIndex, setSelectedInfoIndex ] = useState<number>(0);

  const labels = context.rateRequestLabels;

  const openInfoModal = (rateRequestMode: number) => {
    setSelectedInfoIndex(rateRequestMode || 0);
    setInfoModalOpen(true);
  };

  const gotoNextStep = () => {
    setShowError(true);
    setHasErrors(checkErrors(true));

    if (!hasErrors) {
      history.push(`/rate-requests/${uid}/select`);
    }
  };

  const checkErrors = useCallback((withFocus = false) => {
    // @ts-ignore
    const invalidElement:HTMLElement = document.querySelector('.is-invalid input, .quote-options-list:empty + .button');

    if (invalidElement) {
      if (showError && withFocus) {
        invalidElement.focus();
      }

      return true;
    }

    return false;
  }, [ showError ]);

  useEffect(() => {
    setTimeout(() => {
      setHasErrors(checkErrors());
    }, 1);
  }, [ props.rateRequest, showError, checkErrors ]);

  const hasSuppliers = () => {
    return props.rateRequest.selectedSuppliers && props.rateRequest.selectedSuppliers.length > 0;
  };

  const navigationItems = [
    {
      to: `/rate-requests/${uid}/edit`,
      label: "Create rate request",
      exact: true,
      step: 1
    },
    {
      to: `/rate-requests/${uid}/select`,
      label: "Select suppliers",
      exact: true,
      step: 2,
      disabled: hasErrors
    }
  ];

  const saveChanges = (exit: boolean = false, newData?: RateRequest, exitLocation: string|null = null) => {
    props.setPending(true);
    const newRateRequestData = newData||props.rateRequest;

    console.log('newRateRequestData', newRateRequestData);

    context.firestore.collection("rate_requests").doc(uid).set(newRateRequestData)
    .then(() => {
      store.addNotification({
        message: "Rate request successfully saved",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      props.setPending(false);

      exit && history.push(exitLocation || "/rate-requests");
    })
    .catch((error: string) => {
      store.addNotification({
        message: `Error saving rate request: ${error}`,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      props.setPending(false);
    });
  };

  const deleteRateRequest = () => {
    props.setDeleted(true);
    context.firestore.collection("rate_requests").doc(uid).delete()
    .then(() => {
      store.addNotification({
        message: `Successfully deleted rate request: ${uid}`,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      history.push("/rate-requests");
    })
    .catch((error: string) => {
      props.setDeleted(false);
      store.addNotification({
        message: `Error deleting rate request: ${error}`,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });
    });
  };

  function requestRates() {
    props.setPending(true);
    // Create responses subcollection with pre-determined response objects for each supplier

    // Set up quoteOptionsResponse array based on supplied quoteOptions in rateRequest
    const quoteOptionResponses:Array<QuoteOptionResponse> = [];

    props.rateRequest.quoteOptions.forEach((quoteOption: QuoteOption) => {
      const quoteOptionResponse:QuoteOptionResponse = {
        id: "", // Set id for each supplier uniquely
        quoteOptionId: quoteOption.id,
        accepted: true,
        rate: null,
        loadingDate: null,
        deliveryDate: null,
        status: 0,
        message: null,
        selectedStatus: {
          label: "Accept bid",
          value: true
        }
      };

      quoteOptionResponses.push(quoteOptionResponse);
    });

    // Set up additionalsResponses array based on supplied additionals in rateRequest
    const additionalsResponses:Array<AdditionalResponse> = [];

    props.rateRequest.additionals.forEach((additionalSelection: RateRequestAdditionalSelection) => {
      if (additionalSelection.selectedOrigin || additionalSelection.selectedDestination) {
        const additionalResponse:AdditionalResponse = {
          additionalId: additionalSelection.id,
          rateOrigin: null,
          rateDestination: null
        };

        additionalsResponses.push(additionalResponse);
      }
    });

    // Set up customAdditionalsResponses array based on supplied additionals in rateRequest
    const customAdditionalsResponses:Array<AdditionalResponse> = [];

    props.rateRequest.customAdditionals.forEach((additionalSelection: RateRequestAdditionalSelection) => {
      if (additionalSelection.selectedOrigin || additionalSelection.selectedDestination) {
        const additionalResponse:AdditionalResponse = {
          additionalId: additionalSelection.id,
          rateOrigin: null,
          rateDestination: null
        };

        customAdditionalsResponses.push(additionalResponse);
      }
    });

    // Start batch
    const batch = context.firestore.batch();

    const selectedUids: Array<string> = [];

    // Create response document for each selected supplier
    props.rateRequest.selectedSuppliers
    .filter((selectedSupplier: SelectedSupplier) => {
      // Filtering the suppliers on duplicates.
      // Should not be necessary but there is a rare bug where suppliers apparently get selected twice

      if (selectedUids.includes(selectedSupplier.supplierUid)) {
        return false;
      }

      selectedUids.push(selectedSupplier.supplierUid);

      return true;
    })
    .forEach((selectedSupplier: SelectedSupplier) => {
      const quoteOptionResponsesWithIds = quoteOptionResponses.map((quoteOptionResponse: QuoteOptionResponse) => {
        quoteOptionResponse.id = nanoid();

        return quoteOptionResponse;
      });

      const response:RateRequestResponse = {
        supplierUid: selectedSupplier.supplierUid,
        // supplierEmail: selectedSupplier.supplierUid,
        status: 0,
        contactPerson: null,
        referenceInput: null,
        notes: [],
        additionalsResponses: additionalsResponses,
        customAdditionalsResponses: customAdditionalsResponses,
        quoteOptionsResponses: quoteOptionResponsesWithIds,
        submittedAt: null,
        overrideSupplierEmail: selectedSupplier.overrideEmail || '',
      };

      const newDocRef = context.firestore.collection("rate_requests").doc(uid).collection("responses").doc();

      batch.set(newDocRef, response);
    });

    // Commit batch
    batch.commit().then(() => {
      saveChanges(true, {...props.rateRequest, status: 1, userSubmitted: context.currentUser.custom?.uid}, `/rate-requests/${props.rateRequest.uid}/quotes`);
    });
  };

  return (
    <>
      <Header
        leftContent={ <Button variant="ghost" onClick={ () => saveChanges(true) }>Save and exit</Button> }
        middleContent={ <Navigation navItems={navigationItems} variant="secondary" /> }
        rightContent={
          <>
            <Route exact path="/rate-requests/:uid/edit" render={ () =>
              <Button onClick={ gotoNextStep } disabled={ props.pending }>
                <><span>Next step</span><IconArrowRight className="button__icon" /></>
              </Button>
            } />
            <Route exact path="/rate-requests/:uid/select" render={ () =>
              <Button onClick={ () => setRequestRatesModalVisible(true) } disabled={!hasSuppliers()}>
                <><span>Request rates</span><IconArrowRight className="button__icon" /></>
              </Button>
            } />
          </>
        }
      />

      <form onSubmit={() => saveChanges}>
        <Switch>
          <Route exact path="/rate-requests/:uid/edit" render={ () => <RateRequestEdit rateRequest={ props.rateRequest } openInfoModal={openInfoModal} setRateRequest={ props.setRateRequest } openDeleteModal={() => setDeleteModalVisible(true)} pending={ props.pending } showError={ showError } /> } />
          <Route exact path="/rate-requests/:uid/select" render={ () => <RateRequestSelect rateRequest={ props.rateRequest } setRateRequest={ props.setRateRequest } saveChanges={ saveChanges } pending={ props.pending } requestRatesModalVisible={ requestRatesModalVisible} setRequestRatesModalVisible={ setRequestRatesModalVisible } requestRates={ requestRates }/> } />
        </Switch>
      </form>

      <Modal variant="danger" title="Are you sure?" visible={ deleteModalVisible } pending={ props.pending } confirmAction={ deleteRateRequest } confirmLabel="Delete request" cancelAction={ () => setDeleteModalVisible(false) }>
        Deleting this rate request draft makes it no longer accessible.
      </Modal>

      <Modal visible={infoModalOpen} title={'Request modes'} noCancelButton={ true } cancelAction={ () => setInfoModalOpen(false) } size="large">
        <Tabs className="tab-container" defaultIndex={ selectedInfoIndex }>
          <TabList>
            { labels.rateRequestModes.map((rateRequestMode: any) => <Tab key={rateRequestMode.labelShort} onClick={ () => setSelectedInfoIndex(rateRequestMode.value) }>{ rateRequestMode.labelShort }</Tab>) }
          </TabList>

          <div className="tab-content">
            {/* General */}
            { labels.rateRequestModes.map((rateRequestMode: any) => (
              <TabPanel key={rateRequestMode.labelShort}>
                <h5><b>{ rateRequestMode.labelLong } ({ rateRequestMode.labelShort })</b></h5>
                { rateRequestMode.info }
              </TabPanel>
            ) ) }
          </div>
        </Tabs>
      </Modal>
    </>
  )
}
