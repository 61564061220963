import React from "react";
import Chip from "../Chip/Chip";

type Props = {
  value: string;
  name: string;
  onChange: any;
  required?: boolean;
  label?: string;
  aside?: any;
  disabled?: boolean;
  placeholder?: string;
  optional?: boolean;
  rows?: number;
};

export default function FormTextarea(props: Props) {
  return (
    <div className={`textarea`}>
      { props.label && (
        <div className="textarea__top">
          <label className="textarea__label" htmlFor={props.name}>{props.label}</label>
          {(props.aside || props.optional) && (
            <div className="textarea__aside">
              {props.aside && props.aside}
              {props.optional && <Chip status={4} label="Optional" />}
            </div>
          )}
        </div>
      ) }
      <textarea className="textarea__element" id={props.name} name={props.name} value={props.value} placeholder={props.placeholder} onChange={props.onChange} required={props.required} rows={props.rows} disabled={props.disabled} />
    </div>
  );
}
