import React from "react";

export default function ResultList(props: any) {
  return (
    <ul className="results__list">
      { props.results && props.results.map((result: any) => (
        <React.Fragment key={ result.uid }>
          { React.cloneElement(props.children, { result: result }) }
        </React.Fragment>
      ))}
    </ul>
  )
}
