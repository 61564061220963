import React, { useContext, useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router";
// @ts-ignore
import { store } from "react-notifications-component";
import GlobalData from "../../context/globaldata";

export interface ProtectedRouteProps extends RouteProps {
  restrictedPath: string;
  authenticationPath: string;
}

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const dataContext = useContext(GlobalData);
  const userIsDisabled = dataContext.currentUser.custom?.disabled;

  useEffect(() => {
    if (dataContext.currentUser.firebase?.uid) {
      console.log("Checking current user status...");
      dataContext.firestore.collection("users").doc(dataContext.currentUser.firebase.uid).onSnapshot((doc: any) => {
        if (doc.exists && doc.data().disabled === true) {
          dataContext.firebaseAuth.signOut().then(() => {
            localStorage.removeItem("expectSignIn");
            store.addNotification({
              id: "user-disabled",
              message: "Your account has been disabled, please contact an admin to enable it again.",
              type: "danger",
              insert: "top",
              container: "top-center",
              animationIn: ["animated", "slideDown"],
              animationOut: ["animated", "slideUp"],
              dismiss: {
                pauseOnHover: true,
                duration: 5000
              }
            });
          });
        }
      });
    }
  }, [ userIsDisabled, dataContext.currentUser, dataContext.firestore, dataContext.firebaseAuth ]);

  if (dataContext.currentUser?.firebase && localStorage.getItem("expectSignIn") !== "true" && !dataContext.currentUser?.firebase.emailVerified) {
    return <Redirect to="/verify-email" />;
  }

  if (!dataContext.currentUser?.firebase && localStorage.getItem("expectSignIn") !== "true") {
    return <Redirect to="/login" />;
  }

  if (dataContext.currentUser?.firebase && !dataContext.currentUser?.firebase.emailVerified) {
    store.addNotification({
      message: "User needs to be verified before being able to log in.",
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animated", "slideDown"],
      animationOut: ["animated", "slideUp"],
      dismiss: {
        pauseOnHover: true,
        duration: 5000
      }
    });
    return <Redirect to="/login" />;
  }

  return <Route {...props} render={() =>
    dataContext.currentUser?.custom && dataContext.currentUser?.firebase ? (
      <Redirect to={{ pathname: dataContext.restrictedPath }} />
    ) : (
      <div>Loading user data</div>
    )
  } />;
};
