import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import changelog from "../assets/data/changelog";
import GlobalData from '../context/globaldata';

import Modal from "./Modal/Modal";

export default function ChangelogModal() {
  const history = useHistory();
  const appContext = useContext(GlobalData);
  const [ isModalVisible, setIsModalVisible ] = useState(false);
  const [ isModalShown, setIsModalShown ] = useState(false);

  useEffect(() => {
    if (isModalShown) {
      return;
    }

    if (appContext.currentUser.custom &&
        (!appContext.currentUser.custom?.lastSeenUpdate
        || (appContext.currentUser.custom?.lastSeenUpdate
          && appContext.currentUser.custom?.lastSeenUpdate < changelog.length - 1))) {

      // Update user's lastSeenUpdate field
      appContext.firestore.collection("users")
      .doc(appContext.currentUser.custom.uid)
      .update({ lastSeenUpdate: changelog.length - 1 })
      .then(() => {
        setIsModalVisible(true);
        setIsModalShown(true);
      });
    }
  }, [ appContext.currentUser.custom, appContext.firestore, isModalShown ]);

  return (
    <Modal
      title="What's new"
      headerNoPaddingBottom={ true }
      visible={ isModalVisible }
      cancelAction={ () => { history.push('/changelog'); setIsModalVisible(false) } }
      closeAction={ () => { setIsModalVisible(false) } }
      cancelLabel="View previous updates"
    >
      <p className="modal__subtitle">{ changelog[0].date }</p>
      { changelog[0].summary }
    </Modal>
  )
}
