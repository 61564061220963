import React, { useCallback, useEffect, useState } from "react";

import { ReactComponent as IconLocationPin } from "../../assets/images/icons/location-pin.svg";

type Props = {
  originAddress?: MapLocation;
  origin?: StrictDict;
  destinationAddress?: MapLocation;
  destination?: StrictDict;
};

export default function TransportDetails(props: Props) {
  const [ distance, setDistance ] = useState<null|number>(0);
  const [ distanceString, setDistanceString ] = useState<String>("");

  const mapBoxApiKey = "pk.eyJ1Ijoic3RyYWt6YXQiLCJhIjoiY2s5OGd5N203MDI5eTNmbGx3ZGp1NDY4eSJ9.Uod99sjKQ_nGnRxbwTjVnQ";

  const calcDistance = useCallback(() => {
    setDistanceString("Calculating distance...");

    const originLongLat = props.originAddress?.long
      ? `${ props.originAddress?.long },${ props.originAddress?.lat }`
      : `${ props.origin?.long },${ props.origin?.lat }`;

    const destinationLongLat = props.destinationAddress?.long
      ? `${ props.destinationAddress?.long },${ props.destinationAddress?.lat }`
      : `${ props.destination?.long },${ props.destination?.lat }`;

    let requestURL = `https://api.mapbox.com/directions-matrix/v1/mapbox/driving/${ originLongLat };${ destinationLongLat }?annotations=distance&access_token=${ mapBoxApiKey }`;

    setDistance(0);

    fetch(requestURL)
    .then(response => response.json())
    .then(result => {
      if (!result.distances) {
        setDistance(-1);

        return;
      }

      setDistance(Math.max(...result.distances[0]));
    });
  }, [ props.origin, props.destination, props.originAddress, props.destinationAddress ]);

  useEffect(() => {
    if ((props.origin?.name && props.destination?.name) || (props.originAddress?.name && props.destinationAddress?.name)) {
      calcDistance();
    } else {
      setDistance(null);
    }
  }, [ props.origin, props.destination, props.originAddress, props.destinationAddress, calcDistance ]);

  useEffect(() => {
    if (distance === null) {
      setDistanceString("Unknown distance");
    } else if (typeof distance === "number") {
      if (distance > 0) {
        const distanceKm = (distance / 1000).toFixed(1);
        setDistanceString(`Approx. ${ distanceKm } KM`);
      } else {
        setDistanceString(`Cannot calculate distance`);
      }
    }

  }, [ distance ]);

  return (
    <div className="transport-details" data-disabled={ !props.originAddress || !props.destinationAddress || distance === null }>
      <div
        className={ `transport-details__dashed-line ${ ((props.origin?.name && props.destination?.name) || (props.originAddress?.name && props.destinationAddress?.name)) && (distance && distance > 0) ? "animated" : ""}`}
        data-disabled={ !props.originAddress?.name || !props.originAddress?.name }>
      </div>
      <span className="transport-details__location-label transport-details__location-label--origin" data-disabled={ !props.originAddress?.name && !props.origin?.name }>
        <IconLocationPin className="transport-details__location-icon" />
        { props.origin?.name || props.originAddress?.name ? 'Origin' : 'Enter origin' }
      </span>
      <span className="transport-details__distance" data-disabled={ !props.destinationAddress?.name && !props.destination?.name }>
        { (props.origin?.name && props.destination?.name) || (props.originAddress?.name && props.destinationAddress?.name) ? distanceString : 'Unknown distance' }
      </span>
      <span className="transport-details__location-label transport-details__location-label--destination" data-disabled={ !props.destinationAddress?.name && !props.destination?.name }>
        <IconLocationPin className="transport-details__location-icon" />
        { props.destination?.name || props.destinationAddress?.name ? 'Destination' : 'Enter destination' }
      </span>
    </div>
  );
}
