import React, { useContext, useState, useEffect } from "react";

import { nanoid } from "nanoid";
import deepcopy from 'deepcopy';

import Pagination from "../../components/Pagination/Pagination";
import GlobalData from "../../context/globaldata";
import QuotesCard from "../RateRequests/Details/Quotes/Card";
import { useLocation } from "react-router-dom";
import parseDate from "../../utils/ParseDate";
import moment from "moment";

type Props = {
  supplier: Supplier;
  supplierUid: string|undefined;
};

export default function SupplierOrderHistory(props: Props) {
  const location = useLocation();
  const context = useContext(GlobalData);
  const search = location.search;
  const params = new URLSearchParams(search);
  const paramPage = params.get("page");
  const [ getRateRequestResponses, setGetRateRequestResponses ] = useState(true);
  const [ quoteOptionsResponses, setQuoteOptionsResponses ] = useState<Array<QuoteOptionResponse>>([]);
  const [ currentPage, setCurrentPage ] = useState<number>(paramPage ? parseInt(paramPage) : 1);
  const [ pagedQuoteOptionsResponses, setPagedQuoteOptionsResponses ] = useState<Array<QuoteOptionResponse>>([]);
  const itemsPerPage = 10;

  useEffect(() => {
    // Sort function
    const performSort = (a: QuoteOptionResponse, b: QuoteOptionResponse): number => {
      if (a.rateRequestResponse?.submittedAt < b.rateRequestResponse?.submittedAt) {
        return 1;
      } else if (a.rateRequestResponse?.submittedAt > b.rateRequestResponse?.submittedAt) {
        return -1;
      } else {
        return 0;
      }
    };

    // Perform pagination function
    const performPagination = (rateRequests: Array<QuoteOptionResponse>, currentPage: number): Array<QuoteOptionResponse> => {
      const startOffset = (currentPage - 1) * itemsPerPage;
      return rateRequests.slice(startOffset, startOffset + itemsPerPage);
    };

    const resultsSorted = quoteOptionsResponses.sort(performSort);
    const results = performPagination(resultsSorted, currentPage);

    setPagedQuoteOptionsResponses(results);
  }, [ quoteOptionsResponses, currentPage ]);

  useEffect(() => {
    if (!getRateRequestResponses || !context.rateRequests.length) {
      return;
    }

    setGetRateRequestResponses(false);

    context.firestore.collectionGroup("responses")
    .where("supplierUid", "==", props.supplierUid)
    .get()
    .then((querySnapshot: any) => {
      const foundQuoteOptionsResponses:Array<QuoteOptionResponse> = [];

      querySnapshot.docs.forEach((responseDoc: StrictDict) => {

        const responseData:RateRequestResponse = responseDoc.data();

        if (responseData.submittedAt) {
          responseData.submittedAt = parseDate(responseData.submittedAt);
        } else {
          responseData.submittedAt = null;
        }

        responseData.uid = responseDoc.id;

        const originalRateRequestResponse = deepcopy(responseData);

        responseData.quoteOptionsResponses.forEach((quoteOptionsResponse: QuoteOptionResponse, index: number) => {
          if (quoteOptionsResponse.status === -1) {
            return;
          }

          quoteOptionsResponse.originalRateRequestResponse = originalRateRequestResponse;

          quoteOptionsResponse.rateRequestResponse = responseData;

          quoteOptionsResponse.rateRequestResponse.supplier = context.suppliers.find((supplier: Supplier) => supplier.uid === responseData.supplierUid);

          quoteOptionsResponse.rateRequest = context.rateRequests.find((rateRequest: RateRequest) => rateRequest.uid === responseDoc.ref.parent.parent.id);

          quoteOptionsResponse.quoteOption = quoteOptionsResponse.rateRequest?.quoteOptions.find((quoteOption: QuoteOption) => quoteOption.id === quoteOptionsResponse.quoteOptionId);

          let duration = null;

          if (quoteOptionsResponse.loadingDate && quoteOptionsResponse.deliveryDate) {
            // Calculate duration in milliseconds between loading and delivery date
            duration = moment(parseDate(quoteOptionsResponse.deliveryDate)).diff(parseDate(quoteOptionsResponse.loadingDate), 'days');
          }

          // Update card meta
          quoteOptionsResponse.duration = duration;

          if (quoteOptionsResponse.rateRequest?.timeframe?.hasOwnProperty("nanoseconds")) {
            // @ts-ignore
            quoteOptionsResponse.rateRequest.timeframe = quoteOptionsResponse.rateRequest?.timeframe?.toDate();
          }

          quoteOptionsResponse.quoteAccepted = (quoteOptionsResponse.rateRequest?.acceptedResponse === quoteOptionsResponse.rateRequestResponse.uid && quoteOptionsResponse.rateRequest?.acceptedQuoteOption === quoteOptionsResponse.quoteOptionId);
          quoteOptionsResponse.id = quoteOptionsResponse.id || nanoid();

          foundQuoteOptionsResponses.push(quoteOptionsResponse);
        });
      });

      setQuoteOptionsResponses(foundQuoteOptionsResponses);
    });

  }, [ context.suppliers, props.supplierUid, context.firestore, props.supplier, context.rateRequests, getRateRequestResponses ]);

  console.log(pagedQuoteOptionsResponses);

  return (
    <div className="detail">
      <div className="container">
        <div className="detail__top">
          <h3 className="detail-title">Quote history ({ quoteOptionsResponses.length })</h3>
        </div>

        { pagedQuoteOptionsResponses.map((quoteOptionsResponse: QuoteOptionResponse) =>
          <QuotesCard
            key={ quoteOptionsResponse.id }
            quoteOptionsResponse={ quoteOptionsResponse }
            allQuoteOptionsResponses={ quoteOptionsResponses }
            setGetRateRequestResponses={ setGetRateRequestResponses }
            showRateRequestTitle={ true }
          />
        ) }

        <Pagination
          dataLength={ quoteOptionsResponses.length }
          itemsPerPage={ itemsPerPage }
          currentPage={ currentPage }
          setCurrentPage={ setCurrentPage }
        />

      </div>
    </div>
  )
}
