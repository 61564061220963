import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useParams, useHistory } from "react-router-dom";

import GlobalData from '../../context/globaldata';
import { Header, Navigation } from "../../components/Layout";
import Button from "../../components/Button/Button";
import relationshipLabels from "../../../src/assets/data/relationship-labels.json";

import SupplierDetails from "./Details";
import SupplierquoteHistory from "./QuoteHistory";
import SupplierSettings from "./Settings";

export default function SupplierIndex() {
  const history = useHistory();
  // @ts-ignore
  const { uid } = useParams();
  const context = useContext(GlobalData);
  const [ supplier, setSupplier ] = useState<Supplier>({} as Supplier);
  const [ loadingAdminSupplier, setLoadingAdminSupplier ] = useState<boolean>(false);
  const [ settingsVisibile, setSettingsVisible ] = useState<boolean>(false);

  const navigationItems = [
    {
      to: `/suppliers/${uid}`,
      label: "Supplier details",
      exact: true
    }, {
      to: `/suppliers/${uid}/quote-history`,
      label: "Quote history",
      exact: true
    }
  ];

  const relationshipOptions = relationshipLabels;

  useEffect(() => {
    if (supplier.uid) {
      return;
    }

    const currentSupplier = context.suppliers.find((supplier: any) => supplier.uid === uid) || {};

    // Get supplier from firestore if not found in context and user is admin
    if (!currentSupplier.uid && context.currentUser?.custom?.admin) {
      setLoadingAdminSupplier(true);
      console.info(`Admin only: Supplier with uid ${uid} not found in context, fetching from firestore...`);

      context.firestore.collection("suppliers").doc(uid).get().then((doc: any) => {
        if (doc.exists) {
          setSupplier(doc.data());
        }
      });
    }

    setSupplier(currentSupplier);
  }, [ uid, context.suppliers, context.currentUser?.custom ]);

  const getRelationship = () => {
    return relationshipOptions.find(option => option.value === supplier.relationship);
  };

  return (
    <>
      { supplier.status?.inactive &&
        <div className="notification">
          <div className="notification-item notification-info">
            <div className="notification-content">
              <div className="notification-message">This supplier is deactivated</div>
            </div>
          </div>
        </div>
      }

      { !settingsVisibile &&
        <Header
          title={ `${
            !supplier.car
              ? loadingAdminSupplier ? "Loading deactivated supplier as admin..." : "Loading..."
              : supplier.alias
                ? supplier.alias
                : supplier.car?.Client
          }` }
          subTitle={ supplier.car
            ? `${supplier.car.CompanyAddress.GENCITY}, ${supplier.car.CompanyAddress.GENLAND}`
            : ""
          }
          middleContent={ <Navigation navItems={navigationItems} variant="secondary" /> }
          rightContent={context.currentUser.custom?.admin && <Button variant="tertiary" onClick={() => history.push(`/suppliers/${uid}/edit`)}>Edit supplier</Button>}
          // aside={ <Rating rating={ supplier ? supplier.rating : 0 } ratingCount={ supplier ? supplier.ratingCount : 0 } /> }
          preferRating={ supplier ? supplier.relationship : 0 }
          backLink="/suppliers"
          variant="secondary"
          inactive={ supplier.status?.inactive }
        />
      }

      <Switch>
        <Route exact path="/suppliers/:uid" render={() => supplier.car && <SupplierDetails supplier={supplier} />} />
        <Route exact path="/suppliers/:uid/quote-history" render={() => supplier && <SupplierquoteHistory supplier={supplier} supplierUid={uid} />} />
        <Route
          exact
          path="/suppliers/:uid/edit"
          render={() => supplier &&
            <SupplierSettings
              supplier={ supplier }
              supplierUid={ uid }
              setSupplier={ setSupplier }
              setSettingsVisible={ setSettingsVisible }
              getRelationship={ getRelationship }
              relationshipOptions={ relationshipOptions }
            />
          }
        />
      </Switch>
    </>
  )
}
