import React from "react";
import { Switch, Route, useParams } from "react-router-dom";

import { Header, Navigation } from "../../../components/Layout";

import RateRequestDetails from "./Details";
import RateRequestQuotesOverview from "./Quotes/Overview";

import { ReactComponent as IconArrowRight } from "../../../assets/images/icons/arrow-right.svg";
import RateRequestAlternatives from "./Alternatives";

export default function DetailsIndex(props: any) {
  // @ts-ignore
  const { uid } = useParams();

  if (!props.rateRequest) {
    return null;
  }

  const navigationItems = [
    {
      to: `/rate-requests/${uid}/quotes`,
      label: "Quotes",
      exact: true,
    },
    {
      to: `/rate-requests/${uid}/details`,
      label: "Request details",
      exact: true,
    },
    {
      to: `/rate-requests/${uid}/alternatives`,
      label: "Alternatives",
      exact: true,
    }
  ];

  return (
    <>
      <Route exact path={[ "/rate-requests/:uid/details", "/rate-requests/:uid/quotes", "/rate-requests/:uid/alternatives" ]} render={ () =>
        <>
          <Header
            title={`${props.rateRequest.referenceOrder || "Loading..."}`}
            subTitle={`${props.rateRequest.rateRequestNumber || "Loading..."}`}
            middleContent={ <Navigation navItems={navigationItems} variant="secondary" /> }
            aside={
              <div className="header-direction">
                <div className="header-direction__origin">
                  <h4>{ props.rateRequest.origin?.city }</h4>
                  <h6>{ props.rateRequest.origin?.country }</h6>
                </div>
                <IconArrowRight />
                <div className="header-direction__destination">
                  <h4>{ props.rateRequest.destination?.city }</h4>
                  <h6>{ props.rateRequest.destination?.country }</h6>
                </div>
              </div>
            }
            backLink="/rate-requests"
            variant="secondary"
          />
        </>
      } />

      <Switch>
        <Route exact path="/rate-requests/:uid/details">
          <RateRequestDetails rateRequest={ props.rateRequest } setRateRequest={ props.setRateRequest } pending={ props.pending } setPending={ props.setPending } />
        </Route>

        <Route exact path={[ "/rate-requests/:uid/quotes/:responseId/:quoteId", "/rate-requests/:uid/quotes" ]}>
          <RateRequestQuotesOverview rateRequest={ props.rateRequest } setRateRequest={ props.setRateRequest } />
        </Route>

        <Route exact path="/rate-requests/:uid/alternatives">
          <RateRequestAlternatives rateRequest={ props.rateRequest } setRateRequest={ props.setRateRequest } pending={ props.pending } setPending={ props.setPending } />
        </Route>
      </Switch>
    </>
  )
}
