import React, { useEffect, useState, ReactChild, useRef, useCallback } from "react";

import { ReactComponent as IconChevronDown } from "../../assets/images/icons/chevron-down.svg";

type Props = {
  variant?: "primary" | "secondary";
  label?: string;
  action?: any;
  actionLabel?: string;
  children: ReactChild;
};

export default function DropdownButton(props: Props) {
  const [ dropdownActive, setDropdownActive ] = useState(false);
  const dropdownRef = useRef(null);

  const handleClickOutside = useCallback((event: any) => {
    // @ts-ignore
    if (dropdownRef && !dropdownRef?.current?.contains(event.target)) {
      setDropdownActive(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, []);

  useEffect(() => {
    if (dropdownActive) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [dropdownActive, handleClickOutside]);

  useEffect(() => {
    return document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div ref={ dropdownRef } className={`dropdown-button dropdown-button--${props.variant ? props.variant : "primary"} ${dropdownActive ? "is-active" : ""}`}>
      { props.action && props.actionLabel &&
        <button className="dropdown-button__action" onClick={props.action}>
          { props.actionLabel }
        </button>
      }
      <button className="dropdown-button__toggle" onClick={ () => setDropdownActive(!dropdownActive) } type="button">
        { props.label && <span>{ props.label }</span> }
        <IconChevronDown className="dropdown-button__icon" />
      </button>
      <div className="dropdown-button__options">
        { props.children }
      </div>
    </div>
  )
}
