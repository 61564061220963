import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { Route, useLocation, useParams } from "react-router-dom";
import { nanoid } from "nanoid";
import haversineDistance from "../../../../utils/HaverSineDistance";
import deepcopy from 'deepcopy';

import GlobalData from "../../../../context/globaldata";

import QuotesCard from "./Card";
import RateRequestQuotesSingle from "./Single";
import { DropdownButton, Checkbox, Select } from "../../../../components/Form";
import Pagination from "../../../../components/Pagination/Pagination";
import Button from "../../../../components/Button/Button";
import { ReactComponent as IconNotification } from "../../../../assets/images/icons/notification.svg";
import Modal from "../../../../components/Modal/Modal";

// @ts-ignore
import { store } from "react-notifications-component";
import parseDate from "../../../../utils/ParseDate";
import moment from "moment";

export default function RateRequestQuotesOverview(props: any) {
  const context = useContext(GlobalData);
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const paramPage = params.get("page");
  // @ts-ignore
  const { uid } = useParams();
  const itemsPerPage = 15;

  const labels = context.rateRequestLabels;

  const [ requested, setRequested ] = useState(false);

  const [ getRateRequestResponses, setGetRateRequestResponses ] = useState(false);

  const [ alternativeQuoteOptionsResponses, setAlternativeQuoteOptionsResponses] = useState<Array<QuoteOptionResponse>>([]);

  const statusOptions = [
    {
      label: "Status: All",
      value: "status-all"
    }, {
      label: "Status: Accepted",
      value: "status-accepted"
    }, {
      label: "Status: Active",
      value: "status-active"
    }, {
      label: "Status: Pending",
      value: "status-pending"
    }, {
      label: "Status: Historic",
      value: "status-historic"
    }, {
      label: "Status: Denied",
      value: "status-denied"
    }
  ];

  const sortByOptions = [
    {
      label: "Sort by: Preference",
      value: "preference-desc",
      field: "rateRequestResponse.supplier.relationship",
      order: "asc"
    }, {
      label: "Sort by: Fastest",
      value: "duration-asc",
      field: "duration",
      order: "asc"
    }, {
      label: "Sort by: Cheapest",
      value: "rate-asc",
      field: "rate",
      order: "asc"
    }
  ];

  const [ responses, setResponses ] = useState<Array<RateRequestResponse>>([]);
  const [ quoteOptionsResponses, setQuoteOptionsResponses ] = useState<Array<QuoteOptionResponse>>([]);
  const [ quoteOptionsResponsesWithMeta, setQuoteOptionsResponsesWithMeta ] = useState<Array<QuoteOptionResponse>>([]);
  const [ pagedQuoteOptionsResponses, setPagedQuoteOptionsResponses ] = useState<Array<QuoteOptionResponse>>([]);
  const [ currentPage, setCurrentPage ] = useState<number>(paramPage ? parseInt(paramPage) : 1);
  const [ sortBy, setSortBy ] = useState(sortByOptions[0]);
  const [ selectedStatus, setSelectedStatus ] = useState(statusOptions[0]);
  const [ filters, setFilters ] = useState<StrictDict>({
    selectedQuoteOptions: []
  });

  const [ reminderAllModalVisible, setReminderAllModalVisible ] = useState<boolean>(false);
  const [ reminderSent, setReminderSent ] = useState<boolean>(false);

  // Hide or show cards overview based on path so they can still calculate averages for details screen
  const isVisible = () => {
    let className = 'is-visible';

    if (location.pathname.indexOf('quotes/') > -1) {
      className = 'is-hidden';
    }

    return className;
  };

  const selectOption = (optionId: string) => {
    if (!optionId.length) {
      return;
    }

    setFilters(prevState => {
      const nextState = Object.assign({}, prevState);

      const optionIndex = nextState.selectedQuoteOptions.findIndex((option: string) => option === optionId);

      if (optionIndex === -1) {
        nextState.selectedQuoteOptions.push(optionId);
      } else {
        nextState.selectedQuoteOptions.splice(optionIndex, 1);
      }

      return nextState;
    });
  };

  // Sort by selected sort field and order
  const performSort = useCallback((quoteOptionsResponses: Array<QuoteOptionResponse>, sort: StrictDict): Array<QuoteOptionResponse> => {
    if (quoteOptionsResponses.length === 0) {
      return [];
    }

    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: "base"});

    const dotPick = (dotReference: string, item: StrictDict) => {
      if (dotReference.indexOf(".") !== -1) {
        return dotReference.split(".").reduce((o: StrictDict, i: string) => o && o[i], item);
      }

      return item[dotReference];
    };

    const suppliersFilteredSorted = quoteOptionsResponses.slice();

    suppliersFilteredSorted.sort((a: StrictDict, b: StrictDict) => {
      [a, b] = sort.order === "asc"
                ? [a, b]
                : [b, a];

      if (!a.accepted) {
        return 1;
      }

      if (dotPick(sort.field, a) === undefined) {
        if (sort.order === "asc") {
          return 1;
        } else {
          return -1;
        }
      }

      return collator.compare(dotPick(sort.field, a), dotPick(sort.field, b));
    });

    return suppliersFilteredSorted;
  }, []);

  // Perform filter
  const performFilter = useCallback((quoteOptionsResponses: Array<QuoteOptionResponse>): Array<QuoteOptionResponse> => {
    return quoteOptionsResponses.filter((quoteOptionsResponse: QuoteOptionResponse) => {
      if (filters.selectedQuoteOptions.length > 0) {
        if (filters.selectedQuoteOptions.indexOf(quoteOptionsResponse.quoteOptionId) === -1) {
          return false;
        }
      }

      switch (selectedStatus.value) {
        case "status-accepted":
          return (quoteOptionsResponse.quoteAccepted);
        case "status-active":
          // @ts-ignore
          if (!quoteOptionsResponse.rateRequestResponse?.submittedAt) return false;

          return moment(quoteOptionsResponse.rateRequestResponse?.submittedAt).add(60, 'days').toDate() > (new Date());
        case "status-pending":
          return (!quoteOptionsResponse.rateRequestResponse?.submittedAt);
        case "status-denied":
          return (quoteOptionsResponse.rateRequestResponse?.submittedAt && !quoteOptionsResponse.accepted);
        case "status-historic":
          // @ts-ignore
          return moment(quoteOptionsResponse.rateRequestResponse?.submittedAt).add(60, 'days').toDate() < (new Date());
      }

      return true;
    });
  }, [ filters, selectedStatus ]);

  // Perform pagination
  const performPagination = useCallback((quoteOptionsResponses: Array<QuoteOptionResponse>, currentPage: number): Array<QuoteOptionResponse> => {
    const startOffset = (currentPage - 1) * itemsPerPage;
    return quoteOptionsResponses.slice(startOffset, startOffset + itemsPerPage) || [];
  }, []);

  // Initial load useEffect
  useEffect(() => {
    if (!context.rateRequests || !props.rateRequest?.quoteOptions || requested) {
      return;
    }

    setRequested(true);

    const filter = {
      origin: props.rateRequest.origin,
      destination: props.rateRequest.destination,
      rateRequestModes: props.rateRequest.quoteOptions.map((quoteOption: QuoteOption) => quoteOption.rateRequestMode),
      volumes: props.rateRequest.quoteOptions.map((quoteOption: QuoteOption) => quoteOption.volume)
    };

    setGetRateRequestResponses(true);

    // Prevent this from running for now (We cannot access all rate requests anymore)
    // context.rateRequests.forEach((rateRequest: RateRequest) => {

    //   // Filter rate requests that are relevant for us

    //   if (rateRequest.uid === props.rateRequest.uid) {
    //     return;
    //   }

    //   // Filter on rate request modes
    //   if (!rateRequest.quoteOptions.some((option: QuoteOption) => (option.rateRequestMode !== null) && filter.rateRequestModes.includes(option.rateRequestMode))) {
    //     return;
    //   }

    //   // Filter on volumes
    //   if (!rateRequest.quoteOptions.some((option: QuoteOption) => option.volume && filter.volumes.some((volume: number) => option.volume && ((option.volume >= (volume - 2)) || (option.volume >= volume * .8)) && ((option.volume <= (volume + 2) || (option.volume <= volume * 1.2)))))) {
    //     return;
    //   }

    //   // Filter on origin & destination radius
    //   if (filter.origin) {
    //     if (!rateRequest.origin.lat) {
    //       // Rate request has no origin
    //       return;
    //     }

    //     // Calculate distance between filter origin and rate request origin
    //     const distanceOrig = haversineDistance({
    //       lat: filter.origin.lat,
    //       long: filter.origin.long
    //     }, {
    //       lat: rateRequest.origin.lat,
    //       long: rateRequest.origin.long
    //     });

    //     // Check if out of range
    //     if (distanceOrig > 50) {
    //       return;
    //     }
    //   }

    //   // Filter on destination
    //   if (filter.destination) {
    //     if (!rateRequest.destination.lat) {
    //       // Rate request has no destination
    //       return;
    //     }

    //     // Calculate distance between filter destination and rate request destination
    //     const distanceDest = haversineDistance({
    //       lat: filter.destination.lat,
    //       long: filter.destination.long
    //     }, {
    //       lat: rateRequest.destination.lat,
    //       long: rateRequest.destination.long
    //     });

    //     // Check if out of range
    //     if (distanceDest > 50) {
    //       return;
    //     }
    //   }

    //   // console.log(`passed raterequest:`, rateRequest);

    //   // Passed all tests, now get the responses
    //   context.firestore.collection("rate_requests")
    //   .doc(rateRequest.uid)
    //   .collection("responses")
    //   .get()
    //   .then((querySnapshot: any) => {
    //     const foundResponses:Array<RateRequestResponse> = [];
    //     const foundQuoteOptionsResponses:Array<QuoteOptionResponse> = [];

    //     querySnapshot.docs.forEach((responseDoc: StrictDict) => {
    //       const responseData:RateRequestResponse = responseDoc.data();

    //       const supplierInactive = context.suppliers.some((supplier: Supplier) => {
    //         return (supplier.uid === responseData.supplierUid && supplier.status.inactive);
    //       });

    //       if (supplierInactive) {
    //         return;
    //       }

    //       foundResponses.push(responseData);

    //       responseData.quoteOptionsResponses.forEach((quoteOptionsResponse: QuoteOptionResponse) => {
    //         if (!quoteOptionsResponse.accepted || quoteOptionsResponse.status === -1) {
    //           return;
    //         }

    //         quoteOptionsResponse.rateRequest = context.rateRequests.find((rateRequest: RateRequest) => rateRequest.uid === responseDoc.ref.parent.parent.id);
    //         quoteOptionsResponse.quoteOption = quoteOptionsResponse.rateRequest?.quoteOptions.find((quoteOption: QuoteOption) => quoteOption.id === quoteOptionsResponse.quoteOptionId);

    //         let duration = null;

    //           // Calculate duration in milliseconds between loading and delivery date
    //         if (quoteOptionsResponse.loadingDate && quoteOptionsResponse.deliveryDate) {
    //           console.log(quoteOptionsResponse.deliveryDate);
    //           debugger;
    //           duration = moment(parseDate(quoteOptionsResponse.deliveryDate)).diff(parseDate(quoteOptionsResponse.loadingDate), 'days');
    //         }

    //         // Update card meta
    //         quoteOptionsResponse.duration = duration;

    //         // Volume
    //         const continueVolume = filter.volumes.some((volume: number) => {
    //           if (quoteOptionsResponse.quoteOption?.volume) {
    //             if (quoteOptionsResponse.quoteOption?.volume >= (volume * .8) || quoteOptionsResponse.quoteOption?.volume >= (volume - 2)) {
    //               if (quoteOptionsResponse.quoteOption?.volume <= (volume * 1.2) || quoteOptionsResponse.quoteOption?.volume >= (volume + 2)) {
    //                 return true;
    //               }
    //             }
    //           }

    //           return false;
    //         });

    //         if (!continueVolume) {
    //           return;
    //         }

    //         // Rate request mode
    //         if (!quoteOptionsResponse.quoteOption?.rateRequestMode && filter.rateRequestModes.some((rateRequestMode: number) => quoteOptionsResponse.quoteOption?.rateRequestMode && (quoteOptionsResponse.quoteOption?.rateRequestMode === rateRequestMode))) {
    //           return;
    //         }

    //         foundQuoteOptionsResponses.push(quoteOptionsResponse);
    //       });
    //     });

    //     setAlternativeQuoteOptionsResponses((prevState) => {
    //       return prevState.concat(foundQuoteOptionsResponses);
    //     });
    //   });
    // });

    // Filter on quote option query parameter
    if (location.search.includes("quoteOption=")) {
      const searchParams = new URLSearchParams(location.search);

      let quoteOptionId;
      quoteOptionId = searchParams.get("quoteOption");

      if (quoteOptionId) {
        selectOption(quoteOptionId);
      }
    }

  }, [ context.rateRequests, location.search, props.rateRequest, context.firestore, labels.rateRequestModes, performFilter, requested, context.suppliers ]);


  // Get the rate request responses
  useEffect(() => {
    if (!getRateRequestResponses) {
      return;
    }

    setGetRateRequestResponses(false);

    let foundParentRateRequest:RateRequest;

    // Request rateRequestResponses
    context.firestore.collection("rate_requests").doc(uid).collection('responses').get().then(async (querySnapshot: any) => {
      const foundResponses:Array<RateRequestResponse> = [];
      const foundQuoteOptionsResponses:Array<QuoteOptionResponse> = [];

      if (!querySnapshot.empty && !querySnapshot.docs[0].data().uuid) {
        setGetRateRequestResponses(true);

        return;
      }

      for await (const responseDoc of querySnapshot.docs) {
        const responseData:RateRequestResponse = responseDoc.data();

        if (responseData.submittedAt && !(responseData.submittedAt instanceof Date)) {
          responseData.submittedAt = parseDate(responseData.submittedAt);
        }

        const originalRateRequestResponse = deepcopy(responseData);
        responseData.uid = responseDoc.id;

        for await (const quoteOptionsResponse of responseData.quoteOptionsResponses) {
          if (responseData.submittedAt && !(responseData.submittedAt instanceof Date)) {
            responseData.submittedAt = parseDate(responseData.submittedAt);
          }

          quoteOptionsResponse.rateRequestResponse = responseData;

          if (quoteOptionsResponse.status === -1) {
            continue;
          }

          const foundSupplier = context.suppliers.find((supplier: Supplier) => supplier.uid === responseData.supplierUid);

          // Check if supplier is inactive
          // if (foundSupplier && foundSupplier.uid === responseData.supplierUid) {
          //   if (foundSupplier.status.inactive) {
          //     continue;
          //   }
          // }

          quoteOptionsResponse.rateRequestResponse.supplier = foundSupplier;

          quoteOptionsResponse.originalRateRequestResponse = originalRateRequestResponse;

          if (!foundParentRateRequest) {
            const rateRequestDoc = await context.firestore.collection("rate_requests").doc(responseDoc.ref.parent.parent.id).get();

            foundParentRateRequest = await rateRequestDoc.data();
          }

          quoteOptionsResponse.rateRequest = foundParentRateRequest;

          quoteOptionsResponse.quoteOption = quoteOptionsResponse.rateRequest?.quoteOptions.find((quoteOption: QuoteOption) => quoteOption.id === quoteOptionsResponse.quoteOptionId);

          let duration = null;

          if (quoteOptionsResponse.loadingDate && quoteOptionsResponse.deliveryDate) {
            // Calculate duration in milliseconds between loading and delivery date
            duration = moment(parseDate(quoteOptionsResponse.deliveryDate)).diff(parseDate(quoteOptionsResponse.loadingDate), 'days');
          }

          // Update card meta
          quoteOptionsResponse.duration = duration;

          if (quoteOptionsResponse.rateRequest) {
            quoteOptionsResponse.rateRequest.timeframe = parseDate(quoteOptionsResponse.rateRequest?.timeframe);
          }

          quoteOptionsResponse.quoteAccepted = (quoteOptionsResponse.rateRequest?.acceptedResponse === quoteOptionsResponse.rateRequestResponse.uid && quoteOptionsResponse.rateRequest?.acceptedQuoteOption === quoteOptionsResponse.quoteOptionId);
          quoteOptionsResponse.id = quoteOptionsResponse.id || nanoid();

          foundQuoteOptionsResponses.push(quoteOptionsResponse);
        }

        if (responseData.supplier && responseData.overrideSupplierEmail && !responseData.supplier.originalOverrideEmail) {
          responseData.supplier.originalOverrideEmail = responseData.overrideSupplierEmail;
        }

        foundResponses.push(responseData);
      };

      setResponses(foundResponses);
      setQuoteOptionsResponses(foundQuoteOptionsResponses);
    });
  }, [ context.firestore, context.rateRequests, context.suppliers, uid, getRateRequestResponses ]);

  // Set quote option metas
  useEffect(() => {
    let quoteOptionsMetas:StrictDict = {};

    const quoteOptionsResponsesWithMeta = quoteOptionsResponses.map(quoteOptionsResponse => {
      const foundQuoteOption = quoteOptionsResponse.rateRequest?.quoteOptions.find((quoteOption: QuoteOption) => quoteOption.id === quoteOptionsResponse.quoteOptionId);
      quoteOptionsResponse.quoteOption = foundQuoteOption;
      quoteOptionsResponse.meta = {};
      // @ts-ignore
      quoteOptionsResponse.rate = quoteOptionsResponse.rate ? parseInt(quoteOptionsResponse.rate) : null;

      let duration = null;

      if (quoteOptionsResponse.loadingDate && quoteOptionsResponse.deliveryDate) {
        // Calculate duration in milliseconds between loading and delivery date
        duration = moment(parseDate(quoteOptionsResponse.deliveryDate)).diff(parseDate(quoteOptionsResponse.loadingDate), 'days');
      }

      if (quoteOptionsResponse.rateRequestResponse?.submittedAt) {
        quoteOptionsResponse.rateRequestResponse.submittedAt = parseDate(quoteOptionsResponse.rateRequestResponse?.submittedAt);
      }

      // Update card meta
      quoteOptionsResponse.duration = duration;

      let currentQuoteOptionsMeta = quoteOptionsMetas[quoteOptionsResponse.quoteOption?.rateRequestMode];

      if (!currentQuoteOptionsMeta) {
        currentQuoteOptionsMeta = quoteOptionsMetas[quoteOptionsResponse.quoteOption?.rateRequestMode] = {
          cheapest: [],
          fastest: []
        } as {
          cheapest?: Array<QuoteOptionResponse>
          fastest?: Array<QuoteOptionResponse>
        };
      }

      if (quoteOptionsResponse.rate !== null && quoteOptionsResponse.accepted) {
        if (!currentQuoteOptionsMeta.cheapest[0]
            || currentQuoteOptionsMeta.cheapest.some((otherQuoteOptionResponse: QuoteOptionResponse) => otherQuoteOptionResponse.rate! > quoteOptionsResponse.rate!)) {
          currentQuoteOptionsMeta.cheapest = [ quoteOptionsResponse ];
        }

        if (quoteOptionsResponse.rate === currentQuoteOptionsMeta.cheapest[0].rate) {
          currentQuoteOptionsMeta.cheapest.push(quoteOptionsResponse);
        }
      }

      if (quoteOptionsResponse.duration !== null && quoteOptionsResponse.accepted) {
        if (!currentQuoteOptionsMeta.fastest[0]
            || currentQuoteOptionsMeta.fastest.some((otherQuoteOptionResponse: QuoteOptionResponse) => otherQuoteOptionResponse.duration! > quoteOptionsResponse.duration!)) {
          currentQuoteOptionsMeta.fastest = [ quoteOptionsResponse ];
        }

        if (quoteOptionsResponse.duration === currentQuoteOptionsMeta.fastest[0].duration) {
          currentQuoteOptionsMeta.fastest.push(quoteOptionsResponse);
        }
      }

      return quoteOptionsResponse;
    });

    // Add labels
    quoteOptionsResponsesWithMeta.forEach(quoteOptionsResponse => {
      let currentQuoteOptionsMeta = quoteOptionsMetas[quoteOptionsResponse.quoteOption?.rateRequestMode];

      if (!quoteOptionsResponse.quoteOption) {
        return;
      }

      const rateRequestMode = labels.rateRequestModes[quoteOptionsResponse.quoteOption?.rateRequestMode];

      quoteOptionsResponse.meta = {
        labels: [{
          active: false,
          label: `Cheapest: ${rateRequestMode ? rateRequestMode.labelShort : 'N/A'}`
        }, {
          active: false,
          label: `Fastest: ${rateRequestMode ? rateRequestMode.labelShort : 'N/A'}`
        }]
      };

      if (!quoteOptionsResponse.accepted || quoteOptionsResponse.status === -1) {
        return;
      }

      quoteOptionsResponse.meta.labels[0].active = (currentQuoteOptionsMeta.cheapest.includes(quoteOptionsResponse));
      quoteOptionsResponse.meta.labels[1].active = (currentQuoteOptionsMeta.fastest.includes(quoteOptionsResponse));
    });

    setQuoteOptionsResponsesWithMeta(quoteOptionsResponsesWithMeta);
  }, [ quoteOptionsResponses, labels.rateRequestModes, performFilter ]);

  const matchingQuoteOptions = useMemo(() => {
    let results = performFilter(quoteOptionsResponsesWithMeta);
    results = performSort(results, sortBy);

    return results;
  }, [ sortBy, quoteOptionsResponsesWithMeta, performSort, performFilter ]);

  useEffect(() => {
    const results = performPagination(matchingQuoteOptions, currentPage);

    setPagedQuoteOptionsResponses(results);
  }, [ matchingQuoteOptions, currentPage, performPagination ]);

  const sendReminders = () => {
    responses.forEach(response => {
      if (!response.submittedAt && response.supplier) {
        sendReminder(response);
      }
    });

    setReminderAllModalVisible(false);
    setReminderSent(true);

    store.addNotification({
      message: "The reminder has been sent",
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animated", "slideDown"],
      animationOut: ["animated", "slideUp"],
      dismiss: {
        pauseOnHover: true,
        duration: 5000
      }
    });
  };

  // Send reminder e-mail on modal confirmation
  const sendReminder = (response: RateRequestResponse) => {
    const newData = {
      sendReminder: true,
      sendReminderEmail: response.supplier?.overrideEmail
    };

    context.firestore
    .collection('rate_requests').doc(props.rateRequest?.uid)
    .collection('responses').doc(response.uid)
    .update(newData);
  };

  const getEmailOptions = (supplier: Supplier) => {
    if (!supplier) {
      return null;
    }

    const primaryEmail = supplier.customEmail || supplier.car?.ContactInfo.INFEM[0].toLowerCase();

    let emailOptions: Array<OptionType> = [
      {
        "label": `${ primaryEmail }\n(Primary contact)`,
        "value": primaryEmail
      }
    ];

    if (supplier.escalationEmail) {
      emailOptions.push({
        "label": `${ supplier.escalationEmail }\n(Escalation contact)`,
        "value": supplier.escalationEmail
      });
    }

    let additionalContacts: Array<OptionType> = [];

    if (supplier.contacts) {
      additionalContacts = supplier.contacts.map((contact: SupplierContact, contactIndex: number): OptionType => {
        return {
          "label": `${ contact.email }\n(Additional contact ${ contactIndex + 1 })`,
          "value": contact.email
        };
      });
    }

    emailOptions = emailOptions.concat(additionalContacts);

    if (supplier.originalOverrideEmail && supplier.originalOverrideEmail.length) {
      emailOptions.push({
        "label": supplier.originalOverrideEmail,
        "value": supplier.originalOverrideEmail
      });
    }

    return emailOptions;
  };

  const getSelectedEmail = (supplier: Supplier) => {
    const options = getEmailOptions(supplier);

    if (!options) {
      return;
    }

    if (supplier?.overrideEmail && supplier?.overrideEmail.length > 0) {
      return options.find(option => option.value === supplier?.overrideEmail);
    } else if (supplier?.showCustomEmail) {
      return options[options.length - 1];
    } else {
      return options[0];
    }
  };

  const renderResponseReminder = (response: RateRequestResponse) => {
    if (response.submittedAt || !response.supplier) {
      return null;
    }

    if (!response.supplier) {
      return null;
    }

    const supplier = response.supplier;

    const supplierName = supplier?.alias ? supplier?.alias : supplier?.car?.Client;

    return (
      <React.Fragment>
        <div className="xxs12 md6 item-label item-label--wide">
          { supplierName }
        </div>
        <div className="xxs12 md6">
          <Select
            value={ getSelectedEmail(supplier) }
            name="supplierEmail"
            placeholder="Select e-mail address"
            onChange={ (option: StrictDict) => {
              const newResponse = {
                ...response,
                supplier: {
                  ...supplier,
                  overrideEmail: option.value
                }
              };

              setResponses((prevState) => {
                const newState = prevState.slice();

                const responseIndex = newState.findIndex(iterateResponse => iterateResponse.uid === response.uid);

                newState[responseIndex] = newResponse;

                return newState;
              });
            } }
            options={ getEmailOptions(supplier) }
            searchable={ false }
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <>
      <div className={ `dynamic-visibility ${isVisible()}` }>
        <div className="detail">
          <div className="container">
            <div className="detail__top">
              <div className="group">
                <div className="xxs12 md2">
                  <h3>{ matchingQuoteOptions.length } Result{ matchingQuoteOptions.length !== 1 ? "s" : "" }</h3>
                </div>
                <div className="xxs12 md10 action-bar">
                  { props.rateRequest?.quoteOptions?.length > 1 &&
                    <DropdownButton label="Quote options">
                      <>
                      { props.rateRequest?.quoteOptions?.map((option: QuoteOption) =>
                        <Checkbox
                          key={option.id}
                          value={option.id}
                          label={`${labels.rateRequestModes[option.rateRequestMode].labelShort} / ${option.volume}m³ / ${labels.transportModes[option.transportMode].label} / ${labels.packagingTypes[option.packagingType].label}`}
                          name="checkbox"
                          checked={ filters.selectedQuoteOptions.some((selectedOption: string) => selectedOption === option.id) }
                          onChange={() => selectOption(option.id)}
                        />
                      )}
                      </>
                    </DropdownButton>
                  }
                  <Select name="status" value={selectedStatus} onChange={setSelectedStatus} options={statusOptions}  />
                  <Select name="sort-by" value={sortBy} onChange={setSortBy} options={sortByOptions}  />
                  { responses.some(response => !response.submittedAt) && (
                    <Button variant="tertiary" onClick={ () => setReminderAllModalVisible(true) } disabled={ reminderSent }>
                      <>
                        <IconNotification className="button__icon" />
                        <span>{ reminderSent ? 'Reminders sent' : 'Remind eligible suppliers' }</span>
                      </>
                    </Button>
                  ) }
                </div>
              </div>
            </div>

            { pagedQuoteOptionsResponses && pagedQuoteOptionsResponses.map((quoteOptionsResponse: QuoteOptionResponse) =>
              <QuotesCard
                key={ quoteOptionsResponse.id }
                quoteOptionsResponse={ quoteOptionsResponse }
                allQuoteOptionsResponses={ quoteOptionsResponses }
                alternativeQuoteOptionsResponses={ alternativeQuoteOptionsResponses }
                setGetRateRequestResponses={ setGetRateRequestResponses }
              />
            )}

            <Pagination
              dataLength={ quoteOptionsResponses.length }
              itemsPerPage={ itemsPerPage }
              currentPage={ currentPage }
              setCurrentPage={ setCurrentPage }
            />
          </div>
        </div>
      </div>

      <Modal variant="primary" title="Are you sure you want to send a reminder?" size="large" visible={ reminderAllModalVisible } confirmAction={ sendReminders } confirmLabel="Send reminder" cancelAction={ () => setReminderAllModalVisible(false) } customClassName='modal__box--overflow'>
      <>
        <ul className="list list--selected-suppliers">
          { responses.map((response, responseIndex) => (
            !response.submittedAt && (
              <li key={ responseIndex } className="list__item list__item--align-top">
                { renderResponseReminder(response) }
              </li>
            )
          )) }
        </ul>
      </>
    </Modal>

      <Route path="/rate-requests/:uid/quotes/:responseId/:quoteId">
        <RateRequestQuotesSingle
          rateRequest={ props.rateRequest }
          setRateRequest={ props.setRateRequest }
          allQuoteOptionsResponses={ quoteOptionsResponses }
          setQuoteOptionsResponses={ setQuoteOptionsResponses }
          alternativeQuoteOptionsResponses={ alternativeQuoteOptionsResponses }
        />
      </Route>
    </>
  )
}
