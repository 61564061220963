import React from "react";

type Props = {
  variant?: "primary";
  label?: string | number;
};

export default function Badge(props: Props) {
  return (
    <span className={`badge badge--${props.variant ? props.variant : "primary"}`}>{ props.label }</span>
  );
}
