import React, { useState, useEffect, useRef, useCallback } from "react";
import Chip from "../Chip/Chip";
import { ReactComponent as IconDanger } from "../../assets/images/icons/danger.svg";

type Props = {
  value: string|number;
  name?: string;
  onChange?: any;
  required?: boolean;
  label?: string;
  aside?: any;
  type?: string;
  min?: number;
  step?: number;
  disabled?: boolean;
  placeholder?: string;
  optional?: boolean;
  search?: boolean;
  prepend?: any;
  append?: any;
  isDirty?: boolean;
  showError?: boolean;
  errorLabel?: string;
  autocomplete?: boolean;
};

export default function FormInput(props: Props) {
  const [ isDirty, setIsDirty ] = useState(props.isDirty || false);
  const [ isInvalid, setIsInvalid ] = useState(props.required === true && (props.value === undefined || props.value === ""));
  const inputElement = useRef<HTMLInputElement>(null);
  const onChange = props.onChange;

  const onChangeHandler = useCallback((event: any) => {
    const inputField = event.target as HTMLInputElement;

    setIsDirty(true);

    let isValid = true;

    if (props.type === 'number' && props.required) {
      const matches = inputField?.value?.match(/^(\d{0,6})(\.\d{1,2})?$/);

      if (!matches || !matches[0]) {
        isValid = false;
      }

      if (props.min) {
        if (parseInt(inputField?.value) < props.min) {
          isValid = false;
        }
      }

      setIsInvalid(!isValid);
    } else {
      // @ts-ignore
      if (!inputField?.validity.valid) {
        isValid = false;
      }
    }

    setIsInvalid(!isValid);

    onChange(event);
  }, [ props.min, onChange, props.required, props.type ]);

  useEffect(() => {
    const validateInput = (inputField: HTMLInputElement|null) => {
      const inputType = inputField?.type;

      if (props.showError) {
        setIsDirty(true);
      }

      let isValid = true;

      // @ts-ignore
      if (!inputField.validity.valid) {
        isValid = false;
      }

      if(inputType === "email" && inputField){
        const pattern = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
        const result = pattern.test(inputField?.value);

        if (!result) {
          isValid = false;
        }
      }

      setIsInvalid(!isValid);
    };

    if (inputElement.current) {
      validateInput(inputElement.current);
    }
  }, [ props.showError, props.value, props.required ]);

  return (
    <div className={`input ${props.type ? "input--" + props.type : "input--text"} ${props.search ? "input--search" : ""} ${isDirty ? "is-dirty" : ""} ${isInvalid ? "is-invalid" : ""} ${props.required ? "is-required" : ""} ${props.disabled ? "is-disabled" : ""}`}>
      { props.label && (
        <div className="input__top">
          <label className="input__label" htmlFor={props.name}>{props.label}</label>
          {(props.aside || props.optional) && (
            <div className="input__aside">
              {props.aside && props.aside}
              {props.optional && <Chip status={4} label="Optional" />}
            </div>
          )}
        </div>
      ) }

      <div className="input__container">
        { props.prepend &&
          <div className="input__prepend">{ props.prepend }</div>
        }

        <input
          ref={ inputElement }
          className="input__element"
          id={props.name}
          autoComplete={props.autocomplete ? "on" : "off"}
          type={props.type ? props.type : "text"}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={onChangeHandler}
          required={props.required}
          disabled={props.disabled}
          min={ props.min || undefined }
          step={ props.type === "number" ? props.step || ".01" : undefined }
        />

        { props.append &&
          <div className="input__append">{ props.append }</div>
        }
      </div>

      {props.showError && props.errorLabel &&
        <div className="input__error-label">
          <IconDanger />
          <span>{props.errorLabel}</span>
        </div>
      }
    </div>
  );
}
