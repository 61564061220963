import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";

import GlobalData from '../../context/globaldata';

import { ReactComponent as Logo } from "../../assets/images/logo.svg";
import { ReactComponent as IconArrowLeft } from "../../assets/images/icons/arrow-left.svg";
import { ReactComponent as IconHeaderAccount } from "../../assets/images/icons/header-account.svg";
import { ReactComponent as IconChevronDown } from "../../assets/images/icons/chevron-down.svg";

type Props = {
  backLink?: string;
  leftContent?: any;
  middleContent?: any;
  rightContent?: any;
  title?: string;
  preferRating?: number|null;
  subTitle?: string;
  aside?: any;
  accountDropdown?: boolean;
  inactive?: boolean;
  logo?: boolean;
  narrow?: boolean;
  variant?: "primary" | "secondary";
};

export default function LayoutHeader(props: Props) {
  const history = useHistory();
  const context = useContext(GlobalData);

  const [ dropdownVisible, setDropdownVisible ] = useState(false);

  const signOutUser = () => {
    context.firebaseAuth.signOut().then(() => {
      localStorage.removeItem("expectSignIn");
      history.replace("/login");
    });
  };

  return (
    <>
      <div className="header-bar">
        <div className="container">
          <div className="header-bar__left">
            { props.logo &&
              <Link className="header__logo" to="/">
                <Logo />
              </Link>
            }

            { props.backLink ? (
              <Link className="header__back" to={ props.backLink }>
                <IconArrowLeft />
              </Link>
            ) : (
              props.leftContent && props.leftContent
            )}

          </div>
          <div className="header-bar__middle">
            { props.middleContent && props.middleContent }
          </div>
          <div className="header-bar__right">
            { props.rightContent && props.rightContent }
            { props.accountDropdown && (
              <>
                <div>{ context.currentUser.custom?.email }</div>
                <div className={`account-dropdown ${dropdownVisible ? "is-visible" : ""}`}>
                  <button className="account-dropdown__toggle" onClick={() => setDropdownVisible(!dropdownVisible)}>
                    <IconHeaderAccount />
                    <IconChevronDown />
                  </button>
                  <ul className="account-dropdown__list">
                    { context.currentUser.custom?.admin &&
                      <li className="account-dropdown__item">
                        <button className="account-dropdown__button" onClick={() => { setDropdownVisible(false); history.push("/users?edit=true") }}>Edit account</button>
                      </li>
                    }
                    <li className="account-dropdown__item">
                      <Link to="/changelog">
                        <button className="account-dropdown__button" onClick={ () => { setDropdownVisible(false); } }>Changelog</button>
                      </Link>
                    </li>
                    <li className="account-dropdown__item">
                      <button className="account-dropdown__button" onClick={signOutUser}>Log out</button>
                    </li>
                  </ul>
                </div>
              </>
            ) }
          </div>
        </div>
      </div>
      <header className={`header ${ props.variant ? "header--" + props.variant : "header--primary" } ${ props.inactive ? "is-inactive" : "" }`}>
        <div className={ `container ${ props.narrow ? "container--narrow" : "" }` }>
          { props.title || props.aside ? (
            <div className="header__content">
              <div className="header__content-left">
                <h1 className={`header__heading ${ (props.preferRating && props.preferRating > 0) ? "has-rating" : ""}`}>
                  { (props.preferRating && props.preferRating > 0) ? (
                    <span className="prefer-rating">{ props.preferRating }</span>
                  ) : ("")}
                  <span>{ props.title }</span>
                </h1>
                { props.subTitle &&
                  <h4 className="header__subheading">{ props.subTitle }</h4>
                }
              </div>
              { props.aside &&
                <div className="header__content-right">
                  { props.aside }
                </div>
              }
            </div>
          ) : ("")}
        </div>
      </header>
    </>
  );
}
