import React, { useCallback, useContext } from "react";

import { nanoid } from "nanoid";
import moment from "moment";

import GlobalData from '../../context/globaldata';
import Button from "../../components/Button/Button";
import TransportDetails from "../../components/TransportDetails/TransportDetails";
import { Input, Select, Textarea, DatePicker, Checkbox } from "../../components/Form";
import MapsAutocomplete from "../../components/Maps/Autocomplete";
import EditAdditionals from "./Edit/Additionals";
import Attachments from "../../components/Attachments/Attachments";

import { ReactComponent as IconPlusSmall } from "../../assets/images/icons/plus-small.svg";
import { ReactComponent as IconError } from "../../assets/images/icons/error.svg";
import { ReactComponent as IconCrossRed } from "../../assets/images/icons/cross-red.svg";
import { ReactComponent as IconDuplicate } from "../../assets/images/icons/duplicate.svg";
import { ReactComponent as IconInfo } from "../../assets/images/icons/info.svg";

export default function RateRequestEdit(props: any) {
  process.env.NODE_ENV === 'development' && console.log('Re-rendering RateRequestEdit.tsx');

  const context = useContext(GlobalData);
  const labels = context.rateRequestLabels;

  const updateField = (event: any) => {
    props.setRateRequest({
      ...props.rateRequest,
      [event.target.name]: event.target.value
    });
  };

  const updateOrigin = (locationItem:MapLocation) => {
    props.setRateRequest({
      ...props.rateRequest,
      origin: locationItem
    });
  };

  const updateDestination = (locationItem:MapLocation) => {
    props.setRateRequest({
      ...props.rateRequest,
      destination: locationItem
    });
  };

  const updateOriginAddress = (locationItem:MapLocation) => {
    props.setRateRequest({
      ...props.rateRequest,
      originAddress: locationItem
    });
  };

  const updateDestinationAddress = (locationItem:MapLocation) => {
    props.setRateRequest({
      ...props.rateRequest,
      destinationAddress: locationItem
    });
  };

  const updateLoadingDate = (date: any) => {
    if (date > props.rateRequest.deliveryDate) {
      props.setRateRequest({
        ...props.rateRequest,
        deliveryDate: date,
        loadingDate: date
      })
    } else {
      props.setRateRequest({...props.rateRequest, loadingDate: date})
    }
  };

  const getSelectOption = (property: string, index: null|number) => {
    if (index !== null) {
      return labels[property][index];
    }
  };

  const setQuoteOption = (index: number, property: keyof QuoteOption, selectedValue: StrictDict) => {
    props.setRateRequest((currentState: RateRequest) => {
      const nextState = Object.assign({}, currentState);

      let newValue;

      if (selectedValue.target) {
        newValue = selectedValue.target.value;
      } else {
        newValue = selectedValue.value;
      }

      newValue = isNaN(parseInt(newValue)) ? 0 : parseInt(newValue);

      nextState.quoteOptions[index][property] = newValue;

      checkQuoteErrors(nextState);

      return nextState;
    });
  };

  const addNewQuoteOption = () => {
    props.setRateRequest((currentState: RateRequest) => {
      const nextState = Object.assign({}, currentState);

      nextState.quoteOptions.push({
        id: nanoid(8),
        rateRequestMode: null,
        volume: null,
        transportMode: null,
        packagingType: null
      });

      checkQuoteErrors(nextState);

      return nextState;
    });
  };

  const duplicateQuoteOption = (index: number) => {
    props.setRateRequest((currentState: any) => {
      const nextState = Object.assign({}, currentState);

      const currentQuoteOption = nextState.quoteOptions[index];

      const clonedQuoteOption = Object.assign({}, currentQuoteOption);

      clonedQuoteOption.id = nanoid(8);

      nextState.quoteOptions.splice(index, 0, clonedQuoteOption);

      checkQuoteErrors(nextState);

      return nextState;
    });
  };

  const removeQuoteOption = (index: number) => {
    props.setRateRequest((currentState: any) => {
      const nextState = Object.assign({}, currentState);

      nextState.quoteOptions.splice(index, 1);

      checkQuoteErrors(nextState);

      return nextState;
    });
  };

  const updateNote = (value: string) => {
    props.setRateRequest((currentState: any) => {
      const nextState = Object.assign({}, currentState);

      if (!Array.isArray(nextState.notes)) {
        nextState.notes = [];
      }

      nextState.notes[0] = {
        text: value,
        postedDate: new Date(),
      };

      return nextState;
    });
  };

  // Check if quote options are duplicated, show error
  const checkQuoteErrors = (currentState: StrictDict) => {
    currentState.quoteOptions.forEach((currentQuoteOption: QuoteOption, currentIndex: number) => {
      // Reset errors
      currentQuoteOption.error = false;
      currentQuoteOption.errorMessage = "";

      currentState.quoteOptions.forEach((otherQuoteOption: QuoteOption, otherIndex: number) => {

        // Only once (from bottom to top)
        if (currentIndex > otherIndex
            // Compare properties
            && currentQuoteOption.packagingType === otherQuoteOption.packagingType
            && currentQuoteOption.rateRequestMode === otherQuoteOption.rateRequestMode
            && currentQuoteOption.transportMode === otherQuoteOption.transportMode
            && currentQuoteOption.volume === otherQuoteOption.volume) {

          currentQuoteOption.error = true;
          currentQuoteOption.errorMessage = "This quote option has already been created";
        }
      });
    });
  };

  const testLog = useCallback(() => {
    console.log(context.rateRequests);
    debugger;
  }, [ context.rateRequests ]);

  return (
    <div className="detail">
      <div className="container">
        <div className="detail__top">
          <h3 className="detail-title">Create rate request</h3>
          <Button variant="ghost-danger" onClick={props.openDeleteModal} disabled={ props.pending }>Delete request</Button>
        </div>
        <div className="card card--primary card--vertical-split">
          <div className="group">
            <div className="xxs12 md6">
              <h4 className="card__heading">Origin</h4>
              {/* <button onClick={ testLog }>TEST</button> */}
              <MapsAutocomplete
                placeholder="Search for cities..."
                label="City and country"
                value={ props.rateRequest.origin }
                name="origin"
                type="place"
                onSelectItem={ updateOrigin }
                disabled={ props.pending }
                required
                showError={ props.showError }
              />
              <MapsAutocomplete
                placeholder="Search for address..."
                label="Street and number"
                value={ props.rateRequest.originAddress }
                name="origin"
                type="address"
                parentContext={ props.rateRequest.origin }
                onSelectItem={ updateOriginAddress }
                customInput={ true }
                disabled={ props.pending || !props.rateRequest.origin?.city }
                showError={ props.showError }
                optional
              />
              { props.rateRequest.loadingDateApproximate && (
                <Input
                  label="Approximate loading date"
                  value={ props.rateRequest.loadingDateApproximateValue }
                  onChange={ (input: any) => props.setRateRequest({...props.rateRequest, loadingDateApproximateValue: input.target?.value}) }
                  optional
                />
              ) }
              { !props.rateRequest.loadingDateApproximate && (
                <DatePicker
                  label="Loading date"
                  selected={props.rateRequest.loadingDate}
                  onChange={(date: any) => updateLoadingDate(date)}
                  selectsStart
                  startDate={props.rateRequest.loadingDate}
                  endDate={props.rateRequest.deliveryDate}
                  minDate={new Date()}
                  maxDate={new Date(2099, 1, 1)}
                  dateFormat="dd/MM/yyyy"
                  disabled={ props.pending }
                  optional
                />
              ) }
              <Checkbox
                value="approximate"
                label="This is an approximate date"
                name="checkbox"
                checked={ props.rateRequest.loadingDateApproximate }
                onChange={ () => {
                  console.log(props.rateRequest.loadingDateApproximate)
                  props.setRateRequest({
                    ...props.rateRequest,
                    loadingDateApproximate: !props.rateRequest.loadingDateApproximate
                  });
                } }
              />
            </div>

            <div className="xxs12 md6">
              <h4 className="card__heading">Destination</h4>
              <MapsAutocomplete
                placeholder="Search for cities..."
                label="City and country"
                value={ props.rateRequest.destination }
                name="destination"
                type="place"
                onSelectItem={ updateDestination }
                required
                disabled={ props.pending }
                showError={ props.showError }
              />
              <MapsAutocomplete
                placeholder="Search for address..."
                label="Street and number"
                value={ props.rateRequest.destinationAddress }
                name="destination"
                type="address"
                parentContext={ props.rateRequest.destination }
                onSelectItem={ updateDestinationAddress }
                customInput={ true }
                required
                disabled={ props.pending || !props.rateRequest.destination?.city }
                showError={ props.showError }
                optional
              />
              { props.rateRequest.deliveryDateApproximate && (
                <Input
                  label="Approximate delivery date"
                  value={ props.rateRequest.deliveryDateApproximateValue }
                  onChange={ (input: any) => props.setRateRequest({...props.rateRequest, deliveryDateApproximateValue: input.target?.value}) }
                  optional
                />
              ) }
              { !props.rateRequest.deliveryDateApproximate && (
                <DatePicker
                  label="Delivery date"
                  selected={props.rateRequest.deliveryDate}
                  onChange={(date: any) => props.setRateRequest({...props.rateRequest, deliveryDate: date})}
                  selectsEnd
                  startDate={props.rateRequest.loadingDate}
                  endDate={props.rateRequest.deliveryDate}
                  minDate={props.rateRequest.loadingDate || new Date()}
                  maxDate={new Date(2099, 1, 1)}
                  dateFormat="dd/MM/yyyy"
                  disabled={ props.pending }
                  optional
                />
              ) }
              <Checkbox
                value="approximate"
                label="This is an approximate date"
                name="checkbox"
                checked={ props.rateRequest.deliveryDateApproximate }
                onChange={ () => {
                  console.log(props.rateRequest.deliveryDateApproximate)
                  props.setRateRequest({
                    ...props.rateRequest,
                    deliveryDateApproximate: !props.rateRequest.deliveryDateApproximate
                  });
                } }
              />
            </div>
          </div>
          <div className="group">
            <div className="xxs12">
              <TransportDetails
                originAddress={ props.rateRequest.originAddress }
                origin={ props.rateRequest.origin }
                destinationAddress={ props.rateRequest.destinationAddress }
                destination={ props.rateRequest.destination }
              />
            </div>
          </div>
        </div>
        <div className="card card--primary">
          <h4 className="card__heading">Details</h4>
          <div className="group">
            <div className="xxs12 md6">
              <Input value={ props.rateRequest.referenceOrder || "" } label="Reference order" name="referenceOrder" onChange={(event: InputEvent) => {updateField(event)}} required showError={ props.showError } disabled={props.pending} />
            </div>
            <div className="xxs12 md6">
              <Input value={ props.rateRequest.rateRequestNumber || "" } label="Rate request number" name="rateRequestNumber" disabled />
            </div>
          </div>
          <div className="group">
            <div className="xxs12 md6">
              <Input value={ props.rateRequest.targetRate || "" } label="Target rate" name="targetRate" prepend="€" optional onChange={(event: InputEvent) => {updateField(event)}} disabled={props.pending} type="number" />
            </div>
            <div className="xxs12 md6">
              <DatePicker
                label="Supplier response deadline"
                selected={props.rateRequest.timeframe}
                onChange={(date: any) => props.setRateRequest({...props.rateRequest, timeframe: date})}
                minDate={moment().add(1,'days').toDate()}
                dateFormat="dd/MM/yyyy"
                disabled={ props.pending }
                optional
              />
            </div>
          </div>
        </div>

        <div className="card card--primary">
          <h4 className="card__heading">Quote options</h4>
          <ul className="quote-options-list" tabIndex={0}>
            { props.rateRequest.quoteOptions && props.rateRequest.quoteOptions.map((quoteOption: QuoteOption, index: number) =>
            <li className="quote-options-list__item" key={ index }>
              <div className="group">
                <div className="xxs12 md3">
                  <Select
                    label="Rate request mode"
                    value={ getSelectOption("rateRequestModes", quoteOption.rateRequestMode) }
                    name="rateRequestMode"
                    placeholder="Select mode..."
                    aside={ (
                    <button type='button' onClick={ () => props.openInfoModal(quoteOption.rateRequestMode) }><IconInfo fill="#000" /></button>
                    ) }
                    onChange={ (option: StrictDict) => setQuoteOption(index, "rateRequestMode", option) }
                    options={ labels.rateRequestModes }
                    required
                    showError={ props.showError }
                  />
                </div>

                <div className="xxs12 md2">
                  <Input
                    value={ quoteOption.volume || "" }
                    label="Volume"
                    name="volume"
                    append="m³"
                    onChange={ (event: StrictDict) => setQuoteOption(index, "volume", event) }
                    type="number"
                    required
                    showError={ props.showError }
                    />
                </div>

                <div className="xxs12 md3">
                  <Select
                    label="Transport mode"
                    value={ getSelectOption("transportModes", quoteOption.transportMode) }
                    name="transportMode"
                    placeholder="Select mode..."
                    onChange={ (option: StrictDict) => setQuoteOption(index, "transportMode", option) }
                    options={ labels.transportModes }
                    required
                    showError={ props.showError }
                    />
                </div>

                <div className="xxs12 md2">
                  <Select
                    label="Packing type"
                    value={ getSelectOption("packagingTypes", quoteOption.packagingType) }
                    name="packagingType"
                    placeholder="Select type..."
                    onChange={ (option: StrictDict) => setQuoteOption(index, "packagingType", option) }
                    options={ labels.packagingTypes }
                    required
                    showError={ props.showError }
                    />
                </div>

                <div className="xxs12 md2">
                  <div className="quote-options-list__actions">
                    <Button variant="ghost" tip="Duplicate" circle onClick={ () => duplicateQuoteOption(index) }>
                      <IconDuplicate />
                    </Button>
                    <Button variant="ghost" tip="Delete" circle onClick={ () => removeQuoteOption(index) }>
                      <IconCrossRed />
                    </Button>
                  </div>
                </div>

                { quoteOption.error &&
                  <div className="xxs12">
                    <div className="quote-options-list__error">
                      <IconError className="quote-options-list__error-icon" />
                      <span className="quote-options-list__error-message">{ quoteOption.errorMessage }</span>
                    </div>
                  </div>
                }
              </div>
            </li>
            )}
          </ul>

          <Button variant="ghost" customClassName="button--full-width" onClick={ addNewQuoteOption }>
            <>
              <IconPlusSmall className="button__icon" />
              Add new quote option
            </>
          </Button>
        </div>

        <EditAdditionals rateRequest={props.rateRequest} setRateRequest={props.setRateRequest} />

        <div className="card card--primary">
          <h4 className="card__heading">Notes</h4>
          <div className="group">
            <div className="xxs12">
              <Textarea
                value={ props.rateRequest.notes?.length ? props.rateRequest.notes[0].text : '' }
                name="notes[]"
                label="Add a message"
                placeholder="e.g. difficult access or busy street"
                onChange={ (event: React.FormEvent<HTMLInputElement>) => updateNote((event.target as HTMLTextAreaElement).value) }
                disabled={ props.pending }
              />
            </div>
          </div>
          {/* <p>This note can still be edited and more notes can be added at a later stage as well.</p> */}
        </div>

        <div className="card card--primary">
          <h4 className="card__heading">Attachments</h4>
          <div className="group">
            <div className="xxs12">
              <Attachments rateRequest={ props.rateRequest } />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
