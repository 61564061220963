import React, { useState, ChangeEvent } from "react";

import Rating from "../../components/Rating/Rating";
import { Navigation, Header } from "../../components/Layout";
import { Input, Radio, Checkbox, Select, DropdownButton } from "../../components/Form";
import MapsAutocomplete from "../../components/Maps/Autocomplete";

import { ReactComponent as IconArrowRight } from "../../assets/images/icons/arrow-right.svg";
import { ReactComponent as IconPlus } from "../../assets/images/icons/plus.svg";

export default function Dashboard() {
  const [ demoInput, setDemoInput ] = useState("Demo content");
  const [ demoRadio, setDemoRadio ] = useState("checked");
  const [ demoCheckbox1, setDemoCheckbox1 ] = useState(false);
  const [ demoCheckbox2 ] = useState(true);
  const [ demoCheckbox3 ] = useState(true);
  const [ demoCheckbox4, setDemoCheckbox4 ] = useState(false);
  const [ demoSwitch, setDemoSwitch ] = useState(false);
  const [ demoSelect, setDemoSelect ] = useState({
    label: "Fastest",
    value: "fastest"
  });
  const [ demoMultiSelect, setDemoMultiSelect ] = useState([
    {
      label: "Overall",
      value: "overall"
    }, {
      label: "Fastest",
      value: "fastest"
    }
  ]);

  const [ demoMultiRowSelect, setDemoMultiRowSelect ] = useState([
    {
      label: "Overall",
      value: "overall"
    }, {
      label: "Fastest",
      value: "fastest"
    }, {
      label: "Cheapest",
      value: "cheapest"
    }, {
      label: "Rating",
      value: "rating"
    }
  ]);

  const selectOptions = [
    {
      label: "Overall",
      value: "overall"
    }, {
      label: "Fastest",
      value: "fastest"
    }, {
      label: "Cheapest",
      value: "cheapest"
    }, {
      label: "Rating",
      value: "rating"
    }
  ];

  const navigationItems = [
    {
      to: "/dashboard",
      label: "Dashboard"
    }, {
      to: "/styleguide",
      label: "Styleguide"
    }, {
      to: "/suppliers",
      label: "Suppliers"
    }
  ];

  return (
    <>
      <Header
        middleContent={
          <Navigation navItems={navigationItems} variant="secondary" />
        }
        accountDropdown
        backLink="/login"
        title="Styleguide - Title"
        subTitle="Header subtitle"
        aside={ <Rating rating={4.3} ratingCount={12} /> }
        preferRating={ 3 }
      />

      <div className="styleguide">
        <section className="styleguide__section">
          <div className="container">
            <div className="styleguide__header">
              <h4>Colors</h4>
              <hr/>
            </div>
            <div className="styleguide__content">
              <div className="group">
                <div className="xxs md3">
                  <div className="styleguide__color styleguide__color-primary">
                    <h4>Primary #00A060</h4>
                    <div className="styleguide__color-shade-1"></div>
                    <div className="styleguide__color-shade-2"></div>
                    <div className="styleguide__color-shade-3"></div>
                  </div>
                </div>
                <div className="xxs md3">
                  <div className="styleguide__color styleguide__color-secondary">
                    <h4>Secondary #58B336</h4>
                    <div className="styleguide__color-shade-1"></div>
                    <div className="styleguide__color-shade-2"></div>
                    <div className="styleguide__color-shade-3"></div>
                  </div>
                </div>
                <div className="xxs md3">
                  <div className="styleguide__color styleguide__color-danger">
                    <h4>Danger #F23232</h4>
                    <div className="styleguide__color-shade-1"></div>
                    <div className="styleguide__color-shade-2"></div>
                    <div className="styleguide__color-shade-3"></div>
                  </div>
                </div>
                <div className="xxs md3">
                  <div className="styleguide__color styleguide__color-warning">
                    <h4>Warning #F2AF32</h4>
                    <div className="styleguide__color-shade-1"></div>
                    <div className="styleguide__color-shade-2"></div>
                    <div className="styleguide__color-shade-3"></div>
                  </div>
                </div>
              </div>
              <div className="group">
                <div className="xxs md3">
                  <div className="styleguide__color styleguide__color-default">
                    <h4>Default #27A3E9</h4>
                    <div className="styleguide__color-shade-1"></div>
                    <div className="styleguide__color-shade-2"></div>
                    <div className="styleguide__color-shade-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="styleguide__section">
          <div className="container">
            <div className="styleguide__header">
              <h4>Typography</h4>
              <hr/>
            </div>
            <div className="styleguide__content">
              <div className="group">
                <div className="xxs12 md1">
                  <h5>H1</h5>
                </div>
                <div className="xxs12 md11">
                  <h1>The quick brown fox jumps over the lazy dog</h1>
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md1">
                  <h5>H2</h5>
                </div>
                <div className="xxs12 md11">
                  <h2>The quick brown fox jumps over the lazy dog</h2>
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md1">
                  <h5>H3</h5>
                </div>
                <div className="xxs12 md11">
                  <h3>The quick brown fox jumps over the lazy dog</h3>
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md1">
                  <h5>H4</h5>
                </div>
                <div className="xxs12 md11">
                  <h4>The quick brown fox jumps over the lazy dog</h4>
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md1">
                  <h5>H5</h5>
                </div>
                <div className="xxs12 md11">
                  <h5>The quick brown fox jumps over the lazy dog</h5>
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md1">
                  <h5>H6</h5>
                </div>
                <div className="xxs12 md11">
                  <h6>The quick brown fox jumps over the lazy dog</h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="styleguide__section">
          <div className="container">
            <div className="styleguide__header">
              <h4>Buttons</h4>
              <hr/>
            </div>
            <div className="styleguide__content">
              <div className="group">
                <div className="xxs12 md3">
                  <h6>Primary</h6>
                  <button className="button button--primary">
                    <span>Call to Action</span>
                    <IconArrowRight className="button__icon" />
                  </button>
                </div>
                <div className="xxs12 md3">
                  <h6>Secondary</h6>
                  <button className="button button--secondary">
                    <span>Call to Action</span>
                    <IconArrowRight className="button__icon" />
                  </button>
                </div>
                <div className="xxs12 md3">
                  <h6>Ghost</h6>
                  <button className="button button--ghost">
                    <span>Call to Action</span>
                    <IconArrowRight className="button__icon" />
                  </button>
                </div>
                <div className="xxs12 md3">
                  <h6>Text</h6>
                  <button className="button button--text">
                    <IconPlus className="button__icon" />
                    <span>Call to Action</span>
                  </button>
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md3">
                  <h6>Disabled</h6>
                  <button className="button button--primary" disabled>
                    <span>Call to Action</span>
                    <IconArrowRight className="button__icon" />
                  </button>
                </div>
                <div className="xxs12 md3">
                  <h6>Disabled</h6>
                  <button className="button button--secondary" disabled>
                    <span>Call to Action</span>
                    <IconArrowRight className="button__icon" />
                  </button>
                </div>
                <div className="xxs12 md3">
                  <h6>Disabled</h6>
                  <button className="button button--ghost" disabled>
                    <span>Call to Action</span>
                    <IconArrowRight className="button__icon" />
                  </button>
                </div>
                <div className="xxs12 md3">
                  <h6>Disabled</h6>
                  <button className="button button--text" disabled>
                    <IconPlus className="button__icon" />
                    <span>Call to Action</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="styleguide__section">
          <div className="container">
            <div className="styleguide__header">
              <h4>Form</h4>
              <hr/>
            </div>
            <div className="styleguide__content">
              <div className="group">
                <div className="xxs12 md3">
                  <Input value={ demoInput } label="Default input" name="demoInput" onChange={(event: ChangeEvent<HTMLInputElement>) => {setDemoInput(event.target.value)}} />
                </div>
                <div className="xxs12 md3">
                  <Input value={ demoInput } label="Required input" name="demoInput" onChange={(event: ChangeEvent<HTMLInputElement>) => {setDemoInput(event.target.value)}} required />
                </div>
                <div className="xxs12 md3">
                  <Input value={ demoInput } label="Disabled input" name="demoInput" onChange={(event: ChangeEvent<HTMLInputElement>) => {setDemoInput(event.target.value)}} disabled />
                </div>
                <div className="xxs12 md3">
                  <Input value={ demoInput } label="Error input" name="demoInput" showError errorLabel="Error status" onChange={(event: ChangeEvent<HTMLInputElement>) => {setDemoInput(event.target.value)}} />
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md3">
                  <Input value={ demoInput } label="Prepended input" name="demoInput" prepend="€" onChange={(event: ChangeEvent<HTMLInputElement>) => {setDemoInput(event.target.value)}} />
                </div>
                <div className="xxs12 md3">
                  <Input value={ demoInput } label="Appended input" name="demoInput" append="+ 50km" onChange={(event: ChangeEvent<HTMLInputElement>) => {setDemoInput(event.target.value)}} required />
                </div>
                <div className="xxs12 md3">
                  <Input value={ demoInput } label="Appended disabled input" name="demoInput" append="+ 50km" onChange={(event: ChangeEvent<HTMLInputElement>) => {setDemoInput(event.target.value)}} disabled />
                </div>
                <div className="xxs12 md3">
                  <Input value={ demoInput } label="Appended error input" name="demoInput" append="+ 50km" showError errorLabel="Error status" onChange={(event: ChangeEvent<HTMLInputElement>) => {setDemoInput(event.target.value)}} required />
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md6">
                  <MapsAutocomplete
                    placeholder="Search for cities..."
                    label="Autocomplete map input"
                    value={ { label: "" } }
                    name="origin"
                    type="place"
                    onSelectItem={ () => {} }
                    append="+ 50 km"
                  />
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md3">
                  <Checkbox value="normal" label="Checkbox Normal" name="demoCheckbox" checked={ demoCheckbox1 } onChange={() => {setDemoCheckbox1(!demoCheckbox1)}} />
                  <Checkbox value="active" label="Checkbox Active" name="demoCheckbox" checked={ demoCheckbox2 } onChange={() => {}} />
                  <Checkbox value="child" label="Checkbox Child Active" name="demoCheckbox" checked={ demoCheckbox3 } childActive onChange={() => {}} />
                  <Checkbox value="disabled" label="Checkbox Disabled" name="demoCheckbox" checked={ demoCheckbox4 } onChange={() => {setDemoCheckbox4(!demoCheckbox4)}} disabled />
                </div>
                <div className="xxs12 md3">
                  <Radio value="normal" label="Radio Normal" name="demoRadio" checked={ demoRadio === "normal" } onChange={(event: ChangeEvent<HTMLInputElement>) => {setDemoRadio(event.target.value)}} />
                  <Radio value="checked" label="Radio Active" name="demoRadio" checked={ demoRadio === "checked" } onChange={(event: ChangeEvent<HTMLInputElement>) => {setDemoRadio(event.target.value)}} />
                  <Radio value="disabled" label="Radio Disabled" name="demoRadio" onChange={() => {}} disabled />
                </div>
                <div className="xxs12 md3">
                  <Checkbox value="normal" label="Switch Normal" name="demoCheckbox" checked={ demoSwitch } onChange={() => {setDemoSwitch(!demoSwitch)}} switch />
                  <Checkbox value="checked" label="Switch Active" name="demoCheckbox" checked={true} onChange={() => {}} switch />
                  <Checkbox value="disabled" label="Switch Disabled" name="demoCheckbox" onChange={() => {}} switch disabled />
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md3">
                  <Select
                    label="Filled select"
                    value={demoSelect}
                    name="demoSelect"
                    onChange={setDemoSelect}
                    options={selectOptions}
                  />
                </div>
                <div className="xxs12 md3">
                  <Select
                    label="Disabled select"
                    value={demoSelect}
                    name="demoSelect"
                    onChange={setDemoSelect}
                    options={selectOptions}
                    disabled
                  />
                </div>
                <div className="xxs12 md3">
                  <Select
                    label="Error select"
                    value={demoSelect}
                    name="demoSelect"
                    onChange={setDemoSelect}
                    options={selectOptions}
                    error="Error status"
                  />
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md3"></div>
                <div className="xxs12 md3"></div>
                <div className="xxs12 md3">
                  <DropdownButton label="Quote options">
                    <>
                      <Checkbox value="disabled" label="WTD / 25m3 / Direct load / Blanket wrap" name="demoCheckbox" checked={ demoCheckbox4 } onChange={() => {setDemoCheckbox4(!demoCheckbox4)}} />
                      <Checkbox value="disabled" label="WTD / 25m3 / Combined load / Export wrap" name="demoCheckbox" checked={ demoCheckbox4 } onChange={() => {setDemoCheckbox4(!demoCheckbox4)}} />
                      <Checkbox value="disabled" label="WTD / 30m3 / Direct load / Blanket wrap" name="demoCheckbox" checked={ demoCheckbox4 } onChange={() => {setDemoCheckbox4(!demoCheckbox4)}} />
                      <Checkbox value="disabled" label="WTD / 30m3 / Combined load / Export wrap" name="demoCheckbox" checked={ demoCheckbox4 } onChange={() => {setDemoCheckbox4(!demoCheckbox4)}} />
                    </>
                  </DropdownButton>
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md4">
                  <Select
                    label="Multi select"
                    value={demoMultiSelect}
                    name="demoSelect"
                    onChange={setDemoMultiSelect}
                    options={selectOptions}
                    multi
                  />
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md4">
                  <Select
                    label="Multi-row select"
                    value={demoMultiRowSelect}
                    name="demoSelect"
                    onChange={setDemoMultiRowSelect}
                    options={selectOptions}
                    multi
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
