import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import GlobalData from '../../context/globaldata';
import { Header } from "../../components/Layout";
import { Input, Select, Textarea, DatePicker } from "../../components/Form";
import Button from "../../components/Button/Button";
import TransportDetails from "../../components/TransportDetails/TransportDetails";

import { ReactComponent as IconArrowLeft } from "../../assets/images/icons/arrow-left.svg";
import { ReactComponent as IconArrowRight } from "../../assets/images/icons/arrow-right.svg";
import { ReactComponent as IconError } from "../../assets/images/icons/error.svg";
import { ReactComponent as IconExport } from "../../assets/images/icons/export.svg";
import moment from "moment";
import Footer from "../../components/Footer/Footer";
import Modal from "../../components/Modal/Modal";
import Attachments from "../../components/Attachments/Attachments";

import { ReactComponent as LoadingIcon } from "../../assets/images/logo-animated.svg";
import parseDate from "../../utils/ParseDate";

export default function ResponseIndex() {
  // @ts-ignore
  const { uuid } = useParams();
  const history = useHistory();
  const context = useContext(GlobalData);
  const labels = context.rateRequestLabels;
  const [ rateRequestResponse, setRateRequestResponse ] = useState<RateRequestResponse>({} as RateRequestResponse);
  const [ supplier, setSupplier ] = useState<Supplier>({} as Supplier);
  const [ manager, setManager ] = useState<Dict>({} as Dict);
  const [ rateRequest, setRateRequest ] = useState<RateRequest>({} as RateRequest);
  const [ pending, setPending ] = useState(false);
  const [ showError, setShowError ] = useState(false);
  const [ submitModalVisible, setSubmitModalVisible ] = useState(false);
  const [ responseSubmitted, setResponseSubmitted ] = useState(false);
  const [ loading, setLoading ] = useState(true);
  const [ loadingError, setLoadingError] = useState(false);
  const [ fetching, setFetching ] = useState(false);

  const [ newComment, setNewComment ] = useState('');

  const availableBidOptions = [
    {
      label: "Quote",
      value: true,
    }, {
      label: "Deny",
      value: false
    }
  ];

  const getSelectedBidOption = (value: boolean, index: number) => {
    return (rateRequestResponse.quoteOptionsResponses[index].selectedStatus || responseSubmitted) && availableBidOptions.find((option: StrictDict) => option.value === value);
  };

  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [infoModalTitle, setInfoModalTitle] = useState<string>('');
  const [infoModalContent, setInfoModalContent] = useState<any>();

  const openInfoModal = (rateRequestMode: number) => {
    setInfoModalOpen(true);
    setInfoModalTitle(labels.rateRequestModes[rateRequestMode].labelLong);
    setInfoModalContent(labels.rateRequestModes[rateRequestMode].info);
  };

  useEffect(() => {
    const getRateRequestResponse = () => {
      context.firestore.collectionGroup("responses")
      .where("uuid", "==", uuid)
      .get()
      .then((rateRequestResponseSnapshot: any) => {
        if (rateRequestResponseSnapshot.empty) {
          setLoading(false);
          setLoadingError(true);
          return;
        }

        const rateRequestResponseData = rateRequestResponseSnapshot.docs[0].data();

        rateRequestResponseData.uid = rateRequestResponseSnapshot.docs[0].ref.id;

        setRateRequestResponse(rateRequestResponseData);

        getSupplier(rateRequestResponseData.supplierUid);

        getRateRequest(rateRequestResponseSnapshot.docs[0].ref.parent.parent.id);
      });
    };

    const getRateRequest = (rateRequestUid: string) => {
      context.firestore.collection("rate_requests").doc(rateRequestUid).get().then((rateRequestSnapshot: any) => {
        const rateRequestData = rateRequestSnapshot.data();

        rateRequestData.uid = rateRequestUid;

        rateRequestData.createdAt = parseDate(rateRequestData.createdAt);
        rateRequestData.loadingDate = parseDate(rateRequestData.loadingDate);
        rateRequestData.deliveryDate = parseDate(rateRequestData.deliveryDate);
        rateRequestData.timeframe = parseDate(rateRequestData.timeframe) || null;

        rateRequestData.notes && rateRequestData.notes.forEach((note: StrictDict) => {
          note.isEditing = false;
        });

        setRateRequestResponse((prevState: RateRequestResponse) => {
          const nextState:RateRequestResponse = Object.assign({}, prevState);

          nextState.submittedAt = parseDate(nextState.submittedAt);

          nextState.quoteOptionsResponses.forEach((quoteOptionResponse: QuoteOptionResponse) => {
            quoteOptionResponse.loadingDate = parseDate(quoteOptionResponse.loadingDate);
            quoteOptionResponse.deliveryDate = parseDate(quoteOptionResponse.deliveryDate);
          });

          if (nextState.comments) {
            nextState.comments.forEach((comment: StrictDict) => {
              comment.postedDate = parseDate(comment.postedDate);

              if (comment.editedDate) {
                comment.editedDate = parseDate(comment.editedDate);
              }
            })
          }

          return nextState;
        });

        getManager(rateRequestData.userSubmitted);

        setRateRequest(rateRequestData);
      });
    };

    const getSupplier = (supplierUid: string) => {
      let foundSupplier:Supplier;

      context.firestore.collection("suppliers").doc(supplierUid).get().then(async (supplierSnapshot: any) => {
        if (!supplierSnapshot.exists) {
          return;
        }

        const data = supplierSnapshot.data();

        foundSupplier = data;

        setSupplier(foundSupplier);
      });
    };

    const getManager = (userUid: string) => {
      let foundUser:Dict;

      context.firestore.collection("users").doc(userUid).get().then(async (userSnapshot: any) => {
        if (!userSnapshot.exists) {
          return;
        }

        const data = userSnapshot.data();

        foundUser = data;

        setManager(foundUser);

        setLoading(false);
      });
    };

    // Check if user is signed in
    if (context.currentUser.custom) {
      getRateRequestResponse();
    } else if (!localStorage.getItem('expectSignIn') && !fetching) {
      setFetching(true);

      // If not, and not expected to sign in, retrieve data with https request
      let requestUrl = 'https://europe-west1-gosselin-scw.cloudfunctions.net/';

      // Local testing URL
      // if (window.location.hostname === "localhost") {
      //   requestUrl = 'http://localhost:5001/gosselin-scw/europe-west1/'
      // }

      fetch(`${requestUrl}getResponseData?uuid=${uuid}&r=${ Math.random() }`)
      .then(response => {
        response.json()
        .then(data => {
          if (data.error) {
            console.error(data.message);
            setLoadingError(true);
            setLoading(false);

            return;
          }

          if (data.rateRequest.notes) {
            data.rateRequest.notes.forEach((note: StrictDict) => {
              note.isEditing = false;
              note.postedDate = parseDate(note.postedDate);

              if (note.editedDate) {
                note.editedDate = parseDate(note.editedDate);
              }
            });
          }

          if (data.rateRequestResponse.comments) {
            data.rateRequestResponse.comments.forEach((comment: StrictDict) => {
              comment.postedDate = parseDate(comment.postedDate);;

              if (comment.editedDate) {
                comment.editedDate = parseDate(comment.editedDate);
              }
            })
          }

          data.rateRequest.loadingDate = parseDate(data.rateRequest.loadingDate);
          data.rateRequest.deliveryDate = parseDate(data.rateRequest.deliveryDate);
          data.rateRequest.timeframe = parseDate(data.rateRequest.timeframe);

          data.rateRequestResponse.quoteOptionsResponses.forEach((quoteOptionResponse: QuoteOptionResponse) => {
            quoteOptionResponse.loadingDate = parseDate(quoteOptionResponse.loadingDate);
            quoteOptionResponse.deliveryDate = parseDate(quoteOptionResponse.deliveryDate);
          });

          setRateRequestResponse(data.rateRequestResponse);
          setRateRequest(data.rateRequest);
          setManager(data.manager);
          setSupplier(data.supplier);

          process.env.NODE_ENV === 'development' && console.log(data);

          setLoading(false);
        });
      })
      .catch((error) => {
        console.log(error);
        setLoadingError(true);
        setLoading(false);
      })
      .finally(() => {
      });
    }
  }, [ context.firestore, context.currentUser.custom, fetching, uuid ]);

  useEffect(() => {
    if (rateRequest.origin && supplier.car) {
      setLoading(false);

      if (supplier.status.inactive) {
        setLoadingError(true);
      }

      // @ts-ignore
      // if (rateRequest.loadingDate && rateRequest.deliveryDate && !rateRequest.loadingDateApproximate && !rateRequest.deliveryDateApproximate) {
      //   if (rateRequest.timeframe && rateRequest.timeframe < (new Date())) {
      //     setLoadingError(true);
      //   }
      // }

      if (rateRequestResponse.submittedAt) {
        setResponseSubmitted(true);
      }
    }
  }, [
    rateRequest,
    supplier,
    rateRequestResponse.submittedAt
  ]);

  const updateField = (event: any) => {
    const eventName = event.target.name;
    const eventValue = event.target.value;

    setRateRequestResponse({
      ...rateRequestResponse,
      [eventName]: eventValue
    });
  };

  const updateQuoteOptionField = (event: any, index: number) => {
    const eventName = event.target.name;
    const eventValue = event.target.value;

    setRateRequestResponse((prevState: RateRequestResponse) => {
      const nextState = Object.assign({}, prevState);

      const quoteOptionResponse:QuoteOptionResponse = nextState.quoteOptionsResponses[index];

      // Map the event key to the keys of the QuoteOptionResponse object
      const key:keyof QuoteOptionResponse = eventName;

      (quoteOptionResponse[key] as StrictDict) = eventValue;

      return nextState;
    });
  };

  const updateQuoteOptionStatus = (option: StrictDict, index: number) => {
    setRateRequestResponse((prevState: RateRequestResponse) => {
      const nextState = Object.assign({}, prevState);

      const quoteOptionResponse:QuoteOptionResponse = nextState.quoteOptionsResponses[index];

      quoteOptionResponse.selectedStatus = option;

      quoteOptionResponse.accepted = option.value;

      return nextState;
    });
  };

  const updateQuoteOptionLoadingDate = (date: Date, index: number) => {
    setRateRequestResponse((prevState: RateRequestResponse) => {
      const nextState = Object.assign({}, prevState);

      const quoteOptionResponse:QuoteOptionResponse = nextState.quoteOptionsResponses[index];

      quoteOptionResponse.loadingDate = date;

      return nextState;
    });
  };

  const updateQuoteOptionDeliveryDate = (date: Date, index: number) => {
    setRateRequestResponse((prevState: RateRequestResponse) => {
      const nextState = Object.assign({}, prevState);

      const quoteOptionResponse:QuoteOptionResponse = nextState.quoteOptionsResponses[index];

      quoteOptionResponse.deliveryDate = date;

      return nextState;
    });
  };

  const updateAdditionalField = (event: any, index: number) => {
    const eventName = event.target.name;
    const eventValue = event.target.value;

    setRateRequestResponse((prevState: RateRequestResponse) => {
      const nextState = Object.assign({}, prevState);

      const additionalResponse:AdditionalResponse = nextState.additionalsResponses[index];

      // Map the event key to the keys of the AdditionalResponse object
      const key:keyof AdditionalResponse = eventName;

      (additionalResponse[key] as StrictDict) = eventValue;

      return nextState;
    });
  };

  const updateCustomAdditionalField = (event: any, index: number) => {
    const eventName = event.target.name;
    const eventValue = event.target.value;

    setRateRequestResponse((prevState: RateRequestResponse) => {
      const nextState = Object.assign({}, prevState);

      const additionalResponse:AdditionalResponse = nextState.customAdditionalsResponses[index];

      // Map the event key to the keys of the AdditionalResponse object
      const key:keyof AdditionalResponse = eventName;

      (additionalResponse[key] as StrictDict) = eventValue;

      return nextState;
    });
  };

  const getBidStatusClass = (index: number) => {
    if (rateRequestResponse.quoteOptionsResponses[index].selectedStatus?.value === false) {
      return 'bid-status--denied';
    } else if (rateRequestResponse.quoteOptionsResponses[index].selectedStatus?.value === true) {
      return 'bid-status--accepted';
    }

    return '';
  };

  const checkAdditionalSelection = (id: string, key: string): boolean => {
    const foundAdditionalSelection = rateRequest.additionals.find((additional: RateRequestAdditionalSelection) => id === additional.id);

    const indexedKey:keyof RateRequestAdditionalSelection = key as any;

    return foundAdditionalSelection
      ? (foundAdditionalSelection[indexedKey] as boolean)
      : false;
  };

  const getAdditional = (id: string): RateRequestAdditional => {
    const foundAdditionalSelection = rateRequest.additionals.find((additional: RateRequestAdditionalSelection) => id === additional.id);

    return foundAdditionalSelection?.item || {} as RateRequestAdditional;
  };

  const checkCustomAdditionalSelection = (id: string, key: string): boolean => {
    const foundAdditionalSelection = rateRequest.customAdditionals.find((additional: RateRequestAdditionalSelection) => id === additional.id);

    const indexedKey:keyof RateRequestAdditionalSelection = key as any;

    return foundAdditionalSelection
      ? (foundAdditionalSelection[indexedKey] as boolean)
      : false;
  };

  const additionalsVisible = () => {
    const acceptedQuoteFound = rateRequestResponse.quoteOptionsResponses?.find((response: any) => response.accepted === true);
    const additionalsFound = rateRequest.additionals?.find((additional: any) => additional.selectedDestination === true || additional.selectedOrigin === true) || rateRequest.customAdditionals?.length > 0;
    return acceptedQuoteFound && additionalsFound;
  };

  const getCustomAdditional = (id: string): RateRequestAdditional => {
    const foundAdditionalSelection = rateRequest.customAdditionals.find((additional: RateRequestAdditionalSelection) => id === additional.id);

    return foundAdditionalSelection?.item || {} as RateRequestAdditional;
  };

  const confirmSubmit = () => {
    setShowError(true);

    setTimeout(() => {
      // @ts-ignore
      const invalidElement:HTMLElement = document.querySelector('.is-invalid input');

      if (invalidElement) {
        invalidElement.focus();
      } else {
        setSubmitModalVisible(true);
      }
    }, 10);
  };

  const submitResponse = () => {
    setPending(true);

    // @ts-ignore
    rateRequestResponse.rateRequestUid = rateRequest.uid;

    if (newComment.trim().length) {
      const addedComment = {
        text: newComment,
        postedDate: new Date(),
        author: (supplier.alias ? supplier.alias : supplier.car?.Client),
      };

      if (!rateRequestResponse.comments) {
        rateRequestResponse.comments = [];
      }

      rateRequestResponse.comments?.unshift(addedComment);

      setNewComment('');
    }

    // User is logged in, save user ID in doc
    if (context.currentUser.custom) {
      rateRequestResponse.submittedUserUid = context.currentUser.custom.uid;
    }

    context.firestore.collection("temp_responses").doc(rateRequestResponse.uid).set(rateRequestResponse)
    .then(() => {
      setResponseSubmitted(true);
      setPending(false);
    })
    .catch((error: string) => {
      console.error(error);
      setPending(false);
    });
  };

  const addNewComment = () => {
    if (!newComment.trim().length) {
      return;
    }

    setPending(true);

    let comments = rateRequestResponse.comments ? rateRequestResponse.comments.slice() : [];

    comments.unshift({
      text: newComment,
      postedDate: new Date(),
      author: context.currentUser.custom
        ? 'Gosselin'
        : (supplier.alias ? supplier.alias : supplier.car?.Client),
    });

    context.firestore.collection("rate_requests")
    .doc(rateRequest.uid)
    .collection('responses')
    .doc(rateRequestResponse.uid)
    .update({
      ...rateRequestResponse,
      comments: comments,
    })
    .then(() => {
      setRateRequestResponse((prevState: any) => {
        const nextState = Object.assign({}, prevState);

        nextState.comments = comments;

        setPending(false);
        setNewComment('');

        return nextState;
      });
    })
    .catch((error: string) => {
      console.error(error);
      setPending(false);
    });
  };

  return (
    <>
    { (!loading && loadingError) &&
      <div className="notification">
        <div className="notification-item notification-danger">
          <div className="notification-content">
            <div className="notification-message">Invalid or outdated quote.</div>
          </div>
        </div>
      </div>
    }
    { responseSubmitted &&
      <div className="notification">
        <div className="notification-item notification-info">
          <div className="notification-content">
            <div className="notification-message">This quote has already been submitted and is not editable. However, you can still place comments on this quote.</div>
          </div>
        </div>
      </div>
    }
    { context.currentUser.custom &&
      <div className="notification">
        <div className="notification-item notification-info">
          <div className="notification-content">
            <div className="notification-message">You’re viewing this quote as a supplier.</div>
          </div>
        </div>
      </div>
    }
    <Header
      title={ `${ loading ? 'Loading...' : supplier.alias ? supplier.alias : supplier.car?.Client}` }
      subTitle={ supplier.car
                  ? `${supplier.car.CompanyAddress.GENCITY}, ${supplier.car.CompanyAddress.GENLAND}`
                  : ""
                }
      rightContent={
        (!loading && !loadingError) && (
          <>
            <Button variant="tertiary" onClick={() => window.print()}>
              <>
                <span>Export quote</span>
                <IconExport className="button__icon" />
              </>
            </Button>
            { !responseSubmitted &&
              <Button variant="primary" onClick={confirmSubmit} disabled={responseSubmitted}>
                <><span>Submit</span><IconArrowRight className="button__icon" /></>
              </Button>
            }
          </>
        )
      }
      leftContent={
        context.currentUser.custom && (
          <Button variant="ghost" onClick={() => history.push(`/rate-requests/${rateRequest.uid}/quotes`)}>
            <>
              <IconArrowLeft className="button__icon" />
              <span>Back to overview</span>
            </>
          </Button>
        )
      }
      logo={!context.currentUser.custom}
    />
    { (!loading && !loadingError && rateRequest && rateRequestResponse) &&
      <>
        { !loadingError && (
        <div className="container">
          <form action="" className="main-form">
            <div className="card card--overview">
              <div className="card__top">
                <div className="card-headings">
                  <h4>Request details</h4>
                  <h5>Detailed information about this spot rate request provided by Gosselin.</h5>
                </div>
              </div>
              { rateRequest.origin && rateRequest.destination &&
                <div className="group">
                  <div className="xxs12">
                    <TransportDetails
                      originAddress={ rateRequest.originAddress }
                      origin={ rateRequest.origin }
                      destinationAddress={ rateRequest.destinationAddress }
                      destination={ rateRequest.destination }
                    />
                  </div>
                </div>
              }
              <div className="group">
                <div className="xxs12 md6">
                  <div className="card__column">
                    <h6 className="card__column-subheading">Origin</h6>
                    <p>
                      {
                        rateRequest.originAddress
                          ? rateRequest.originAddress.city
                            ? rateRequest.originAddress.name
                            : `${ rateRequest.originAddress.name }, ${ rateRequest.origin.city }, ${ rateRequest.origin.country }`
                          : rateRequest.origin?.name
                      }
                    </p>
                  </div>
                  <div className="card__column">
                    <h6 className="card__column-subheading">Target loading date ({ !!rateRequest.loadingDateApproximate ? 'approximate' : 'fixed' })</h6>
                    { !!rateRequest.loadingDateApproximate
                      ? (<p>{ rateRequest.loadingDateApproximateValue || "N/A" }</p>)
                      : (<p>{ rateRequest.loadingDate ? moment(rateRequest.loadingDate).format("DD-MM-YYYY") : "N/A" }</p>)
                    }
                  </div>
                  <div className="card__column">
                    <h6 className="card__column-subheading">Reference order</h6>
                    <p>{ rateRequest.referenceOrder }</p>
                  </div>
                  <div className="card__column">
                    <h6 className="card__column-subheading">Target rate</h6>
                    <p>{ rateRequest.targetRate ? `€ ${rateRequest.targetRate}` : "N/A" }</p>
                  </div>
                </div>
                <div className="xxs12 md6">
                  <div className="card__column">
                    <h6 className="card__column-subheading">Destination</h6>
                    <p>
                      {
                        rateRequest.destinationAddress
                          ? rateRequest.destinationAddress.city
                            ? rateRequest.destinationAddress.name
                            : `${ rateRequest.destinationAddress.name }, ${ rateRequest.destination.city }, ${ rateRequest.destination.country }`
                          : rateRequest.destination?.name
                      }
                    </p>
                  </div>
                  <div className="card__column">
                    <h6 className="card__column-subheading">Target delivery date ({ !!rateRequest.deliveryDateApproximate ? 'approximate' : 'fixed' })</h6>
                    { !!rateRequest.deliveryDateApproximate
                      ? (<p>{ rateRequest.deliveryDateApproximateValue || "N/A" }</p>)
                      : (<p>{ rateRequest.deliveryDate ? moment(rateRequest.deliveryDate).format("DD-MM-YYYY") : "N/A" }</p>)
                    }
                  </div>
                  <div className="card__column">
                    <h6 className="card__column-subheading">Rate request number</h6>
                    <p>{ rateRequest.rateRequestNumber }</p>
                  </div>
                </div>
              </div>
              { !rateRequest.notes && rateRequest.message && (
                <>
                  <div className="group">
                    <div className="xxs12">
                      <hr/>
                    </div>
                  </div>
                  <div className="group">
                    <div className="xxs12">
                      <strong>Notes</strong>
                      <p>{ rateRequest.message || "N/A" }</p>
                    </div>
                  </div>
                </>
              )}
            </div>

            { rateRequest.notes && (
              <div className="card card--primary">
                <div className="group">
                  <div className="xxs12">
                    <h4>Notes</h4>
                  </div>
                </div>
                { rateRequest.notes?.length && rateRequest.notes?.map((note: StrictDict, index: number) =>
                  <React.Fragment key={ index }>
                    <div className="group note">
                      <div className="xxs10">
                        <p>{ note.text }</p>
                        { note?.postedDate && (
                          <time className='note__date' title={ note.postedDate }>Posted on {
                            moment(parseDate(note?.postedDate.toDate)).format('MMMM Do YYYY [at] HH:mm')
                          }</time>
                        ) }
                        { note?.editedDate && (
                          <time className='note__date' title={ note.editedDate }>&nbsp;• Last edited on {
                          moment(parseDate(note?.editedDate.toDate)).format('MMMM Do YYYY [at] HH:mm')
                        }</time>
                        ) }
                      </div>
                    </div>
                  </React.Fragment>
                ) }
              </div>
            ) }

            { (rateRequest.attachments && rateRequest.attachments[0] !== undefined) && (
              <div className="card card--primary">
                <h4 className="card__heading">Attachments</h4>
                <div className="group">
                  <div className="xxs12">
                    <Attachments rateRequest={ rateRequest } forceAdmin={ false } />
                  </div>
                </div>
              </div>
            ) }

            <div className="card card--primary">
              <div className="card__top card__top--secondary">
                <div className="card-headings">
                  <h4>Supplier details</h4>
                  <h5>Please enter a contact person and a reference for yourself.</h5>
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md6">
                  <div className="card__column">
                    <h6 className="card__column-subheading">E-mail address</h6>
                    <p>{ rateRequestResponse.overrideSupplierEmail || supplier.customEmail || supplier.car?.ContactInfo.INFEM }</p>
                  </div>
                </div>
              </div>
              <div className="group">
                <div className="xxs12 md6">
                  <div className="card__column">
                    <Input name="contactPerson" value={ rateRequestResponse.contactPerson || "" } label="Contact person" placeholder="Your full name" required onChange={ updateField } showError={ showError } disabled={ responseSubmitted }/>
                  </div>
                </div>
                <div className="xxs12 md6">
                  <div className="card__column">
                    <Input name="referenceInput" value={ rateRequestResponse.referenceInput || "" } label="Reference input" placeholder="Your reference input" optional onChange={ updateField } showError={ showError } disabled={ responseSubmitted }/>
                  </div>
                </div>
              </div>
            </div>

            <div className="card card--primary">
              <div className="card-headings">
                <h4 className="card__heading">Quote options</h4>
                <h5>Various options for this order.</h5>
              </div>
              <ul className="quote-options-list">
                { rateRequest.quoteOptions && rateRequest.quoteOptions.map((quoteOption: QuoteOption, index: number) =>
                <li
                  className={ `quote-options-list__item quote-options-list__item--has-border bid-status ${getBidStatusClass(index)}` }
                  key={ index }>
                  <div className="group">
                    <h5 className="xxs12">Option {(index + 1)}</h5>
                    <div className="card__column xxs12 md3">
                      <h6 className="card__column-subheading">Volume</h6>
                      { quoteOption.volume } m³
                    </div>

                    <div className="card__column xxs12 md3">
                      <h6 className="card__column-subheading">Packaging type</h6>
                      { labels.packagingTypes[quoteOption.packagingType].label }
                    </div>

                    <div className="card__column xxs12 md3">
                      <h6 className="card__column-subheading">Rate request mode</h6>
                      { labels.rateRequestModes[quoteOption.rateRequestMode].labelLong }
                      <button className="link-button" type="button" onClick={() => openInfoModal(quoteOption.rateRequestMode)}>Inclusions</button>
                    </div>

                    <div className="card__column xxs12 md3">
                      <h6 className="card__column-subheading">Transport mode</h6>
                      { labels.transportModes[quoteOption.transportMode].label }
                    </div>

                    <div className="card__row-spacer"></div>

                    <div className="card__column xxs12 md3">
                      <Select
                        value={getSelectedBidOption(rateRequestResponse.quoteOptionsResponses[index].accepted, index)}
                        name="quoteOptionAccepted"
                        label="Status"
                        onChange={ (option: StrictDict) => updateQuoteOptionStatus(option, index) }
                        required
                        options={ availableBidOptions }
                        searchable={ false }
                        showError={ showError }
                        disabled={ responseSubmitted }
                      />
                    </div>

                    { (rateRequestResponse.quoteOptionsResponses[index].selectedStatus || responseSubmitted) && rateRequestResponse.quoteOptionsResponses[index].accepted && (
                      <>
                        <div className="card__column xxs12 md3">
                          <Input
                            value={ rateRequestResponse.quoteOptionsResponses[index].rate || "" }
                            label="Rate"
                            name="rate"
                            type="number"
                            append="€"
                            required
                            step={ 1 }
                            min={ 50 }
                            onChange={(event: InputEvent) => updateQuoteOptionField(event, index)}
                            showError={ showError }
                            disabled={ responseSubmitted }
                          />
                        </div>

                        <div className="card__column xxs12 md3">
                          { rateRequest.loadingDateApproximate
                            // If the date is approximate, show the date as a date picker
                            ? moment(rateRequest.loadingDateApproximateValue, 'DD/MM/YYYY').isValid()
                              ? (
                                <DatePicker
                                  label={ `Loading date` }
                                  selected={rateRequestResponse.quoteOptionsResponses[index].loadingDate || moment(rateRequest.loadingDateApproximateValue, 'DD/MM/YYYY').toDate()}
                                  onChange={(date: any) => updateQuoteOptionLoadingDate(date, index)}
                                  minDate={moment(rateRequest.loadingDateApproximateValue, 'DD/MM/YYYY').subtract(5, 'days').toDate()}
                                  maxDate={rateRequest.deliveryDate || moment(rateRequest.deliveryDateApproximateValue, 'DD/MM/YYYY').toDate() || null}
                                  dateFormat="dd/MM/yyyy"
                                  optional
                                  disabled={ responseSubmitted }
                                  selectsStart
                                  startDate={rateRequestResponse.quoteOptionsResponses[index].loadingDate || moment(rateRequest.loadingDateApproximateValue, 'DD/MM/YYYY').toDate()}
                                  endDate={ rateRequestResponse.quoteOptionsResponses[index].deliveryDate || rateRequest.deliveryDate || moment(rateRequest.deliveryDateApproximateValue, 'DD/MM/YYYY').toDate() || null}
                                />
                              )
                              // If the date is not valid, show the date as a string
                              : (
                                <>
                                  <h6 className="card__column-subheading">Loading date (approx)</h6>
                                  { rateRequest.loadingDateApproximateValue || "N/A" }
                                </>
                              )
                            // Fixed loading date
                          : (
                            <>
                              <h6 className="card__column-subheading">Loading date (fixed)</h6>
                              { rateRequest.loadingDate ? moment(rateRequest.loadingDate).format("DD-MM-YYYY") : "N/A" }
                            </>
                          ) }
                        </div>

                        <div className="card__column xxs12 md3">
                        { rateRequest.deliveryDateApproximate
                            // If the date is approximate, show the date as a date picker
                            ? moment(rateRequest.deliveryDateApproximateValue, 'DD/MM/YYYY').isValid()
                              ? (
                                <DatePicker
                                  label={ `Delivery date` }
                                  selected={rateRequestResponse.quoteOptionsResponses[index].deliveryDate || moment(rateRequest.deliveryDateApproximateValue, 'DD/MM/YYYY').toDate()}
                                  onChange={(date: any) => updateQuoteOptionDeliveryDate(date, index)}
                                  minDate={rateRequestResponse.quoteOptionsResponses[index].loadingDate || rateRequest.loadingDate || moment(rateRequest.loadingDateApproximateValue, 'DD/MM/YYYY').toDate()}
                                  maxDate={rateRequest.deliveryDateApproximateValue ? moment(rateRequest.deliveryDateApproximateValue, 'DD/MM/YYYY').add(5, 'days').toDate() : null}
                                  dateFormat="dd/MM/yyyy"
                                  optional
                                  disabled={ responseSubmitted }
                                  selectsEnd
                                  endDate={rateRequestResponse.quoteOptionsResponses[index].deliveryDate || moment(rateRequest.deliveryDateApproximateValue, 'DD/MM/YYYY').toDate()}
                                  startDate={rateRequestResponse.quoteOptionsResponses[index].loadingDate || rateRequest.loadingDate || moment(rateRequest.loadingDateApproximateValue, 'DD/MM/YYYY').toDate()}
                                />
                              )
                              // If the date is not valid, show the date as a string
                              : (
                                <>
                                  <h6 className="card__column-subheading">Delivery date (approx)</h6>
                                  { rateRequest.deliveryDateApproximateValue || "N/A" }
                                </>
                              )
                            // Fixed delivery date
                          : (
                            <>
                              <h6 className="card__column-subheading">Delivery date (fixed)</h6>
                              { rateRequest.deliveryDate ? moment(rateRequest.deliveryDate).format("DD-MM-YYYY") : "N/A" }
                            </>
                          ) }
                        </div>

                        { quoteOption.error &&
                          <div className="xxs12">
                            <div className="quote-options-list__error">
                              <IconError className="quote-options-list__error-icon" />
                              <span className="quote-options-list__error-message">{ quoteOption.errorMessage }</span>
                            </div>
                          </div>
                        }
                      </>
                    ) }

                    { (rateRequestResponse.quoteOptionsResponses[index].selectedStatus || responseSubmitted) && rateRequestResponse.quoteOptionsResponses[index].accepted === false && (
                      <>
                        <div className="card__column xxs12 md9">
                          <Input value={ rateRequestResponse.quoteOptionsResponses[index].message || "" } label="Motivation" placeholder="Describe reason for denial" name="message" onChange={(event: InputEvent) => updateQuoteOptionField(event, index)} required showError={ showError } disabled={ responseSubmitted }/>
                        </div>
                      </>
                    ) }
                  </div>
                </li>
                )}
              </ul>
            </div>

            { additionalsVisible() &&
              <div className="card card--primary">
                <div className="card-headings">
                  <h4 className="card__heading">Additionals</h4>
                  <h5 className="card__subheading">Please enter fixed rates for the listed additionals.</h5>
                </div>
                <div className="additionals-list is-expanded">
                  <div className="additionals-list__header">
                    <div className="additionals-list__column additionals-list__column-label"></div>
                    <div className="additionals-list__column">
                      <strong>Origin rate</strong>
                    </div>
                    <div className="additionals-list__column">
                      <strong>Destination rate</strong>
                    </div>
                  </div>
                  <ul className="list list--additionals">
                    { rateRequestResponse.additionalsResponses?.map((additionalResponse: AdditionalResponse, index: number) =>
                      <li key={ additionalResponse.additionalId } className="list__item">
                        <div className="additionals-list__column additionals-list__column-label">
                          { getAdditional(additionalResponse.additionalId).label }
                        </div>
                        <div className="additionals-list__column">
                          { checkAdditionalSelection(additionalResponse.additionalId, 'selectedOrigin') && <Input value={ additionalResponse.rateOrigin || "" } name="rateOrigin" step={1} min={5} prepend="€" required onChange={(event: InputEvent) => updateAdditionalField(event, index)} showError={ showError } disabled={ responseSubmitted }/> }
                        </div>
                        <div className="additionals-list__column">
                          { checkAdditionalSelection(additionalResponse.additionalId, 'selectedDestination') && <Input value={ additionalResponse.rateDestination || "" } name="rateDestination" step={1} min={5} prepend="€" required onChange={(event: InputEvent) => updateAdditionalField(event, index)} showError={ showError } disabled={ responseSubmitted }/> }
                        </div>
                      </li>
                    )}
                    { rateRequestResponse.customAdditionalsResponses?.map((customAdditionalResponse: AdditionalResponse, index: number) =>
                      <li key={ customAdditionalResponse.additionalId } className="list__item">
                        <div className="additionals-list__column additionals-list__column-label">
                          { getCustomAdditional(customAdditionalResponse.additionalId).label }
                        </div>
                        <div className="additionals-list__column">
                          { checkCustomAdditionalSelection(customAdditionalResponse.additionalId, 'selectedOrigin') && <Input value={ customAdditionalResponse.rateOrigin || "" } name="rateOrigin" step={1} min={5} prepend="€" required onChange={(event: InputEvent) => updateCustomAdditionalField(event, index)} showError={ showError } disabled={ responseSubmitted }/> }
                        </div>
                        <div className="additionals-list__column">
                          { checkCustomAdditionalSelection(customAdditionalResponse.additionalId, 'selectedDestination') && <Input value={ customAdditionalResponse.rateDestination || "" } name="rateDestination" step={1} min={5} prepend="€" required onChange={(event: InputEvent) => updateCustomAdditionalField(event, index)} showError={ showError } disabled={ responseSubmitted }/> }
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            }

            <div className="card card--primary">
              <div className="group">
                <div className="xxs12">
                  <h4>Comments</h4>
                </div>
              </div>
              <>
                <div className="group group--small-margin">
                  <div className="xxs12">
                    <Textarea
                      value={ newComment }
                      name="notes[]"
                      placeholder={ `Your message for ${ context.currentUser.custom ? (supplier.alias ? supplier.alias : supplier.car?.Client) : 'Gosselin' }...` }
                      onChange={ (event: React.FormEvent<HTMLInputElement>) => setNewComment((event.target as HTMLTextAreaElement).value) }
                      disabled={ pending }
                    />
                  </div>
                </div>
                <div className="group">
                  <div className="xxs12 flex align-right">
                    <Button variant='primary' onClick={ addNewComment }>Post comment</Button>
                  </div>
                </div>
              </>
              { rateRequestResponse.comments?.map((comment: StrictDict, index: number) =>
                <React.Fragment key={ index }>
                  <div className="group note">
                    <div className="xxs10">
                      <p>{ comment.text }</p>
                      <p className='comment__date'>Posted by { comment.author } on { ' ' }
                        <time title={ comment.postedDate }> {
                          moment(parseDate(comment.postedDate)).format('MMMM Do YYYY [at] HH:mm')
                        }</time>
                        { comment.editedDate && (
                          <>
                            &nbsp;• Last edited on
                            <time title={ comment.editedDate }> {
                              moment(parseDate(comment.editedDate)).format('MMMM Do YYYY [at] HH:mm')
                            } </time>
                          </>
                        ) }
                      </p>
                    </div>
                  </div>
                </React.Fragment>
              ) }
            </div>
          </form>
        </div>
        )}
        <Footer/>

        <Modal variant="confirm" title="Submit quote" visible={ submitModalVisible } pending={ pending } cancelLabel={ responseSubmitted ? 'Close' : 'Cancel'} confirmAction={ !responseSubmitted && submitResponse } confirmLabel="Submit" cancelAction={ () => setSubmitModalVisible(false) }
          metaContent={
            !responseSubmitted && <span>By submitting this quote you accept our <a href="https://gosselingroup.eu/en/general-conditions-moving" target="_blank" rel="noopener noreferrer">terms &amp; conditions</a> and <a href="https://gosselin-moving.com/uploads/media/default/0001/02/Gosselin_PartnerSLA.pdf" target="_blank" rel="noopener noreferrer">SLA</a>.</span>
          }
        >
          { responseSubmitted ? (
            <>
              <p>Quote succesfully submitted, you can now close this window.</p>
              <p>
                Contact <a href={ `mailto:${ manager?.displayName }<${ manager?.email }` }>
                  { manager?.displayName } ({ manager?.email })
                </a> to make adjustments.
              </p>
            </>
          ) : (
            <>
              <p>You're going to submit this quote to Gosselin.</p>
              <p>
                Once sent out, reach out to <a href={ `mailto:${ manager?.displayName }<${ manager?.email }` }>
                  { manager?.displayName } ({ manager?.email })
                </a> to make adjustments.
              </p>
            </>
          ) }
        </Modal>
      </>
    }

    { loading &&
      <div className="loader loader--full">
        <LoadingIcon />
      </div>
    }

    <Modal visible={infoModalOpen} title={infoModalTitle} cancelAction={ () => setInfoModalOpen(false) } size="large">
      { infoModalContent }
    </Modal>
  </>
  )
}
