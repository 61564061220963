import React, { useContext, useEffect, useState } from "react";
import GlobalData from "../../context/globaldata";
import AsyncSelect from "react-select/async";
import ReactTooltip from "react-tooltip";
import { OptionTypeBase } from "react-select";
import Chip from "../Chip/Chip";

type Props = {
  value: Dict;
  name: string;
  required?: boolean;
  label?: string;
  aside?: any;
  type?: string;
  region?: string;
  disabled?: boolean;
  optional?: boolean;
  placeholder?: string;
  onSelectItem: any;
  prepend?: any;
  append?: any;
  showError?: boolean;
  parentContext?: Dict;
  customInput?: boolean;
  tip?: string;
};

export default function MapsAutocomplete(props: Props) {
  const context = useContext(GlobalData);
  const propsParentContext = props.parentContext;
  const onSelectItem = props.onSelectItem;

  const [ defaultValue, setDefaultValue ] = useState<Dict>(null);
  const [ isDirty, setIsDirty ] = useState<boolean>(false);
  const [ parentContext, setParentContext ] = useState<Dict>(null);

  const onChangeHandler = ((selectedItem: any) => {
    setIsDirty(true);
    setDefaultValue(null);
    props.onSelectItem(selectedItem?.locationItem || null);
  });

  useEffect(() => {
    if (props.showError) {
      setIsDirty(true);
    }
  }, [ props.showError, defaultValue, props.required ]);

  useEffect(() => {
    if (props.value && props.value.name) {
      setDefaultValue({
        label: (props.value) ? props.value.name : null,
        value: props.value.city || props.value.country,
        continent: props.value.continent,
        locationItem: props.value
      });
    } else {
      setDefaultValue(null);
    }
  }, [ props.value ]);

  useEffect(() => {
    if (propsParentContext) {

      if (parentContext && (propsParentContext?.name !== parentContext?.name)) {
        onSelectItem(null);
      }

      setParentContext(propsParentContext);
    }
  }, [ propsParentContext, parentContext, onSelectItem ]);

  const mapBoxApiKey = "pk.eyJ1Ijoic3RyYWt6YXQiLCJhIjoiY2s5OGd5N203MDI5eTNmbGx3ZGp1NDY4eSJ9.Uod99sjKQ_nGnRxbwTjVnQ";

  const requestCountries = (inputValue: string, callback: Function) => {
    const originalInputValue = inputValue;
    inputValue = encodeURIComponent(inputValue);

    if (props.type === "address" && props.parentContext) {
      inputValue = `${ props.parentContext.city } ${ inputValue }`;
    }

    let requestURL = `https://api.mapbox.com/geocoding/v5/mapbox.places/${ inputValue }.json?access_token=${mapBoxApiKey}&cachebuster=${Math.random()}&autocomplete=true&types=${props.type || "country"}&limit=${ props.type !== "country" ? 10 : 5}&language=en%2Cnl%2Cfr%2Ces%2Cde`;

    if (props.type === "address" && props.parentContext) {
      const countryCode = context.countryCodesInverted[props.parentContext.country]?.countryCode;

      console.log(props.parentContext.country);

      if (countryCode) {
        requestURL += `&country=${ context.countryCodesInverted[props.parentContext.country]?.countryCode }`;
      }
    }

    fetch(requestURL)
    .then(response => response.json())
    .then(result => {
      const foundCountries:Array<Object> = [];

      result.features && result.features.forEach((feature: StrictDict) => {
        let data:StrictDict = {};

        if (feature.place_type[0] === "country") {
          const localCountry = feature.properties.short_code ? context.countryCodes[feature.properties.short_code.toUpperCase()] : null;

          if (localCountry && feature.place_name !== localCountry.name) {
            feature.place_name = localCountry.name;
          }

          feature.text = feature.place_name;

          const locationItem:MapLocation = {
            id: "",
            name: feature.place_name,
            lat: feature.center[1],
            long: feature.center[0],
            country: feature.text,
            continent: context.countriesContinents[feature.text] || '',
          };

          data = {
            label: feature.place_name,
            value: feature.place_name,
            continent: context.countriesContinents[feature.text] || '',
            locationItem
          };
        } else if (Array.from(feature.place_type).includes("place")) {
          let foundCountry = feature.context.find((context: StrictDict) => {
            return (context.id.indexOf("country") > -1);
          });

          if (!foundCountry) {
            foundCountry = feature.context[0];
          }

          const localCountry = foundCountry?.short_code ? context.countryCodes[foundCountry.short_code.toUpperCase()] : null;

          if (localCountry && foundCountry) {
            foundCountry.local_text = localCountry.name;
          }

          if (localCountry && foundCountry && foundCountry.text !== localCountry.name) {
            foundCountry.local_text = localCountry.name;
          }

          const locationItem:MapLocation = {
            id: "",
            name: `${ feature.place_name }${ foundCountry?.local_text !== foundCountry?.text ? ` (${ foundCountry.local_text})` : '' }`,
            lat: feature.center[1],
            long: feature.center[0],
            city: feature.text,
            region: feature.context[1] ? feature.context[0].text : null,
            country: foundCountry?.local_text || null,
            continent: context.countriesContinents[foundCountry?.text] || null,
          };

          data = {
            label: `${ feature.place_name }${ foundCountry?.local_text !== foundCountry?.text ? ` (${ foundCountry.local_text})` : '' }`,
            value: `${ feature.place_name }${ foundCountry?.local_text !== foundCountry?.text ? ` (${ foundCountry.local_text})` : '' }`,
            continent: context.countriesContinents[foundCountry?.text] || null,
            locationItem
          };
        } else if (Array.from(feature.place_type).includes("address")) {
          let foundCountry = feature.context.filter((context: StrictDict) => {
            return (context.id.indexOf("country") > -1);
          })[0];

          if (!foundCountry) {
            foundCountry = feature.context[0];
          }

          const localCountry = foundCountry?.short_code ? context.countryCodes[foundCountry.short_code.toUpperCase()] : null;

          if (localCountry && foundCountry) {
            foundCountry.local_text = localCountry.name;
          }

          if (localCountry && foundCountry && foundCountry.text !== localCountry.name) {
            foundCountry.local_text = localCountry.name;
          }

          if (!feature.context.some((contextItem: StrictDict) => contextItem.text === props.parentContext?.city && (contextItem.id.indexOf('place') > -1 || contextItem.id.indexOf('region') > -1))) {
            return;
          }

          const locationItem:MapLocation = {
            id: "",
            name: feature.place_name,
            lat: feature.center[1],
            long: feature.center[0],
            city: feature.text,
            region: feature.context[1] ? feature.context[0].text : null,
            country: foundCountry.text,
            continent: context.countriesContinents[foundCountry.text] || null
          };

          data = {
            label: feature.place_name,
            value: feature.text,
            continent: context.countriesContinents[foundCountry.text] || null,
            locationItem
          };
        }

        if (data.label) {
          foundCountries.push(data);
        } else {
          console.error(feature, data);
        }
      });

      if (props.customInput) {
        foundCountries.push({
          label: '➡️ ' + originalInputValue,
          value: originalInputValue,
          locationItem: {
            name: originalInputValue,
          },
        });
      }

      callback(foundCountries);
    });
  };

  const formatOptionLabel = (option: OptionTypeBase) => (
    <>
      <span className="suggestions-item__text">{ option.label }</span>
      <span className="suggestions-item__context">{ option.continent }</span>
    </>
  );

  return (
    <div className={`input ${props.type ? "input--" + props.type : "input--text"} select ${isDirty ? "is-dirty" : ""} ${props.required ? "is-required" : ""}`}>
      { props.label && (
        <div className="input__top">
          <label className="input__label" htmlFor={props.name}>{props.label}
            { props.tip && <ReactTooltip place="top" effect="solid" delayShow={500} /> }
          </label>
          {props.aside && (
            <div className="input__aside">{props.aside}</div>
          )}
          {(props.aside || props.optional) && (
            <div className="input__aside">
              {props.aside && props.aside}
              {props.optional && <Chip status={4} label="Optional" />}
            </div>
          )}
        </div>
      )}

      <div className="select">
        <div className="select__container">
          { props.prepend &&
            <div className="input__prepend">{ props.prepend }</div>
          }

          <AsyncSelect
            className="react-select"
            classNamePrefix="react-select"
            cacheOptions
            value={ defaultValue }
            isSearchable
            loadOptions={ requestCountries }
            onChange={ onChangeHandler }
            placeholder={ props.placeholder || "Search..." }
            formatOptionLabel={ formatOptionLabel }
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            isDisabled={props.disabled}
            isClearable
            data-tip={ props.tip }
          />

          { props.append &&
            <div className="input__append">{ props.append }</div>
          }
        </div>
      </div>
    </div>
  );
}
