import React, { useContext, useEffect, useState, ReactEventHandler, ChangeEvent, FormEvent } from "react";
import NavigationPrompt from "react-router-navigation-prompt";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// @ts-ignore
import { store } from "react-notifications-component";

import GlobalData from "../../context/globaldata";

import { Header } from "../../components/Layout";
import { Input, Checkbox, Select, AccentuateBox, Textarea } from "../../components/Form";
import Button from "../../components/Button/Button";
import MapsAutocomplete from "../../components/Maps/Autocomplete";
import Modal from "../../components/Modal/Modal";

import { ReactComponent as IconCrossRed } from "../../assets/images/icons/cross-small-red.svg";
import { ReactComponent as IconPlusSmall } from "../../assets/images/icons/plus-small.svg";
import { nanoid } from "nanoid";

import subRegionOptionsData from "../../assets/data/sub-region-options.json";
import countryCodes from "../../assets/data/country-codes-iso3316-2.json";

const subRegionOptions: StrictDict = subRegionOptionsData;
type CountryCode = keyof typeof countryCodes;

export default function SupplierSettings(props: any) {
  const context = useContext(GlobalData);
  const uid = props.supplierUid;
  const supplier = props.supplier;
  const setSupplier = (data: Supplier | Function) => {
    setNotSaved(true);
    props.setSupplier(data)
  };

  const [ newLane, setNewLane ] = useState<StrictDict>({
    origin: null,
    destination: null,
  });

  const [ supplierCopy, setSupplierCopy ] = useState<Supplier>();

  const [ notSaved, setNotSaved ] = useState<boolean>(false);

  const setSettingsVisible = props.setSettingsVisible;

  useEffect(() => {
    setSettingsVisible(true);

    return () => {
      setSettingsVisible(false);
    }
  }, [ setSettingsVisible ]);

  useEffect(() => {
    // Save a version of the current edited item for
    if (supplier.relationship !== undefined && !supplierCopy) {
      setSupplierCopy(JSON.parse(JSON.stringify(supplier)));
    }
  }, [ supplier, supplierCopy, setSupplierCopy ]);

  const saveSettings = (event: Event) => {
    event?.preventDefault();

    const saveObject = {
      alias: supplier.alias || "",
      subtitle: supplier.subtitle || null,
      agreementSigned: supplier.agreementSigned || false,
      relationship: supplier.relationship || null,
      status: supplier.status || {},
      locationSubRegions: supplier.locationSubRegions || [],
      coverage: supplier.coverage || [],
      coverageLanes: supplier.coverageLanes || [],
      groups: supplier.groups || {},
      type: supplier.type || {},
      comment: supplier.comment || "",
      customName: supplier.customName || null,
      customPhone: supplier.customPhone || null,
      customEmail: supplier.customEmail || null,
      escalationName: supplier.escalationName || null,
      escalationEmail: supplier.escalationEmail || null,
      escalationPhone: supplier.escalationPhone || null,
      contacts: supplier.contacts || [],
      ignoreSupplierLocation: supplier.ignoreSupplierLocation || null,
    };

    console.log(saveObject);

    context.firestore.collection("suppliers").doc(uid).update(saveObject)
    .then(() => {
      store.addNotification({
        message: "Settings successfully updated",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      setNotSaved(false);
    })
    .catch((error: string) => {
      store.addNotification({
        message: `Error updating document: ${error}`,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });
    })
    .finally(() => {
    });
  };

  const updateSupplier = (event:  ChangeEvent<HTMLInputElement>) => {
    setSupplier({
      ...supplier,
      [event.target.name]: event.target.value
    })
  };

  const updateType = (event:  ChangeEvent<HTMLInputElement>) => {
    setSupplier({
      ...supplier,
      type: {
        ...supplier.type,
        [event.target.value]: supplier.type ? !supplier.type[event.target.value] : false
      }
    })
  };

  const setRelationship = (newValue: any) => {
    setSupplier({
      ...supplier,
      relationship: newValue.value
    })
  };

  const setStatusInactive = (newValue: boolean) => {
    setSupplier({
      ...supplier,
      status: {
        ...supplier.status,
        inactive: newValue
      }
    })
  };

  const setActiveGroup = (event:  ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    setSupplier({
      ...supplier,
      groups: {
        ...supplier.groups,
        [event.target.value]: isChecked
      }
    })
  };

  const onCoverageAdd = (locationItem:MapLocation) => {
    if (!locationItem) {
      return;
    }

    if (supplier.coverage?.some((selectedCountry: StrictDict) => selectedCountry.item.country === locationItem.country)) {
      return;
    }

    const supplierCoverage = supplier.coverage ? [ ...supplier.coverage ] : [];

    supplierCoverage.push({
      id: "",
      item: locationItem,
      selected: false
    });

    setSupplier({
      ...supplier,
      coverage: supplierCoverage
    });
  };

  const removeCountry = (index: number) => {
    const coverageCopy = supplier.coverage.slice();

    coverageCopy.splice(index, 1);

    setSupplier({
      ...supplier,
      coverage: coverageCopy
    });
  };

  const updateCustomEmail = (event: any) => {
    setSupplier({
      ...supplier,
      customEmail: event.target.value
    });
  };

  const updateCustomName = (event: any) => {
    setSupplier({
      ...supplier,
      customName: event.target.value
    });
  };

  const updateEscalationName = (event: any) => {
    setSupplier({
      ...supplier,
      escalationName: event.target.value
    });
  };

  const updateCustomPhone = (event: any) => {
    setSupplier({
      ...supplier,
      customPhone: event.target.value
    });
  };

  const updateEscalationEmail = (event: any) => {
    setSupplier({
      ...supplier,
      escalationEmail: event.target.value
    });
  };

  const updateEscalationPhone = (event: any) => {
    setSupplier({
      ...supplier,
      escalationPhone: event.target.value
    });
  };

  const updateContact = (event:  ChangeEvent<HTMLInputElement>, contactIndex: number, field: "email" | "phone" | "name") => {
    const value = event.target.value;

    setSupplier((prevState: Supplier) => {
      const newState: Supplier = Object.assign({}, prevState);
      newState.contacts![contactIndex][field] = value;

      return newState;
    });
  };

  const addContact = () => {
    setSupplier((prevState: Supplier) => {
      const newState: Supplier = Object.assign({}, prevState);

      if (!newState.contacts) {
        newState.contacts = [];
      }

      newState.contacts.push({
        name: "",
        email: "",
        phone: ""
      });

      return newState;
    });
  };

  const removeContact = (contactIndex: number) => {
    setSupplier((prevState: Supplier) => {
      const newState: Supplier = Object.assign({}, prevState);

      newState.contacts?.splice(contactIndex, 1);

      return newState;
    });
  };

  const updateNewLane = (locationItem: MapLocation, direction: string) => {
    setNewLane({
      ...newLane,
      [direction]: locationItem
    });
  };

  const addNewLane = (event: FormEvent) => {
    event.preventDefault();

    if (!newLane.origin || !newLane.destination) {
      return;
    }

    const supplierCoverageLanes = supplier.coverageLanes ? [ ...supplier.coverageLanes ] : [];

    supplierCoverageLanes.push({
      id: nanoid(),
      lane: newLane,
    });

    setSupplier({
      ...supplier,
      coverageLanes: supplierCoverageLanes
    });

    setNewLane({
      origin: null,
      destination: null,
    });
  };

  const removeLane = (index: number) => {
    const coverageLanesCopy = supplier.coverageLanes.slice();

    coverageLanesCopy.splice(index, 1);

    setSupplier({
      ...supplier,
      coverageLanes: coverageLanesCopy
    });
  };

  if (!supplier.car) {
    return null;
  }

  return (
    <>
      <NavigationPrompt
        when={ notSaved }
        >
        { ({ onConfirm, onCancel }) =>
          <Modal
            visible={ true }
            confirmAction={ onCancel }
            cancelAction={ () => {
              // Place the confirm on the cancel button
              if (supplierCopy) {
                setSupplier(supplierCopy);
              }
              onConfirm();
            } }
            title="You have unsaved changes"
            confirmLabel={ "Cancel" }
            cancelLabel={ "Confirm" }
            noCloseButton={ true }>
            <p>Are you sure you want to leave this page and discard your changes?</p>
          </Modal>
        }
      </NavigationPrompt>
      <Header
        title={`Supplier settings`}
        subTitle={ supplier.alias ? supplier.alias : supplier.car?.Client }
        backLink={`/suppliers/${supplier.uid}`}
        rightContent={context.currentUser.custom?.admin && <Button variant="primary" onClick={ saveSettings }>Save settings</Button> }
        variant="secondary"
      />
      <div className="detail supplier-details">
        <div className="container">
          <Tabs className="tabbed-pages">
            <TabList>
              <Tab>General</Tab>
              <Tab>Contact details</Tab>
              <Tab>Country coverage</Tab>
            </TabList>

            <div className="tabbed-pages__page">

              {/* General */}
              <TabPanel>
                <div className="detail__top">
                  <h3>General</h3>
                </div>

                <div className="form-group">
                  <Select
                    label="Relationship status"
                    value={ props.getRelationship() }
                    name="demoSelect"
                    onChange={ (value: ReactEventHandler) => setRelationship(value) }
                    options={ props.relationshipOptions }
                  />
                </div>

                <div className="form-group">
                  <Input value={ supplier.alias || "" } name="alias" onChange={ updateSupplier } label="Alias name" placeholder="e.g. Gosselin" />
                </div>

                <div className="form-group">
                  <Input value={ supplier.subtitle || "" } name="subtitle" onChange={ updateSupplier } label="Supplier subtitle" placeholder="e.g. 15 m3 / 750kgs vans" />
                </div>

                <div className="form-group">
                  <label className="form-group__label">Groups</label>
                  { context.groups && context.groups.map((option: any) =>
                    <Checkbox key={ option.id } value={ option.id } name="groups" onChange={ setActiveGroup } label={ option.name } checked={ (supplier.groups && supplier.groups[option?.id] === true) || false } />
                  )}
                </div>

                <div className="form-group">
                  <label className="form-group__label">Type</label>
                  <Checkbox
                    value="private"
                    label="Private"
                    name="type"
                    checked={ supplier.type && supplier.type["private"] }
                    onChange={ updateType }
                  />
                  <Checkbox
                    value="rmc"
                    label="RMC"
                    name="type"
                    checked={ supplier.type && supplier.type["rmc"] }
                    onChange={ updateType }
                  />
                  <Checkbox
                    value="diplo"
                    label="Diplo"
                    name="type"
                    checked={ supplier.type && supplier.type["diplo"] }
                    onChange={ updateType }
                  />
                </div>

                <div className="form-group">
                  <label className="form-group__label">Gosselin SLA &amp; Compliant status</label>
                  <Checkbox
                    value="true"
                    label="Signed"
                    name="agreementSigned"
                    checked={ supplier.agreementSigned || false }
                    onChange={ () => setSupplier({...supplier, agreementSigned: !supplier.agreementSigned }) }
                  />
                </div>

                <div className="form-group">
                  <Textarea value={ supplier.comment } label="Comment" name="comment" placeholder="e.g. certain specifics regarding this supplier" onChange={ updateSupplier } rows={5} />
                </div>

                <AccentuateBox heading={`Supplier status: ${!supplier.status?.inactive ? "Active" : "Inactive"}`} content="When a supplier is deactivated it is not shown in the supplier overview.">
                  <Checkbox value={ supplier.status?.inactive } checked={ !supplier.status?.inactive || false } name="inactive" onChange={ () => setStatusInactive(!supplier.status?.inactive) } switch />
                </AccentuateBox>
              </TabPanel>

              {/* Contact details */}
              <TabPanel>
                <div className="detail__top">
                  <h3>Contact details</h3>
                </div>

                <div className="form-group">
                  <h4 className="form-group__title">Primary contact</h4>
                  <Input label="Full name" value={ supplier.customName ? supplier.customName : "" } placeholder={supplier.car.ContactInfo.INFCONT} name="custom-name" onChange={ updateCustomName } type="text" />
                </div>

                <div className="form-group">
                  <Input label="E-mail address" value={ supplier.customEmail ? supplier.customEmail : "" } placeholder={supplier.car?.ContactInfo.INFEM[0]} name="custom-email" onChange={ updateCustomEmail } type="email" />
                </div>

                <div className="form-group">
                  <Input label="Phone" value={ supplier.customPhone ? supplier.customPhone : "" } placeholder={supplier.car?.ContactInfo.INFTEL } name="custom-phone" onChange={ updateCustomPhone } type="tel" />
                </div>

                <div className="form-group">
                  <h4 className="form-group__title">Escalation contact</h4>
                  <Input label="Full name" value={ supplier.escalationName ? supplier.escalationName : "" } name="escalation-name" onChange={ updateEscalationName } type="text" />
                </div>

                <div className="form-group">
                  <Input label="E-mail address" value={ supplier.escalationEmail ? supplier.escalationEmail : "" } name="escalation-email" onChange={ updateEscalationEmail } type="email" />
                </div>

                <div className="form-group">
                  <Input label="Phone number" value={ supplier.escalationPhone ? supplier.escalationPhone : "" } name="escalation-phone" onChange={ updateEscalationPhone } type="tel" />
                </div>

                { supplier.contacts
                  ? supplier.contacts.map((contact: SupplierContact, contactIndex: number) =>
                    <React.Fragment key={ contactIndex }>
                      <div className="form-group">
                        <Button variant="ghost" tip="Remove contact" size="small" circle onClick={ () => removeContact(contactIndex) }>
                          <IconCrossRed />
                        </Button>
                        <h4 className="form-group__title">Additional contact ({ contactIndex + 1 })</h4>
                        <Input label="Full name" value={ contact.name ? contact.name : "" } name="contact-name" onChange={ (event:  ChangeEvent<HTMLInputElement>) => updateContact(event, contactIndex, "name") } type="text" />
                      </div>
                      <div className="form-group">
                        <Input label="E-mail address" value={ contact.email ? contact.email : "" } name="contact-email" onChange={ (event:  ChangeEvent<HTMLInputElement>) => updateContact(event, contactIndex, "email") } type="email" />
                      </div>
                      <div className="form-group">
                        <Input label="Phone number" value={ contact.phone ? contact.phone : "" } name="contact-phone" onChange={ (event:  ChangeEvent<HTMLInputElement>) => updateContact(event, contactIndex, "phone") } type="tel" />
                      </div>
                    </React.Fragment> )
                  : <div className="form-group">
                      <h4 className="form-group__title">Additional contact</h4>
                    </div>
                }
                <Button variant="ghost" onClick={ addContact }><><IconPlusSmall className="button__icon" />Add additional contact</></Button>
              </TabPanel>

              {/* Country coverage */}
              <TabPanel>
                <div className="detail__top">
                  <h3>Country coverage</h3>
                </div>

                { context.currentUser.custom?.admin &&
                  <div className="form-group">
                    <Checkbox
                      value="on"
                      switch
                      label={ `Origin or destination should match supplier's location: ${ context.countryCodes[supplier.car?.CompanyAddress.GENLAND[0]].name } (${ supplier.car?.CompanyAddress.GENLAND[0] })` }
                      name="ignoreSupplierLocation"
                      checked={ supplier.ignoreSupplierLocation !== undefined ? !supplier.ignoreSupplierLocation : true }
                      weight="bold"
                      onChange={ () => setSupplier({...supplier, ignoreSupplierLocation: supplier.ignoreSupplierLocation !== undefined ? !supplier.ignoreSupplierLocation : false }) }
                    />
                    <p>When enabled this supplier will be displayed as a suggestion for a rate request when "{ context.countryCodes[supplier.car?.CompanyAddress.GENLAND[0]].name }" is an origin or destination country.</p>
                  </div>
                }

                { subRegionOptions[(supplier.car?.CompanyAddress.GENLAND[0] as CountryCode)] && (
                  <Select
                    label="Location sub-region"
                    value={ supplier.locationSubRegions }
                    name="locationSubRegion"
                    multi={ true }
                    placeholder="Select country sub regions where supplier operates"
                    onChange={ (option: StrictDict) => setSupplier({
                      ...supplier,
                      locationSubRegions: option
                    }) }
                    options={ subRegionOptions[(supplier.car?.CompanyAddress.GENLAND[0])] }
                  />
                ) }

                <hr />

                <h4>Coverage</h4>
                <div className="card__row-spacer"></div>

                <div style={{ zIndex: 11, position: 'relative'}}>
                <MapsAutocomplete placeholder="Add new country" value={ null } name="search" onSelectItem={ onCoverageAdd } />
                </div>
                <ul className="country-list">
                  { supplier.coverage?.map((coverageItem: any, index: number) =>
                    <li className="country-list__item" key={ index }>
                      <span className="country-list__item-title">{ coverageItem.item.country }</span>
                      <span className="country-list__item-continent">{ coverageItem.item.continent }</span>
                      <Button variant="ghost" tip="Delete" size="small" circle onClick={ () => removeCountry(index) }>
                        <IconCrossRed />
                      </Button>
                    </li>
                  )}
                </ul>

                <hr />

                <h4>Add specific lanes</h4>
                <div className="card__row-spacer"></div>
                <form onSubmit={ addNewLane }>
                  <table className="column-table">
                    <thead>
                      <tr>
                        <th><label>Origin</label></th>
                        <th colSpan={2}><label>Destination</label></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <MapsAutocomplete placeholder="Add origin" required value={ newLane.origin } name="search" onSelectItem={ (location: MapLocation) => updateNewLane(location, 'origin') } />
                        </td>
                        <td>
                          <MapsAutocomplete placeholder="Add destination" required value={ newLane.destination } name="search" onSelectItem={ (location: MapLocation) => updateNewLane(location, 'destination') } />
                        </td>
                        <td width={0}>
                          <Button
                            variant="primary"
                            type="submit"
                            >
                            Add
                          </Button>
                        </td>
                      </tr>
                      { supplier.coverageLanes?.map((coverageItem: any, index: number) =>
                        <tr className="column-table__list-item" key={ index }>
                          <td>
                            <span className="country-list__item-title">{ coverageItem.lane.origin.country }</span>
                            <span className="country-list__item-continent">{ coverageItem.lane.origin.continent }</span>
                          </td>
                          <td>
                            <span className="country-list__item-title">{ coverageItem.lane.destination.country }</span>
                            <span className="country-list__item-continent">{ coverageItem.lane.destination.continent }</span>
                          </td>
                          <td>
                            <Button variant="ghost" tip="Delete" size="small" circle onClick={ () => removeLane(index) }>
                              <IconCrossRed />
                            </Button>
                          </td>
                        </tr>
                      ) }
                    </tbody>
                  </table>
                </form>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </>
  )
}
