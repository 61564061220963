import React, { ReactElement } from "react";
import ReactTooltip from "react-tooltip";

type Props = {
  variant?: "primary" | "secondary" | "tertiary" | "ghost" | "text" | "danger" | "ghost-danger" | "ghost-primary" | "full-width" | "icon";
  children?: string | ReactElement;
  onClick?: any;
  disabled?: boolean;
  href?: string;
  download?: string|boolean;
  target?: string;
  type?: "button" | "submit" | "reset" | "link" | undefined;
  circle?: boolean;
  tip?: string;
  size?: "small" | "large";
  customClassName?: string;
};

export default function Button(props: Props) {

  const customOnClick = () => {
    props.onClick();
    ReactTooltip.hide();
  };

  return (
    <>
      { props.tip && <ReactTooltip place="top" effect="solid" delayShow={500} /> }
      { props.type !== "link"
        ? (
          <button
            className={`button button--${props.variant ? props.variant : "primary"} ${props.size ? `button--${props.size}` : ""} ${props.circle ? "button--circle" : ""} ${props.customClassName ? props.customClassName : ""}`}
            onClick={ !props.disabled ? (props.onClick ? customOnClick : undefined) : undefined }
            disabled={ props.disabled }
            type={ props.type ? props.type : "button" }
            data-tip={ props.tip }
            >
              { props.children }
          </button>
        )
        : (
          <a
            className={`button button--${props.variant ? props.variant : "primary"} ${props.size ? `button--${props.size}` : ""} ${props.circle ? "button--circle" : ""} ${props.customClassName ? props.customClassName : ""}`}
            onClick={ !props.disabled ? (props.onClick ? customOnClick : undefined) : undefined }
            download={ props.download }
            target={ props.target }
            href={ props.href }
            data-tip={ props.tip }
            >
              { props.children }
          </a>
        )
      }

    </>
  );
}
