import React, { useContext, useEffect, useState } from "react";
import GlobalData from "../../context/globaldata";
import Chip from "../../components/Chip/Chip";
import { ReactComponent as IconValid } from "../../assets/images/icons/list-valid.svg";
import { ReactComponent as IconInvalid } from "../../assets/images/icons/list-invalid.svg";

export default function SupplierDetails(props: any) {
  const context = useContext(GlobalData);
  const [ coverageAreas, setCoverageAreas ] = useState<StrictDict>({});

  useEffect(() => {
    const newCoverageAreas:StrictDict = {};

    props.supplier.coverage?.forEach((coverage: CoverageItemSelection) => {
      const continent = coverage.item.continent || "Other";

      if (!newCoverageAreas[continent]) {
        newCoverageAreas[continent] = [];
      }

      newCoverageAreas[continent].push(coverage.item);
    });

    let orderedCoverageAreas:StrictDict = {};

    Object.keys(newCoverageAreas).sort((a:string, b:string) => {
      if (a === "Other") {
        return 1;
      }

      // Alphabetize the continents list
      return (a < b) ? -1 : 1;
    }).forEach(function(key:string) {
      // Alphabetize the countries list
      newCoverageAreas[key].sort((a:StrictDict, b:StrictDict) => {
        return (a.country < b.country) ? -1 : 1;
      });

      orderedCoverageAreas[key] = newCoverageAreas[key];
    });

    setCoverageAreas(orderedCoverageAreas);

  }, [ props.supplier.coverage ]);

  return (
    <div className="detail supplier-details">
      <div className="container">
        <div className="detail__top">
          <h3>Supplier details</h3>
        </div>
        <div className="card card--primary">
          <div className="group">
            <div className="xxs12 sm6">
              <div className="card__column">
                <h5 className="card__column-subheading">Full Supplier name</h5>
                <p>{ props.supplier.car.CompanyAddress.GENNAME }</p>
              </div>
              <div className="card__column">
                <h5 className="card__column-subheading">Subtitle</h5>
                <p>{ props.supplier.subtitle ? props.supplier.subtitle : '-' }</p>
              </div>
              <div className="card__column">
                <h5 className="card__column-subheading">Primary contact</h5>
                <ul className="list list--icons">
                  <li className="list__item">
                    <div className="list__item-content">
                      { props.supplier.customName ? props.supplier.customName : props.supplier.car.ContactInfo.INFCONT }
                    </div>
                  </li>
                  <li className="list__item">
                    <div className="list__item-content">
                      <a href={`mailto:${ props.supplier.customEmail || props.supplier.car?.ContactInfo.INFEM }`}>{ props.supplier.customEmail || props.supplier.car?.ContactInfo.INFEM }</a>
                    </div>
                  </li>
                  <li className="list__item">
                    <div className="list__item-content">
                      { props.supplier.customPhone ? props.supplier.customPhone : props.supplier.car.ContactInfo.INFTEL }
                    </div>
                  </li>
                </ul>
              </div>
              <div className="card__column">
                <h5 className="card__column-subheading">Escalation contact</h5>
                <ul className="list list--icons">
                  <li className="list__item">
                    <div className="list__item-content">
                      { props.supplier.escalationName }
                    </div>
                  </li>
                  <li className="list__item">
                    <div className="list__item-content">
                      <a href={`mailto:${props.supplier.escalationEmail}`}>{ props.supplier.escalationEmail }</a>
                    </div>
                  </li>
                  <li className="list__item">
                    <div className="list__item-content">
                      { props.supplier.escalationPhone }
                    </div>
                  </li>
                </ul>
              </div>

              { props.supplier.contacts
                && props.supplier.contacts.map((contact: SupplierContact, contactIndex: number) =>
                <div className="card__column" key={ contactIndex }>
                  <h5 className="card__column-subheading">Additional contact ({ contactIndex + 1 })</h5>
                  <ul className="list list--icons">
                    <li className="list__item">
                      <div className="list__item-content">
                        <a href={`mailto:${contact.email}`}>{ contact.email }</a>
                      </div>
                    </li>
                    <li className="list__item">
                      <div className="list__item-content">
                        { contact.phone }
                      </div>
                    </li>
                  </ul>
                </div>
                )
              }
            </div>
            <div className="xxs12 sm6">
              <div className="card__column">
                <h5 className="card__column-subheading">Gosselin SLA &amp; Compliant</h5>
                { props.supplier.agreementSigned ? (
                  <Chip status={1} label="Signed" />
                ) : (
                  <Chip status={2} label="Unsigned" />
                ) }
              </div>
              <div className="card__column">
                <h5 className="card__column-subheading">Groups</h5>
                <ul className="list list--icons">
                  { context.groups.map((group: Dict, index: number) =>
                    <li className="list__item" key={ index }>
                      <div className="list__item-icon">
                        { props.supplier.groups && props.supplier.groups[group?.id] === true
                          ? <IconValid />
                          : <IconInvalid />
                        }
                      </div>
                      { group?.name }
                    </li>
                  )}
                </ul>
              </div>
              { props.supplier.type &&
                <div className="card__column">
                  <h5 className="card__column-subheading">Type</h5>
                  <div className="supplier-details__types">
                    { Object.entries(props.supplier.type).map(([key, value]) => {
                        return value === true && <span key={key}>{key}</span>
                      })
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        { props.supplier.comment &&
          <div className="card card--primary">
            <h5 className="card__column-subheading">Comment</h5>
            <p>{ props.supplier.comment }</p>
          </div>
        }

        <div className="card card--primary">
          <h4>Area coverage</h4>
          <div className="supplier-details__card-group">
            { Object.keys(coverageAreas).map((continentName: string, continentIndex: number) =>
              <div className="supplier-details__list-group" key={ continentIndex }>
                <h5 className="card__column-subheading">{ continentName }</h5>

                <ul className="list list--reset group">
                  { coverageAreas[continentName].map((item: MapLocation, countryIndex: number) =>
                    <li className="xxs12 sm4" key={ countryIndex }>{ item.country }</li>
                  )}
                </ul>
              </div>
            )}

            { Object.keys(coverageAreas).length === 0 ? <h5 className="supplier-details__list-empty">No countries selected</h5> : '' }
          </div>
        </div>
      </div>
    </div>
  )
}
