import React, { ChangeEvent, SyntheticEvent, useState } from "react";
import { nanoid } from "nanoid";

import Button from "../../../components/Button/Button";
import { Input, Checkbox } from "../../../components/Form";

import { ReactComponent as IconPlusSmall } from "../../../assets/images/icons/plus-small.svg";

export default function EditAdditionals(props: any) {
  const [ expanded, setExpanded ] = useState(false);

  const createAdditional = () => {
    props.setRateRequest({
      ...props.rateRequest,
      customAdditionals: [
        ...(props.rateRequest.customAdditionals ? props.rateRequest.customAdditionals : []),
        {
          id: nanoid(8),
          item: {
            forOrigin: true,
            forDestination: true,
            label: ""
          },
          selectedOrigin: false,
          selectedDestination: false
        }
      ]
    })
  };

  const updateAdditionalLabel = (event: SyntheticEvent, id: string) => {
    const tempArray = [...props.rateRequest.customAdditionals];
    const additionalIndex = props.rateRequest.customAdditionals.findIndex(((item:RateRequestAdditionalSelection) => item.id === id));
    tempArray[additionalIndex].item.label = (event.target as HTMLInputElement).value;

    props.setRateRequest({
      ...props.rateRequest,
      customAdditionals: tempArray
    });
  };

  const removeCustomAdditional = (id: string) => {
    props.setRateRequest((currentState: any) => {
      const nextState = Object.assign({}, currentState);
      const additionalIndex = props.rateRequest.customAdditionals.findIndex(((item:RateRequestAdditionalSelection) => item.id === id));
      nextState.customAdditionals.splice(additionalIndex, 1);
      return nextState;
    });
  };

  const setAdditionalSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    const id = event.currentTarget.value;

    const selectionItem = props.rateRequest.additionals.findIndex(((item:RateRequestAdditionalSelection) => item.id === id));

    props.setRateRequest((currentState: any) => {
      const nextState = Object.assign({}, currentState);

      nextState.additionals[selectionItem][event.currentTarget.name] = isChecked;

      return nextState;
    });
  };

  const setCustomAdditionalSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.currentTarget.checked;
    const id = event.currentTarget.value;

    const selectionItem = props.rateRequest.customAdditionals.findIndex(((item:RateRequestAdditionalSelection) => item.id === id));

    props.setRateRequest((currentState: any) => {
      const nextState = Object.assign({}, currentState);

      nextState.customAdditionals[selectionItem][event.currentTarget.name] = isChecked;

      return nextState;
    });
  };

  return (
    <div className="card card--primary">
      <div className="card-headings">
        <h4 className="card__heading">Additionals</h4>
        <h5 className="card__subheading">Suppliers will provide an additional rate for your selected services</h5>
      </div>
      <div className={`additionals-list ${expanded ? "is-expanded" : ""}`}>
        <div className="additionals-list__header">
          <div className="additionals-list__column additionals-list__column-label"></div>
          <div className="additionals-list__column additionals-list__column--center">
            <strong>Origin</strong>
          </div>
          <div className="additionals-list__column additionals-list__column--center">
            <strong>Destination</strong>
          </div>
        </div>
        <ul className="list list--additionals">
          { props.rateRequest.additionals && props.rateRequest.additionals.map((additional: any, index: number) =>
            <li key={ additional.id } className="list__item">
              <div className="additionals-list__column additionals-list__column-label">
                { additional.item?.label }
              </div>
              <div className="additionals-list__column additionals-list__column--center">
                { additional.item?.forOrigin ? <Checkbox value={ additional.id } name="selectedOrigin" onChange={ setAdditionalSelection } checked={ additional.selectedOrigin } /> : null }
              </div>
              <div className="additionals-list__column additionals-list__column--center">
                { additional.item?.forDestination ? <Checkbox value={ additional.id } name="selectedDestination" onChange={ setAdditionalSelection } checked={ additional.selectedDestination } /> : null }
              </div>
            </li>
          )}
          { props.rateRequest.customAdditionals?.map((additional: any) =>
            <li key={ additional.id } className="list__item">
              <div className="additionals-list__column additionals-list__column-label">
                <Input value={additional.item.label} name="label" onChange={ (event: SyntheticEvent) => updateAdditionalLabel(event, additional.id) } />
                <Button onClick={ () => removeCustomAdditional(additional.id) } variant="ghost-danger">Remove</Button>
              </div>
              <div className="additionals-list__column additionals-list__column--center">
                { additional.item?.forOrigin ? <Checkbox value={ additional.id } name="selectedOrigin" onChange={ setCustomAdditionalSelection } checked={ additional.selectedOrigin } /> : null }
              </div>
              <div className="additionals-list__column additionals-list__column--center">
                { additional.item?.forDestination ? <Checkbox value={ additional.id } name="selectedDestination" onChange={ setCustomAdditionalSelection } checked={ additional.selectedDestination } /> : null }
              </div>
            </li>
          ) }
          <Button onClick={ createAdditional } variant="ghost">
            <>
              <IconPlusSmall className="button__icon" />
              <span>Add custom</span>
            </>
          </Button>
        </ul>
        <div className="additionals-list__show-all">
          <button onClick={() => setExpanded(!expanded)} type="button">
            <span>{ expanded ? "Show less" : "Show all" }</span>
          </button>
        </div>
      </div>
    </div>
  )
}
