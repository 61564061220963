import React, { useContext } from "react";

import GlobalData from '../../../context/globaldata';

import { Input, Select } from "../../../components/Form";
import Modal from "../../../components/Modal/Modal";

export default function RequestModal(props: any) {
  const context = useContext(GlobalData);

  const getSupplier = (listedSupplier: StrictDict) => {
    if (context.suppliers.length > 0) {
      return context.suppliers.find((supplier: any) => supplier.uid === (listedSupplier.uid || listedSupplier.supplierUid));
    }

    return null;
  };

  const getSupplierName = (listedSupplier: StrictDict) => {
    const foundSupplier = getSupplier(listedSupplier);

    return foundSupplier ? foundSupplier.alias || foundSupplier.car?.Client : null;
  };

  const getSupplierEmail = (listedSupplier: StrictDict) => {
    const foundSupplierContext = getSupplier(listedSupplier);

    let foundEmail = null;

    // Default to custom email if available, otherwise use the CAR email
    if (foundSupplierContext) {
      foundEmail = foundSupplierContext.customEmail || foundSupplierContext.car?.ContactInfo.INFEM[0].toLowerCase();
    }

    // Find overriden email if available
    let foundSupplier = props.rateRequest.selectedSuppliers.find((selectedSupplier: SelectedSupplier) => selectedSupplier.supplierUid === (listedSupplier.uid || listedSupplier.supplierUid));

    if (foundSupplier) {
      if (foundSupplier.overrideEmail !== null) {
        foundEmail = foundSupplier.overrideEmail;
      }
    }

    return foundEmail ? foundEmail : "";
  };

  const getEmailOptions = (listedSupplier: StrictDict) => {
    const foundSupplier = getSupplier(listedSupplier);

    if (!foundSupplier) {
      return null;
    }

    const primaryEmail = foundSupplier.customEmail || foundSupplier.car?.ContactInfo.INFEM[0].toLowerCase();

    let emailOptions: Array<OptionType> = [
      {
        "label": `${ primaryEmail }\n(Primary contact)`,
        "value": primaryEmail
      }
    ];

    if (foundSupplier.escalationEmail) {
      emailOptions.push({
        "label": `${ foundSupplier.escalationEmail }\n(Escalation contact)`,
        "value": foundSupplier.escalationEmail
      });
    }

    let additionalContacts: Array<OptionType> = [];

    if (foundSupplier.contacts) {
      additionalContacts = foundSupplier.contacts.map((contact: SupplierContact, contactIndex: number): OptionType => {
        return {
          "label": `${ contact.email }\n(Additional contact ${ contactIndex + 1 })`,
          "value": contact.email
        };
      });
    }

    emailOptions = emailOptions.concat(additionalContacts);

    emailOptions.push({
      "label": `Add custom e-mail`,
      "value": foundSupplier.overrideEmail || null
    });

    return emailOptions;
  };

  const getSelectedEmail = (listedSupplier: StrictDict) => {
    const options = getEmailOptions(listedSupplier);

    if (!options) {
      return;
    }

    if (listedSupplier.showCustomEmail) {
      return options[options.length - 1];
    }

    if (listedSupplier.overrideEmail && listedSupplier.overrideEmail.length > 0) {
      return options.find(option => option.value === listedSupplier.overrideEmail);
    } else {
      return options[0];
    }
  };

  return (
    <Modal variant="primary"
      size="large" title="Request rates" visible={ props.visible } pending={ props.pending } confirmAction={ props.confirmAction } confirmLabel="Request rates" cancelAction={ props.cancelAction }>
      <p>You're going to send out the request to the selected suppliers. Once sent out, suppliers will get notified and it’s not possible to make adjustments to this order anymore.</p>
      <ul className="list list--selected-suppliers">
        <li className="list__item">
          <div className="xxs12 md4">
            <strong>Supplier</strong>
          </div>
          <div className="xxs12 md8">
            <strong>E-mail address</strong>
          </div>
        </li>
        { !props.currentlySelectedSupplier && props.rateRequest && props.rateRequest.selectedSuppliers?.map((supplier: StrictDict, supplierIndex: number) =>
          <li key={ supplier.supplierUid } className="list__item list__item--align-top">
            <div className="xxs12 md4 item-label">
              { getSupplierName(supplier) }
            </div>
            <div className="xxs12 md8">
              <Select
                value={ getSelectedEmail(supplier) }
                name="supplierEmail"
                placeholder="Select e-mail address"
                onChange={ (option: StrictDict) => {
                  if (option.label === "Add custom e-mail") {
                    props.toggleCustomEmail(true, supplier.supplierUid);
                  } else {
                    props.toggleCustomEmail(false, supplier.supplierUid);
                  }

                  props.updateEmail(option.value, supplier.supplierUid);
                } }
                options={ getEmailOptions(supplier) }
                showError={ props.showError }
                searchable={ false }
              />
              { supplier.showCustomEmail &&
                <Input placeholder="Custom e-mail address"
                  type="email"
                  value={ supplier.overrideEmail }
                  onChange={(event: InputEvent) => {
                    props.updateEmail((event.target as HTMLInputElement).value, supplier.supplierUid)
                  }}
                  name={`email-${supplier.supplierUid}`}
                  required
                />
              }
            </div>
          </li>
        )}

        { props.currentlySelectedSupplier &&
          <li key={ props.currentlySelectedSupplier.uid } className="list__item">
            <div className="xxs12 md4">
              { getSupplierName(props.currentlySelectedSupplier) }
            </div>
            <div className="xxs12 md8">
              <Input placeholder="E-mail address"
                type="email"
                value={ getSupplierEmail(props.currentlySelectedSupplier) }
                onChange={(event: InputEvent) => {
                  props.updateEmail((event.target as HTMLInputElement).value, props.currentlySelectedSupplier.uid)
                }}
                name={`email-${props.currentlySelectedSupplier.uid}`}
                required
              />
            </div>
          </li>
        }
      </ul>
    </Modal>
  )
}
