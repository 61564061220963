import React, { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import GlobalData from "../../../context/globaldata";

import TransportDetails from "../../../components/TransportDetails/TransportDetails";

import { ReactComponent as IconCrossRed } from "../../../assets/images/icons/cross-red.svg";
import { ReactComponent as IconCheckmarkGreen } from "../../../assets/images/icons/checkmark-green.svg";
import { Textarea } from "../../../components/Form";
import Button from "../../../components/Button/Button";
import EllipsisButton from "../../../components/Button/EllipsisButton";
import Attachments from "../../../components/Attachments/Attachments";

// @ts-ignore
import { store } from "react-notifications-component";
import Modal from "../../../components/Modal/Modal";

export default function RateRequestDetails(props: any) {
  const history = useHistory();
  const context = useContext(GlobalData);
  // @ts-ignore
  const { uid } = useParams();
  const labels = context.rateRequestLabels;
  const [ addingNewNote, setAddingNewNote ] = useState(false);
  const [ newNote, setNewNote ] = useState('');
  const [ deleteModalVisible, setDeleteModalVisible ] = useState(false);
  const [ deleteNoteIndex, setDeleteNoteIndex] = useState<number>();

  const addNewNote = () => {
    props.setRateRequest((prevState: any) => {
      const nextState = Object.assign({}, prevState);

      if (!nextState.notes) {
        nextState.notes = [];
      }

      nextState.notes.unshift({
        text: newNote,
        postedDate: new Date(),
      });

      saveChanges(nextState, 'newNote');

      return nextState;
    });
  };

  const editNote = (index: number) => {
    props.setRateRequest((prevState: any) => {
      const nextState = Object.assign({}, prevState);
      const originalNote = nextState.notes[index];

      originalNote.isEditing = false;
      originalNote.editedDate = new Date();

      saveChanges(nextState, 'editNote');

      return nextState;
    });
  };

  const deleteNote = () => {
    if (typeof deleteNoteIndex !== 'undefined') {
      props.setRateRequest((prevState: any) => {
        const nextState = Object.assign({}, prevState);
        nextState.notes.splice(deleteNoteIndex, 1);

        return nextState;
      });

      saveChanges(props.rateRequest, 'deleteNote');
      setDeleteModalVisible(false);
    }
  };

  const updateNote = (index: number, text: string) => {
    props.setRateRequest((prevState: any) => {
      const nextState = Object.assign({}, prevState);
      const originalNote = nextState.notes[index];

      originalNote.text = text;

      return nextState;
    });
  };

  const toggleEditNote = (index: number, state: boolean) => {
    props.setRateRequest((prevState: any) => {
      const nextState = Object.assign({}, prevState);
      const originalNote = nextState.notes[index];

      originalNote.isEditing = state;

      return nextState;
    });
  };

  const saveChanges = (newData: RateRequest, event: 'newNote' | 'editNote' | 'deleteNote') => {
    props.setPending(true);

    const actionLabels = {
      newNote: {
        successMessage: 'New note added successfully',
        errorMessage: 'Error adding new note',
      },
      editNote: {
        successMessage: 'Note edited successfully',
        errorMessage: 'Error editing note',
      },
      deleteNote: {
        successMessage: 'Note deleted successfully',
        errorMessage: 'Error deleting note',
      },
    };

    if (event === 'newNote') {
      setNewNote('');
      setAddingNewNote(false);
    }

    context.firestore.collection("rate_requests").doc(uid).set(newData||props.rateRequest)
    .then(() => {
      store.addNotification({
        message: actionLabels[event].successMessage,
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      props.setPending(false);
    })
    .catch((error: string) => {
      store.addNotification({
        message: `${ actionLabels[event].errorMessage }: ${error}`,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      props.setPending(false);
    });
  };

  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);
  const [infoModalTitle, setInfoModalTitle] = useState<string>('');
  const [infoModalContent, setInfoModalContent] = useState<any>();

  const openInfoModal = (rateRequestMode: number) => {
    setInfoModalOpen(true);
    setInfoModalTitle(labels.rateRequestModes[rateRequestMode].labelLong);
    setInfoModalContent(labels.rateRequestModes[rateRequestMode].info);
  };

  return (
    <>
      <Modal variant="danger" title="Are you sure you want to delete this note?" visible={ deleteModalVisible } confirmAction={ deleteNote } confirmLabel="Delete note" cancelAction={ () => setDeleteModalVisible(false) }>
        This action can not be restored.
      </Modal>
      <div className="detail">
        <div className="container">

          <div className="card card--primary">
            <div className="group">
              <div className="xxs12">
                <h4>Origin &amp; Destination</h4>
              </div>
            </div>
            <div className="group">
              <div className="xxs12 md6">
                <div className="card__column">
                  <h6 className="card__column-subheading">Origin</h6>
                  <p>
                    {
                      props.rateRequest.originAddress
                        ? props.rateRequest.originAddress.city
                          ? props.rateRequest.originAddress.name
                          : `${ props.rateRequest.originAddress.name }, ${ props.rateRequest.origin.city }, ${ props.rateRequest.origin.country }`
                        : props.rateRequest.origin?.name
                    }
                  </p>
                </div>
                <div className="card__column">
                  <h6 className="card__column-subheading">Loading date ({ !!props.rateRequest.loadingDateApproximate ? 'approximate' : 'fixed' })</h6>
                  { !!props.rateRequest.loadingDateApproximate
                    ? (<p>{ props.rateRequest.loadingDateApproximateValue || "N/A" }</p>)
                    : (<p>{ props.rateRequest.loadingDate ? moment(props.rateRequest.loadingDate).format("DD-MM-YYYY") : "N/A" }</p>)
                  }
                </div>
              </div>
              <div className="xxs12 md6">
                <div className="card__column">
                  <h6 className="card__column-subheading">Destination</h6>
                  <p>
                    {
                      props.rateRequest.destinationAddress
                        ? props.rateRequest.destinationAddress.city
                          ? props.rateRequest.destinationAddress.name
                          : `${ props.rateRequest.destinationAddress.name }, ${ props.rateRequest.destination.city }, ${ props.rateRequest.destination.country }`
                        : props.rateRequest.destination?.name
                    }
                  </p>
                </div>
                <div className="card__column">
                  <h6 className="card__column-subheading">Delivery date ({ !!props.rateRequest.deliveryDateApproximate ? 'approximate' : 'fixed' })</h6>
                  { !!props.rateRequest.deliveryDateApproximate
                    ? (<p>{ props.rateRequest.deliveryDateApproximateValue || "N/A" }</p>)
                    : (<p>{ props.rateRequest.deliveryDate ? moment(props.rateRequest.deliveryDate).format("DD-MM-YYYY") : "N/A" }</p>)
                  }
                </div>
              </div>
            </div>

            { props.rateRequest.origin && props.rateRequest.destination &&
              <div className="group">
                <div className="xxs12">
                  <TransportDetails
                    originAddress={ props.rateRequest.originAddress }
                    origin={ props.rateRequest.origin }
                    destinationAddress={ props.rateRequest.destinationAddress }
                    destination={ props.rateRequest.destination }
                  />
                </div>
              </div>
            }
          </div>

          <div className="card card--primary">
            <div className="group">
              <div className="xxs12">
                <h4>Details</h4>
              </div>
            </div>
            <div className="group">
              <div className="xxs12 md6">
                <div className="card__column">
                  <h6 className="card__column-subheading">Reference order</h6>
                  <p>{ props.rateRequest.referenceOrder }</p>
                </div>
                <div className="card__column">
                  <h6 className="card__column-subheading">Target rate</h6>
                  <p> { props.rateRequest.targetRate ? `€ ${props.rateRequest.targetRate}` : 'N/A' }</p>
                </div>
              </div>
              <div className="xxs12 md6">
                <div className="card__column">
                  <h6 className="card__column-subheading">Rate request number</h6>
                  <p>{ props.rateRequest.rateRequestNumber }</p>
                </div>
                <div className="card__column">
                  <h6 className="card__column-subheading">Supplier response deadline</h6>
                  <p>{ moment(props.rateRequest.timeframe).format('DD-MM-YYYY') }</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card card--primary">
              <div className="card-headings">
                <h4 className="card__heading">Quote options</h4>
                <h5>Various options for this order.</h5>
              </div>
              <ul className="quote-options-list">
                { props.rateRequest.quoteOptions && props.rateRequest.quoteOptions.map((quoteOption: QuoteOption, index: number) =>
                <li
                  className={ `quote-options-list__item quote-options-list__item--thin quote-options-list__item--has-border` }
                  key={ index }>
                  <div className="group">
                    <div className="card__column xxs12 md2">
                      <h6 className="card__column-subheading">Volume</h6>
                      { quoteOption.volume } m³
                    </div>

                    <div className="card__column xxs12 md2">
                      <h6 className="card__column-subheading">Packaging type</h6>
                      { labels.packagingTypes[quoteOption.packagingType].label }
                    </div>

                    <div className="card__column xxs12 md3">
                      <h6 className="card__column-subheading">Rate request mode</h6>
                      { labels.rateRequestModes[quoteOption.rateRequestMode].labelLong }
                      <button className="link-button" type="button" onClick={() => openInfoModal(quoteOption.rateRequestMode)}>Inclusions</button>
                    </div>

                    <div className="card__column xxs12 md3">
                      <h6 className="card__column-subheading">Transport mode</h6>
                      { labels.transportModes[quoteOption.transportMode].label }
                    </div>

                    <div className="card__column xxs12 md2">
                      <Button variant="ghost" onClick={ () => history.push(`/rate-requests/${uid}/quotes?quoteOption=${quoteOption.id}`) }>
                        View quotes
                      </Button>
                    </div>
                  </div>
                </li>
                )}
              </ul>
            </div>

          { (props.rateRequest.additionals?.find((additional: any) => additional.selectedDestination === true || additional.selectedOrigin === true) || props.rateRequest.customAdditionals?.length > 0) &&
            <div className="card card--primary">
              <div className="group">
                <div className="xxs12">
                  <h4>Additionals</h4>
                </div>
              </div>
              <ul className="list list--additionals">
                <li className="list__item">
                  <div className="additionals-list__column additionals-list__column-label"></div>
                  <div className="additionals-list__column additionals-list__column--center">
                    <strong>Origin</strong>
                  </div>
                  <div className="additionals-list__column additionals-list__column--center">
                    <strong>Destination</strong>
                  </div>
                </li>

                { props.rateRequest.additionals?.map((additional: any) =>
                  ( additional.selectedDestination || additional.selectedOrigin) && (
                    <li className="list__item" key={additional.id}>
                      <div className="additionals-list__column additionals-list__column-label">
                        { additional.item.label }
                      </div>
                      <div className="additionals-list__column additionals-list__column--center">
                        { additional.selectedOrigin ? (<IconCheckmarkGreen />) : (<IconCrossRed />) }
                      </div>
                      <div className="additionals-list__column additionals-list__column--center">
                        { additional.selectedDestination ? (<IconCheckmarkGreen />) : (<IconCrossRed />) }
                      </div>
                    </li>
                  )
                ) }

                { props.rateRequest.customAdditionals?.map((additional: any) =>
                  ( additional.selectedDestination || additional.selectedOrigin) && (
                    <li className="list__item" key={additional.id}>
                      <div className="additionals-list__column additionals-list__column-label">
                        { additional.item.label }
                      </div>
                      <div className="additionals-list__column additionals-list__column--center">
                        { additional.selectedOrigin ? (<IconCheckmarkGreen />) : (<IconCrossRed />) }
                      </div>
                      <div className="additionals-list__column additionals-list__column--center">
                        { additional.selectedDestination ? (<IconCheckmarkGreen />) : (<IconCrossRed />) }
                      </div>
                    </li>
                  )
                ) }
              </ul>
            </div>
          }

          <div className="card card--primary">
            <div className="group">
              <div className="xxs12">
                <h4>Notes</h4>
              </div>
            </div>
            <>
              { addingNewNote
                ? (
                  <>
                    <div className="group group--small-margin">
                      <div className="xxs12">
                        <Textarea
                          value={ newNote }
                          name="notes[]"
                          placeholder="e.g. difficult access or busy street"
                          onChange={ (event: React.FormEvent<HTMLInputElement>) => setNewNote((event.target as HTMLTextAreaElement).value) }
                          disabled={ props.pending }
                        />
                      </div>
                    </div>
                    <div className="group">
                      <div className="xxs12 flex align-right">
                        <Button variant='ghost' onClick={ () => setAddingNewNote(false) }>Cancel</Button>
                        <Button variant='primary' onClick={ addNewNote }>Add new note</Button>
                      </div>
                    </div>
                  </>
                )
                : (
                  <div className="group">
                    <div className="xxs12">
                      <Button variant='ghost' onClick={ () => setAddingNewNote(true) }>Add extra note</Button>
                    </div>
                  </div>
                )
              }
            </>

            { props.rateRequest.notes?.map((note: StrictDict, index: number) =>
              <React.Fragment key={ index }>
                  { note.isEditing
                  ? (
                    <div className="note-container-editing">
                      <div className="group group--small-margin">
                        <div className="xxs12">
                          <Textarea
                            value={ note.text }
                            name="notes[]"
                            placeholder="e.g. difficult access or busy street"
                            onChange={ (event: React.FormEvent<HTMLInputElement>) => updateNote(index, (event.target as HTMLTextAreaElement).value) }
                            disabled={ props.pending }
                          />
                        </div>
                      </div>
                      <div className="group">
                        <div className="xxs12 flex align-right">
                          <Button variant='ghost' onClick={ () => toggleEditNote(index, false) }>Cancel</Button>
                          <Button variant='primary' onClick={ () => editNote(index) }>Save changes</Button>
                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <div className="group note">
                      <div className="xxs10">
                        <p>{ note.text }</p>
                        <time className='note__date' title={ note.postedDate }>Posted on {
                          moment(typeof note.postedDate.toDate === 'function' ? note.postedDate.toDate() : note.postedDate).format('MMMM Do YYYY [at] HH:mm')
                        }</time>
                        { note.editedDate && (
                          <time className='note__date' title={ note.editedDate }>&nbsp;• Last edited on {
                          moment(typeof note.editedDate.toDate === 'function' ? note.editedDate.toDate() : note.editedDate).format('MMMM Do YYYY [at] HH:mm')
                        }</time>
                        ) }
                      </div>
                      <div className="xxs2">
                        <EllipsisButton variant="ghost">
                          <>
                            <button onClick={ () => toggleEditNote(index, true) }>Edit note</button>
                            <button onClick={ () => { setDeleteModalVisible(true); setDeleteNoteIndex(index); } }>Delete note</button>
                          </>
                        </EllipsisButton>
                      </div>
                    </div>
                  )
                }
              </React.Fragment>
            ) }
          </div>

          {/* Below section is for legacy notes. In the past there could be only one note added to a rate request */}
          { props.rateRequest.message &&
            <div className="card card--primary">
              <div className="group">
                <div className="xxs12">
                  <h4>Notes</h4>
                </div>
              </div>
              <div className="group">
                <div className="xxs12">
                  <p>{ props.rateRequest.message }</p>
                </div>
              </div>
            </div>
          }

          <div className="card card--primary">
            <h4 className="card__heading">Attachments</h4>
            <div className="group">
              <div className="xxs12">
                <Attachments rateRequest={ props.rateRequest } />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal visible={infoModalOpen} title={infoModalTitle} cancelAction={ () => setInfoModalOpen(false) } size="large">
        { infoModalContent }
      </Modal>
    </>
  )
}
