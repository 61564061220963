import moment from 'moment';

export default function parseDate(unknownDate: any) {
  if (!unknownDate) {
    return unknownDate;
  }

  if (unknownDate instanceof Date && typeof unknownDate.getMonth === 'function') {
    return unknownDate;
  }

  const seconds = unknownDate.seconds || unknownDate._seconds;

  if (seconds) {
    return moment(seconds, 'X').toDate();
  }

  console.error('Could not parse date:', unknownDate);

  return null;
}
