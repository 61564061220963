import React, { useState, useMemo, useContext, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Header, Navigation } from "../../components/Layout";
import Pagination from "../../components/Pagination/Pagination";
import GlobalData from "../../context/globaldata";
import { Input, Select } from "../../components/Form";
import Modal from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import Badge from "../../components/Badge/Badge";

import Result from "./Result";
import ResultList from "./ResultList";

import { ReactComponent as IconFilter } from "../../assets/images/icons/filter.svg";

import subRegionOptionsData from "../../assets/data/sub-region-options.json";
import countryCodes from "../../assets/data/country-codes-iso3316-2.json";

const subRegionOptions: StrictDict = subRegionOptionsData;
type CountryCode = keyof typeof countryCodes;

type FiltersType = {
  [key: string]: {
    [key: string]: boolean
  }
};

export default function SuppliersOverview() {
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const paramQuery = params.get("q");
  const paramPage = params.get("page");
  const context = useContext(GlobalData);
  const itemsPerPage = 15;
  const [ filterDraft, setFilterDraft ] = useState<StrictDict>({
    "relationship": null,
    "rating": null,
    "type": null,
    "agreementSigned": false,
    "groups": null,
    "coverage": null,
    "located": null,
    "locatedSubRegions": null
  });

  // const searchValueStorage = localStorage.getItem(`SEARCH_${location.pathname}`);
  const searchValueStorage = '';

  const [ filter, setFilter ] = useState<StrictDict>({});
  const [ filterModalVisible, setFilterModalVisible ] = useState<boolean>(false);

  const filterCounter = Object.keys(filter).filter(filterItem => (!!filter[filterItem] && !!filter[filterItem].length && filterItem !== "status")).length;

  const [ currentPage, setCurrentPage ] = useState(paramPage ? parseInt(paramPage) : 1);
  const [ searchValue, setSearchValue ] = useState<string>(searchValueStorage ? searchValueStorage : paramQuery ? paramQuery : '');
  const [ pagedSuppliersResults, setPagedSuppliersResults ] = useState<Array<Object>>([]);

  useEffect(() => {
    let foundFilters = window.localStorage.getItem('supplier-filter');

    if (foundFilters) {
      foundFilters = JSON.parse(foundFilters);
      setFilter((foundFilters || {}) as StrictDict);
      setFilterDraft((foundFilters || {}) as StrictDict);
    }

    // deleteInactiveSuppliers();
    // setSuppliersInactive();
    // setSuppliersWithoutStatusAsActive();
    // setSuppliersIgnoreLocation();
  }, []);

  const deleteInactiveSuppliers = async () => {
    console.log('>>> deleting suppliers');
    const inactiveSuppliers = await context.firestore
      .collection('suppliers')
      .limit(800)
      .get();

    const batch = context.firestore.batch();

    inactiveSuppliers.forEach((doc: any) => {
      const data = doc.data();

      if (!data.relationship) {
        console.log(data);
        batch.delete(doc.ref);
      }
    });

    await batch.commit();
  }

  const setSuppliersInactive = async () => {
    console.log('>>> adjusting suppliers');

    const suppliersSnapshot = context.firestore.collection("suppliers").limit(5000).onSnapshot(async (querySnapshot: any) => {
      const batch = context.firestore.batch();

      const foundSuppliers:Array<Supplier> = [];
      /**
       * Use commented code in this function to export the suppliers list
       */
      // const exportArray:Array<StrictDict> = [];

      querySnapshot.docs.forEach((doc: any) => {
        const docData:Supplier = doc.data();

        if (!docData.relationship) {

          batch.set(doc.ref, {
            status: {
              inactive: true,
            },
          }, { merge: true });
        }
      });

      await batch.commit();
    });
  }

  const setSuppliersWithoutStatusAsActive = async () => {
    console.log('>>> adjusting suppliers');

    const suppliersSnapshot = context.firestore.collection("suppliers").onSnapshot(async (querySnapshot: any) => {
      const batch = context.firestore.batch();

      querySnapshot.docs.forEach((doc: any) => {
        const docData:Supplier = doc.data();

        if (!docData.status || typeof docData.status.inactive === 'undefined') {

          batch.set(doc.ref, {
            status: {
              inactive: false,
            },
          }, { merge: true });
        }
      });

      console.log(batch);

      await batch.commit();

      console.log('DONE!');
    });
  }

  const setSuppliersIgnoreLocation = async () => {
    console.log('>>> adjusting suppliers');

    const suppliersSnapshot = context.firestore.collection("suppliers").limit(5000).onSnapshot(async (querySnapshot: any) => {
      const batch = context.firestore.batch();

      const foundSuppliers:Array<Supplier> = [];
      /**
       * Use commented code in this function to export the suppliers list
       */
      // const exportArray:Array<StrictDict> = [];

      querySnapshot.docs.forEach((doc: any) => {
        const docData:Supplier = doc.data();

        if (context.countryCodes[docData.car?.CompanyAddress.GENLAND[0]].continent === 'EU') {
          batch.set(doc.ref, {
            ignoreSupplierLocation: null
          }, { merge: true });
        }
      });

      await batch.commit();
    });
  }

  const sortOptions = [
    {
      label: "Preference (High > Low)",
      value: "preference-desc",
      field: "relationship",
      order: "asc"
    }, {
      label: "Preference (Low > High)",
      value: "preference-asc",
      field: "relationship",
      order: "desc"
    }, {
      label: "Alphabetical (A-Z)",
      value: "alphabetical-asc",
      field: "car.Client",
      order: "asc"
    }, {
      label: "Alphabetical (Z-A)",
      value: "alphabetical-desc",
      field: "car.Client",
      order: "desc"
    },
  ];

  const [ sort, setSort ] = useState(sortOptions[0]);

  const navigationItems = [
    {
      to: "/rate-requests",
      label: "Rate requests"
    },
    {
      to: "/suppliers",
      label: "Suppliers"
    },
    {
      to: "/users",
      label: "Users"
    }
  ];

  // Check if selected filters have changed and perform filter on suppliers object
  const performFilter = useCallback((suppliers: Array<Supplier>, filter: StrictDict): Array<Supplier> => {
    if (suppliers.length === 0) {
      return [];
    }

    let filteredSuppliers = suppliers.slice();

    filteredSuppliers = filteredSuppliers.filter((supplier: Supplier) => {

      // Filter based on relationship
      if ((filter.relationship && filter.relationship.length > 0)) {
        const foundRelationship = filter.relationship.some((relationship: StrictDict) => relationship.value === supplier.relationship);

        if (!foundRelationship) {
          return false;
        }
      }

      // Filter based on type
      if (filter.type && filter.type.length > 0) {
        if (!(supplier.type instanceof Object)) {
          return false;
        }

        const foundType = filter.type.some((type: StrictDict) => {
          return Object.entries(supplier.type).some(([selectedType, value]) => {
            return (value === true && selectedType === type.value);
          });
        });

        if (!foundType) {
          return false;
        }
      }

      // Filter based on status
      // const foundStatus = (filter.status && Object.keys(filter.status).length > 0)
      //   ? filter.status.some((status: StrictDict) => status.value == supplier.status)
      //   : true;

      // if (!foundStatus) {
      //   return false;
      // }

      // Filter based on agreement
      const foundAgreement = (filter.agreement && Object.keys(filter.agreement).length > 0)
        ? filter.agreement.some((agreement: StrictDict) => !!agreement.value === !!supplier.agreementSigned)
        : true;

      if (!foundAgreement) {
        return false;
      }

      // Filter based on groups
      if (filter.groups && filter.groups.length > 0) {
        if (!(supplier.groups instanceof Object)) {
          return false;
        }

        const foundType = filter.groups.some((group: StrictDict) => {
          return Object.entries(supplier.groups).some(([selectedType, value]) => {
            return (value === true && !!selectedType === !!group.value);
          });
        });

        if (!foundType) {
          return false;
        }
      }

      // Filter based on origin
      // const foundOrigin = (filter.origin && filter.origin.length > 0)
      //   ? filter.origin.some((origin: StrictDict) => origin.value == supplier.origin)
      //   : true;

      // if (!foundOrigin) {
      //   return false;
      // }

      // Filter based on coverage area
      if (filter.coverage && filter.coverage.length > 0) {
        if (!(supplier.coverage instanceof Object)) {
          return false;
        }

        const foundCoverage = filter.coverage.some((coverage: StrictDict) => {
          return Object.entries(supplier.coverage).some((item: StrictDict) => {
            return (item[1].item.country === coverage.value);
          });
        });

        if (!foundCoverage) {
          return false;
        }
      }

      // Filter based on company location
      if (filter.located && filter.located.length > 0) {
        if (!supplier.car?.CompanyAddress.GENLAND[0]) {
          return false;
        }

        const foundLocation = filter.located.some((located: StrictDict) => {
          return supplier.car?.CompanyAddress.GENLAND[0] === located.value;
        });

        if (foundLocation) {
          // Filter based on company location subregions
          if (filter.locatedSubRegions && filter.locatedSubRegions.length > 0) {
            if (supplier.locationSubRegions && supplier.locationSubRegions.length) {
              const foundSubRegion = filter.locatedSubRegions.some((locatedSubRegion: StrictDict) => {
                return supplier.locationSubRegions.some((supplierLocatedSubRegion: StrictDict) => {
                  return supplierLocatedSubRegion.value === locatedSubRegion.value;
                });
              });

              if (!foundSubRegion) {
                return false;
              }
            }
          }
        }

        if (!foundLocation) {
          return false;
        }
      }

      return true;
    });

    return filteredSuppliers;
  }, []);

  // Search supplier by string
  const performSearch = useCallback((suppliers: Array<Supplier>, searchValue: string): Array<Supplier> => {
    if (!searchValue.length) {
      return suppliers;
    }

    searchValue = searchValue.toLowerCase();

    localStorage.setItem(`SEARCH_${ location.pathname }`, searchValue);

    const whiteListProps: StrictDict = [
      "car", // CAR data object
        "Client", // CAR Supplier name
        "INFEM", // CAR Supplier email
        "INFCONT", // CAR contact person name
        "CompanyAddress",
          "GENNAME",

      "contacts", // Additional contacts array
        "name", // Additional contact name
        "email", // Additional contact email
        "phone", // Additional contact phone

      "alias", // Supplier alias

      "customName", // Custom contact name
      "customEmail", // Custom contact email
      "customPhone", // Custom contact phone

      "escalationName", // Escalation contact name
      "escalationEmail", // Escalation contact email
      "escalationPhone" // Escalation contact phone
    ];

    if (suppliers.length === 0) {
      return [];
    }

    function iterateSearch(subject: StrictDict): any {
      for (const key in subject) {
        const currentValue = subject[key];

        if ((whiteListProps.includes(key)
             || typeof key === "number")
            && typeof currentValue === "object"
            && currentValue !== null) {

          if (iterateSearch(currentValue)) {
            return true;
          }
        } else if ((whiteListProps.includes(key)
                    && typeof currentValue === "string"
                    && currentValue.length > 0)) {

          const value = currentValue.toLowerCase();

          if (value.indexOf(searchValue) > -1) {
            return true;
          }
        }
      }
    };

    const result = suppliers.filter(item => iterateSearch(item) || false);

    return result;
  }, [ location.pathname ]);

  // Sort by selected sort field and order
  const performSort = useCallback((suppliers: Array<Supplier>, sort: StrictDict): Array<Supplier> => {
    if (suppliers.length === 0) {
      return [];
    }

    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: "base"});

    const dotPick = (dotReference: string, item: StrictDict) => {
      if (dotReference.indexOf(".") !== -1) {
        return dotReference.split(".").reduce((o: StrictDict, i: string) => o ? o[i] : null, item);
      }

      return item[dotReference];
    };

    const suppliersFilteredSorted = suppliers.slice();

    suppliersFilteredSorted.sort((a: object, b: object) => {
      const multiplier = sort.order === 'asc' ? 1 : -1;

      const valueA = dotPick(sort.field, a);
      const valueB = dotPick(sort.field, b);

      if (valueA === valueB) {
        return 0;
      } else if (!valueA) {
        return 1;
      } else if (!valueB) {
        return -1;
      } else {
        return collator.compare(valueA, valueB) * multiplier;
      }
    });

    return suppliersFilteredSorted;
  }, []);

  // Perform pagination
  const performPagination = useCallback((suppliers: Array<Supplier>, currentPage: number): Array<Supplier> => {
    const startOffset = (currentPage - 1) * itemsPerPage;
    return suppliers.slice(startOffset, startOffset + itemsPerPage);
  }, []);

  const suppliersResults: Array<Supplier> = useMemo(() => {
    let filteredSuppliers: Array<Supplier> = [];

    if(context.currentUser?.custom?.admin) {
      filteredSuppliers = context.suppliers;
    } else {
      filteredSuppliers = context.suppliers.filter((supplier: any) => supplier.status?.inactive !== true);
    }

    let results: Array<Supplier> = performFilter(filteredSuppliers, filter);
    results = performSearch(results, searchValue);
    results = performSort(results, sort);

    return results;
  }, [ searchValue, sort, filter, context.currentUser, context.suppliers, performFilter, performSearch, performSort ]);

  useEffect(() => {
    const results = performPagination(suppliersResults, currentPage);

    setPagedSuppliersResults(results);
  }, [ suppliersResults, currentPage, performPagination]);

  // Filter functions
  const updateFilterDraft = (key: string, value: string|number|Date|StrictDict|MapLocation|null) => {
    setFilterDraft({
      ...filterDraft,
      [key]: value
    });
  };

  const applyFilterDraft = (event: Event|null, preventClose: boolean) => {
    event?.preventDefault();

    if (!preventClose) {
      setFilterModalVisible(false);
    }

    saveFilterInLocalStorage(filterDraft);

    setFilter(filterDraft);
  };

  const saveFilterInLocalStorage = (filterDraft: StrictDict) => {
    window.localStorage.setItem('supplier-filter', JSON.stringify(filterDraft));
  };

  const resetFilterDraft = (event: Event) => {
    event?.preventDefault();

    const resetObject = {
      "relationship": null,
      "rating": null,
      "type": null,
      "agreementSigned": false,
      "located": null,
      "locatedSubRegions": null,
      "groups": null,
      "coverage": null
    };

    setFilterDraft(resetObject);
    setFilter(resetObject);
    saveFilterInLocalStorage(resetObject);
  };

  const getGroupOptions = () => {
    const groupOptions = context.groups.map((group: StrictDict, index: number) => {
      return {
        label: group.name,
        value: group.id
      }
    });

    return groupOptions;
  };

  const coverageOptions = (() => {
    // Use a Set to store unique coverage values
    const coverageArray: Array<MapLocation> = [];

    context.suppliers.forEach((supplier: Supplier) => {
      supplier.coverage && supplier.coverage.forEach((coverageItemSelection: CoverageItemSelection) => {
        if (!coverageArray.some(item => item.country === coverageItemSelection.item.country)) {
          coverageArray.push(coverageItemSelection.item);
        }
      });
    });

    const coverageArraySorted = coverageArray.sort((a: MapLocation, b: MapLocation) => {
      return (a.country < b.country) ? -1 : 1;
    });

    return coverageArraySorted.map((coverageItem: MapLocation) => {
      return {
        label: coverageItem.name,
        value: coverageItem.country
      }
    });
  })();

  const locationOptions = (() => {
    // Use a Set to store unique coverage values
    const locationsArray: Array<StrictDict> = [];

    context.suppliers.forEach((supplier: Supplier) => {
      const foundCountryCode = supplier.car?.CompanyAddress.GENLAND[0];

      if (!foundCountryCode) {
        console.error('No location found for supplier:', supplier);
        return;
      }

      const foundLocation = context.countryCodes[foundCountryCode];

      if (!foundLocation) {
        console.error(`No country found for code: ${ foundCountryCode }`);
        return;
      }

      if (!locationsArray.some(item => item.code === foundCountryCode)) {
        locationsArray.push({
          country: foundLocation.name,
          code: foundCountryCode
        });
      }
    });

    const locationsArraySorted = locationsArray.sort((a: StrictDict, b: StrictDict) => {
      return (a.country < b.country) ? -1 : 1;
    });

    return locationsArraySorted.map((item: StrictDict) => {
      return {
        label: item.country,
        value: item.code
      }
    });
  })();

  const getFilterSubRegions = useCallback(() => {
    let options: StrictDict[] = [];

    filterDraft.located.forEach((locatedIn: StrictDict) => {
      console.log(subRegionOptions[locatedIn.value]);
      if (subRegionOptions[locatedIn.value]) {
        options = [
          ...options,
          ...subRegionOptions[locatedIn.value]
        ];
      }
    });

    console.log(options);

    return options;
  }, [ filterDraft ]);

  return (
    <>
      <Header
        title="Suppliers"
        leftContent={ <Navigation navItems={navigationItems} /> }
        aside={
          <>
            <Input value={searchValue} name="search" onChange={(event: any) => setSearchValue(event.target.value)} placeholder="Search for suppliers" search />
            <Button variant="tertiary" onClick={() => setFilterModalVisible(true)}>
              <>
                <IconFilter className="button__icon" />
                <span>Filter</span>
                { filterCounter > 0 &&
                  <Badge label={filterCounter} />
                }
              </>
            </Button>
          </>
        }
        logo
        accountDropdown
      />

      <div>
        <div className="container">
          <div className="card card--overview">
            <section>
              <div className="results">
                <div className="results__meta group">
                  <div className="xxs12 md7">
                    <h4>{ suppliersResults.length } Result{ suppliersResults.length !== 1 ? "s" : "" }</h4>
                  </div>
                  <div className="xxs12 md5">
                    <Select
                      value={sort}
                      name="demoSelect"
                      label="Sort on"
                      onChange={setSort}
                      options={sortOptions}
                      searchable={false}
                    />
                  </div>
                </div>

                <ResultList results={ pagedSuppliersResults }>
                  <Result />
                </ResultList>


              </div>
            </section>
          </div>
        </div>

        <Pagination
          dataLength={ suppliersResults.length }
          itemsPerPage={ itemsPerPage }
          currentPage={ currentPage }
          setCurrentPage={ setCurrentPage }
        />
      </div>

      <Modal customClassName="modal__box" title="Filter suppliers" visible={ filterModalVisible } confirmAction={ applyFilterDraft } confirmLabel="Apply filters" cancelLabel="Reset filters" resetAction={ resetFilterDraft } cancelAction={ () => setFilterModalVisible(false) } form>
        <>
          <div className="form-group">
            <Select
              label="Relationship"
              value={ filterDraft.relationship }
              name="relationship"
              multi={ true }
              placeholder="Select relationships"
              onChange={ (option: StrictDict) => updateFilterDraft("relationship", option) }
              options={ context.relationshipLabels }
            />
          </div>

          <div className="form-group">
            <Select
              label="Type"
              value={ filterDraft.type }
              name="type"
              multi={ true }
              placeholder="Select types"
              onChange={ (option: StrictDict) => updateFilterDraft("type", option) }
              options={ context.typeLabels }
            />
          </div>

          <div className="form-group">
            <Select
              label="Groups"
              value={ filterDraft.groups }
              name="groups"
              multi={ true }
              placeholder="Select groups"
              onChange={ (option: StrictDict) => updateFilterDraft("groups", option) }
              options={ getGroupOptions() }
            />
          </div>

          <div className="form-group">
            <Select
              label="Located in"
              value={ filterDraft.located }
              name="located"
              multi={ true }
              placeholder="Select countries where suppliers are located"
              onChange={ (option: StrictDict) => updateFilterDraft("located", option) }
              options={ locationOptions }
            />
          </div>

          { filterDraft.located && filterDraft.located.some((locatedIn: StrictDict) => subRegionOptions[(locatedIn.value as CountryCode)]) &&
            (
              <div className="form-group form-group--indented">
                <Select
                  label="Location(s) sub-regions"
                  value={ filterDraft.locatedSubRegions }
                  name="locationSubRegion"
                  multi={ true }
                  placeholder="Select country sub regions where supplier operates"
                  onChange={ (option: StrictDict) => updateFilterDraft("locatedSubRegions", option) }
                  options={ getFilterSubRegions() }
                />
              </div>
            )
          }

          <div className="form-group">
            <Select
              label="Area coverage"
              value={ filterDraft.coverage }
              name="coverage"
              multi={ true }
              placeholder="Select coverage area countries"
              onChange={ (option: StrictDict) => updateFilterDraft("coverage", option) }
              options={ coverageOptions }
            />
          </div>
        </>
      </Modal>
    </>
  )
}
