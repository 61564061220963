// Compare distance between two lat/long coordinates with Haversine formula (https://en.wikipedia.org/wiki/Haversine_formula)
export default function haversineDistance(coordsA: StrictDict, coordsB: StrictDict) {
  const toRadians = (angle: number) => {
    return angle * Math.PI / 180;
  }

  const R = 6371; // Earth radius in KM
  const aLatRad = toRadians(coordsA.lat), aLongRad = toRadians(coordsA.long);
  const bLatRad = toRadians(coordsB.lat), bLongRad = toRadians(coordsB.long);
  const deltaLat = bLatRad - aLatRad;
  const deltaLong = bLongRad - aLongRad;
  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) + Math.cos(aLatRad) * Math.cos(bLatRad) * Math.sin(deltaLong / 2) * Math.sin(deltaLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const d = R * c;

  // Distance in KM
  return d;
};
