import React, { useState, useEffect } from "react";

type Props = {
  status: number;
  label?: string;
};

export default function Chip(props: Props) {
  const statusList = [
    {
      id: 1,
      label: "60 days valid",
      class: "valid"
    },
    {
      id: 2,
      label: "Pending",
      class: "warning"
    },
    {
      id: 3,
      label: "Denied",
      class: "danger"
    },
    {
      id: 4,
      label: "Historic",
      class: "disabled"
    },
    {
      id: 5,
      label: "Draft",
      class: "default"
    }
  ];

  const status = statusList.find((item) => item.id === props.status)!;

  return (
    <span className={`chip chip--${status.class}`}>{ props.label ? props.label : (status && status.label) }</span>
  );
}
