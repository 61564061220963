import React, { ReactElement } from "react";

type Props = {
  checked?: boolean;
  value: string|number;
  name: string;
  onChange: any;
  required?: boolean;
  label?: string|ReactElement;
  weight?: "bold";
  aside?: any;
  switch?: boolean;
  disabled?: boolean;
  childActive?: boolean;
};

export default function FormCheckbox(props: Props) {
  return (
    <label className={`checkbox ${props.switch ? "checkbox--switch" : "checkbox--default"} ${props.disabled ? "is-disabled" : ""} ${props.childActive ? "has-active-child" : ""} ${props.weight ? "is-bold" : ""}`}>
      <input className="checkbox__input" type="checkbox" name={props.name} value={props.value} checked={props.checked || false} onChange={props.onChange} required={props.required} disabled={props.disabled}/>
      <div className="checkbox__element"></div>
      { props.label && <span className="checkbox__label">{props.label}{props.required && (<span>*</span>)}</span> }
    </label>
  );
}
