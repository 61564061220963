import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
  navItems: any;
  variant?: string;
};

export default function LayoutNavigation(props: Props) {

  const handleClick = (event: any, navItem: any) => {
    if(navItem.disabled) {
      event.preventDefault();
    }
  };

  return (
    <nav className={`navigation navigation--${props.variant ? props.variant : "primary"}`}>
      <ul className="navigation__list">
        { props.navItems.map((navItem: any) => {
          return (
            <li className="navigation__list-item" key={ navItem.to }>
              <NavLink
                className={`navigation__button ${navItem.disabled ? "is-disabled" : ""}`}
                onClick={(event: any) => handleClick(event, navItem)}
                to={ navItem.to }
                exact={ navItem.exact && true }
                activeClassName="is-active"
              >
                { navItem.step &&
                  <div className="navigation__button-step">
                    { navItem.step }
                  </div>
                }
                <span>{ navItem.label }</span>
              </NavLink>
            </li>
          )
        }) }
      </ul>
    </nav>
  );
}
