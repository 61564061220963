import React, { useContext, useState, useEffect, ChangeEvent, useCallback, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { nanoid } from "nanoid";

// @ts-ignore
import { store } from "react-notifications-component";

import GlobalData from "../../../context/globaldata";
import { Input, Select, DropdownButton, Checkbox } from "../../../components/Form";
import Card from "../Select/Card";
import Pagination from "../../../components/Pagination/Pagination";
import RequestModal from "../Edit/RequestModal";
import haversineDistance from "../../../utils/HaverSineDistance";
import { ReactComponent as IconSync } from "../../../assets/images/icons/sync.svg";
import parseDate from "../../../utils/ParseDate";
import moment from "moment";

export default function RateRequestSelect(props: any) {
  // @ts-ignore
  const { uid } = useParams();
  const location = useLocation();
  const context = useContext(GlobalData);
  const search = location.search;
  const params = new URLSearchParams(search);
  const paramQuery = params.get("q");
  const paramPage = params.get("page");
  const itemsPerPage = 15;

  const [ matchingSuppliers, setMatchingSuppliers ] = useState<Array<Supplier>>([]);
  const [ currentPage, setCurrentPage ] = useState<number>(paramPage ? parseInt(paramPage) : 1);
  const [ searchValue, setSearchValue ] = useState<string>(paramQuery ? paramQuery : "");
  const [ filters, setFilters ] = useState<StrictDict>({
    selectedQuoteOptions: []
  });

  const [ mailSubject, setMailSubject ] = useState('');
  const [ mailBody, setMailBody ] = useState('');

  const [ filteredQuoteOptionsResponses, setFilteredQuoteOptionsResponses ] = useState<Array<QuoteOptionResponse>>([]);
  const [ alternativeQuoteOptionsResponses, setAlternativeQuoteOptionsResponses ] = useState<Array<QuoteOptionResponse>>([]);
  const [ loading, setLoading ] = useState(true);
  const [ requested, setRequested ] = useState(false);
  const [ loadingAlternatives, setLoadingAlternatives ] = useState<boolean>(true);
  const [ requestRatesModalVisible, setRequestRatesModalVisible ] = useState(false);
  const [ currentlySelectedSupplier, setCurrentlySelectedSupplier ] = useState<Supplier>();

  const [ pagedMatchingSuppliersResults, setPagedMatchingSuppliersResults ] = useState<Array<Supplier>>([]);

  const labels = context.rateRequestLabels;

  const [ quoteOptionsMetas, setQuoteOptionsMetas ] = useState<StrictDict>({});

  const rateRequest = props.rateRequest;

  const sortByOptions = [
    {
      label: "Preference",
      value: "preference-desc",
      field: "relationship",
      order: "asc"
    }, {
      label: "Fastest",
      value: "duration-asc",
      field: "meta.duration",
      order: "asc"
    }, {
      label: "Cheapest",
      value: "rate-asc",
      field: "meta.rate",
      order: "asc"
    }
  ];

  const [ sortBy, setSortBy ] = useState(sortByOptions[0]);

  const checkSelected = useCallback((matchingSupplier: Supplier) => rateRequest.selectedSuppliers?.some((selectedSupplier: SelectedSupplier) => selectedSupplier.supplierUid === matchingSupplier.uid), [ rateRequest.selectedSuppliers ]);

  function updateEmail(newValue: string, supplierUid: string) {
    props.setRateRequest((prevState: any) => {
      const nextState = Object.assign({}, prevState);

      const supplierIndex = nextState.selectedSuppliers?.findIndex((selectedSupplier: SelectedSupplier) => selectedSupplier.supplierUid === supplierUid);

      if (nextState.selectedSuppliers[supplierIndex]) {
        nextState.selectedSuppliers[supplierIndex].overrideEmail = newValue;
      }

      return nextState;
    });
  }

  const requestSupplier = (supplier: Supplier) => {
    setCurrentlySelectedSupplier(supplier);

    updateSupplierSelection(supplier);

    setRequestRatesModalVisible(true);
  };

  async function requestRates() {
    if (!currentlySelectedSupplier) {
      return;
    }

    // Create responses subcollection with pre-determined response objects for each supplier

    // Set up quoteOptionsResponse array based on supplied quoteOptions in rateRequest
    const quoteOptionResponses:Array<QuoteOptionResponse> = [];

    props.rateRequest.quoteOptions.forEach((quoteOption: QuoteOption) => {
      const quoteOptionResponse:QuoteOptionResponse = {
        id: nanoid(),
        quoteOptionId: quoteOption.id,
        accepted: true,
        rate: null,
        status: 0,
        loadingDate: null,
        deliveryDate: null,
        message: null,
        selectedStatus: {
          label: "Accept bid",
          value: true
        }
      };

      quoteOptionResponses.push(quoteOptionResponse);
    });

    // Set up additionalsResponses array based on supplied additionals in rateRequest
    const additionalsResponses:Array<AdditionalResponse> = [];

    props.rateRequest.additionals.forEach((additionalSelection: RateRequestAdditionalSelection) => {
      if (additionalSelection.selectedOrigin || additionalSelection.selectedDestination) {
        const additionalResponse:AdditionalResponse = {
          additionalId: additionalSelection.id,
          rateOrigin: null,
          rateDestination: null
        };

        additionalsResponses.push(additionalResponse);
      }
    });

    // Set up customAdditionalsResponses array based on supplied additionals in rateRequest
    const customAdditionalsResponses:Array<AdditionalResponse> = [];

    props.rateRequest.customAdditionals.forEach((additionalSelection: RateRequestAdditionalSelection) => {
      if (additionalSelection.selectedOrigin || additionalSelection.selectedDestination) {
        const additionalResponse:AdditionalResponse = {
          additionalId: additionalSelection.id,
          rateOrigin: null,
          rateDestination: null
        };

        customAdditionalsResponses.push(additionalResponse);
      }
    });

    // Start batch
    const batch = context.firestore.batch();

    // Create response document for each selected supplier
    const response:RateRequestResponse = {
      supplierUid: currentlySelectedSupplier.uid as string,
      // supplierEmail: selectedSupplier.supplierUid,
      status: 0,
      contactPerson: null,
      referenceInput: null,
      message: null,
      additionalsResponses: additionalsResponses,
      customAdditionalsResponses: customAdditionalsResponses,
      quoteOptionsResponses: quoteOptionResponses,
      submittedAt: null,
      overrideSupplierEmail: props.rateRequest.selectedSuppliers?.find((selectedSupplier: SelectedSupplier) => selectedSupplier.supplierUid === currentlySelectedSupplier.uid).overrideEmail
    };

    const newDocRef = context.firestore.collection("rate_requests").doc(uid).collection("responses").doc();

    batch.set(newDocRef, response);

    // Commit batch
    batch.commit().then(() => {
      saveChanges({...props.rateRequest, status: 1, userSubmitted: context.currentUser.custom?.uid});
    });
  };

  const saveChanges = (newData: RateRequest) => {
    props.setPending(true);

    context.firestore.collection("rate_requests").doc(uid).set(newData||props.rateRequest)
    .then(() => {
      store.addNotification({
        message: "Quote requested successfully",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      setRequestRatesModalVisible(false);

      props.setPending(false);
    })
    .catch((error: string) => {
      store.addNotification({
        message: `Error saving rate request: ${error}`,
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "slideDown"],
        animationOut: ["animated", "slideUp"],
        dismiss: {
          pauseOnHover: true,
          duration: 5000
        }
      });

      props.setPending(false);
    });
  };

  function updateSupplierSelection(supplier?: Supplier) {
    if (!supplier) {
      supplier = currentlySelectedSupplier;
    }

    props.setRateRequest((prevState: any) => {
      const nextState = Object.assign({}, prevState);

      if (!nextState.selectedSuppliers) {
        nextState.selectedSuppliers = [];
      }

      const supplierIndex = nextState.selectedSuppliers?.findIndex((selectedSupplier: SelectedSupplier) => selectedSupplier.supplierUid === supplier?.uid);

      if (supplierIndex === -1) {
        const selectedSupplier: SelectedSupplier = {
          supplierUid: supplier?.uid as string,
          status: 0,
          overrideEmail: null
        };

        nextState.selectedSuppliers.push(selectedSupplier);
      } else {
        nextState.selectedSuppliers.splice(supplierIndex, 1);
      }

      return nextState;
    });
  }

    const getSupplierQuotes = (matchingSuppliers: Supplier[], matchingSupplierIndex: number, totalLoadedAlternativeSuppliers: number, filter: StrictDict, setupQuoteOptionsMetas: StrictDict) =>
    new Promise (async (resolve, reject) => {
      console.log(matchingSupplierIndex, matchingSuppliers.length);

      const assocRateRequests: StrictDict = {};

      const matchingSupplier = matchingSuppliers[matchingSupplierIndex];
      const collectionRef = await context.firestore.collectionGroup("responses")
      .where("supplierUid", "==", matchingSupplier.uid)
      .where("submittedAt", "!=", null);

      const responsesSnapshot = await collectionRef.get();

      const foundResponses:Array<RateRequestResponse> = [];

      let foundQuoteOptionsResponses:Array<QuoteOptionResponse> = [];

      for (let responseDocIndex = 0; responseDocIndex < responsesSnapshot.docs.length; responseDocIndex++) {
        const responseDoc: StrictDict = responsesSnapshot.docs[responseDocIndex];
        const responseData: RateRequestResponse = responseDoc.data();

        foundResponses.push(responseData);

        if (!assocRateRequests[responseDoc.ref.parent.parent.id]) {
          for (let i = 0; i < context.rateRequests.length; i++) {
            const rateRequest = context.rateRequests[i];

            if (responseDoc.ref.parent.parent.id === rateRequest.uid) {
              responseData.rateRequest = rateRequest;
            }
          }
        }

        for (let quoteOptionsResponseIndex = 0; quoteOptionsResponseIndex < responseData.quoteOptionsResponses.length; quoteOptionsResponseIndex++) {
          const quoteOptionsResponse: QuoteOptionResponse = responseData.quoteOptionsResponses[quoteOptionsResponseIndex];

          if (!quoteOptionsResponse.accepted || quoteOptionsResponse.status === -1) {
            continue;
          }

          // @ts-ignore
          quoteOptionsResponse.rate = quoteOptionsResponse.rate !== null ? parseInt(quoteOptionsResponse.rate) : null;

          quoteOptionsResponse.rateRequest = responseData.rateRequest;
          quoteOptionsResponse.quoteOption = quoteOptionsResponse.rateRequest?.quoteOptions.find((quoteOption: QuoteOption) => quoteOption.id === quoteOptionsResponse.quoteOptionId);
          quoteOptionsResponse.rateRequestResponse = responseData;

          if (quoteOptionsResponse.rateRequest?.timeframe?.hasOwnProperty("nanoseconds")) {
            // @ts-ignore
            quoteOptionsResponse.rateRequest.timeframe = quoteOptionsResponse.rateRequest?.timeframe?.toDate();
          }

          quoteOptionsResponse.quoteAccepted = (quoteOptionsResponse.rateRequest?.acceptedResponse === quoteOptionsResponse.rateRequestResponse?.uid && quoteOptionsResponse.rateRequest?.acceptedQuoteOption === quoteOptionsResponse.quoteOptionId);

          if (!quoteOptionsResponse.quoteOption
            || !quoteOptionsResponse.quoteOption.volume
            || !quoteOptionsResponse.rate) {
            continue;
          }

          // Rate request mode
          if (!quoteOptionsResponse.quoteOption?.rateRequestMode && filter.rateRequestModes.some((rateRequestMode: number) => quoteOptionsResponse.quoteOption?.rateRequestMode && (quoteOptionsResponse.quoteOption?.rateRequestMode === rateRequestMode))) {
            continue;
          }

          // Filter on distance
          if (rateRequest.origin) {
            if (!quoteOptionsResponse.rateRequest?.origin.lat) {
              // Rate request has no origin
              continue;
            }

            // Calculate distance between filter origin and rate request origin
            const distanceOrig = haversineDistance({
              lat: rateRequest.origin.lat,
              long: rateRequest.origin.long
            }, {
              lat: quoteOptionsResponse.rateRequest?.origin.lat,
              long: quoteOptionsResponse.rateRequest?.origin.long
            });

            // Check if out of range
            if (distanceOrig > 50) {
              continue;
            }
          }

          // rateRequest on destination
          if (rateRequest.destination) {
            if (!quoteOptionsResponse.rateRequest?.destination.lat) {
              // Rate request has no destination
              continue;
            }

            // Calculate distance between rateRequest destination and rate request destination
            const distanceDest = haversineDistance({
              lat: rateRequest.destination.lat,
              long: rateRequest.destination.long
            }, {
              lat: quoteOptionsResponse.rateRequest?.destination.lat,
              long: quoteOptionsResponse.rateRequest?.destination.long
            });

            // Check if out of range
            if (distanceDest > 50) {
              continue;
            }
          }

          let duration = null;

          if (quoteOptionsResponse.loadingDate && quoteOptionsResponse.deliveryDate) {
            // Calculate duration in milliseconds between loading and delivery date
            duration = moment(parseDate(quoteOptionsResponse.deliveryDate)).diff(parseDate(quoteOptionsResponse.loadingDate), 'days');
          }

          // Update card meta
          quoteOptionsResponse.duration = duration;

          // Volume
          const continueVolume = filter.volumes.some((volume: number) => {
            const otherQuoteOptionVolume = quoteOptionsResponse.quoteOption?.volume as number;

            if (otherQuoteOptionVolume) {
              let minVolume = volume * .8;
              let maxVolume = volume * 1.2;

              if (minVolume > (volume - 2)) {
                minVolume = volume - 2;
              }

              if (maxVolume < (volume + 2)) {
                maxVolume = volume + 2;
              }

              if (otherQuoteOptionVolume >= minVolume && otherQuoteOptionVolume <= maxVolume) {
                return true;
              }
            }

            return false;
          });

          if (!continueVolume) {
            continue;
          }

          if (!filter.rateRequestModes.some((rateRequestMode: number) => rateRequestMode === quoteOptionsResponse.quoteOption?.rateRequestMode)) {
            continue;
          }

          // Find cheapest and fastest per raterequestmode
          let currentQuoteOptionsMeta = setupQuoteOptionsMetas[quoteOptionsResponse.quoteOption?.rateRequestMode];

          if (!currentQuoteOptionsMeta) {
            currentQuoteOptionsMeta = setupQuoteOptionsMetas[quoteOptionsResponse.quoteOption?.rateRequestMode] = {
              cheapest: [],
              fastest: []
            } as {
              cheapest?: Array<QuoteOptionResponse>
              fastest?: Array<QuoteOptionResponse>
            };
          }

          if (quoteOptionsResponse.rate !== null && quoteOptionsResponse.accepted) {
            if (!currentQuoteOptionsMeta.cheapest[0]
                || currentQuoteOptionsMeta.cheapest.some((otherQuoteOptionResponse: QuoteOptionResponse) => otherQuoteOptionResponse.rate! > quoteOptionsResponse.rate!)) {
              currentQuoteOptionsMeta.cheapest = [ quoteOptionsResponse ];
            }

            if (quoteOptionsResponse.rate === currentQuoteOptionsMeta.cheapest[0].rate) {
              currentQuoteOptionsMeta.cheapest.push(quoteOptionsResponse);
            }
          }

          if (quoteOptionsResponse.duration !== null && quoteOptionsResponse.accepted) {
            if (!currentQuoteOptionsMeta.fastest[0]
                || currentQuoteOptionsMeta.fastest.some((otherQuoteOptionResponse: QuoteOptionResponse) => otherQuoteOptionResponse.duration! > quoteOptionsResponse.duration!)) {
              currentQuoteOptionsMeta.fastest = [ quoteOptionsResponse ];
            }

            if (quoteOptionsResponse.duration === currentQuoteOptionsMeta.fastest[0].duration) {
              currentQuoteOptionsMeta.fastest.push(quoteOptionsResponse);
            }
          }

          foundQuoteOptionsResponses.push(quoteOptionsResponse);
        }
      };

      setAlternativeQuoteOptionsResponses((prevState) => {
        return prevState.concat(foundQuoteOptionsResponses);
      });

      totalLoadedAlternativeSuppliers++;

      if (matchingSupplierIndex < matchingSuppliers.length - 1) {
        matchingSupplierIndex++;

        await getSupplierQuotes(matchingSuppliers, matchingSupplierIndex, totalLoadedAlternativeSuppliers, filter, setupQuoteOptionsMetas);
      } else {
        setQuoteOptionsMetas(setupQuoteOptionsMetas);
        setLoadingAlternatives(false);
      }

      resolve(true);
    }
  );

  const findMatchingSuppliers = useCallback(async () => {
    let totalLoadedAlternativeSuppliers = 0;

    setRequested(true);
    setLoading(true);

    const tempMatchingSuppliers = context.suppliers.filter((supplier: Supplier) => {
      const supplierCountry = context.countryCodes[supplier.car?.CompanyAddress.GENLAND[0]].name;
      let originCountry = rateRequest.origin?.country;
      let destinationCountry = rateRequest.destination?.country;

      if (supplier.status?.inactive) {
        return false;
      }

      // Check if the supplier's origin and destination coverage lanes match the rate request origin and destination
      if (supplier.coverageLanes) {
        if (supplier.coverageLanes.some(laneItem => (laneItem.lane.origin.country === originCountry && laneItem.lane.destination.country === destinationCountry))) {
          return true;
        }
      }

      let originMatched = false;
      let destinationMatched = false;

      // Check if the supplier's location matches the rate request origin or destination
      if (!supplier.ignoreSupplierLocation) {
        if (supplierCountry === originCountry) {
          originMatched = true;
        } else if (supplierCountry === destinationCountry) {
          destinationMatched = true;
        }

        if (!originMatched && !destinationMatched) {
          return false;
        }
      }

      // Check if the supplier's origin and destination coverage areas match the rate request origin and destination
      if (!originMatched && destinationMatched) {
        originMatched = supplier.coverage?.some((coverage: any) => {
          return (coverage.item.country === originCountry);
        });

        return originMatched;
      }

      if (!destinationMatched && originMatched) {
        destinationMatched = supplier.coverage?.some((coverage: any) => {
          return (coverage.item.country === destinationCountry);
        });

        return destinationMatched;
      }

      // Rate request origin and destination both are covered by supplier origin and destination coverage areas
      // Check if supplier needs to ignore its own "located in" condition
      if (supplier.ignoreSupplierLocation) {
        originMatched = supplier.coverage?.some((coverage: any) => {
          return (coverage.item.country === originCountry);
        });

        destinationMatched = supplier.coverage?.some((coverage: any) => {
          return (coverage.item.country === destinationCountry);
        });

        return originMatched && destinationMatched;
      }

      // Otherwise the supplier does not match
      return false;
    });

    setMatchingSuppliers(tempMatchingSuppliers);
    setLoadingAlternatives(false);
    setLoading(false);
    return;

    if (tempMatchingSuppliers.length === 0) {
      setLoadingAlternatives(false);
      return;
    }

    setLoadingAlternatives(true);

    const filter = {
      origin: rateRequest.origin,
      destination: rateRequest.destination,
      rateRequestModes: rateRequest.quoteOptions.map((quoteOption: QuoteOption) => quoteOption.rateRequestMode),
      volumes: rateRequest.quoteOptions.map((quoteOption: QuoteOption) => quoteOption.volume)
    };

    // Cancel loading the meta information for performance reasons
    // setLoadingAlternatives(false);
    // return;

    const setupQuoteOptionsMetas: StrictDict = {};

    // Find all quote options for each supplier
    // for (let tempMatchingSupplierIndex = 0; tempMatchingSupplierIndex < tempMatchingSuppliers.length; tempMatchingSupplierIndex++) {
    //   const matchingSupplier: Supplier = tempMatchingSuppliers[tempMatchingSupplierIndex];
    // };

    await getSupplierQuotes(tempMatchingSuppliers, 0, totalLoadedAlternativeSuppliers, filter, setupQuoteOptionsMetas);
  }, [ context.suppliers, context.countryCodes, rateRequest ]);

  // Find matching suppliers
  useEffect(() => {
    if (rateRequest) {
      setMailSubject(`SCW - No suppliers matched for: ${ rateRequest.origin?.country } → ${ rateRequest.destination?.country }`);
      setMailBody(`Rate request link: ${ document.location.href.replace('/select', '') }`);
    }

    if (!rateRequest.quoteOptions || requested) {
      return;
    }

    findMatchingSuppliers();
  }, [ rateRequest, requested, findMatchingSuppliers ]);

  // Search supplier by string
  const performSearch = useCallback((users: Array<Supplier>, searchValue: string): Array<Supplier> => {
    searchValue = searchValue.toLowerCase();
    const ignoreProps: StrictDict = [];

    if (users.length === 0) {
      return [];
    }

    function iterateSearchObject(subject: StrictDict): boolean {
      let keys:Array<any> = [];
      let keysLength = 0;

      if (subject instanceof Array) {
        keysLength = subject.length;
      } else {
        keys = Object.keys(subject);
        keysLength = keys.length;
      }

      for (let i = 0; i < keysLength; i++) {
        let currentProperty;

        if (keys.length === 0) {
          currentProperty = subject[i];
        } else {
          currentProperty = subject[keys[i]];
        }

        if (typeof currentProperty === "object" && currentProperty !== null) {
          if (!ignoreProps.includes(keys[i])) {
            const found = iterateSearchObject(currentProperty);

            if (found) {
              return true;
            }
          }
        } else if (currentProperty !== null) {
          const value = typeof currentProperty === "string"
                          ? currentProperty.toLowerCase()
                          : currentProperty.toString().toLowerCase();

          if (value.length > 0 && value.indexOf(searchValue) > -1) {
            return true;
          }
        }
      }

      return false;
    };

    let result:Array<Supplier> = users.slice();

    result = result.filter((item): boolean => {
      return iterateSearchObject(item);
    });

    return result;
  }, []);

  const dotPick = (dotReference: string, item: StrictDict) => {
    if (dotReference.indexOf(".") !== -1) {
      return dotReference.split(".").reduce((o: StrictDict, i: string) => o && o[i], item);
    }

    return item[dotReference];
  };

  // Sort by selected sort field and order
  const performSort = useCallback((suppliers: Array<Supplier>, sort: StrictDict): Array<Supplier> => {
    if (suppliers.length < 2) {
      return suppliers;
    }

    const suppliersFilteredSorted = suppliers.slice();

    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: "base"});

    suppliersFilteredSorted.sort((a: StrictDict, b: StrictDict) => {
      [a, b] = sort.order === "asc"
                ? [a, b]
                : [b, a];

      const valueA = dotPick(sort.field, a);
      const valueB = dotPick(sort.field, b);

      if (valueA && !valueB) {
        return -1;
      }

      return collator.compare(valueA, valueB);
    });

    return suppliersFilteredSorted;
  }, []);

  // Perform filter
  const performFilter = useCallback((quoteOptionsResponses: Array<QuoteOptionResponse>): Array<QuoteOptionResponse> => {
    return quoteOptionsResponses.filter((quoteOptionsResponse: QuoteOptionResponse) => {
      if (filters.selectedQuoteOptions.length > 0) {
        let showQuote = filters.selectedQuoteOptions.every((filterQuoteOption: QuoteOption) => {
          if (filterQuoteOption.rateRequestMode !== quoteOptionsResponse.quoteOption?.rateRequestMode) {
            return false;
          }

          const otherQuoteOptionVolume = quoteOptionsResponse.quoteOption?.volume as number;

          if (!otherQuoteOptionVolume) {
            return false;
          }

          // @ts-ignore
          const volume = parseInt(filterQuoteOption.volume);

          let minVolume = volume * .8;
          let maxVolume = volume * 1.2;

          if (minVolume > (volume - 2)) {
            minVolume = volume - 2;
          }

          if (maxVolume < (volume + 2)) {
            maxVolume = volume + 2;
          }

          if (otherQuoteOptionVolume < minVolume || otherQuoteOptionVolume > maxVolume) {
            return false;
          }

          return true;
        });

        return showQuote;
      }

      return true;
    });
  }, [ filters ]);

  // Perform pagination
  const performPagination = useCallback((suppliers: Array<Supplier>, currentPage: number): Array<Supplier> => {
    const startOffset = (currentPage - 1) * itemsPerPage;
    return suppliers.slice(startOffset, startOffset + itemsPerPage);
  }, []);

  const addSupplierMetas = useCallback((matchingSuppliers: Array<Supplier>) => {
    return matchingSuppliers.map((matchingSupplier: Supplier) => {
      const associatedQuoteOptionsResponses = alternativeQuoteOptionsResponses.filter((quoteOptionsResponse: QuoteOptionResponse) => {
        return quoteOptionsResponse.rateRequestResponse?.supplierUid === matchingSupplier.uid;
      });

      matchingSupplier.meta = {
        labels: []
      };

      rateRequest.quoteOptions?.forEach((quoteOption: QuoteOption) => {
        if (!matchingSupplier.meta) {
          return;
        }

        let currentQuoteOptionsMeta = quoteOptionsMetas[quoteOption.rateRequestMode];

        if (!currentQuoteOptionsMeta) {
          return;
        }

        // Label for current rate request mode already exists
        if (matchingSupplier.meta.labels.some((labelObj: StrictDict) => labelObj.rateRequestMode === quoteOption.rateRequestMode)) {
          return;
        }

        matchingSupplier.meta?.labels.push({
          active: associatedQuoteOptionsResponses.some((assocQuoteOptionsResponse: QuoteOptionResponse) => (currentQuoteOptionsMeta.cheapest.includes(assocQuoteOptionsResponse))),
          label: `Cheapest: ${labels.rateRequestModes[quoteOption.rateRequestMode].labelShort}`,
          rateRequestMode: quoteOption.rateRequestMode
        });

        matchingSupplier.meta?.labels.push({
          active: associatedQuoteOptionsResponses.some((assocQuoteOptionsResponse: QuoteOptionResponse) => (currentQuoteOptionsMeta.fastest.includes(assocQuoteOptionsResponse))),
          label: `Fastest: ${labels.rateRequestModes[quoteOption.rateRequestMode].labelShort}`,
          rateRequestMode: quoteOption.rateRequestMode
        });
      });

      // @ts-ignore
      matchingSupplier.meta.rate = associatedQuoteOptionsResponses.reduce((total, quoteOptionResponse) => total + parseInt(quoteOptionResponse.rate), 0);
      matchingSupplier.meta.duration = associatedQuoteOptionsResponses.reduce((total, quoteOptionResponse) => total + (quoteOptionResponse.duration || 0), 0);

      return matchingSupplier;
    });
  }, [ quoteOptionsMetas, alternativeQuoteOptionsResponses, labels.rateRequestModes, rateRequest.quoteOptions ]);

  const matchingSuppliersResults = useMemo(() => {
    let results = addSupplierMetas(matchingSuppliers);
    results = results.filter(supplier => !checkSelected(supplier));
    results = performSearch(results, searchValue);
    results = performSort(results, sortBy);

    return results;
  }, [ searchValue, sortBy, matchingSuppliers, performSort, performSearch, addSupplierMetas, checkSelected ]);

  useEffect(() => {
    const results = performPagination(matchingSuppliersResults, currentPage);

    setPagedMatchingSuppliersResults(results);
  }, [ matchingSuppliersResults, currentPage, performPagination ]);

  useEffect(() => {
    setFilteredQuoteOptionsResponses(performFilter(alternativeQuoteOptionsResponses));
  }, [ alternativeQuoteOptionsResponses, filters, performFilter ]);

  const selectOption = (selectedOption: QuoteOption) => {
    setFilters(prevState => {
      const nextState = Object.assign({}, prevState);

      const optionIndex = nextState.selectedQuoteOptions.findIndex((option: QuoteOption) => option === selectedOption);

      if (optionIndex === -1) {
        nextState.selectedQuoteOptions.push(selectedOption);
      } else {
        nextState.selectedQuoteOptions.splice(optionIndex, 1);
      }

      return nextState;
    });
  };

  return (
    <div className="detail">
      <div className="container">
        <div className="detail__top">
          <div className="detail__top-heading">
            <h3>Alternative suppliers ({ matchingSuppliersResults.length })</h3>
            <div className={`spinner spinner--inline ${!loadingAlternatives ? "is-hidden" : ""}`}>
              <IconSync className="spinner__element" />
            </div>
          </div>
          <div className="detail__top-actions action-bar">
            <Input name="search" value={searchValue} onChange={(event: ChangeEvent) => setSearchValue((event.target as HTMLInputElement).value)} placeholder="Search for suppliers" search />
            { props.rateRequest?.quoteOptions?.length > 1 &&
              <DropdownButton label="Quote options">
                <>
                { rateRequest?.quoteOptions?.map((option: QuoteOption) =>
                  <Checkbox
                    key={option.id}
                    value={option.id}
                    label={`${labels.rateRequestModes[option.rateRequestMode].labelShort} / ${option.volume}m³ / ${labels.transportModes[option.transportMode].label} / ${labels.packagingTypes[option.packagingType].label}`}
                    name="checkbox"
                    checked={ filters.selectedQuoteOptions.some((selectedOption: QuoteOption) => selectedOption === option) }
                    onChange={() => selectOption(option)}
                  />
                )}
                </>
              </DropdownButton>
            }
            <Select name="sort-by" value={sortBy} onChange={setSortBy} options={sortByOptions}  />
          </div>
        </div>

        { pagedMatchingSuppliersResults.length > 0 ? pagedMatchingSuppliersResults.map((matchingSupplier: Supplier) =>
          matchingSupplier &&
          <Card
            key={ matchingSupplier.uid }
            isSelected={ checkSelected(matchingSupplier) }
            requestSupplier={ requestSupplier }
            supplier={ matchingSupplier }
            rateRequest={ props.rateRequest }
            alternativeQuoteOptionsResponses={ filteredQuoteOptionsResponses }
            filters={ filters }
            // isVisible={ !checkSelected(matchingSupplier) }
          />
        ) : (
          !loading &&
            <>
              <p>No suppliers found. Please reach out to <a href={ `mailto:Gert van Engelenburg <g.vanengelenburg@gosselin-moving.com>?subject=${ mailSubject }&body=${ mailBody }`}>Gert van Engelenburg</a> to get a solution for this lane.</p>
            </>
        ) }
      </div>

      <Pagination
        dataLength={ matchingSuppliersResults.length }
        itemsPerPage={ itemsPerPage }
        currentPage={ currentPage }
        setCurrentPage={ setCurrentPage }
      />

      <RequestModal visible={ requestRatesModalVisible } updateEmail={ updateEmail } rateRequest={ props.rateRequest } currentlySelectedSupplier={ currentlySelectedSupplier } pending={ props.pending } confirmAction={ requestRates } cancelAction={ () => { setRequestRatesModalVisible(false); updateSupplierSelection() } } />
    </div>
  )
}
