import React from "react";

export default function ErrorPageNotFound() {

  return (
    <div className="container">
      <h3 className="heading heading--secondary">This page doesn't exist (yet), go back or try again later.</h3>
    </div>
  );
}
