import React from "react";
import changelog from "../../assets/data/changelog";
import { Header, Navigation } from "../../components/Layout";

const navigationItems = [
  {
    to: "/rate-requests",
    label: "Rate requests"
  }, {
    to: "/suppliers",
    label: "Suppliers"
  }, {
    to: "/users",
    label: "Users"
  }
];

export default function ChangelogOverview() {
  return (
    <>
      <Header
        title="Changelog"
        leftContent={ <Navigation navItems={navigationItems} /> }
        narrow={ true }
        aside={
          <>

          </>
        }
        logo
        accountDropdown
      />
      <div className="overview">
        <div className="container container--narrow">
          { changelog.map((log, logIndex) => (
            <div className="card card--overview" key={ logIndex }>
              <h4 className="card__heading">{ log.date }</h4>
              <div className="card__row">
                { log.summary }
              </div>
            </div>
          )) }
        </div>
        <br />
      </div>
    </>
  )
}
