import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import { NavLink, useLocation } from "react-router-dom";

import { ReactComponent as IconChevronLeft } from "../../assets/images/icons/chevron-left.svg";
import { ReactComponent as IconChevronRight } from "../../assets/images/icons/chevron-right.svg";

type Props = {
  dataLength: number;
  itemsPerPage: number;
  currentPage: number;
  visibleRange?: number;
  setCurrentPage: (currentPage: number) => void
};

export default function Pagination(props: Props) {
  const { dataLength, itemsPerPage, currentPage, setCurrentPage } = props;

  const location = useLocation();
  const [ totalPages, setTotalPages ] = useState(1);
  const [ scrollAtBottom, setScrollAtBottom ] = useState(false);
  const visibleRange = props.visibleRange || 2;
  const [ prevDataLength, setPrevDataLength ] = useState(dataLength);

  // TODO: Maybe get the location for creating relative URLs for pages?
  // const currentLocation = useLocation();

  useEffect(() => {
    const totalPages = Math.ceil(dataLength / itemsPerPage) || 1;

    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }

    setTotalPages(totalPages);
  }, [ dataLength, itemsPerPage, currentPage, setCurrentPage ]);

  // Run once
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, { passive: true });

    const savedPage = localStorage.getItem(`PAGE_${ location.pathname }`) || '1';

    setCurrentPage(parseInt(savedPage));

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    }
  }, [ location.pathname, setCurrentPage ]);

  useEffect(() => {
    if (prevDataLength !== dataLength) {
      setCurrentPage(1);
      localStorage.setItem(`PAGE_${ location.pathname }`, '1');
      setPrevDataLength(dataLength);
    }
    
  }, [ dataLength, location.pathname, prevDataLength, setCurrentPage ])

  const scrollHandler = () => {
    setScrollAtBottom(window.scrollY - 32 > document.body.scrollHeight - window.innerHeight);
  };

  const updatePage = (position: number) => {
    props.setCurrentPage(position);

    localStorage.setItem(`PAGE_${ location.pathname }`, position.toString());

    setTimeout(() => {
      scrollHandler();
    }, 60);
  }

  const createPages = () => {
    let startPosition = props.currentPage - visibleRange;
    let endPosition = props.currentPage + visibleRange;

    if (startPosition < 3) {
      endPosition += (3 - startPosition);
    }

    if (endPosition > totalPages - 2) {
      startPosition -= (endPosition - (totalPages - 2));
    }

    startPosition = (startPosition < 2) ? 2 : startPosition;
    endPosition = (endPosition > totalPages - 1) ? totalPages - 1 : endPosition;

    let totalVisible = endPosition - startPosition;

    let pages = [];

    // Arrow left
    pages.push(
      <button type="button" className={ `pagination__link ${1 === props.currentPage ? `is-disabled` : ``}` } key={ `pagination-chevron-left` } onClick={ () => updatePage(props.currentPage - 1) }>
        <IconChevronLeft />
      </button>
    );

    // Always render first and last page
    pages.push(
      <button type="button"  className={ `pagination__link ${1 === props.currentPage ? `is-active` : ``}` } key={ `pagination-${1}` } onClick={ () => updatePage(1) }>1</button>
    );

    let pageNumber = startPosition;
    let pageCount = 0;

    while (pageNumber <= endPosition) {
      const currentNumber = pageNumber;

      if (pageCount === 0) {
        if (currentNumber > 3) {
          // Render ...
          pages.push(
            <button type="button"  className="pagination__link" key="pagination-skip-low" onClick={ () => updatePage(Math.round((startPosition) / 2)) }>...</button>
          );
        } else if (currentNumber === 3) {
          // Render 2nd page
          pages.push(
            <button type="button"  className="pagination__link" key="pagination-2" onClick={ () => updatePage(2) }>2</button>
          );
        }
      }

      // Always render the default pages
      pages.push(
        <button type="button"  className={ `pagination__link ${currentNumber === props.currentPage ? `is-active` : ``}` } key={ `pagination-${currentNumber}` } onClick={ () => updatePage(currentNumber) }>{ currentNumber }</button>
      );

      if (pageCount === totalVisible) {
        if (currentNumber < totalPages - 2) {
          // Render ...
          pages.push(
            <button type="button"  className="pagination__link" key="pagination-skip-high" onClick={ () => updatePage(Math.round((endPosition + totalPages) / 2)) }>...</button>
          );
        } else if (currentNumber === totalPages - 2) {
          // Render page before last
          pages.push(
            <button type="button"  className="pagination__link" key={ `pagination${totalPages - 1}` } onClick={ () => updatePage(totalPages - 1) }>{ totalPages - 1 }</button>
          );
        }
      }

      pageNumber++;

      pageCount++;
    }

    // Render last page
    if (totalPages !== 1) {
      pages.push(
        <button type="button"  className={ `pagination__link ${totalPages === props.currentPage ? `is-active` : ``}` } key={ `pagination-${totalPages}` } onClick={ () => updatePage(totalPages) }>{ totalPages }</button>
      );
    }

    // Arrow right
    pages.push(
      <button type="button"  className={ `pagination__link ${totalPages === props.currentPage ? `is-disabled` : ``}` } key={ `pagination-chevron-right` } onClick={ () => updatePage(props.currentPage + 1) }>
        <IconChevronRight />
      </button>
    );

    return pages;
  }

  return (
    <>
      { totalPages > 1 &&
        <>
          <div className="pagination-spacer"></div>
          <div className={ `pagination-overlay ${ scrollAtBottom ? 'pagination-overlay--at-bottom' : '' }` }></div>
          <div className={ `pagination ${ scrollAtBottom ? 'pagination--at-bottom' : '' }` }>
            { createPages() }
          </div>
        </>
      }
    </>
  );
}
