import React, { ReactElement } from "react";

type Props = {
  heading: string;
  content: any;
  children: ReactElement;
};

export default function FormAccentuateBox(props: Props) {
  return (
    <div className="accentuate-box">
      <label className="accentuate-box__left">
        <span className="accentuate-box__heading">{ props.heading }</span>
        <div className="accentuate-box__content">{ props.content }</div>
      </label>
      <div className="accentuate-box__right">
        { props.children }
      </div>
    </div>
  );
}
