import React, { useCallback, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// @ts-ignore
import ReactNotification, { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { GlobalDataProvider } from './context/globaldata';

import "./assets/styles/application.scss";

import Root from "./screens/Root";
import AuthRoute from "./screens/Auth/Route";

import Login from "./screens/Auth/Login";
import Reset from "./screens/Auth/Reset";
// import Signup from "./screens/Auth/Signup";
import VerifyEmail from "./screens/Auth/VerifyEmail";
import ResponseIndex from "./screens/Response/Index";

import ProtectedRoute from "./components/Routing/ProtectedRoute";
import ChangelogModal from "./components/ChangelogModal";

window.addEventListener("offline", () => {
  store.addNotification({
    id: "offline-notification",
    message: "Your are currently offline, the data shown isn't up to date",
    type: "danger",
    insert: "top",
    container: "top-center",
    animationIn: ["animated", "slideDown"],
    animationOut: ["animated", "slideUp"],
    dismiss: {
      click: false,
      duration: 0
    }
  });
});

window.addEventListener("online", () => {
  store.removeNotification("offline-notification");
  store.addNotification({
    message: "You are back online! All data is up-to-date",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animated", "slideDown"],
    animationOut: ["animated", "slideUp"],
    dismiss: {
      duration: 5000
    }
  });
});

export default function App() {
  console.log('Re-rendering App.tsx');

  return (
    <Router>
      <GlobalDataProvider>
        <ReactNotification />
        <ChangelogModal />
        <Switch>
          <Route exact path="/login/">
            <AuthRoute>
              <Login />
            </AuthRoute>
          </Route>
          <Route exact path="/reset-password/">
            <AuthRoute>
              <Reset />
            </AuthRoute>
          </Route>
          <Route exact path="/verify-email/">
            <AuthRoute>
              <VerifyEmail />
            </AuthRoute>
          </Route>
          <Route exact path="/response/:uuid">
            <ResponseIndex />
          </Route>
          <ProtectedRoute
            authenticationPath="/login"
            restrictedPath="/"
            component={Root}
          />
        </Switch>
      </GlobalDataProvider>
    </Router>
  );
}
