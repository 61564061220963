import React from "react";

export default function Footer() {
  return (
    <footer>
      <p>Gosselin, Belcrownlaan 23, 2100 Antwerpen, Belgium</p>
      <p>You received this request because you appear in our supplier database.<br/>By submitting your quote you accept our <a href="https://gosselingroup.eu/en/general-conditions-moving" target="_blank" rel="noopener noreferrer">terms &amp; conditions</a> and <a href="https://gosselin-moving.com/uploads/media/default/0001/02/Gosselin_PartnerSLA.pdf" target="_blank" rel="noopener noreferrer">SLA</a>.</p>
    </footer>
  );
}
