import React from "react";

type Props = {
  rating: number;
  ratingCount?: number;
  prefix?: string;
};

export default function Rating(props: Props) {
  const maxStars = 5; // Might be nice if we need to change this later
  const starWidth = 24; // Might be nice if we need to change this later

  return (
    <div className="rating">
      { props.prefix &&
        <span>{ props.prefix }</span>
      }
      <div className="rating__stars" style={{width: maxStars * starWidth + "px"}}>
        <div className="rating__stars-inner" style={{width: props.rating / maxStars * 100 + "%"}}></div>
      </div>
      { props.ratingCount !== undefined &&
        <div className="rating__number">({props.ratingCount})</div>
      }
    </div>
  );
}
