import React, { useContext } from "react";

import GlobalData from '../../context/globaldata';
import Chip from "../../components/Chip/Chip";
import Button from "../../components/Button/Button";

export default function Result(props: any) {
  const context = useContext(GlobalData);

  return (
    <li className={`result ${props.result.disabled ? "is-inactive" : ""}`}>
      <div className="result__anchor">
        <div className="result__content">
          <div className="result__heading result__heading--alt">
            { props.result.displayName }
            { props.result.admin && <Chip status={5} label="Admin" /> }
            { !props.result.verified && <Chip status={2} label="Invited" /> }
          </div>
          <div className="result__subheading result__subheading--alt">
            { props.result.email }
          </div>
        </div>
        <div className="result__aside">
          { context.currentUser.custom?.admin &&
            <Button variant="ghost" onClick={() => props.showEditModal(props.result.uid) }>
              Edit user
            </Button>
          }
        </div>
      </div>
    </li>
  )
}
