import React, { useState, useContext } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";

import GlobalData from '../../context/globaldata';
import { Input } from "../../components/Form";
import Button from "../../components/Button/Button";

import { ReactComponent as Logo } from "../../assets/images/logo-auth.svg";
import { ReactComponent as IconDanger } from "../../assets/images/icons/danger.svg";
import { ReactComponent as Throbber } from "../../assets/images/throbber.svg";

export default function AuthLogin() {
  const location = useLocation();
  const context = useContext(GlobalData);
  const history = useHistory();
  const search = location.search;
  const params = new URLSearchParams(search);
  const paramEmail = params.get("email");
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ formFields, setFormFields ] = useState({
    email: paramEmail || "",
    password: ""
  });
  const [ errors, setErrors ] = useState("");

  const updateFormFields = (field: string, data: string) => {
    setFormFields({
      ...formFields,
      [field]: data
    });
  };

  const authenticateWithEmail = (event: any) => {
    event.preventDefault();
    setIsLoading(true);
    setErrors("");

    if (formFields.email && formFields.password) {
      context.firebaseApp.auth()
        .signInWithEmailAndPassword(formFields.email, formFields.password)
        .then((user: any) => {
          if (user.user.emailVerified) {

            // Set user account verified property on sign in
            context.firestore.collection("users").doc(user.user.uid).get()
            .then(async (userDoc: StrictDict) => {
              const userData = userDoc.data();

              if (userData && !userData.verified) {
                userDoc.ref.update({ verified: true });
              }

              if (userData?.disabled) {
                setErrors("User is disabled. Please contact and admin to enable it again.");
                setIsLoading(false);
              }

              if (!userData?.disabled) {
                localStorage.setItem("expectSignIn", "true");
                history.replace("/");
              }
            });

          } else {
            setIsLoading(false);
            setErrors("You need to verify your account first.");
          }
        })
        .catch((error: any) => {
          console.log(error);
          setIsLoading(false);
          setErrors(error.message);
      });
    } else {
      setErrors("Email and password fields are required.");
    }
  };

  return (
    <>
      <Logo className="auth__logo" />
      <h1 className="auth__heading">Sign in to the portal</h1>
      <form className="auth__form" onSubmit={authenticateWithEmail}>
        <Input value={formFields.email} type="email" placeholder="Email address" name="email" label="" onChange={(event: any) => updateFormFields("email", event.target.value)} required />
        <Input value={formFields.password} type="password" placeholder="Password" name="password" label="" onChange={(event: any) => updateFormFields("password", event.target.value)} aside={<Link className="input__aside" to="/reset-password">Forgot password?</Link>} required />
        { errors &&
          <div className="auth__errors">
            <IconDanger className="icon" />
            { errors }
          </div>
        }
        <Button type="submit" disabled={ isLoading }>
          { isLoading
            ? <>
                <Throbber className="button__icon" />
                Signing in...
              </>
            : 'Sign in'
          }
        </Button>
        <Link className="auth__sublink" to="/reset-password/">Forgot your password?</Link>
      </form>
    </>
  );
}
